import _ from "lodash";
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import * as socket from "../socket";
import * as socket_pushacc from "../socket_pushacc";
import { CommonWidgetUtils, Random } from "../utils";

// import * as socket_bond from "../socket_bond";
class SocketManager extends Component {

    state = {
    };

    accountCallerId = Random.randomComponentId();
    accountQuickOrderCallerId = Random.randomComponentId();
    accountScreenModalCallerId = Random.randomComponentId();

    marketCallerId = Random.randomComponentId();
    custCallerId = Random.randomComponentId(); //custid của kh/ của môi giới
    accountMessageCallerId = Random.randomComponentId();
    messageAnalyticCallerId = Random.randomComponentId();
    accountScreenModalallerId = Random.randomComponentId();

    connect_socket_pushacc = (nextCustid) => {
        let { custid, accounts, lang, accountsQuickOrder, accountsScreenModal } = this.props;
        custid = nextCustid || custid
        if (custid !== "GUEST") {
            socket_pushacc.connect();
            this.registerAllCustTopics(custid);
            this.registerAllAccountTopics(accounts);
            this.registerAllAccountQuickOrderTopics(accountsQuickOrder);
            this.registerAllAccountScreenModalTopics(accountsScreenModal);
            socket_pushacc.registerAnalyticMessageTopics(lang, custid, this.messageAnalyticCallerId);
        }
    }

    connect_socket_market = () => {
        socket.connect();
        // if (this.props.location.pathname.indexOf(path.PRICEBOARD) == -1) {
        //     //console.log('subscribe index first tab /Trade!')
        //     socket.registerMarketInforTopics(this.marketCallerId);
        // }else{
        //     socket.unregisterAllMarketInfoTopics(this.marketCallerId);
        // }
    }

    componentDidMount() {
        //socket thị trường
        this.connect_socket_market()
        //socket tài khoản
        this.connect_socket_pushacc()
        //socket bond
        // socket_bond.connect();
    };
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { accounts, lang, custid, socketMarketStatus, accountsQuickOrder, accountsScreenModal, currentLayoutPageActive, isActiveTabBrowser } = this.props;
        const { accounts: nextAccounts,
            custid: nextCustid,
            accountsQuickOrder: nextAccountsQuickOrder,
            accountsScreenModal: nextAccountsScreenModal,
            currentLayoutPageActive: nextCurrentLayoutPageActive,
            isActiveTabBrowser: nextIsActiveTabBrowser
        } = nextProps;
        
        if (!(_.isEqual(accounts, nextAccounts))) {
            this.registerAllAccountTopics(nextAccounts);
        }
        
        if (!(_.isEqual(accountsQuickOrder, nextAccountsQuickOrder))) {
            this.registerAllAccountQuickOrderTopics(nextAccountsQuickOrder);
        }
        
        if (!(_.isEqual(accountsScreenModal, nextAccountsScreenModal))) {
            this.registerAllAccountScreenModalTopics(nextAccountsScreenModal);
        }
        
        if (custid !== nextCustid) {
            if (custid == "GUEST" && nextCustid !== "GUEST") {
                this.connect_socket_pushacc(nextCustid)
            } else {
                this.registerAllCustTopics(nextCustid);
            }
        };
        if (lang !== nextProps.lang) {
            socket_pushacc.unregisterAnalyticMessage(lang, custid, this.messageAnalyticCallerId);
            socket_pushacc.registerAnalyticMessageTopics(nextProps.lang, custid, this.messageAnalyticCallerId);
        }
        
        if (nextIsActiveTabBrowser && isActiveTabBrowser !== nextIsActiveTabBrowser) {
            // call reload Api by socket
            socket.reloadData("IsActiveTabBrowser")
        }
        // Tab tài khoản có subscribe instrument cho danh mục đầu tư ==> connect socket để nhận giá (unsub những topic không cần thiết vd: Index)
        // if (this.props.location != nextProps.location && socketMarketStatus && socketMarketStatus !== SocketStatus.DISCONNECTED) {
        //     if (nextProps.location.pathname.indexOf(path.TRADE) !== -1) {
        //         //console.log('socket subscribe index tab /Trade!', nextProps.location.pathname)
        //         socket.registerMarketInforTopics(this.marketCallerId);
        //     }
        //     else {
        //         //console.log('socket unsubscribe index when change tab NOT /Trade!', nextProps.location.pathname)
        //         socket.unregisterCallerId(this.marketCallerId);
        //     }
        // }
    };

    componentWillUnmount() {
        socket.unregisterCallerId(this.marketCallerId);
        socket.disconnect();
        //
        socket_pushacc.unregisterCallerId(this.accountCallerId);
        socket_pushacc.unregisterCallerId(this.accountQuickOrderCallerId);
        socket_pushacc.unregisterCallerId(this.accountScreenModalallerId);
        socket_pushacc.unregisterCallerId(this.custCallerId);
        socket_pushacc.unregisterCallerId(this.accountMessageCallerId);
        socket_pushacc.unregisterCallerId(this.messageAnalyticCallerId);
        socket_pushacc.disconnect();

        // socket_bond.disconnect();
    }

    registerAllAccountTopics = (accounts) => {
        if (accounts) {
            var accountIds = [];
            accounts.forEach(account => {
                accountIds.push(account.id)
            });
            socket_pushacc.registerAllAccountTopics(accountIds, this.accountCallerId);
        };
    };

    registerAllAccountQuickOrderTopics = (accounts) => {
        if (accounts) {
            var accountIds = [];
            accounts.forEach(account => {
                accountIds.push(account.id)
            });
            socket_pushacc.registerAllAccountTopics(accountIds, this.accountQuickOrderCallerId);
        };
    };


    registerAllAccountScreenModalTopics = (accounts) => {
        if (accounts) {
            var accountIds = [];
            accounts.forEach(account => {
                accountIds.push(account.id)
            });
            socket_pushacc.registerAllAccountTopics(accountIds, this.accountScreenModalCallerId);
        };
    };

    registerAllCustTopics = (custid) => {
        if (custid) {
            socket_pushacc.registerCustomerTopics(custid, this.custCallerId);
            socket_pushacc.registerAccountMessageTopics(custid, this.accountMessageCallerId);
        };
    };

    render() {
        return (<Fragment />);
    }

}

const mapStateToProps = state => {

    let _placeQuickOrder = state.orderNew.placeQuickOrder["C&B"]
    let _accountsQuickOrder = _placeQuickOrder && _placeQuickOrder["accountQuickOrderInfo"]["accounts"]
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accounts = _layoutPage && _layoutPage["accountInfo"]["accounts"]


    let _ScreenModal = state.layout.listIsOpenModal["C&B"]["ScreenModal"]
    let _accountsScreenModal = _ScreenModal && _ScreenModal["accountInfo"]["accounts"]

    return {
        custid: state.user.userInfo.custid,
        currentAccount: state.account.currentAccount,
        currentLayoutPageActive: _currentLayoutPageActive,
        accounts: _accounts,
        accountsScreenModal: _accountsScreenModal,
        accountsQuickOrder: _accountsQuickOrder,
        userInfo: state.user.userInfo,
        lang: state.app.language,
        role: state.user.userInfo.role,
        currentCustomer: state.customer.currentCustomer,
        location: state.router.location,
        socketMarketStatus: state.app.socketMarketStatus,
        isActiveTabBrowser: state.app.isActiveTabBrowser,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SocketManager);