import React, { Component, Fragment } from 'react'
import _ from 'lodash';
import { connect } from 'react-redux';

import * as actions from "../../store/actions";
import { CommonUtils, ORDER_TYPE_USE, Role } from "../../utils";

import './SelectorAccountId.scss'
import SelectOptionCustom from 'components/SelectOptionCustom/SelectOptionCustom';
import { emitter } from 'utils/EventEmitter';
import { ReactComponent as DropSelectIcon_Dark } from '../../assets/icons/header/DropSelectIcon_Dark.svg'
import { ReactComponent as DropSelectIcon_Light } from '../../assets/icons/header/DropSelectIcon_Light.svg'
const globalVar = window._env_
class SelectorAccountId extends Component {

    initialState = {
        isSelectOpen: false
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.accountSelectRef = React.createRef();
        this.listenToTheEmitter();
    };

    callback = data => {
        this.accountSelectRef && this.accountSelectRef.current && this.accountSelectRef.current.focus();
    }

    listenToTheEmitter() {
        emitter.on('onTabSymbolTrade_Customer', this.callback);
    }

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };


    componentDidMount() {
        this.mounted = true
        if (!this.isBroker()) {
            this.setSavedAccount();
        }
    };

    componentWillUnmount() {
        emitter.removeListener('onTabSymbolTrade_Customer', this.callback);
        this.mounted = false
    }

    setSavedAccount = () => {
        if (globalVar.switch.enableSaveLoginAccount) {
            const { accountInfo, custodycd } = this.props;
            const { currentAccountId } = { ...accountInfo }
            let currentUsersToken = JSON.parse(localStorage.getItem('token-users'));
            let savedAccountId = currentUsersToken[`${custodycd}`] && currentUsersToken[`${custodycd}`]['savedAccountInfo'] ? currentUsersToken[`${custodycd}`]['savedAccountInfo'] : '';
            if ((!savedAccountId || savedAccountId !== currentAccountId) && currentUsersToken[`${custodycd}`]) {
                currentUsersToken[`${custodycd}`]['savedAccountInfo'] = currentAccountId;
                localStorage.setItem(`token-users`, JSON.stringify(currentUsersToken));
            }
        }
    }

    setSavedAccountInfo(accountId) {
        if (globalVar.switch.enableSaveLoginAccount) {
            const { custodycd } = this.props;
            let currentUsersToken = JSON.parse(localStorage.getItem('token-users'));
            if (currentUsersToken[`${custodycd}`]) {
                currentUsersToken[`${custodycd}`]['savedAccountInfo'] = accountId;
            }
            localStorage.setItem(`token-users`, JSON.stringify(currentUsersToken));
        }
    }


    setSelectedAccount = (value) => {
        const { accountInfo, setAccountIdScreen, selectorAcountScreen } = this.props;
        setAccountIdScreen(value, selectorAcountScreen)
        if (!this.isBroker()) {
            this.setSavedAccountInfo(value);
        }
    };

    setSelectedAccountHeader = (value, title) => {
        const { accountInfo, setAccountIdScreen, selectorAcountScreen } = this.props;
        let accountId = value
        setAccountIdScreen(accountId, selectorAcountScreen)
        if (!this.isBroker()) {
            this.setSavedAccountInfo(accountId);
        }
    };

    componentDidUpdate(prevProps) {
    }

    renderOption = () => {
        const { accountInfo, isHeader } = this.props
        const { accounts } = accountInfo
        let listAccounts = this.getListAccounts()
        let arr = []
        if (accountInfo) {
            arr = listAccounts && listAccounts.length && listAccounts.map((item, index) => {
                return {
                    title: `${item.cdcontent}`,
                    value: item.id
                }
            })
        }
        return arr || []
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    handleOpenAccountSelect = () => {
        const { isSelectOpen } = this.state;
        this._setState({ isSelectOpen: !isSelectOpen }, () => {
            this.accountSelectRef.current && this.accountSelectRef.current.setIsOpen(!isSelectOpen);
        })

    }

    getListAccounts = () => {
        const { accountInfo, isHeader, defaultTheme, editOnly } = this.props
        let { curCustomer, accounts, currentAccountId } = { ...accountInfo }
        if (CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_NM_ORDER || CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) {
            accounts = _.filter(accounts, (item, index) => item.typename.includes("TPRL"))
        } else {
            accounts = _.filter(accounts, (item, index) => !item.typename.includes("TPRL"))
        }
        return accounts
    }

    render() {
        const { custodycd, listCustomers, infoTextShow } = this.state;
        const { accountInfo, isHeader, defaultTheme, editOnly } = this.props
        const { curCustomer, accounts, currentAccountId } = { ...accountInfo }
        // const disableAccountSelector = accounts && accounts.length <= 1;
        //VIX: Cho tab kể cả khi chưa load dc tiểu khoản
        const disableAccountSelector = editOnly === true ? true : false;

        let classNameSelect = "txt---500-14-20 custom-form-select focus-item"
        if (isHeader) {
            classNameSelect = "txt---400-14-20 custom-form-select focus-item"
        }
        if (disableAccountSelector) {
            classNameSelect = classNameSelect + " disable-select"
        }
        let listAccounts = this.getListAccounts()
        return (
            <div className={isHeader ? "custom-form-group-header" : "custom-form-group-select accountId-select"}>
                {isHeader &&
                    (
                        <Fragment>
                            <SelectOptionCustom
                                ref={this.accountSelectRef}
                                options={this.renderOption()}
                                defaultValue={currentAccountId}
                                onChange={this.setSelectedAccountHeader}
                                classWrap="txt---400-14-20"
                            />
                            <div className='dropdown-icon' onClick={() => this.handleOpenAccountSelect()}> {defaultTheme === "dark" ? <DropSelectIcon_Light /> : <DropSelectIcon_Dark />}</div>
                        </Fragment>
                    )
                }
                {!isHeader && <select tabindex={0} ref={this.accountSelectRef} className={isHeader ? "txt---400-14-20 custom-form-select " : "txt---500-14-20 custom-form-select focus-item"} value={currentAccountId ? currentAccountId : ''} onChange={(e) => this.setSelectedAccount(e.target.value)} disabled={disableAccountSelector}>
                    {_.map(listAccounts, (account) => {
                        return (<option key={account.id} value={account.id} selected={currentAccountId && currentAccountId == account.id ? true : false}>{account.cdcontent}</option>);
                    })}
                </select>}
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    const { orderTypeUse } = ownProps;
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        role: state.user.userInfo.role,
        custodycd: state.user.userInfo.username,
        defaultTheme: defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAccountIdScreen: (accountId, typeScreen) => dispatch(actions.setAccountIdScreen(accountId, typeScreen)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectorAccountId);