import React, { Component } from 'react';
import moment from 'moment';

/** For valid format please see <a href="https://momentjs.com/docs/#/displaying/">Moment format options</a> */
const dateTimeFormat = 'DD/MM/YYYY HH:mm:ss';

//huy.quang: sử dụng với utc time trả từ postgres --> hiển thị utc từ postgres với timezone trên máy client
class FormattedUtcDateTime extends Component {

    render() {
        const { format, value, ...otherProps } = this.props;
        var dFormat = format ? format : dateTimeFormat;
        const formattedValue = value ? moment(value).format(dFormat) : null;
        return (
            <span {...otherProps}>{formattedValue}</span>
        );
    }
}

export default FormattedUtcDateTime;