import React, { Component } from 'react';

import { KeyCodeUtils } from "../../utils";

class NumberInput extends Component {

    static _toFixedDown(num, step, r) {
        if ("number" !== typeof num && num != null) {
            num = Number(num);
        }
        if (Number.isNaN(num) || !Number.isFinite(num)) {
            return '';
        }
        if (step === 0) {
            return num.toFixed(0);
        }
        const remainder = NumberInput.floatSafeRemainder(Math.abs(num), step);
        const numberSign = NumberInput.getNumberSign(num);
        const o = numberSign * remainder;
        let s = num;

        if (r === 0) {
            s = num - o;
        } else if (r === 3 || (r === 1 && Math.abs(remainder - step / 2) < (step / 10000))) {
            s = num - o + (0 === o ? 0 : numberSign * step);
        } else if (1 === r) {
            s = num - o + (remainder > step / 2 ? 1 : 0) * step;
        }

        return s.toFixed(NumberInput.decimalPlaces(step));
    }

    static toFixedDown(num, step) {
        return this._toFixedDown(num, step, 0);
    }

    static roundToNearest(num, step) {
        return this._toFixedDown(num, step, 1);
    }

    static decimalPlaces(num) {
        if ("string" !== typeof num) {
            num = String(num);
        }

        for (let i = num.length; i > 0; i--) {
            const char = num[i];
            if ("." === char || "," === char) {
                return num.length - i - 1;
            }
        }
        if (Number(num) < 1) {
            const matched = num.match(/e-(\d+)$/i);
            if (matched && matched[1]) {
                return Number(matched[1]);
            }
        }
        return 0;
    }

    static floatSafeRemainder(num, step) {
        if (step === 0) {
            return 0;
        }
        let numberOfSteps = num % step;
        const magicNumber = step / 10000;
        if (numberOfSteps < magicNumber || numberOfSteps + magicNumber > step) {
            numberOfSteps = 0
        }
        return numberOfSteps
    }

    static getNumberSign(number) {
        return number > 0 ? 1 : number < 0 ? -1 : 0
    }

    static noop() { }

    static defaultProps = {
        allowDecimal: true,
        allowNegative: true,
        allowZero: true,
        autoFocus: false,
        className: "",
        disabled: false,
        name: "",
        onBlur: NumberInput.noop,
        onFocus: NumberInput.noop,
        onKeyDown: NumberInput.noop,
        onValidityChange: NumberInput.noop(),
        placeholder: "",
        required: false,
        selectOnFocus: true,
        step: 1,
        size: null,
        style: { },
        min: -Infinity,
        max: Infinity
    };

    state = {
        isValid: true,
        wasValid: true
    };

    input = null;

    ref = element => {
        this.input = element;
    };

    maybeSetValue = (value, props) => {
        if (!props) {
            props = this.props;
        }

        if (this.input) {
            const step = props.step;
            const currentValue = this.input.value;
            const numberValue = Number(value);
            const correctedValue = Number.isFinite(numberValue) ? NumberInput.toFixedDown(value, step) : "";
            const isChanged = numberValue !== Number(currentValue);
            const isCorrected = numberValue !== Number(correctedValue);
            const isDecimalPlaceChanged = !isChanged && (correctedValue.split(/[.,]/)[1] || "").length < (currentValue.split(/[.,]/)[1] || "").length;

            if ((isChanged || isCorrected || isDecimalPlaceChanged) && this.input) {
                this.input.value = correctedValue;
            }

            this.validate(correctedValue, props, true);
        }
    };

    validate = (value, props, updateState) => {
        const numberValue = parseFloat(value);
        const input = this.input;

        let result = true;

        const valueInvalid = !value.length || value.split(".").length > 2
            || (!props.allowZero && numberValue === 0)
            || (!props.allowDecimal && (numberValue !== parseInt(value, 10) || NumberInput.floatSafeRemainder(numberValue, props.step) !== 0));

        const inputElementInvalid = input && input.validity && !input.validity.valid && !input.validity.stepMismatch;

        if (valueInvalid || inputElementInvalid) {
            result = false;
        }

        if (updateState) {
            if (result !== this.state.isValid && props.onValidityChange) {
                props.onValidityChange(result);
            }
            this._setState({
                isValid: result,
                wasValid: this.state.isValid
            });
        }

        return result;
    };

    handleValueChange = (event) => {
        const correctedValue = NumberInput.toFixedDown(parseFloat(event.target.value || "0"), this.props.step);
        const correctedValueNumber = Number(correctedValue);
        const isValid = this.validate(correctedValue, this.props, false);

        if (this.props.value !== correctedValueNumber && isValid) {
            this.props.onChange(correctedValueNumber)
        } else {
            if (Number.isFinite(correctedValueNumber)) {
                this.maybeSetValue(correctedValueNumber, this.props)
            }
        }
    };

    handleKeyDown = (event) => {
        this.props.onKeyDown(event);

        const key = event.which || event.keyCode;
        const numberString = "string" === typeof event.target.value ? event.target.value : "0";
        const isNavigationKey = KeyCodeUtils.isNavigation(key);
        const isDecimalKey = KeyCodeUtils.isDecimal(key);
        const isNumericKey = KeyCodeUtils.isNumeric(key);
        const isDashKey = KeyCodeUtils.isDash(key);
        const isNotContainDecimal = !/[.,]/.test(numberString);

        if (!isDecimalKey || (this.props.allowDecimal && isNotContainDecimal)) {
            if (!this.props.allowNegative && isDashKey) {
                event.preventDefault();
            } else if (isDashKey && Number(numberString) < 0) {
                event.preventDefault();
            } else if (key === KeyCodeUtils.UP || key === KeyCodeUtils.DOWN) {
                let willChangeValue = (this.props.inputStep || this.props.step) * (key === KeyCodeUtils.DOWN ? -1 : 1);
                if (event.ctrlKey) {
                    willChangeValue *= 10;
                }
                if (event.shiftKey) {
                    willChangeValue *= 100;
                }
                let changedValue = parseFloat(NumberInput.roundToNearest(parseFloat(numberString) + willChangeValue, this.props.step));
                if ("number" === typeof this.props.max) {
                    changedValue = Math.min(this.props.max, changedValue);
                }

                if ("number" === typeof this.props.min) {
                    changedValue = Math.max(this.props.min, changedValue);
                }

                if (!this.props.allowNegative) {
                    changedValue = Math.max(0, changedValue);
                }

                this.handleValueChange({
                    target: {
                        value: NumberInput.toFixedDown(changedValue, this.props.step)
                    }
                });

                event.preventDefault();
            } else {
                event.ctrlKey || event.metaKey || isNumericKey || isNavigationKey || isDecimalKey || isDashKey || event.preventDefault();
            }
        } else {
            event.preventDefault();
        }
    };

    selectOnFocus = (event) => {
        this.props.onFocus(event);
        if (this.props.selectOnFocus) {
            const target = event.target;
            try {
                target.select();
                target.setSelectionRange(0, target.value.length);
            } catch (e) {}
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        const correctedValue = NumberInput.toFixedDown(this.props.value, this.props.step);
        if (this.input) {
            this.input.value = correctedValue
        }
        this.validate(correctedValue, this.props, true);
    }

	componentWillUnmount() {
        this.mounted = false
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.props.value === nextProps.value || isNaN(nextProps.value) || this.maybeSetValue(nextProps.value, nextProps);
    }

    componentWillUpdate(nextProps) {
        if (this.props === nextProps && this.state.wasValid !== this.state.isValid) {
            this.UNSAFE_componentWillReceiveProps(nextProps);
        }
    }

    componentDidUpdate(prevProps) {
        const minMaxPropsChanged = !(prevProps.max === this.props.max && prevProps.min === this.props.min);
        if (minMaxPropsChanged && this.input) {
            this.validate(this.input.value || "", this.props, true);
        }
    }

    render() {
        const { name, placeholder, min, max, allowNegative, required, size, step, style, onBlur, autoFocus } = this.props;
        const minVal = "number" === typeof min ? min : allowNegative ? "" : 0;

        return (
            <input
                ref={this.ref}
                id={name}
                name={name}
                type="number"
                placeholder={placeholder}
                autoComplete="off"
                className={"".concat(this.props.className, " ").concat(this.state.isValid ? "valid" : "invalid")}
                min={minVal}
                max={max}
                required={required}
                size={size}
                step={step}
                style={style}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleValueChange}
                onFocus={this.selectOnFocus}
                onBlur={onBlur}
                autoFocus={autoFocus}
            />
        );
    }

}

export default NumberInput;