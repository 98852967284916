import React, { Component } from 'react';
import CashTransactionReport from 'containers/Account/Report/History/CashTransactionReportNew';
import ConsolidatedProfitLossReport from 'containers/Account/Report/History/ConsolidatedProfitLossReport';
import MatchingHistoryNew from 'containers/Account/Report/History/MatchingHistoryNew';
import OrderHistory from 'containers/Account/Report/History/OrderHistoryNew';
import RealizedProfitLoss from 'containers/Account/Report/History/RealizedProfitLoss';
import SecuritiesTransactionReport from 'containers/Account/Report/History/SecuritiesTransactionReportNew';
import CashInAdvance from 'containers/Account/Utilities/CashInAdvanceNew/CashInAdvance';
import CashTransfer from 'containers/Account/Utilities/CashTransferNew/CashTransfer';
import CatalogLookup from 'containers/Account/Utilities/CatalogLookup/CatalogLookup';
import IFlex from 'containers/Account/Utilities/IFlex/IFlex';
import StockTransfer from 'containers/Account/Utilities/StockTransferNew/StockTransfer';
import AssetTotal from 'containers/AccountManager/AssetTotal';
import DebtManagement from 'containers/AccountManager/DebtManagement';
import MarketAlert from 'containers/Settings/MarketAlert/MarketAlert';
import MarketIndexDetailPage from 'containers/marketindexes/MarketIndexDetail/MarketIndexDetailPage';
import MarketIndexScreen from 'containers/marketindexes/MarketIndexScreen';
import PersonalInfo from 'containers/Settings/PersonalInfo';
import ServiceRegister from 'containers/Settings/ServiceRegister';
//Extend screen
import SymbolDetailPage from 'containers/Trade/SymbolSelector/SymbolDetailPage';
import VoucherRegister from 'containers/Utilities/VoucherRegister';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Role, TransferType } from 'utils';

import { ReactComponent as CloseIconDark } from '../../assets/icons/header/CloseIcon_Dark.svg';
import { ReactComponent as CloseIconLight } from '../../assets/icons/header/CloseIcon_Light.svg';

import * as actions from "../../store/actions";

import RightOffRegister from './../../containers/Account/Utilities/RightOffRegister/RightOffRegister';
import RightOffPerform from './../../containers/Account/Utilities/RightOffRegister/RightOffPerform';

import HeaderCustom4ScreenModal from './HeaderCustom4ScreenModal'

import './ScreenModal.scss';
import CustomScrollbars from 'components/CustomScrollbars';
import Security from 'containers/Security/Security';
import ConditonOrderHistory from 'containers/Account/Report/History/ConditonOrderHistory';
import CustomerNeedAdditionalMargin from 'containers/BrokerReport/CustomerNeedAdditionalMargin/CustomerNeedAdditionalMargin';
import CustomerDebtMargin from 'containers/BrokerReport/CustomerDebtMargin/CustomerDebtMargin';
import CustomerDealtSell from 'containers/BrokerReport/CustomerDealtSell/CustomerDealtSell';
import AccountLookupBySymbol from 'containers/BrokerReport/AccountLookupBySymbol/AccountLookupBySymbol';
import TradingValueReport from 'containers/BrokerReport/TradingValueReport/TradingValueReport';
import SummaryOrderMatching from 'containers/AccountManager/SummaryOrderMatching';
import BrokerOrder from 'containers/Trade/BrokerOrderBook/BrokerOrder';
import ConfirmOrders from 'containers/Account/Utilities/ConfirmOrder/ConfirmOrders';
import Unhold from 'containers/Account/Utilities/Unhold@/Unhold';


class ScreenModal extends Component {

    closeScreenModal = () => {
        const { setIsOpenModalHaveData, isOpenScreenModal } = this.props;
        setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: !isOpenScreenModal,
            curScreenModalActive: "",
        })
        this.props.onChangeStatusMenuSideBar(0)
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentLayoutPageActive } = this.props;
        const { currentLayoutPageActive: preCurrentLayoutPageActive } = prevProps;

        //Bắt sự kiện tắt màn hình khi chuyển tab
        if (currentLayoutPageActive !== preCurrentLayoutPageActive) {
            // this.toggleOpenModal();
        };
    }

    renderUI() {
        const { curScreenModalActive } = this.props;
        const key = curScreenModalActive
        switch (key) {
            /*----------------Bảng giá----------------*/
            // Chi tiết mã chứng khoán
            case 'menu-sidebar.title-1.1':
                return <SymbolDetailPage />;
            case 'menu-sidebar.title-1.2':
                return <MarketIndexDetailPage />;
            case 'menu-sidebar.title-1.3':
                return <MarketIndexScreen />;
            /*----------------Giao dịch tiền----------------*/
            //  3.1 Chuyển tiền nội bộ
            case 'menu-sidebar.title-3.1':
                return <CashTransfer transType={TransferType.INTERNAL} />;
            //  3.1 Chuyển tiền ra ngoài
            case 'menu-sidebar.title-3.7':
                return <CashTransfer transType={TransferType.EXTERNAL} />;
            //  3.2 Nộp/Rút tiền tài khoản phái sinh
            // case 'menu-sidebar.title-3.2':
            //     return <TradingDerivative />;
            //  3.3 Nộp/Rút tiền CCP
            // case 'menu-sidebar.title-3.3':
            //     return <TradingCCP />;
            //  3.5 Ứng trước tiền bán
            case 'menu-sidebar.title-3.5':
                return <CashInAdvance />;
            //  3.6 iFlex
            case 'menu-sidebar.title-3.6':
                return <IFlex />;
            // 3.7 Giải tỏa tiền tài khoản corebank
            case 'menu-sidebar.title-3.8':
                return <Unhold />;

            /*----------------Giao dịch chứng khoán----------------*/
            //  4.1 Chuyển Chứng khoán
            case 'menu-sidebar.title-4.1':
                return <StockTransfer />
            //  8.1 Sao kê tiền
            case 'menu-sidebar.title-8.1':
                return <CashTransactionReport />;
            //  8.2 Sao kê CK
            case 'menu-sidebar.title-8.2':
                return <SecuritiesTransactionReport />;
            // 4.2 Đăng ký quyền mua
            case 'menu-sidebar.title-4.2':
                return <RightOffRegister />;
            // 4.3 Đăng ký quyền mua
            // case 'menu-sidebar.title-4.3':
            //     return <IPORegister />;
            // 4.4 Tra cứu thực hiện quyền
            case 'menu-sidebar.title-4.4':
                return <RightOffPerform />;

            /*----------------Tiện ích----------------*/
            // 5.1 Đăng ký  gói Voucher
            case 'menu-sidebar.title-5.1':
                return <VoucherRegister />;
            //  5.2 Xác nhận lệnh Online
            case 'menu-sidebar.title-5.2':
                return <ConfirmOrders />;
            case 'menu-sidebar.title-5.3':
                return <CatalogLookup />;
            case 'menu-sidebar.title-5.4':
                return <MarketAlert />;
            /*----------------Quản lý tài khoản----------------*/
            //  6.1 Tài sản
            case 'menu-sidebar.title-6.1':
                return <AssetTotal />;
            //  6.2 Dư nợ ký quỹ
            case 'menu-sidebar.title-6.2':
                return <DebtManagement />;
            //  6.3 Sao kê tiền
            case 'menu-sidebar.title-6.3':
                return <CashTransactionReport />;
            //  6.4 Sao kê chứng khoán
            case 'menu-sidebar.title-6.4':
                return <SecuritiesTransactionReport />;
            //  6.5 Lịch sử lệnh
            case 'menu-sidebar.title-6.5':
                return <OrderHistory />;
            //  6.10 Tổng hợp lệnh khớp
            case 'menu-sidebar.title-6.10':
                return <SummaryOrderMatching />;
            //  6.6 Lịch sử lệnh điều kiện
            case 'menu-sidebar.title-6.6':
                return <ConditonOrderHistory />;
            //  6.7 Lịch sử khớp lệnh
            case 'menu-sidebar.title-6.7':
                return <MatchingHistoryNew />;
            //  6.8 Lãi/Lỗ đã thực hiện
            case 'menu-sidebar.title-6.8':
                return <ConsolidatedProfitLossReport />;
            //  6.9 Báo cáo tổng hợp
            case 'menu-sidebar.title-6.9':
                return <RealizedProfitLoss />;

            /*-----------------Báo cáo môi giới---------------*/
            // //  7.1 Tra cứu tài khoản khách hàng
            // case 'menu-sidebar.title-7.1':
            //     return <CustomerAccountLookup />;
            // case 'menu-sidebar.title-7.1-extend':
            //     return <CustomerDetailAccount />;

            // //  7.2 Tra cứu tài sản khách hàng
            // case 'menu-sidebar.title-7.2':
            //     return <LookCustomerAssets />;
            // case 'menu-sidebar.title-7.2-extend':
            //     return <CustomerDetailAssets />;
            // //  7.3 Quản trị khách hàng
            // case 'menu-sidebar.title-7.3':
            //     return <CustomerManagement />;
            // //  7.4 Tra cứu doanh số
            // case 'menu-sidebar.title-7.4':
            //     return <LookupSales />;
            // //  7.5 Tra cứu trạng thái xác nhận lệnh
            // case 'menu-sidebar.title-7.5':
            //     return <LookUpConfirmStatus />;
            // //  7.6 Báo cáo tra cứu lệnh
            // case 'menu-sidebar.title-7.6':
            //     return <LookupCommand />;

            //  7.7 DSKH cần bổ sung ký quỹ
            case 'menu-sidebar.title-7.7':
                return <CustomerNeedAdditionalMargin />;
            //  7.8 DSKH còn dư nợ ký quỹ
            case 'menu-sidebar.title-7.8':
                return <CustomerDebtMargin />;
            //  7.9 DSKH bị xử lý bán
            case 'menu-sidebar.title-7.9':
                return <CustomerDealtSell />;
            //  7.11 Tra cứu thông tin tài sản theo chứng khoán
            case 'menu-sidebar.title-7.11':
                return <AccountLookupBySymbol />;
            //  7.10 Báo cáo số liệu giao dịch
            case 'menu-sidebar.title-7.10':
                return <TradingValueReport />;
            /*-----------------Cài đặt---------------*/
            //9.1 Thông tin cá nhân
            case 'menu-sidebar.title-9.1':
                return <PersonalInfo />;
            //9.2 Đăng ký dịch vụ
            case 'menu-sidebar.title-9.2':
                return <ServiceRegister />;
            // Bảo mật
            case 'menu-sidebar.title-10':
                return <Security />;
            case 'menu-sidebar.title-11':
                return <BrokerOrder />
            case 'menu-sidebar.title-12':
                return <SummaryOrderMatching />
            default:
                return null;
        }
    }


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    isCheckSelectCustomer = () => {
        const { curScreenModalActive } = this.props;
        const key = curScreenModalActive
        switch (key) {
            /*----------------Giao dịch tiền----------------*/
            //  3.1 Chuyển tiền
            case 'menu-sidebar.title-3.1':
                return true;
            /*----------------Giao dịch chứng khoán----------------*/
            //  4.1 Chuyển CK
            case 'menu-sidebar.title-4.1':
                return true;
            /*----------------Quản lý tài khoản----------------*/
            //  6.1 Tài sản
            case 'menu-sidebar.title-6.1':
                return true;
            //  6.3 Sao kê tiền
            case 'menu-sidebar.title-6.3':
                return true;
            //  6.4 Sao kê chứng khoán
            case 'menu-sidebar.title-6.4':
                return true;
            //  6.5 Lịch sử đặt lệnh
            case 'menu-sidebar.title-6.5':
                return true;
            //  6.6 Lịch sử khớp lệnh
            case 'menu-sidebar.title-6.6':
                return true;
            //  6.7 Lãi/Lỗ đã thực hiện
            case 'menu-sidebar.title-6.7':
                return true;
            //  6.8 Báo cáo tổng hợp
            case 'menu-sidebar.title-6.8':
                return true;
            default:
                return false;
        }
    }

    genderIcon = (keyIcon) => {
        let { widthMenuSideBar, isOpenScreenModal, layoutsCustom, defaultTheme } = this.props
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < CloseIconDark /> : < CloseIconLight />
                break;
            default:
                break;
        }
    }

    render() {
        const { curScreenModalActive, widthMenuSideBar, useCustomHeader, headerCustom, accountInfo, isSelectCustomer, isOpenMenuTool } = this.props;
        let title = curScreenModalActive
        // let width = widthMenuSideBar === "226px" ? `calc(100% - 226px)` : `calc(100% - 75px)`
        // let left = widthMenuSideBar === "226px" ? "226px" : "75px"

        let isSidebarOpen = widthMenuSideBar === "300px"
        // let isSidebarOpen = widthMenuSideBar === "400px"

        const { curCustomer } = { ...accountInfo }
        return (
            <Card
                // style={{ width: width, left: left }}
                className={
                    (isSidebarOpen ? "custom-scrollbar container-modal-screen open" : "custom-scrollbar container-modal-screen")
                    + (isOpenMenuTool ? " menu-tool-open" : " menu-tool-close")
                }>
                <CustomScrollbars
                    className="scrollbar-screen-modal"
                >
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        {!useCustomHeader && (<CardHeader className="header-container">
                            <div className='title headB---700-20-30'>  <FormattedMessage id={title} /></div>
                            <div className='container-end-header'>
                                {/* {
                            this.isCheckSelectCustomer() && this.isBroker() &&
                            <div className="block-info-account">
                                <SelectorAccountCustomer
                                    curCustomer={curCustomer}
                                    selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                    disabled={false}
                                    className="custom-form-group-n"
                                />
                            </div>
                        } */}
                                <span style={{ cursor: 'pointer' }} onClick={() => this.closeScreenModal()}>
                                    {this.genderIcon(1)}
                                </span>
                            </div>
                        </CardHeader>)}

                        {useCustomHeader && (headerCustom && headerCustom.title !== undefined) && <HeaderCustom4ScreenModal
                            headerCustom={headerCustom}
                        />}
                        <CardBody className="body-container" >
                            {this.renderUI()}
                        </CardBody>
                    </div>
                </CustomScrollbars>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const menuHeader = state.user.userInfo && state.user.userInfo.menuHeader
    const widthMenuSideBar = menuHeader.menuSideBar.widthMenuSideBar
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        widthMenuSideBar: widthMenuSideBar,
        curScreenModalActive: state.layout.listIsOpenModal["C&B"]["ScreenModal"].curScreenModalActive,
        isOpenScreenModal: state.layout.listIsOpenModal["C&B"]["ScreenModal"].isOpenScreenModal,
        currentLayoutPageActive: state.layout.listIsOpenModal["C&B"]["ScreenModal"].curScreenModalActive,
        useCustomHeader: state.layout.listIsOpenModal["C&B"]["ScreenModal"].useCustomHeader,
        headerCustom: state.layout.listIsOpenModal["C&B"]["ScreenModal"].headerCustom,
        accountInfo: state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo,
        role: state.user.userInfo.role,
        isOpenMenuTool: state.user.isOpenMenuTool,
        defaultTheme,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        onChangeStatusMenuSideBar: (statusMenuSideBar) => dispatch(actions.onChangeStatusMenuSideBar(statusMenuSideBar)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenModal);