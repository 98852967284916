import React, { Component } from "react";
import { push } from "connected-react-router";
import _ from 'lodash';
import { connect } from "react-redux";

import * as actions from "../../store/actions";
import { CommonUtils, EXCHANGE, KeyCodeUtils, LoadStatus, STOCKTYPE } from '../../utils';

import './SelectSearchBySuggest.scss';

class SelectSearchBySuggest extends Component {
    initialState = {
        foundList: [],
        oldCurrentSymbol: "",
        ownCurrentSymbol: "",
        isDisplayOwnSymbol: false,
        onMouseOver: false,
        symbolObj: null,
        isFocusingInput: false,
        isHoveringInput: false,
        currentIsDisplayLabel: true,  // Có hiển thị chữ mã CK ở trên input không
        symbolSearchArray: [],  // Array tím kiếm khác với mã symbol để trong trường symbol của từng phần tử
        isRedirect: true,  // Có thay đổi state currentSymbok trong redux không
        defaultSymbolList: [], // Mảng symbol mặc định khi array không được truyền vào
        moreClicked: false, // Nút sổ xuống đã được nhấn hay chưa 
        mouseOverMoreBtn: false, // Chuột đang di vào nút sổ xuống
        disableMoreBtn: false, // disable nút sổ xuống
        // Phần dưới dành cho phân trang
        rowsPerPage: 10, // Mặc định có 10 bản gi trong một trang
        pages: 1, // Số trang sẽ được cập nhật theo số lượng bản ghi được sử dụng để bỏ vào foundList
        saveSymbol: [], // Mảng symbol để dành để bắn vào foundList
        //symbolSearchArrayStr
        isDisplayError: true, // hiển thị lỗi dưới input
        errorMsgID: "", // ID của lỗi cần hiển thị,
        titleID: "", // ID của title cần hiển thị ngoài Mã CK
        isUpperCase: true, // Có upperCase cho giá trị nhập vào 
        oldValidValue: "",
        unicodeValid: false,
        isUnicodePrevented: false,
        currentActiveIndex: -1, // bằng -1 trong trường hợp không có active
        visibleTopLimit: 0,   // Khoảng cách nhìn thấy từ top đến đỉnh các bản ghi nhìn thấy
        visibleBottomLimit: 130, // Khoản cách từ đỉnh đến đuôi của các bản ghi nhìn thấy,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.inputRef = React.createRef();
    }

    INVISIBLE_TOP_OFFSET = 130;  // Độ cao vùng mã nhìn thấy trong suggest box (130px)

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }	
    
    componentDidMount() {
        this.mounted = true
        const { symbols, fetchSymbolsList, fetchSymbolsListMapping, symbolLoadStatus } = this.props;
        const { isDisplayLabel, symbolSearchArray, isRedirect, isDisplayError, errorMsgID, titleID, isUpperCase } = this.props;

        if (symbolLoadStatus !== LoadStatus.LOADED) {
            //fetchSymbolsList();
            fetchSymbolsListMapping();
        }

        if (isDisplayLabel !== undefined && isDisplayLabel !== null) {
            this._setState({ currentIsDisplayLabel: isDisplayLabel });
        }

        if (isRedirect !== undefined && isRedirect !== null) {
            this._setState({ isRedirect: isRedirect });
        }

        if (isDisplayError !== undefined && isDisplayError !== null) {
            this._setState({ isDisplayError: isDisplayError });
        }

        let allSymbol = [];
        if (symbolSearchArray && symbolSearchArray.length > 0) {
            let sortArray = _.sortBy(symbolSearchArray, (element) => {
                return element;
            });
            this._setState({ symbolSearchArray: sortArray });
        } else {
            symbols.forEach(element => {
                if (element.id) {
                    allSymbol.push(element.id);
                }
            });
            let sortArray = _.sortBy(allSymbol, (element) => {
                return element;
            });
            this._setState({ defaultSymbolList: sortArray });
        }

        if (errorMsgID) {
            this._setState({ errorMsgID: errorMsgID });
        }

        if (titleID) {
            this._setState({ titleID: titleID });
        }

        if (isUpperCase !== undefined && isUpperCase !== null) {
            this._setState({ isUpperCase: isUpperCase });
        }

        //document.body.addEventListener('keydown', this.handleBodyKeyDown);

    }

    componentWillUnmount() {
        // document.body.removeEventListener('keydown', this.handleBodyKeyDown);
        this._setState({
            ...this.initialState
        })
        this.inputRef = undefined
        this.mounted = false
    }

    clearUpdownEvent = () => {
        this._setState({
            ...this.state,
            currentActiveIndex: -1,
            visibleTopLimit: 0,
            visibleBottomLimit: 130
        });
    }

    // Clear trạng thái active của foundList
    clearActiveStatus = () => {
        const { foundList, currentActiveIndex } = this.state;
        let newfoundList = foundList;
        newfoundList.forEach(element => {
            element.isActive = false;
        });
        this._setState({ foundList: newfoundList });
    }

    handleBodyKeyDown = async (event) => {
        const { isFocusingInput, currentActiveIndex, foundList, visibleTopLimit, visibleBottomLimit, onMouseOver, limitHit, ownCurrentSymbol } = this.state;
        const { onChange } = this.props;
        const keyCode = event.which || event.keyCode;
        if ((currentActiveIndex != 0 || (keyCode === KeyCodeUtils.DOWN && currentActiveIndex + 1 > 0)) && (currentActiveIndex != foundList.length - 1 || (keyCode === KeyCodeUtils.UP && currentActiveIndex - 1 < foundList.length - 1))) {
            await this.clearActiveStatus();
        }
        if (currentActiveIndex === -1) {
            if (document.getElementById("suggestion-box")) {
                document.getElementById("suggestion-box").scrollTop = 0;
            }
        }
        let newFoundList = foundList;
        if (isFocusingInput && !onMouseOver) {
            if (keyCode === KeyCodeUtils.DOWN) {
                let newActiveIndex = currentActiveIndex + 1;
                if (newActiveIndex <= newFoundList.length - 1) {
                    var elementHeight = document.getElementsByClassName("suggest-item")[newActiveIndex].offsetHeight * (newActiveIndex + 1);
                    // Chạm đáy có thể nhìn thấy ==> update lại giới hạn vùng nhìn thấy
                    if (elementHeight >= visibleBottomLimit) {
                        var bottomLimit = elementHeight;
                        var topLimit = bottomLimit - this.INVISIBLE_TOP_OFFSET + 26;
                        if (document.getElementById("suggestion-box")) {
                            document.getElementById("suggestion-box").scrollTop = elementHeight - this.INVISIBLE_TOP_OFFSET + 30;
                        }
                        this._setState({ visibleBottomLimit: bottomLimit, visibleTopLimit: topLimit });
                    }
                    newFoundList[newActiveIndex]["isActive"] = true;
                    this._setState({ foundList: [...newFoundList], currentActiveIndex: newActiveIndex, ownCurrentSymbol: newFoundList[newActiveIndex]["symbol"] });
                }
            } else if (keyCode === KeyCodeUtils.UP) {
                let newActiveIndex = currentActiveIndex - 1;
                if (newActiveIndex >= 0) {
                    var elementHeight = document.getElementsByClassName("suggest-item")[newActiveIndex].offsetHeight * (newActiveIndex + 1);
                    if (elementHeight <= visibleTopLimit) {
                        var topLimit = elementHeight - 26;
                        var bottomLimit = topLimit + this.INVISIBLE_TOP_OFFSET;
                        if (document.getElementById("suggestion-box")) {
                            document.getElementById("suggestion-box").scrollTop = topLimit;
                        }
                        this._setState({ visibleBottomLimit: bottomLimit, visibleTopLimit: topLimit });
                    }
                    newFoundList[newActiveIndex]["isActive"] = true;
                    this._setState({ foundList: [...newFoundList], currentActiveIndex: newActiveIndex, ownCurrentSymbol: newFoundList[newActiveIndex]["symbol"] });
                }
            }

        }

        if (keyCode === KeyCodeUtils.ENTER || keyCode === KeyCodeUtils.TAB) {
            const { onChange, symbols, navigate, setSymbolInfoBySelectSuggest } = this.props;
            let instruments = CommonUtils.getInstruments();
            const { ownCurrentSymbol, symbolSearchArray } = this.state;
            if (ownCurrentSymbol) {
                // xử lý onChange
                let arrToSearch = [];
                let symbolDFound = null;
                if (symbolSearchArray && symbolSearchArray.length > 0) {
                    arrToSearch = this.state.symbolSearchArray;
                    //symbolDFound = arrToSearch.find((symbol) => (symbol.symbol === ownCurrentSymbol));
                    symbolDFound = arrToSearch.find((symbol) => (symbol === ownCurrentSymbol));
                } else {
                    arrToSearch = symbols;
                    symbolDFound = arrToSearch.find((symbol) => (symbol.id === ownCurrentSymbol));
                }

                //let symbolDFound = arrToSearch.find((symbol) => (symbol.id === ownCurrentSymbol));
                if (symbolDFound) {
                    setSymbolInfoBySelectSuggest(symbolDFound.id);
                    if (this.state.isRedirect) {
                        navigate(`/webtrade/${symbolDFound.id}`);
                    }
                    var instrument = instruments[symbolDFound.id] || null;
                } else {
                    setSymbolInfoBySelectSuggest(null);
                }
                this._setState({ symbolObj: symbolDFound, onMouseOver: false }, () => {
                    if (onChange) {
                        onChange(ownCurrentSymbol, ((!symbolDFound) || (instrument && instrument.EX === EXCHANGE.HOSE && instrument.ST === STOCKTYPE.BOND)) ? false : true, symbolDFound);
                    }
                    this.focus(); // Focus ngay sau khi chọn mã
                })
            }
            this._setState({ foundList: [] });
            this.clearActiveStatus();
            this.clearUpdownEvent();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { symbols } = this.props;
        const { symbols: prevSymbols } = prevProps;
        let allSymbol = [];
        if (symbols !== prevSymbols) {
            symbols.forEach(element => {
                allSymbol.push(element.id);
            });
            let sortArray = _.sortBy(allSymbol, (element) => {
                return element;
            });
            this._setState({ defaultSymbolList: sortArray });
        }
    }

    // Di chuột vào một mã trong ô gợi ý
    onSuggestMouseOver = (symbol) => {
        this.clearActiveStatus();
        this.clearUpdownEvent();
        this._setState({ onMouseOver: true, ownCurrentSymbol: symbol });
    };

    // Khi tab ra khỏi ô nhập mã (Chọn mã)
    onUnFocusInput = () => {
        const { onChange, symbols, navigate, setSymbolInfoBySelectSuggest } = this.props;
        let instruments = CommonUtils.getInstruments();
        const { onMouseOver, ownCurrentSymbol, symbolSearchArray, moreClicked } = this.state;
        if (onMouseOver && ownCurrentSymbol !== "") {
            // xử lý onChange
            let arrToSearch = [];
            let symbolDFound = null;
            if (symbolSearchArray && symbolSearchArray.length > 0) {
                arrToSearch = this.state.symbolSearchArray;
                symbolDFound = arrToSearch.find((symbol) => (symbol === ownCurrentSymbol));
            } else {
                arrToSearch = symbols;
                symbolDFound = arrToSearch.find((symbol) => (symbol.id === ownCurrentSymbol));
            }

            if (symbolDFound) {
                setSymbolInfoBySelectSuggest(symbolDFound.id);
                if (this.state.isRedirect) {
                    navigate(`/webtrade/${symbolDFound.id}`);
                }
                var instrument = instruments[symbolDFound.id] || null;
            } else {
                setSymbolInfoBySelectSuggest(null);
            }
            this._setState({ symbolObj: symbolDFound, onMouseOver: false }, () => {
                if (onChange) {
                    onChange(ownCurrentSymbol, ((!symbolDFound) || (instrument && instrument.EX === EXCHANGE.HOSE && instrument.ST === STOCKTYPE.BOND)) ? false : true, symbolDFound);
                }
                this.focus(); // Focus ngay sau khi chọn mã
            })
        }
        this.clearUpdownEvent();
        this._setState({ foundList: [], disableMoreBtn: false, isHoveringInput: false, onMouseOver: false });
        if (moreClicked) {
            this._setState(({ moreClicked: false }));
        }
    };


    onSymbolChange = async (event) => {
        this.clearActiveStatus();
        this.clearUpdownEvent();
        const { symbolSearchArray, isUpperCase, unicodeValid, oldValidValue, defaultSymbolList } = this.state;
        const { symbols, onChange, navigate, setSymbolInfoBySelectSuggest } = this.props;
        let instruments = CommonUtils.getInstruments();
        let allSymbol = [];
        // Danh sách tìm kiếm
        if (symbolSearchArray && symbolSearchArray.length > 0) {
            allSymbol = symbolSearchArray;
        } else {
            allSymbol = defaultSymbolList;
        }
        let symbolvalue = isUpperCase ? event.target.value.toUpperCase() : event.target.value;
        //var combining = /[^\x00-\x7F]/g;

        // symbolvalue = CommonUtils.removeDiacritics(symbolvalue) // convert dạng không dấu
        if (symbolvalue && symbolvalue !== "") {
            let FoundArray = allSymbol.filter(item => {
                return (item ? (item.toLowerCase().indexOf(symbolvalue.toLowerCase()) > -1) : false);
            });
            let sortByMatchCharIndexArr = _.sortBy(FoundArray, (item) => {
                return item.toLowerCase().indexOf(symbolvalue.toLowerCase());
            });
            let finalFoundList = [];
            sortByMatchCharIndexArr.forEach(element => {
                finalFoundList.push({ symbol: element, isActive: false });
            })
            this._setState({ foundList: finalFoundList });
        } else {
            this._setState({ foundList: [] });
        }

        // scroll lên đầu nếu người dùng đang tìm kiếm (Mã nhập vào chưa đúng )
        let suggestionBox = document.getElementById("suggestion-box");
        if (suggestionBox) {
            suggestionBox.scrollTop = 0;
        }

        // Xử lý onChange
        let arrToSearch = [];
        let symbolDFound = null;
        if (symbolSearchArray && symbolSearchArray.length > 0) {
            arrToSearch = this.state.symbolSearchArray;
            symbolDFound = arrToSearch.find((symbol) => (symbol === symbolvalue));
        } else {
            arrToSearch = symbols;
            symbolDFound = arrToSearch.find((symbol) => (symbol.id === symbolvalue));
        }

        this._setState({ symbolObj: symbolDFound, ownCurrentSymbol: symbolDFound ? symbolvalue : "" }, () => {
            if (onChange) {
                onChange(symbolvalue, symbolDFound);
            }
        })

        //const { onChange } = this.props;

        //onChange(symbolvalue);
    };

    onSuggestMouseOut = () => {
        this.clearActiveStatus();
        this.clearUpdownEvent();
        this._setState({ onMouseOver: false, ownCurrentSymbol: "" })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        this._setState({ symbolSearchArray: nextProps.symbolSearchArray });
    }

    shouldComponentUpdate(nextProps, nextState) {
        const props = this.props;
        const state = this.state;
        return props.symbolSearchArray !== nextProps.symbolSearchArray
            || props.currentSymbol !== nextProps.currentSymbol
            || props.symbolValid !== nextProps.symbolValid
            || props.symbols !== nextProps.symbols
            || state.foundList !== nextState.foundList
            || state.isRedirect !== nextState.isRedirect
            || state.isDisplayError !== nextState.isDisplayError
            || state.currentIsDisplayLabel !== nextState.currentIsDisplayLabel
            || state.errorMsgID !== nextState.errorMsgID
            || state.titleID !== nextState.titleID
            || state.isUpperCase !== nextState.isUpperCase
            || state.symbolSearchArray !== nextState.symbolSearchArray
            || state.defaultSymbolList !== nextState.defaultSymbolList
        //|| state.ownCurrentSymbol !== nextState.ownCurrentSymbol
        //|| props !== nextProps
        //|| state != nextState
    }

    //Gán lại event mouse click khi nhấn vào nút sổ xuống để ẩn box khi nhấn ra ngoài 
    mouseClickEvent = () => {
        document.body.removeEventListener('click', this.mouseClickEvent);
        const { onMouseOver, mouseOverMoreBtn } = this.state;
        if (!onMouseOver) {
            this._setState({ foundList: [] });
            if (!mouseOverMoreBtn) {
                this._setState({ moreClicked: false });
            }
        }
    };

    // Sự kiện chuột nhấn di vào nút sổ xuống
    mouseOverMoreButton = () => {
        this._setState({ mouseOverMoreBtn: true });
    };

    // Sự kiện chuột nhấn di ra khỏi nút sổ xuống
    mouseOutMoreButton = () => {
        this._setState({ mouseOverMoreBtn: false });
    };


    showAllOptions = () => {
        const { symbolSearchArray, defaultSymbolList, moreClicked } = this.state;
        let newClick = !moreClicked;
        let testArr = [];
        if (moreClicked === false) {
            let tmpArr = [];
            if (symbolSearchArray && symbolSearchArray.length > 0) {
                tmpArr = symbolSearchArray;
                //this._setState({ foundList: symbolSearchArray })
            } else {
                tmpArr = defaultSymbolList;
                //this._setState({ foundList: defaultSymbolList })
            }
            tmpArr.forEach(element => {
                testArr.push({ symbol: element, isActive: false });
            });
            this._setState({ foundList: testArr });
        } else {
            this._setState({ foundList: [] })
        }
        this._setState({ moreClicked: newClick });
        document.body.addEventListener('click', this.mouseClickEvent);
    };

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    onInputFocus = (e) => {
        e.target.select();
        this._setState({ isFocusingInput: true })
    }

    setInputHover = (ishover) => {
        this._setState({ isHoveringInput: ishover });
    }

    render() {
        const { foundList, currentIsDisplayLabel, disableMoreBtn, isDisplayError, errorMsgID,
            titleID, isFocusingInput, isHoveringInput } = this.state;
        const { currentSymbol, symbolValid, placeholder, disabled, className, onKeyDown } = this.props;
        // tạm sửa
        document.body.removeEventListener('keydown', this.handleBodyKeyDown);
        if (foundList.length > 0) {
            document.body.addEventListener('keydown', this.handleBodyKeyDown);
        }
        return (
            <div className="select-search-by-suggest">
                <div className="custom-form-group ">
                    <input
                        ref={this.inputRef}
                        type="text"
                        className={"icon custom-form-control " + className}
                        onChange={this.onSymbolChange}
                        value={currentSymbol}
                        onBlur={this.onUnFocusInput}
                        spellCheck="false"
                        onFocus={this.onInputFocus}
                        onMouseEnter={() => this.setInputHover(true)}
                        onMouseLeave={() => this.setInputHover(false)}
                        onClick={this.showAllOptions}
                        placeholder={placeholder ? placeholder : ""}
                        disabled={disabled}
                        onKeyDown={onKeyDown}
                    />
                </div>
                {foundList && foundList.length > 0 && (
                    <div id='suggestion-box' className={currentIsDisplayLabel ? "" : "no-label-suggest-box"}>
                        {foundList.map((element, key) => {
                            return (
                                <div key={key} className={"col-12 suggest-item " + (element.isActive ? "suggest-active" : "")}
                                    onMouseOver={this.onSuggestMouseOver.bind(null, element.symbol)}
                                    onMouseOut={this.onSuggestMouseOut}
                                    onClick={this.onUnFocusInput}
                                >
                                    {element.symbol}
                                </div>
                            )
                        })}
                    </div>
                )
                }
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        symbolLoadStatus: state.symbol.symbolLoadStatus,
        symbols: [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        setSymbolInfoBySelectSuggest: (symbolId) => dispatch(actions.setSymbolInfoBySelectSuggest(symbolId)),
        // fetchSymbolsList: (obj) => dispatch(actions.fetchSymbolsList(obj)),
        fetchSymbolsListMapping: () => dispatch(actions.fetchSymbolsListMapping())
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SelectSearchBySuggest);