//combineConfig đi theo môi trường cài đặt
//file nay ghi de tuy theo moi truong
const config = {
    switch: {
        enableRefreshToken: false
    },
    conditionOrderList: [
        { id: "GTC", nameId: "trade.advance-condition-order.gtc-full-name", isShow: true },
        { id: "STO", nameId: "trade.advance-condition-order.sto-full-name", isShow: false },
        { id: "SEO", nameId: "trade.advance-condition-order.seo-full-name", isShow: false },
        { id: "SO", nameId: "trade.advance-condition-order.so-full-name", isShow: false },
        { id: "OCO", nameId: "trade.advance-condition-order.oco-full-name", isShow: false },
        { id: "MCO", nameId: "trade.advance-condition-order.mco-full-name", isShow: false },
        { id: "OTO", nameId: "trade.advance-condition-order.oto-full-name", isShow: false },
        { id: "ICO", nameId: "trade.advance-condition-order.ico-full-name", isShow: false },
        { id: "PCO", nameId: "trade.advance-condition-order.pco-full-name", isShow: false },
        { id: "CPO", nameId: "trade.advance-condition-order.cpo-full-name", isShow: false },
        { id: "TSO", nameId: "trade.advance-condition-order.tso-full-name", isShow: false }
    ],
    // ALL_LINK: {
    //     vi: {
    //         TONG_HOP_TAI_SAN: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/2. Màn hình tổng hợp tài sản của tiểu khoản.pdf',
    //         GUI_TIEN_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.1 Gửi tiền iDeposit.pdf',
    //         RUT_TIEN_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.2 Rút tiền iDeposit.pdf',
    //         THAY_DOI_MON_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.3 Thay đổi món iDeposit.pdf',
    //         MUA_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/3.1 Mua trái phiếu.pdf',
    //         BAN_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/3.2 Bán lại và gia hạn trái phiếu.pdf',
    //         UNG_TRUOC: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.1 Ứng trước.pdf',
    //         DANG_KY_QUYEN_MUA: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.2 Đăng ký quyền mua.pdf',
    //         XAC_NHAN_LENH: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.3 Xác nhận lệnh.pdf',
    //         DANG_KY_CHUYEN_DOI_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.4 Đăng ký chuyển đổi trái phiếu.pdf'
    //     },
    //     en: {
    //         TONG_HOP_TAI_SAN: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/2. Account balance.pdf',
    //         GUI_TIEN_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.1 iDeposit depositing.pdf',
    //         RUT_TIEN_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.2 iDeposit withdrawing.pdf',
    //         THAY_DOI_MON_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.3 iDeposit changing.pdf',
    //         MUA_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/3.1 Bond Purchasing.pdf',
    //         BAN_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/3.2 Bond Managing and Repurchasing.pdf',
    //         UNG_TRUOC: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.1 Cash Advance.pdf',
    //         DANG_KY_QUYEN_MUA: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.2 Purchase Right Register.pdf',
    //         XAC_NHAN_LENH: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.3 Order Confirmation.pdf',
    //         DANG_KY_CHUYEN_DOI_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.4 Convertible bonds Register.pdf'
    //     },
    // },
    ALL_LINK: {
        vi: {
            TONG_HOP_TAI_SAN: '',
            GUI_TIEN_IDEPOSIT: '',
            RUT_TIEN_IDEPOSIT: '',
            THAY_DOI_MON_IDEPOSIT: '',
            MUA_TRAI_PHIEU: '',
            BAN_TRAI_PHIEU: '',
            UNG_TRUOC: '',
            DANG_KY_QUYEN_MUA: '',
            XAC_NHAN_LENH: '',
            DANG_KY_CHUYEN_DOI_TRAI_PHIEU: '',
        },
        en: {
            TONG_HOP_TAI_SAN: '',
            GUI_TIEN_IDEPOSIT: '',
            RUT_TIEN_IDEPOSIT: '',
            THAY_DOI_MON_IDEPOSIT: '',
            MUA_TRAI_PHIEU: '',
            BAN_TRAI_PHIEU: '',
            UNG_TRUOC: '',
            DANG_KY_QUYEN_MUA: '',
            XAC_NHAN_LENH: '',
            DANG_KY_CHUYEN_DOI_TRAI_PHIEU: '',
        },
    },
    // Cấu hình index hiển thị (department: index thuộc sở HOSE/HNX)
    ALL_INDEXES: [
        { name: 'VNINDEX', subName: 'VNINDEX', title: 'VN-INDEX', code: 'HOSE', department: 'HOSE', collectionId: 'HOSE', searchKey: 'VNINDEX', isDefault: true },
        { name: 'VN30', subName: 'VN30', title: 'VN30-INDEX', code: '30', department: 'HOSE', collectionId: 'VN30', searchKey: 'VN30', isDefault: true },
        { name: 'HNXINDEX', subName: 'HNXINDEX', title: 'HNX-INDEX', code: 'HNX', department: 'HNX', collectionId: 'HNX', searchKey: 'HNXINDEX', isDefault: true },
        // { name: 'HNX30', subName: 'HNX30', title: 'HNX30-INDEX', code: 'HNX30', department: 'HNX', collectionId: 'HNX30', searchKey: 'HNX30' },
        { name: 'UPCOM', subName: 'UPCOMINDEX', title: 'UPCOM', code: 'UPCOM', department: 'HNX', collectionId: 'UPCOM', searchKey: 'UPCOM', isDefault: true },
        // { name: 'VNMID', subName: 'VNMID', title: 'VNMID-INDEX', code: 'MID', department: 'HOSE', collectionId: 'MID', searchKey: 'VNMID', isDefault: true },
        { name: 'VN100', subName: 'VN100', title: 'VN100-INDEX', code: '100', department: 'HOSE', collectionId: '100', searchKey: 'VN100', isDefault: false },
        // { name: 'VNSML', subName: 'VNSMALLCAP', title: 'VNSML-INDEX', code: 'SML', department: 'HOSE', collectionId: 'SML', searchKey: 'VNSML', isDefault: false },
        { name: 'VNXALL', subName: 'VNXALL', title: 'VNXALL-INDEX', code: 'XALL', department: 'HOSE', collectionId: 'XALL', searchKey: 'VNXALL', isDefault: false },
        // { title: 'VNX50-INDEX', code: 'X50', department: 'HOSE', collectionId: 'X50' },
        // { title: 'VNSI-INDEX', code: 'SI', department: 'HOSE', collectionId: 'SI' },
        // { title: 'VNX200-INDEX', code: 'X200', department: 'HOSE', collectionId: 'X200' },
        // { name: 'VNALL', subName: 'VNALLSHARE', title: 'VNALL-INDEX', code: 'ALL', department: 'HOSE', collectionId: 'ALL', searchKey: 'VNALL', isDefault: false },
        // { name: 'VNDIAMOND', subName: 'VNDIAMOND', title: 'DIAMOND-INDEX', code: 'DIAMOND', department: 'HOSE', collectionId: 'DIAMOND', searchKey: 'VNDIAMOND', isDefault: false },
        // { name: 'VNFINLEAD', subName: 'VNFINLEAD', title: 'FINLEAD-INDEX', code: 'FINLEAD', department: 'HOSE', collectionId: 'FINLEAD', searchKey: 'VNFINLEAD', isDefault: false },
        // { name: 'VNFINSELECT', subName: 'VNFINSELECT', title: 'FINSELECT-INDEX', code: 'FINSELECT', department: 'HOSE', collectionId: 'FINSELECT', searchKey: 'VNFINSELECT', isDefault: false },
        // { title: 'HOSE-NEW-INDEX', code: 'NEW', department: 'HOSE', collectionId: 'HOSENEW', searchKey: 'VNNEW' }
    ],
};
export default config;