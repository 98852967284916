import { BROKER_VIEW_RULE, ToastUtil } from "../../utils";
export const brokerScreenRules = {
    "CS#PRICEBOARD#CB": { // Bảng giá
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    },
    "CS#TRADE#CB": { // Đặt lệnh thường
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    },
    "menu-sidebar.title-1.1": { // Chi tiết mã
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    },
    'menu-sidebar.title-1.2': { // Chi tiết chỉ số
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    },
    'menu-sidebar.title-1.3': { // Đồ thị chỉ số
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    },
    "menu-sidebar.title-3.1": { // Chuyển tiền nội bộ
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    },
    "menu-sidebar.title-3.7": { // Chuyển tiền ra ngoài
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    },
    "menu-sidebar.title-3.5": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Ứng trước tiền bán
    "menu-sidebar.title-4.1": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Chuyển chứng khoán
    "menu-sidebar.title-8.1": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Sao kê tiền
    "menu-sidebar.title-8.2": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Sao kê CK
    "menu-sidebar.title-4.2": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Đăng ký quyền mua
    "menu-sidebar.title-4.4": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Tra cứu sự kiện quyền
    "menu-sidebar.title-5.2": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Xác nhận lệnh
    "menu-sidebar.title-5.3": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Tra cứu danh mục Margin
    "menu-sidebar.title-5.4": {
        rule: BROKER_VIEW_RULE.HIDE
    }, // Cảnh báo giá
    "menu-sidebar.title-6.1": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Tài sản
    "menu-sidebar.title-6.2": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Dư nợ ký quỹ
    "menu-sidebar.title-6.3": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Sao kê tiền
    "menu-sidebar.title-6.4": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Sao kê CK
    "menu-sidebar.title-6.5": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Lịch sử lệnh
    "menu-sidebar.title-6.10": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Tổng hợp lệnh khớp
    "menu-sidebar.title-6.6": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Lịch sử lệnh điều kiện
    "menu-sidebar.title-6.7": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Lịch sử khớp lệnh

    "menu-sidebar.title-6.8": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Lãi lỗ đã thực hiện
    "menu-sidebar.title-6.9": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Báo cáo tổng hợp
    "menu-sidebar.title-7.7": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Danh sách khách hàng cần bổ sung ký quỹ
    "menu-sidebar.title-7.8": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // DSKH cần bổ sung ký quỹ
    "menu-sidebar.title-7.9": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // DSKH bị xử lý bán
    "menu-sidebar.title-7.11": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Tra cứu thông tin tài sản theo chứng khoán
    "menu-sidebar.title-7.10": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Báo cáo số liệu giao dịch

    "menu-sidebar.title-9.1'": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Thông tin cá nhân
    "menu-sidebar.title-9.2": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Đăng ký dịch vụ

    "menu-sidebar.title-10'": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Bảo mật
    "menu-sidebar.title-11'": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Sổ lệnh môi giới
    "menu-sidebar.title-12'": {
        rule: BROKER_VIEW_RULE.OPEN_ALLOW
    }, // Tổng hợp lệnh khớp
}

export const checkBrokerRule = (keyName) => {
    let rule = BROKER_VIEW_RULE.OPEN_ALLOW;
    let config = brokerScreenRules[`${keyName}`]
    if (config) {
        rule = config.rule;
        switch (rule) {
            case BROKER_VIEW_RULE.OPEN_ALLOW:
                return true;
            case BROKER_VIEW_RULE.DISPLAY_NOT_ALOW_WARNNING:
                ToastUtil.error('common.fail-to-load-broker-lookup-list', 'common.fail-to-load-broker');
                return false;
            case BROKER_VIEW_RULE.NO_ACCESS:
                return false;
            case BROKER_VIEW_RULE.HIDE:
                return BROKER_VIEW_RULE.HIDE;
            default:
                return true;
        }
    }
}