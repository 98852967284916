import React, { Component, Fragment } from 'react';


import "./MatrixInput.scss"

class MatrixInput extends Component {

    static noop() { }

    static defaultProps = {
        // onKeyDown: PinInput.noop
    };

    constructor(props) {
        super(props);
        this.state = {
            matrixCode1: '',
            matrixCode2: '',
            matrixCode3: '',
            matrixCodeValue: ''
        }
    }

    input1 = null;
    input2 = null;
    input3 = null;

    ref1 = element => {
        this.input1 = element;
    };

    ref2 = element => {
        this.input2 = element;
    };

    ref3 = element => {
        this.input3 = element;
    };

    handleValueChange = () => {
        const { matrixCode1, matrixCode2, matrixCode3 } = this.state;
        let matrixCodeValue = matrixCode1 + matrixCode2 + matrixCode3;
        this._setState({ matrixCodeValue: matrixCodeValue });
        // this._setState({
        //     tokenValue: convertedValue
        // })
        if (this.props.onChange) {
            this.props.onChange(matrixCodeValue);
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }


    componentDidMount() {
        this.mounted = true
        const { disabled, autoFocus } = this.props;
        if (!disabled && autoFocus) {
            this.input1 && this.input1.focus();
        }
    }

    onChangeMatrix1 = (codeToSet) => {
        // // Lấy số cuối người dùng nhập làm code (Nếu không set qua keyup)
        // let codeToSet = '';
        // if (event.target.value) {
        //     codeToSet = event.target.value.substring(event.target.value.length - 1, event.target.value.length);
        // }
        this._setState({ matrixCode1: codeToSet }, () => {
            // focus to next matrix code
            codeToSet && this.input2 && this.input2.focus();
            this.handleValueChange();
        });
    }

    onChangeMatrix2 = (codeToSet) => {
        this._setState({ matrixCode2: codeToSet }, () => {
            // focus to next matrix code
            codeToSet && this.input3 && this.input3.focus();
            this.handleValueChange();
        });
    }

    onChangeMatrix3 = (codeToSet) => {
        this._setState({ matrixCode3: codeToSet }, () => {
            this.handleValueChange();
        });
    }

    // Chặn nhập ký tự số e
    onKeyDown = (event) => {
        var invalidChars = [
            "-",
            "+",
            "e",
        ];

        if (invalidChars.includes(event.key)) {
            event.preventDefault();
        }
        this.props.handlerKeyDown && this.props.handlerKeyDown(event);

    }

    // Set giá trị với event keyUp
    onKeyUp = (event, setFunct) => {
        // if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57) {
        //     event.preventDefault();
        //     return;
        // }

        // bắt event nhấn backspace và delete
        if (event.which != 8 && event.which != 46) {
            let numberInput = String.fromCharCode(event.which);
            setFunct && setFunct(numberInput)
        } else {
            setFunct && setFunct('')
        }
    }

    getChallengeDisplay(challengeQuestion) {
        let challengeQuestionArr = challengeQuestion ? challengeQuestion.split('--') : [];
        // Format hiên thị cho matrix - A14 => A:14
        if (challengeQuestion && challengeQuestionArr.length > 0) {
            challengeQuestionArr = challengeQuestionArr.map(item => {
                return item.substring(0, 1) + ':' + item.substring(1, challengeQuestion.length - 1);
            })
        }
        return challengeQuestionArr;
    }

    render() {
        // noinspection JSUnusedLocalSymbols
        const { ref, className, toggleDisplayPin, showPin, challengeQuestion, ...otherProps } = this.props;
        let challengeDisplay = this.getChallengeDisplay(challengeQuestion);
        return (
            <Fragment>
                <div className='row input-matrix-row text-center'>
                    <div className='col-4 custom-form-group'>
                        <input
                            value={this.props.disabled !== null ? (!this.props.disabled ? this.state.matrixCode1 : "•") : this.state.matrixCode1}
                            ref={this.ref1}
                            className='custom-form-control'
                            placeholder='Nhập'
                            // type='number'
                            autoComplete="off"
                            onKeyUp={(e) => this.onKeyUp(e, this.onChangeMatrix1)}
                            onKeyDown={this.onKeyDown}
                            onPaste={(e) => { e.preventDefault() }}
                            {...otherProps}
                        />
                    </div>
                    <div className='col-4 custom-form-group'>
                        <input
                            value={this.props.disabled !== null ? (!this.props.disabled ? this.state.matrixCode2 : "•") : this.state.matrixCode2}
                            ref={this.ref2}
                            className='custom-form-control'
                            placeholder='Nhập'
                            // type='number'
                            autoComplete="off"
                            onKeyUp={(e) => this.onKeyUp(e, this.onChangeMatrix2)}
                            onKeyDown={this.onKeyDown}
                            onPaste={(e) => { e.preventDefault() }}
                            {...otherProps}
                        />
                    </div>
                    <div className='col-4 custom-form-group'>
                        <input
                            value={this.props.disabled !== null ? (!this.props.disabled ? this.state.matrixCode3 : "•") : this.state.matrixCode3}
                            ref={this.ref3}
                            className='custom-form-control'
                            placeholder='Nhập'
                            // type='number'
                            autoComplete="off"
                            onKeyUp={(e) => this.onKeyUp(e, this.onChangeMatrix3)}
                            onKeyDown={this.onKeyDown}
                            onPaste={(e) => { e.preventDefault() }}
                            {...otherProps}
                        />
                    </div>
                </div>
                {challengeQuestion && (
                    <div className='row input-matrix-row text-center question-container'>
                        <div className='col-4'>
                            [{challengeDisplay[0]}]
                        </div>
                        <div className='col-4'>
                            [{challengeDisplay[1]}]
                        </div>
                        <div className='col-4'>
                            [{challengeDisplay[2]}]
                        </div>
                    </div>
                )}
            </Fragment >
        );
    }

}

export default MatrixInput;