import React, { Component } from 'react';
import moment from 'moment';

/** For valid format please see <a href="https://momentjs.com/docs/#/displaying/">Moment format options</a> */
const timeFormat = 'HH:mm:ss';

class FormattedTime extends Component {

    render() {
        const { value, ...otherProps } = this.props;
        const formattedValue = value ? moment(value).format(timeFormat) : null;
        return (
            <span {...otherProps}>{formattedValue}</span>
        );
    }
}

export default FormattedTime;