import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { emitter } from 'utils/EventEmitter';
import './ErrorBoundary.scss';
import { Modal, ModalBody } from 'reactstrap';
import { CommonUtils } from 'utils';
class ErrorBoundary extends Component {

    initstate = { hasError: false, error: null, errorInfo: null, isOpenModal: false, isReRender: false };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initstate
        }
        this.listenToTheEmitter();
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    callback = data => {
        this._setState({ isReRender: !this.state.isReRender })
    }

    listenToTheEmitter = () => {
        emitter.on('REMOVE_LocalConfigWeb', this.callback);
    }

    reloadContent = () => {
        this._setState({ hasError: false });
        window.location.reload(false);
    };

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, isOpenModal: true };
    }

    componentDidMount() {
        this.mounted = true
        window.addEventListener("storage", this.onLocalStorageChange);
    }

    onLocalStorageChange = (e) => {
        //console.log("onLocalStorageChange", e, { key, newValue, oldValue })
        const { key, newValue, oldValue } = e;
        if (key === "LocalConfigWeb") {
            let _newValue, _oldValue;
            try {
                _newValue = JSON.parse(newValue)
                _oldValue = JSON.parse(oldValue)
            } catch (error) {
                localStorage.removeItem("LocalConfigWeb");
            }
            if (_newValue && _oldValue) {
                if (_newValue.isOpenErrorBoundary !== _oldValue.isOpenErrorBoundary) {
                    this._setState({ isReRender: this.state.isReRender })
                }
            }
        }

    };

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        //console.log("componentDidCatch", { error, info })
        this._setState({ error: error, errorInfo: info })
        // //console.log('')
    }

    componentWillUnmount() {
        emitter.removeListener('REMOVE_LocalConfigWeb', this.callback);
        window.removeEventListener("storage", this.onLocalStorageChange);
        this.mounted = false
    }

    onClose = () => {
        this._setState({ isOpenModal: false })
    }

    render() {
        const { errorInfo, error } = this.state

        let isOpenErrorBoundary = false
        let LocalConfigWeb = JSON.parse(localStorage.getItem('LocalConfigWeb'))
        if (LocalConfigWeb && LocalConfigWeb.isOpenErrorBoundary) {
            isOpenErrorBoundary = LocalConfigWeb.isOpenErrorBoundary
        }
        let errText = ""
        if (error && error.stack && error.stack.toString()) {
            errText += error.stack.toString()
        }
        if (errorInfo && errorInfo.componentStack.toString()) {
            errText += errorInfo.componentStack.toString()
        }
        if (this.state.hasError) {
            return (
                <Modal
                    isOpen={this.state.isOpenModal}
                    centered={true}
                    className="error-boundary-modal"
                >
                    <div className="custom-header">
                        <div className=" headB---700-16-24">
                            <FormattedMessage id="CM4" />
                        </div>
                        <div className="btn-icon-fm" onClick={this.onClose}>
                            <i className="fal fa-times"></i>
                        </div>
                    </div>
                    <ModalBody className="error-boundary-wrapper">
                        <div className="error-boundary-content">

                            <div className='headB---700-16-24 title'>
                                <FormattedMessage id="error-boundary.title" />
                            </div>
                            <div className='txt---400-16-24 content'>
                                <FormattedMessage id="error-boundary.message" />
                                <div className={"check-error-deboundary " + (isOpenErrorBoundary ? "" : "d-none")}>
                                    <>
                                        <div className={"content-text "}>
                                            {errText}
                                        </div>
                                        <div style={{ paddingLeft: "5px", cursor: "pointer" }} className="icon-copy" onClick={() => { CommonUtils.onCopyText(errText) }}>
                                            <i class="fa fa-clone" aria-hidden="true"></i>
                                            COPY
                                        </div>
                                    </>
                                </div>
                            </div>
                            <div className="row gutters-5 align-items-center h-row container-btn">
                                <div className="col-12">
                                    <button className="btn-refresh txt---500-16-24 btn bg-btn-login bg-primary text-custom"
                                        onClick={this.reloadContent}
                                    >
                                        <FormattedMessage id="error-boundary.action" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal >
            )
        }
        // if (this.state.hasError) {
        //     return (
        //         <div className="error-boundary">
        //             <div className="error-boundary-wrapper">
        //                 <div className="error-boundary-content">
        //                     <div className="icon">
        //                         <i className="far fa-exclamation-triangle" />
        //                     </div>
        //                     <div className="message">
        //                         <FormattedMessage id="error-boundary.message" />
        //                     </div>
        //                     <div className="action">
        //                         <button className="btn btn-info" onClick={this.reloadContent}>
        //                             <FormattedMessage id="error-boundary.action" />
        //                         </button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }

        return this.props.children;
    }
}

export default ErrorBoundary;