import React, { Component } from 'react';
import FitText from "../../components/FitText";

import NumberFormatCustom from "./NumberFormatCustom";
import { getPriceColorClassName } from "./NumberFormatCustom";

class FormattedVolume extends Component {

    renderShortForm = (formattedVolume) => {
        const { value, instrument, colorCalcValue } = this.props;
        return (
            <span className={getPriceColorClassName(colorCalcValue != null ? colorCalcValue : value, instrument)}>
                {/* Giá trị chỉ được clear khi value = null (Nếu value= undefined ===> formattedVolume là giá trị cũ) */}
                {/*formattedVolume && value && value !== 0 ? formattedVolume.substr(0, formattedVolume.length - 1) : null*/}
                <FitText>
                    {formattedVolume && value && value !== 0 ? formattedVolume : null}
                </FitText>
            </span>
        );
    };

    render() {
        const { value, instrument, shortForm, colorCalcValue, decimalScale } = this.props;
        // return (
        //     <NumberFormat
        //         value={value}
        //         renderText={shortForm ? this.renderShortForm : null}
        //         displayType={'text'}
        //         decimalScale={decimalScale && decimalScale || (shortForm ? 2 : 0)}
        //         fixedDecimalScale={false}
        //         thousandSeparator={','}
        //         decimalSeparator={'.'}
        //         className={getPriceColorClassName(colorCalcValue != null ? colorCalcValue : value, instrument)} />
        // );

        return (
            <NumberFormatCustom
                value={value}
                displayType={'text'}
                decimalScale={decimalScale}
                fixedDecimalScale={false}
                thousandSeparator={','}
                decimalSeparator={'.'}
                fitText={shortForm}  // Giữ logic renderText={shortForm ? this.renderShortForm : null}
                colorCalcValue={colorCalcValue}
                renderfitText={this.renderShortForm} // Giữ logic renderText={shortForm ? this.renderShortForm : null}
                shortForm={shortForm}
                instrument={instrument}
                isNotThousand={true} // Không phải chia 1000
                {...this.props}
            />
        );
    }
}

export default FormattedVolume;
