import { Component } from 'react';
import { connect } from "react-redux";
import _ from "lodash";


import "./RenderGridCellRealTime.scss"
import CommonOrderUtils from './../../../utils/CommonOrderUtils';
import { emitter } from 'utils/EventEmitter';
class RenderGridCellRealTime extends Component {
    componentDidMount() {
        // // //console.log('RenderGridCellRealTime.:RealTime.:componentDidUpdate.:2====componentDidMount')
        // const { instrument, data, keyStoreUpdate } = this.props;
        // let _instrument2 = _.cloneDeep(instrument)
        // let _instrument = {
        //     Id: data.symbol,
        //     SB: data.symbol,
        //     CP: _instrument2.CP,
        //     keyStoreUpdate: data[keyStoreUpdate]
        // }
        // emitter.emit("UPDATE_REALTIME_EXTREME", { instrument: _instrument })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { instrument, data, keyStoreUpdate } = this.props;
        const { instrument: prevInstrument } = prevProps;
        if ((instrument.SB == prevInstrument.SB) && (instrument.CP && !(_.isEqual(instrument.CP, prevInstrument.CP)))) {
            // let _instrument2 = _.cloneDeep(instrument)
            let _instrument = {
                Id: data.symbol,
                SB: data.symbol,
                CP: instrument.CP,
                s: instrument.s,
                keyStoreUpdate: data[keyStoreUpdate]
            }
            emitter.emit("UPDATE_REALTIME_EXTREME", { instrument: _instrument })
        }
    }

    render() {
        return null
    }
}
const mapStateToProps = (state, props) => {
    const { data, keyRealTime } = props;
    const instrument = data[keyRealTime] ? state.symbol.instruments[CommonOrderUtils.getSymbolOfSymbolSpecial(data[keyRealTime])] || {} : {};
    return {
        instrument: instrument
    };
};

export default connect(mapStateToProps, null)(RenderGridCellRealTime);
