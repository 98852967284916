import React, { Component } from 'react';
import _ from "lodash";
import { connect } from 'react-redux';

import * as socket from "../../socket";
import { CommonOrderUtils, Random } from "../../utils";
class RealtimeInstrumentComponent extends Component {

    callerId = Random.randomComponentId();
    subscribleToTopic = (record) => {
        if (!record || !record.symbol) {
            return;
        }

        socket.registerInstrumentTopic(CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol), this.callerId);
    };

    componentDidMount() {
        const { record, instrument, onRowDataChange } = this.props;
        if (instrument) {
            let _instrument = _.cloneDeep(instrument)
            onRowDataChange(CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol), _instrument);
        }
        this.subscribleToTopic(record);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { record, onRowDataChange, instrument } = this.props;
        const { record: prevRecord, instrument: prevInstrument } = prevProps;

        if (!(_.isEqual(record, prevRecord)) && record.symbol !== prevRecord.symbol) {
            this.subscribleToTopic(record);
        }

        if (!(_.isEqual(record, prevRecord)) || !(_.isEqual(instrument, prevInstrument))) {
            let _instrument = _.cloneDeep(instrument)
            onRowDataChange(CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol), _instrument);
        }
    }

    componentWillUnmount() {
        socket.unregisterCallerId(this.callerId);
    }

    render() {
        const { instrument, typeAccount, record } = this.props;
        return (
            <div className="realtime-instrument" style={{ display: "none" }}>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { record } = props;
    const instrument = record.symbol ? state.symbol.instruments[CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol)] || {} : {};
    return {
        instrument: instrument,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RealtimeInstrumentComponent);