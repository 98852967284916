import React, { Fragment } from 'react';

// import IconCancelDark from '../../../assets/icons_new/icon_cancel_dark.svg'
// import IconEditDark from '../../../assets/icons_new/icon_edit_dark.svg';
// import IconEditLight from '../../../assets/icons_new/icon_edit_light.svg';

import ViewActive from '../../../assets/icons_new/view-dark.svg'
import { FormattedAmount, FormattedPercentage, FormattedPrice, FormattedPriceDiff } from "../../../components/Formating";
import { activeOrderExecType, CommonUtils } from "../../../utils";
import FormattedDate from 'components/Formating/FormattedDate'

import "./RenderGridCell.scss"
import { FormattedMessage } from 'react-intl';

export default class RenderGridCell {


    static renderGridCellCheckboxOrderActive = (dataObj, lang, handleCheckBox) => {
        const record = dataObj.row.data;
        return (
            <div >
                <input
                    type="checkbox"
                    name="type-deposit"
                    checked={record.checked}
                    disabled={!record.cancelOrderPermission}
                    onChange={(e) => handleCheckBox(e, record.odorderid)}
                />
            </div>
        )
    }

    static renderGridCellActionOrder = (dataObj, onCopyOrder) => {
        const record = dataObj.row.data;
        return (
            <div className="action-order">
                <div style={{ width: '100%' }}>
                    <div className="block-btn block-btn-copy">
                        <button className='btn-action action-copy'
                            onClick={() => { onCopyOrder(record) }}
                        // disabled={!item.cancelOrderPermission}
                        >
                            <FormattedMessage id="quick-order.place-order.copy" />
                        </button>
                    </div>
                </div>
            </div >
        )
    }

    static renderGridCellActionCancelProduct = (dataObj, oncancelProduct) => {
        const record = dataObj.row.data;
        return (
            <div className="action-order">
                {record.iscancel && record.iscancel === 'Y' && (
                    <div style={{ width: '100%' }}>
                        <div className="block-btn block-btn-cancel">
                            <button className='btn-action action-cancel'
                                onClick={() => { oncancelProduct(record) }}
                            // disabled={!item.cancelOrderPermission}
                            >
                                <FormattedMessage id="quick-order.place-order.cancel" />
                            </button>
                        </div>
                    </div>
                )}
            </div >
        )
    }

    static renderGridCellActionOrderActive = (dataObj, onEditOrder, onCancelOrder, numberAction, onCopyOrder) => {
        const record = dataObj.row.data;
        let _width = `calc(100% / ${numberAction})`
        return (
            <div className="action-order">
                {numberAction == 1 &&
                    <>
                        {record.amendOrderPermission &&
                            <div className="block-btn block-btn-edit" style={{ width: _width }}>
                                <button className='btn-action action-edit'
                                    onClick={() => onEditOrder(record)}
                                // disabled={true}
                                // disabled={!item.amendOrderPermission}
                                >
                                    <FormattedMessage id="quick-order.place-order.edit" />
                                </button>
                            </div>
                        }
                        {record.cancelOrderPermission &&
                            <div className="block-btn block-btn-cancel" style={{ width: _width }}>
                                <button className='btn-action action-cancel'
                                    onClick={() => { onCancelOrder(record) }}
                                // disabled={!item.cancelOrderPermission}
                                >
                                    <FormattedMessage id="quick-order.place-order.cancel" />
                                </button>
                            </div>
                        }
                    </>
                }
                {numberAction == 2 &&
                    <>
                        <div style={{ width: _width }}>
                            {record.amendOrderPermission &&
                                <div className="block-btn block-btn-edit">
                                    <button className='btn-action action-edit'
                                        onClick={() => onEditOrder(record)}
                                    // disabled={true}
                                    // disabled={!item.amendOrderPermission}
                                    >
                                        <FormattedMessage id="quick-order.place-order.edit" />
                                    </button>
                                </div>
                            }
                        </div>
                        <div style={{ width: _width }}>
                            {record.cancelOrderPermission &&
                                <div className="block-btn block-btn-cancel">
                                    <button className='btn-action action-cancel'
                                        onClick={() => { onCancelOrder(record) }}
                                    // disabled={!item.cancelOrderPermission}
                                    >
                                        <FormattedMessage id="quick-order.place-order.cancel" />
                                    </button>
                                </div>
                            }
                        </div>
                    </>
                }
                {numberAction == 3 &&
                    <>
                        <div style={{ width: _width }}>
                            {record.amendOrderPermission &&
                                <div className="block-btn block-btn-edit">
                                    <button className='btn-action action-edit'
                                        onClick={() => onEditOrder(record)}
                                    // disabled={true}
                                    // disabled={!item.amendOrderPermission}
                                    >
                                        <FormattedMessage id="quick-order.place-order.edit" />
                                    </button>
                                </div>
                            }
                        </div>
                        <div style={{ width: _width }}>
                            {record.cancelOrderPermission &&
                                <div className="block-btn block-btn-cancel">
                                    <button className='btn-action action-cancel'
                                        onClick={() => { onCancelOrder(record) }}
                                    // disabled={!item.cancelOrderPermission}
                                    >
                                        <FormattedMessage id="quick-order.place-order.cancel" />
                                    </button>
                                </div>
                            }
                        </div>
                        <div style={{ width: _width }}>
                            <div className="block-btn block-btn-copy">
                                <button className='btn-action action-copy'
                                    onClick={() => { onCopyOrder(record) }}
                                // disabled={!item.cancelOrderPermission}
                                >
                                    <FormattedMessage id="quick-order.place-order.copy" />
                                </button>
                            </div>
                        </div>
                    </>
                }
            </div >
        )
    }


    static renderGridCellActionAllCarebyOrder = (dataObj, onEditOrder, onCancelOrder, numberAction, onCopyOrder) => {
        const record = dataObj.row.data;
        let _width = `calc(100% / ${numberAction})`
        return (
            <div className="action-order">
                {numberAction == 1 &&
                    <>
                        {record.isadmend === 'Y' &&
                            <div className="block-btn block-btn-edit" style={{ width: _width }}>
                                <button className='btn-action action-edit'
                                    onClick={() => onEditOrder(record)}
                                // disabled={true}
                                // disabled={!item.amendOrderPermission}
                                >
                                    <FormattedMessage id="quick-order.place-order.edit" />
                                </button>
                            </div>
                        }
                        {record.iscancel === 'Y' &&
                            <div className="block-btn block-btn-cancel" style={{ width: _width }}>
                                <button className='btn-action action-cancel'
                                    onClick={() => { onCancelOrder(record) }}
                                // disabled={!item.cancelOrderPermission}
                                >
                                    <FormattedMessage id="quick-order.place-order.cancel" />
                                </button>
                            </div>
                        }
                    </>
                }
                {numberAction == 2 &&
                    <>
                        <div style={{ width: _width }}>
                            {record.isadmend === 'Y' &&
                                <div className="block-btn block-btn-edit">
                                    <button className='btn-action action-edit'
                                        onClick={() => onEditOrder(record)}
                                    // disabled={true}
                                    // disabled={!item.amendOrderPermission}
                                    >
                                        <FormattedMessage id="quick-order.place-order.edit" />
                                    </button>
                                </div>
                            }
                        </div>
                        <div style={{ width: _width }}>
                            {record.iscancel === 'Y' &&
                                <div className="block-btn block-btn-cancel">
                                    <button className='btn-action action-cancel'
                                        onClick={() => { onCancelOrder(record) }}
                                    // disabled={!item.cancelOrderPermission}
                                    >
                                        <FormattedMessage id="quick-order.place-order.cancel" />
                                    </button>
                                </div>
                            }
                        </div>
                    </>
                }
                {numberAction == 3 &&
                    <>
                        <div style={{ width: _width }}>
                            {record.isadmend === 'Y' &&
                                <div className="block-btn block-btn-edit">
                                    <button className='btn-action action-edit'
                                        onClick={() => onEditOrder(record)}
                                    // disabled={true}
                                    // disabled={!item.amendOrderPermission}
                                    >
                                        <FormattedMessage id="quick-order.place-order.edit" />
                                    </button>
                                </div>
                            }
                        </div>
                        <div style={{ width: _width }}>
                            {record.iscancel === 'Y' &&
                                <div className="block-btn block-btn-cancel">
                                    <button className='btn-action action-cancel'
                                        onClick={() => { onCancelOrder(record) }}
                                    // disabled={!item.cancelOrderPermission}
                                    >
                                        <FormattedMessage id="quick-order.place-order.cancel" />
                                    </button>
                                </div>
                            }
                        </div>
                        <div style={{ width: _width }}>
                            <div className="block-btn block-btn-copy">
                                <button className='btn-action action-copy'
                                    onClick={() => { onCopyOrder(record) }}
                                // disabled={!item.cancelOrderPermission}
                                >
                                    <FormattedMessage id="quick-order.place-order.copy" />
                                </button>
                            </div>
                        </div>
                    </>
                }
            </div >
        )
    }

    static renderGridCellActionOrderPutthroughNoti = (dataObj, onConfirmNoti, onCancelOrder, lang, defaultTheme) => {
        const record = dataObj.row.data;
        return (
            <div className="action-order-putthrough">
                <div className="container-btn">
                    {record.allowconfirm === 'Y' &&
                        <div className="action action-edit "
                        // disabled={true}
                        // disabled={!item.amendOrderPermission}
                        >
                            {/*<img src={defaultTheme === "dark" ? IconEditDark : IconEditLight} alt="icon-edit" />*/}
                            <button className='btn-action ' onClick={() => onConfirmNoti(record)}>Xác nhận</button>
                        </div>
                    }
                    {record.allowcancel === 'Y' &&
                        <div className="action action-cancel"
                        // onClick={() => { onCancelOrder(record) }}
                        // disabled={!item.cancelOrderPermission}
                        >
                            {/*<img src={IconCancelDark} alt="icon-cancel" />*/}
                            <button className='btn-action btn-cancel' onClick={() => { onCancelOrder(record) }}> Hủy </button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    static renderGridCellActionOrderConfirmPutthrough = (dataObj, onConfirmNoti, onCancelOrder, lang, defaultTheme) => {
        const record = dataObj.row.data;
        return (
            <div className="action-order-putthrough">
                <div className="container-btn">
                    {record.allowconfirm === 'Y' &&
                        <div className="action action-edit "
                        // disabled={true}
                        // disabled={!item.amendOrderPermission}
                        >
                            {/*<img src={defaultTheme === "dark" ? IconEditDark : IconEditLight} alt="icon-edit" />*/}
                            <button className='btn-action ' onClick={() => onConfirmNoti(record)}>Xác nhận</button>
                        </div>
                    }
                    {record.allowcancel === 'Y' &&
                        <div className="action action-cancel"
                        // onClick={() => { onCancelOrder(record) }}
                        // disabled={!item.cancelOrderPermission}
                        >
                            {/*<img src={IconCancelDark} alt="icon-cancel" />*/}
                            <button className='btn-action btn-cancel' onClick={() => { onCancelOrder(record) }}> Từ chối </button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    static renderGridCellActionConfirmCancelPutthroughOrder = (dataObj, onConfirmNoti, onCancelOrder, lang, defaultTheme) => {
        const record = dataObj.row.data;
        return (
            <div className="action-order-putthrough">
                <div className="container-btn">
                    {record.allowconfirm === 'Y' &&
                        <div className="action action-edit "
                        // disabled={true}
                        // disabled={!item.amendOrderPermission}
                        >
                            {/*<img src={defaultTheme === "dark" ? IconEditDark : IconEditLight} alt="icon-edit" />*/}
                            <button className='btn-action ' onClick={() => onConfirmNoti(record)}>Xác nhận</button>
                        </div>
                    }
                    {record.allowcancel === 'Y' &&
                        <div className="action action-cancel"
                        // onClick={() => { onCancelOrder(record) }}
                        // disabled={!item.cancelOrderPermission}
                        >
                            {/*<img src={IconCancelDark} alt="icon-cancel" />*/}
                            <button className='btn-action btn-cancel' onClick={() => { onCancelOrder(record) }}> Từ chối </button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    static renderGridCellActionOrderViewDetail = (dataObj, onViewDetail, defaultTheme) => {
        const record = dataObj.row.data;
        return (
            <div className="action-order">
                <div className="container-btn item-center">
                    <div className="action action-view-detail" onClick={() => { onViewDetail(record) }} >
                        <img src={ViewActive} alt="icon-cancel" />
                    </div>
                </div>
            </div>
        )
    }

    static renderGridCellSideCode = (sidecode, language) => {
        let className = (sidecode === activeOrderExecType.BUY) ? 'text-green style-text-ellipsis' : 'text-red style-text-ellipsis';
        return (
            <Fragment>
                <span className={className}> <FormattedMessage id={"common.exec-types." + sidecode} /></span>
            </Fragment>
        )
    }

    static renderTitleCashTranReport = (item) => {
        return (
            <Fragment>
                <div className='text-active txt-bold style-text-ellipsis'>
                    {item}
                </div>
            </Fragment>
        )
    }

    static renderGridTotalCashTranReport = (item) => {
        return (
            <Fragment>
                <div className='text-active txt-bold text-right style-text-ellipsis'>
                    <FormattedPrice value={item} />
                </div>
            </Fragment>
        )
    }

    static renderGridCellSideCode2 = (dataObj, language) => {
        let className = (String(dataObj).includes("Mua") || String(dataObj).includes("mua")) ? 'text-green style-text-ellipsis' : 'text-red style-text-ellipsis';
        let _data = dataObj === "Mua" ? (language === "vi" ? "Mua" : "Buy") : (language === "vi" ? "Bán" : "Sell")
        return (
            <Fragment>
                <span className={className}>{dataObj}</span>
            </Fragment>
        )
    }

    static renderGridCellSideCode3 = (dataObj, language) => {
        let className = (dataObj === "B") ? 'text-green style-text-ellipsis' : 'text-red style-text-ellipsis';
        let _data = dataObj === "B" ? (language === "vi" ? "Mua" : "Buy") : (language === "vi" ? "Bán" : "Sell")
        return (
            <Fragment>
                <span className={className}>{_data}</span>
            </Fragment>
        )
    }

    static renderGridCellSideCode4 = (dataObj, language) => {
        let className = language === "vi" ? ((dataObj === "Mua") ? 'text-green style-text-ellipsis' : 'text-red style-text-ellipsis') : ((dataObj === "Buy") ? 'text-green' : 'text-red')
        return (
            <Fragment>
                <span className={className}>{dataObj}</span>
            </Fragment>
        )
    }

    static renderGridCellAction = (dataObj, _lang, onHandleBuy, onHandleSell) => {
        const record = dataObj.row.data;
        return (
            <div className="action-container">
                {
                    <div className="block-btn block-btn-buy" onClick={() => onHandleBuy(record)}>
                        <button className='btn-action buy-button'>
                            <FormattedMessage id={"trade.order.buy"} />
                        </button>
                    </div>
                }
                {
                    (record.issell === "Y" || record.isclose === "Y")
                        ?
                        <div className="block-btn block-btn-sell" onClick={() => onHandleSell(record)}>
                            <button className='btn-action sell-button'>
                                <FormattedMessage id={"trade.order.sell"} />
                            </button>
                        </div>
                        :
                        <div className="block-btn">

                        </div>
                }
            </div>
        )
    }

    static renderGridCellActionOpenPosition = (dataObj, lang, onHandleBuy, onHandleSell) => {
        const record = dataObj.row.data;
        //console.log("binh_renderGridCellActionOpenPosition", record)
        if (record && record.symbol && record.symbol.endsWith("_WFT")) {
            return
        }
        return (
            <div className="action-container action-container-position">
                {
                    <div className="block-btn block-btn-buy" onClick={() => onHandleBuy(record)}>
                        <button className='btn-action buy-button'>
                            <FormattedMessage id={"trade.order.buy"} />
                        </button>
                    </div>
                }
                {
                    (record.issell === "Y" || record.isclose === "Y")
                        ?
                        <div className="block-btn block-btn-sell" onClick={() => onHandleSell(record)}>
                            <button className='btn-action sell-button'>
                                <FormattedMessage id={"trade.order.sell"} />
                            </button>
                        </div>
                        :
                        <div className="block-btn">

                        </div>
                }
                {/* <div className={"block-btn block-btn-tpsl " + ((record['bl_tpsl_quoteid'] !== null) ? "active" : "")}>
                    <button className={'btn-action tpsl-button '} onClick={() => onHandleTPSL(record)}>
                        TP/SL
                    </button>
                </div> */}
            </div >
        )
    }

    static renderGridCellActionBlbOrder = (dataObj, lang, onHandlePlaceOrder, onHandleCancelOrder, onHandleOpenDetail) => {
        const record = dataObj.row.data;
        return (
            <div className="action-container action-container-position">
                {record['isplaceorder'] === 'Y' &&
                    (<div className="block-btn block-btn-buy">
                        <button className='btn-action buy-button' onClick={() => onHandlePlaceOrder(record)}>
                            <FormattedMessage id={"customer-organzition.blb-match-order-detail.place-order"} />
                        </button>
                    </div>)
                }

                {record['iscancelmaporder'] === 'Y' &&
                    (<div className="block-btn block-btn-sell">
                        <button className='btn-action sell-button' onClick={() => onHandleCancelOrder(record)}>
                            <FormattedMessage id={"customer-organzition.blb-match-order-detail.cancel-order"} />
                        </button>
                    </div>)
                }

                <div className={"block-btn block-btn-tpsl " + ((record['bl_tpsl_quoteid'] !== null) ? " " : " not-active")}>
                    <button className={'btn-action tpsl-button '} onClick={() => onHandleOpenDetail(record)}>
                        <FormattedMessage id={"customer-organzition.blb-match-order-detail.detail"} />
                    </button>
                </div>
            </div >
        )
    }

    static renderGridCellSelectionBlbOrder = (dataObj, handleSelectOrder) => {
        const record = dataObj.row.data;
        return (
            <div>
                <label className=" radio-container">
                    <input className='' type="radio" name="select-order-blb" onChange={() => handleSelectOrder(record)} />
                    <span class="checkmark"></span>
                </label>
            </div>

        )
    }

    static renderGridCellActionBlbChildOrder = (dataObj, onHandleEditOrder, onHandleCancelOrder) => {
        const record = dataObj.row.data;
        return (
            <div className="action-container action-container-position">

                <div className={"block-btn block-btn-tpsl " + ((record['bl_tpsl_quoteid'] !== null) ? " " : " not-active")}>
                    <button className={'btn-action tpsl-button '} onClick={() => onHandleEditOrder(record)}>
                        <FormattedMessage id={"customer-organzition.blb-child-order.edit"} />
                    </button>
                </div>
                {record['iscancel'] === 'Y' &&
                    (<div className="block-btn block-btn-sell">
                        <button className='btn-action sell-button' onClick={() => onHandleCancelOrder(record)}>
                            <FormattedMessage id={"customer-organzition.blb-child-order.cancel"} />
                        </button>
                    </div>)
                }
            </div >
        )
    }

    static renderGridCellCommon = (item) => {
        return (
            <Fragment>
                <div className='text-near-active style-text-ellipsis'>
                    {item}
                </div>
            </Fragment>
        )
    }

    static renderGridCellSymbolOpenPosition = (item) => {
        return (
            <Fragment>
                <div className='text-active'>
                    {item}
                </div>
            </Fragment>
        )
    }

    static renderGridCellCommonByLanguage = (language, item_vi, item_en) => {
        let item = ""
        if (language == "en") {
            item = item_en
        } else {
            item = item_vi || ""
        }
        return (
            <Fragment>
                <div className='text-near-active style-text-ellipsis'>
                    {item}
                </div>
            </Fragment>
        )
    }

    static renderGridCellAccount = (item) => {
        return (
            <Fragment>
                <div className='text-near-active style-text-ellipsis'>
                    {CommonUtils.formatFDS(item)}
                </div>
            </Fragment>
        )
    }

    static renderGridCellContent = (item) => {
        return (
            <Fragment>
                <div className='text-near-active text-left style-text-ellipsis'>
                    {item}
                </div>
            </Fragment>
        )
    }

    static renderGridCellSymbol = (item) => {
        return (
            <Fragment>
                <div className='style-text-ellipsis'>
                    {item}
                </div>
            </Fragment>
        )
    }


    static renderGridCellPriceConvert = (item) => {
        return (
            <Fragment>
                <div className='text-near-active style-text-ellipsis'>
                    {CommonUtils.roundToTwo(item, 1000)}
                </div>
            </Fragment>
        )
    }

    static renderGridCellPrice = (item, dataType = "PRICE", isCheckColor = false) => {
        item = CommonUtils.getValidValue(item)
        return (
            <Fragment>
                {dataType === "PRICE" && !isCheckColor &&
                    <div className='text-near-active text-right style-text-ellipsis'>
                        <FormattedPrice value={item} />
                    </div>
                }
                {dataType === "PRICE" && isCheckColor && <div className={" text-right style-text-ellipsis " + (CommonUtils.getClassCheckValue(item))}>
                    <span>
                        {item > 0 && "+"}
                    </span>
                    {item === 0 ? "0" : < FormattedPrice value={item} />}
                </div>}
                {dataType === "PRICE_FIXED" && !isCheckColor &&
                    <div className='text-near-active text-right style-text-ellipsis'>
                        <FormattedPrice value={item} shortForm={true} isFormatZero={true} />
                    </div>
                }
            </Fragment>
        )
    }

    static renderGridCellPriceDecimal = (item) => {
        let price = CommonUtils.roundToTwo(item, 1000)
        return (
            <div className='text-near-active text-right style-text-ellipsis'>
                <FormattedPrice value={item} shortForm={true} isNotThousand={true} />
            </div>
        )
    }

    static renderGridCellQuantity = (item) => {
        return (
            <Fragment>
                <div className='text-near-active text-right style-text-ellipsis'>
                    <FormattedAmount value={item} />
                </div>
            </Fragment>
        )
    }

    static renderGridCellDate = (item) => {
        return (
            <Fragment>
                <div className='text-near-active style-text-ellipsis'>
                    <FormattedDate value={item} />
                </div>
            </Fragment>
        )
    }

    static renderGridCellPercentage = (item, decimalScale) => {
        let decimalValue = decimalScale ? decimalScale : null
        return (
            <Fragment>
                <div className='text-near-active style-text-ellipsis'>
                    <FormattedPercentage decimalScale={decimalValue} value={item} />%
                </div>
            </Fragment>
        )
    }

    static renderGridCellPercentageRate = (item, decimalScale) => {
        let decimalValue = decimalScale ? decimalScale : null
        item = CommonUtils.getValidValue(item)
        return (
            <Fragment>
                <div className={" style-text-ellipsis " + (CommonUtils.getClassCheckValue(item))}>
                    <FormattedPriceDiff isFormatZero={true} decimalScale={decimalValue} value={item || 0} shortForm={false} prefix={(item > 0 ? '+' : '')} suffix="%" />
                </div>
            </Fragment>
        )
    }

    static renderGridCellPercentageRateUnsigned = (item) => {
        return (
            <Fragment>
                <div className={" style-text-ellipsis " + (CommonUtils.getClassCheckValue(item))}>
                    <FormattedPercentage value={item} />%
                </div>
            </Fragment>
        )
    }

    static renderGridCellvalue = (item) => {
        return (
            <Fragment>
                <div className={" style-text-ellipsis " + (CommonUtils.getClassCheckValue(item))}>
                    <span>
                        {item > 0 && "+"}
                    </span>
                    <FormattedPrice value={item} />
                </div>
            </Fragment>
        )
    }

    static renderGridCellConvertDateFDS = (item) => {
        // "2021-12-31T00:00:00.000Z"
        let date = ''
        if (item) {
            let result = item.split("T")[0];
            let year = result.split("-")[0]
            let month = result.split("-")[1]
            let day = result.split("-")[2]
            date = day + "/" + month + "/" + year
        }
        //console.log("renderGridCellConvertDateFDS", date)
        return (
            <Fragment>
                <div className={" text-near-active style-text-ellipsis"}>
                    {date}
                </div>
            </Fragment>
        )
    }
    static renderGridCellPriceTransLog = (item, valueCheck) => {
        let itemCheck = CommonUtils.getValidValue(valueCheck)
        return (
            <Fragment>
                <div className={'text-near-active text-right style-text-ellipsis ' + (CommonUtils.getClassCheckValue(itemCheck))}>
                    <FormattedPrice value={item} />
                </div>
            </Fragment>
        )
    }

    static renderGridCellCommonTransLog = (item, valueCheck) => {
        let itemCheck = CommonUtils.getValidValue(valueCheck)
        return (
            <Fragment>
                <div className={'text-near-active style-text-ellipsis ' + (CommonUtils.getClassCheckValue(itemCheck))}>
                    {item}
                </div>
            </Fragment>
        )
    }

    static renderGridCellPercentTransLog = (item, decimalValue) => {
        //item.RE = 14000;
        let itemCheck = CommonUtils.getValidValue(item.FCV)
        if (item.FMP && item.RE && item.RE != 0) {
            item.percent = ((Number(item.FMP) / Number(item.RE)) * 100) // tính % thay đổi
        }
        return (
            <Fragment>
                {item.percent ? (
                    <div className={'text-near-active text-right style-text-ellipsis ' + (CommonUtils.getClassCheckValue(itemCheck))}>
                        <FormattedPrice decimalScale={decimalValue} value={item.percent && item.percent} />%
                    </div>
                ) : (<span></span>)}
            </Fragment>
        )
    }
}
