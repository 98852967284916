import React, { Component } from 'react';
import FitText from "../../components/FitText";

import NumberFormatCustom from "./NumberFormatCustom";
import { getPriceColorClassName } from "./NumberFormatCustom";

class FormattedPrice extends Component {

    renderfitText = (formattedValue) => {
        const { value, instrument, colorCalcValue } = this.props;
        return (
            <span className={getPriceColorClassName(colorCalcValue != null ? colorCalcValue : value, instrument)}>
                {/* Giá trị chỉ được clear khi value = null (Nếu value= undefined ===> formattedVolume là giá trị cũ) */}
                {/*formattedVolume && value && value !== 0 ? formattedVolume.substr(0, formattedVolume.length - 1) : null*/}
                <FitText>
                    {formattedValue}
                </FitText>
            </span>
        );
    };

    render() {
        const { value, instrument, shortForm, decimalScale, colorCalcValue, isNotThousand, isFormatZero, fitText } = this.props;
        // if (CommonUtils.isContainLetter(value)) {
        //     return (<span>{value}</span>);
        // }
        // if (!value && isFormatZero) {
        //     return (
        //         <NumberFormat
        //             value={decimalScale || (shortForm && value ? (!isNotThousand ? value / 1000 : parseFloat(value)).toFixed(2) : parseFloat(value)).toFixed(2)}
        //             renderText={fitText ? this.renderfitText : null}
        //             displayType={'text'}
        //             decimalScale={decimalScale && decimalScale || (shortForm ? 2 : 0)}
        //             fixedDecimalScale={false}
        //             thousandSeparator={','}
        //             decimalSeparator={'.'}
        //             className={getPriceColorClassName(colorCalcValue != null ? colorCalcValue : value, instrument)}
        //         />
        //     )
        // }

        // return (
        //     <NumberFormat
        //         value={decimalScale && value || (shortForm && value ? (!isNotThousand ? value / 1000 : parseFloat(value)).toFixed(2) : value)}
        //         displayType={'text'}
        //         renderText={fitText ? this.renderfitText : null}
        //         decimalScale={decimalScale && decimalScale || (shortForm ? 2 : 0)
        //         }
        //         fixedDecimalScale={false}
        //         thousandSeparator={','}
        //         decimalSeparator={'.'}
        //         className={getPriceColorClassName(colorCalcValue != null ? colorCalcValue : value, instrument)} />
        // );

        return (
            <NumberFormatCustom
                value={value}
                displayType={'text'}
                decimalScale={decimalScale}
                fixedDecimalScale={false}
                thousandSeparator={','}
                decimalSeparator={'.'}
                fitText={fitText}
                colorCalcValue={colorCalcValue}
                isFormatZero={isFormatZero}
                renderfitText={this.renderfitText}
                shortForm={shortForm}
                instrument={instrument}
                isNotThousand={isNotThousand}
                {...this.props}
            />
        );
    }
}

export default FormattedPrice;
