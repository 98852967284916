import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";

import DraggableModal from '../../components/Modal/DraggableModal';
import * as actions from "../../store/actions";
import { KeyCodeUtils } from '../../utils';

import './ConfirmModal.scss';

class ConfirmModal extends Component {

    state = {

    };

    constructor(props) {
        super(props);
        this.confirmBtnRef = React.createRef();
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) return;
            this.confirmBtnRef.current.click();
        }

    }

    toggle = () => {
        const { confirmModalProps } = this.props;
        this.props.setConfirmModalProps(false, confirmModalProps.messageId, null, {});
    }

    onCloseClick = () => {
        const { confirmModalProps } = this.props;
        if (confirmModalProps.closeHandle) {
            confirmModalProps.closeHandle(confirmModalProps.clProps);
        }
        this.toggle();
    }

    onAcceptClick = () => {
        const { confirmModalProps } = this.props;
        if (confirmModalProps.acceptHandle) {
            confirmModalProps.acceptHandle(confirmModalProps.cbProps);
        }
        this.toggle();
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handlerKeyDown);
    }

    componentDidUpdate(prevProps, prevState) {
        const { confirmModalProps } = this.props;
        const { confirmModalProps: prevConfirmModalProps } = prevProps;
        if (prevConfirmModalProps.isOpen !== confirmModalProps.isOpen) {
            if (confirmModalProps.isOpen === true) {
                document.removeEventListener('keydown', this.handlerKeyDown);
                document.addEventListener('keydown', this.handlerKeyDown);
            } else {
                document.removeEventListener('keydown', this.handlerKeyDown);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlerKeyDown);
    }

    render() {
        const { confirmModalProps } = this.props;

        const { options } = confirmModalProps || {};
        const { msgParams } = options || {};

        return (
            <DraggableModal
                isOpen={confirmModalProps.isOpen}
                toggle={this.toggle}
                onClose={this.toggle}
                className='confirm-modal'
                titleId="confirm-modal.title"
            >
                <div className="body">
                    <div className='col-12 text-center text-content' style={{ whiteSpace: "pre-wrap" }}>
                        <FormattedMessage id={confirmModalProps.messageId ? confirmModalProps.messageId : "confirm-modal.default-message"} values={msgParams} />
                    </div>
                    <div className="seperate-line"></div>
                    <div className="col-12">
                        <div className="btn-container text-center">
                            <button className="btn-refresh txt---500-16-24" onClick={this.onCloseClick} >
                                <FormattedMessage id="common.cancel" />
                            </button>
                            <button ref={this.confirmBtnRef} className="btn-ok" onClick={this.onAcceptClick}>
                                <FormattedMessage id="common.btn-ok" />
                            </button>
                        </div>
                    </div>
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = state => {
    return {
        confirmModalProps: state.app.confirmModalProps,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);