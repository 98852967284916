import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

class FormattedAmount extends Component {

    render() {
        return (
            <NumberFormat
                value={this.props.value}
                displayType={'text'}
                decimalScale={this.props.decimalScale ? this.props.decimalScale : 0}
                fixedDecimalScale={false}
                thousandSeparator={','}
                decimalSeparator={'.'} />
        );
    }
}

export default FormattedAmount;