import React, { Component } from 'react';
import './CustomMenuTab.scss';
import { FormattedMessage } from 'react-intl';

class CustomMenuTab extends Component {
    constructor(props) {
        super(props);
    }

    onChangeTabItem = (tab) => {
        const { onChangeTab } = this.props
        onChangeTab && onChangeTab(tab)
    };

    render() {
        const { activeTab, listTab, addClassTitle } = this.props;

        return (
            <div className="tab-menu">
                {listTab && listTab.length > 0 && listTab.map((item, index) => {
                    let classItem = "title-name "
                    if (addClassTitle) {
                        classItem = classItem + addClassTitle
                    }
                    if (listTab.length == 1) {
                        classItem = classItem + " active-tab "
                    }
                    if (activeTab === item.value) {
                        classItem = classItem + " active-tab "
                    }
                    return <div className="tab-item " onClick={() => this.onChangeTabItem(item.value)}>
                        <div className="wrap-tab-item item-center">
                            <div className={classItem}>
                                <span className='title'>
                                    <FormattedMessage id={item.title} />
                                </span>
                                {item.nextTitle && <span>
                                    {item.nextTitle(item)}
                                </span>}
                            </div>
                            {/* {listTab.length > 1 && <span className="title-line"></span>} */}
                        </div>
                    </div>
                })}
            </div>
        );
    }
}

export default CustomMenuTab;