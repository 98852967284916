import config from './combineConfig';
import reduxStore from './redux';
// import { State, StateStore } from "./utils";
import StateStore from 'utils/auth/StateStore';
import State from 'utils/auth/State';

const globalVar = window._env_
export const stateStore = new StateStore();

let ssoUrl = globalVar.api.API_BASE_URL + 'sso/';
let baseName = globalVar.app.ROUTER_BASE_NAME;
// let ssoUrl = 'http://localhost:1347/';

export const buildAuthorizeUrl = (data) => {
    const state = new State({ data: data });
    // stateStore.set(state.id, state);
    // let language = getValueFromLocalStorage(keyFactory.language);
    const stateStoreRedux = reduxStore.getState();
    let language = stateStoreRedux.app.language;
    language = language ? language : '';
    const scopes = ['account-read', 'account-write', 'order-read', 'order-write', 'balance-read', 'balance-write'].join(' ');
    return `${ssoUrl}oauth/authorize`
        + `?client_id=${encodeURIComponent(globalVar.api.CLIENT_ID)}`
        + `&response_type=code`
        + `&redirect_uri=${encodeURIComponent(globalVar.api.REDIRECT_URL)}`
        + `&scope=${encodeURIComponent(scopes)}`
        + `&state=${encodeURIComponent(state.id)}` + `&ui_locales=${language}`;
};

export const buildAuthorizeUrlLogedIn = (custodycd) => {
    const stateStoreRedux = reduxStore.getState();
    let usersTokens = localStorage.getItem('token-users') ? JSON.parse(localStorage.getItem('token-users')) : {};
    let name = custodycd && usersTokens && usersTokens[`${custodycd}`] ? usersTokens[`${custodycd}`]['name'] : '';
    let language = stateStoreRedux.app.language;
    let theme = stateStoreRedux.user.userInfo && stateStoreRedux.user.userInfo.defaultTheme;
    let inputCustodycd = custodycd ? custodycd : '';
    language = language ? language : '';
    return `${globalVar.api.WEB_HOST}/login?webtrade=true&language=${language}&name=${name}&username=${inputCustodycd}&theme=${theme}`; //WEB_HOST
};


// export const buildAuthorizeUrlLogedIn = (custodycd) => {
//     const stateStoreRedux = reduxStore.getState();
//     let usersTokens = localStorage.getItem('token-users') ? JSON.parse(localStorage.getItem('token-users')) : {};
//     let name = custodycd && usersTokens && usersTokens[`${custodycd}`] ? usersTokens[`${custodycd}`]['name'] : '';
//     let language = stateStoreRedux.app.language;
//     let theme = stateStoreRedux.user.userInfo && stateStoreRedux.user.userInfo.defaultTheme;
//     language = language ? language : '';
//     let inputCustodycd = custodycd ? custodycd : '';
//     return `${ssoUrl}loginToken`
//         + `?clientId=${encodeURIComponent(globalVar.api.CLIENT_ID)}`
//         + `&client_secret=${encodeURIComponent(globalVar.api.CLIENT_SECRET)}`
//         + `&grant_type=password`
//         + `&clientRedirect=${encodeURIComponent(globalVar.api.REDIRECT_URL)}`
//         + `&username=${inputCustodycd}`
//         + `&ui_locales=${language}`
//         + `&name=${name}`
//         + `&ui_theme=${theme}`;
// };

export const buildRefreshTokenUrl = () => {
    return `${ssoUrl}oauth/token`;
};

export const buildLogoutUrl = () => {
    if (globalVar.app.IS_LOGIN_POPUP == true || globalVar.app.IS_LOGIN_POPUP == 'local') {
        localStorage.setItem('sso-code', null)
        localStorage.setItem('sso-stateId', null)
    }
    return `${ssoUrl}oauth/logout?clientId=${globalVar.api.CLIENT_ID}`;
    // return `${ssoUrl}oauth/logoutfs2pro?from=${globalVar.api.REDIRECT_URL}`;
};

export const checkStateValid = (stateId) => {
    stateStore.clearStaleState(config.api.STATE_AGE);

    const state = stateStore.get(stateId);
    if (state) {
        const now = Date.now() / 1000;
        const age = config.api.STATE_AGE;
        const valid = state.created + age > now;
        return valid ? state : null;
    }
    return null;
};

export const removeState = (stateId) => {
    stateStore.remove(stateId);
};