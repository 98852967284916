import React, { Component } from 'react';

import './CustomIcon.scss'

class CustomIcon extends Component {
    render() {
        const { isSidebar, unavailableActive, src, alt } = this.props
        let className = 'custom-icon-asc'
        if (isSidebar === true && unavailableActive === true) {
            className = 'custom-icon-asc sidebar-icon unavailable-active'
        }
        if (isSidebar === true && !unavailableActive) {
            className = 'custom-icon-asc sidebar-icon'
        }
        return (
            <div className={className}>
                {/* <img  src={src} alt={alt ? alt : "icon"} /> */}
                {this.props.children}
            </div>
        );
    }

}


export default CustomIcon