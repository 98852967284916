import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { SkyLightStateless } from 'react-skylight';
import * as actions from "../../../store/actions";
import DraggableWrapper from '../../DraggableWrapper';
import _ from 'lodash';

import "./dragableSkyLight.scss";

const styles = {
    overlayStyles: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 98,
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
    },
    dialogStyles: {
        width: '700px',
        height: 'auto',
        overflowX: 'hidden',
        padding: '0px 0px 0px 0px',
        left: '50%',
        top: '50%',
        opacity: '1',
        zIndex: 98,
        borderRadius: '10px',
        border: '2px solid #888',
        // backgroundColor: '#222',
        boxShadow: '0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28)',
        maxHeight: '500px',
        transform: 'translate(-50%,-50%)',
        margin: 'unset'
    },
    closeDialogStyles: {
        position: 'absolute',
        fontSize: '1.8rem',
        right: '4px',
        top: '5px',
        color: '#f5f5f5',
        zIndex: 99
    }
};

class DraggableSkyLight extends Component {
    constructor(props) {
        super(props);
        this.draggableWrapper = React.createRef();
        this.state = {
            timeOpen: 0
        }
    };

    handlerKeyDown = (event) => {
        const { close, onEnter, isOpen, isDisableEscapeClose } = this.props;
        const keyCode = event.which || event.keyCode;
        if (!isDisableEscapeClose) {
            switch (keyCode) {
                case 27: // Escape\
                    setTimeout(() => {
                        if (this.isCheckCloseModalByKey()) {
                            event.preventDefault();
                            if (isOpen && close) close();
                        }
                    }, 100)
                    break;
                case 13: // Enter
                    event.preventDefault();
                    if (onEnter) {
                        onEnter();
                    }
                    break;
                default:
                    break;
            }
        }
    };

    componentDidUpdate() {
        if (this.props.isOpen === true) {
            let skylightDialogs = document.getElementsByClassName("skylight-dialog");
            if (skylightDialogs.length > 0) {
                const skylightDialog = skylightDialogs[0];
                let h2Tags = skylightDialog.getElementsByTagName("h2");
                if (h2Tags.length > 0) {
                    let titleClassName = h2Tags[0].className;
                    if (!titleClassName.includes("dragHandler")) h2Tags[0].className += " dragHandler";
                }
            }
        }
    }

    isCheckCloseModalByKey = () => {
        let { listTimeOpen, setIsOpenModalHaveData } = this.props
        const { timeOpen } = this.state
        if (listTimeOpen && listTimeOpen.length > 0) {
            const lastTime = listTimeOpen[listTimeOpen.length - 1]
            if (lastTime && lastTime == timeOpen) {
                return true
            }
        } else {
            return true
        }
        return false
    }

    componentDidMount() {
        const { listTimeOpen, setIsOpenModalHaveData } = this.props
        document.addEventListener('keydown', this.handlerKeyDown);
        const time = new Date().getTime();
        this.setState(({ timeOpen: time }))
        document.addEventListener('keydown', this.handlerKeyDown);
        listTimeOpen.push(time)
        setIsOpenModalHaveData("TimeOpenModal", {
            listTimeOpen
        })
    }


    componentWillUnmount() {
        let { listTimeOpen, setIsOpenModalHaveData } = this.props
        const { timeOpen } = this.state
        listTimeOpen = _.filter(listTimeOpen, item => item !== timeOpen)
        setIsOpenModalHaveData("TimeOpenModal", {
            listTimeOpen
        })
        document.removeEventListener('keydown', this.handlerKeyDown);
    }



    render() {
        let { isOpen, close, title, dialogStyles, overlayStyles, closeButtonStyle, isLight, widthMenuSideBar, className, defaultLeft = true, isDisableEscapeClose } = this.props;
        dialogStyles = { ...styles.dialogStyles, ...dialogStyles }
        let calcWidth = this.props.isLoggedIn ? widthMenuSideBar : '0px'
        let left = defaultLeft ? { left: `calc((100% - ${dialogStyles.width} + ${calcWidth})/2)` } : {}
        console.debug("skylight")
        return (
            <Fragment>
                {isLight ? '' : (isOpen ? (<div className="starLight-overlay-replacement"></div>) : '')}
                <DraggableWrapper
                    ref={this.draggableWrapper}
                    dragClass=".dragHandler"
                    wrapperClass="skylight-dialog"
                    className={className}

                >
                    <div className={`${this.props.className} Dragable-wrapper ` + (isOpen ? 'opening' + ' ' + widthMenuSideBar : "")}
                        style={left}
                    >
                        {isOpen && <SkyLightStateless
                            isVisible={isOpen}
                            dialogStyles={dialogStyles ? dialogStyles : styles.dialogStyles}
                            overlayStyles={overlayStyles ? overlayStyles : styles.overlayStyles}
                            closeButtonStyle={closeButtonStyle ? closeButtonStyle : styles.closeDialogStyles}
                            onCloseClicked={() => {
                                // !isDisableEscapeClose && close();
                            }}
                            title={title}
                        >
                            {this.props.children}
                        </SkyLightStateless>}
                    </div>
                </DraggableWrapper>
            </Fragment>
        )
    };
};

const mapStateToProps = state => {
    const menuHeader = state.user.userInfo && state.user.userInfo.menuHeader
    const widthMenuSideBar = menuHeader.menuSideBar.widthMenuSideBar
    return {
        widthMenuSideBar: widthMenuSideBar,
        isLoggedIn: state.user.isLoggedIn,
        listTimeOpen: state.layout.listIsOpenModal["C&B"].TimeOpenModal.listTimeOpen || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DraggableSkyLight);
