import merge from 'deepmerge';

import config from './config';

const internalConfig = {
    api: {
        BOND_API_BASE_URL: "https://192.168.1.242/bond/",
        // API_MARKET_URL: "http://192.168.1.41",
        // API_CHART_DATA_URL: "https://trading.dsc.com.vn/tvchart",
        // API_CHART_DATA_URL: "http://192.168.26.77/datafeed",
        //api lấy tin tức, đồ thị bên thứ 3
        // API_NEWS_SERVICE: "https://trading.dsc.com.vn/newsmarket/tvchart",

        SIGN_PLUGIN_CHROME_URL: 'http://127.0.0.1:11368/',
        SIGN_PLUGIN_NOT_CHROME_URL: 'http://127.0.0.1:11369/',
        STATE_AGE: 60 * 5,
        TIME_OUT: 100 * 1000
    },
    app: {
        /**
         * The base URL for all locations. If your app is served from a sub-directory on your server, you'll want to set
         * this to the sub-directory. A properly formatted basename should have a leading slash, but no trailing slash.
         */
        SERVER_DATE_FORMAT: "DD/MM/YYYY",
        PRICE_BOARD_URL: '/priceboard/checkLoginSSO',
        IS_CHECK_TRADE_QTTY: true,
        TIME_SKIP_EVENT_ACCOUNT: 1000,
    },

    constant: {
        //custodycd cua cty CK
        OWN_CUSTODYCD: "001P000000",
        //accountid cua ty ck
        OWN_ACCOUNTID: "0001900001",
        //huy.quang: paging page size
        ROW_PER_PAGE: 10,
        //huy.quang: interval in seconds
        RESEND_OTP_INTERVAL: 60,
    },

    switch: {
        enableRefreshToken: false,
        //huy.quang: config cac widget k move + resize dc
        isWidgetStatic: false,
        //huy.quang: config co cho doi config duyet lenh dat ko
        enableReconfirmOrderSwitch: false,
    },

    conditionOrderList: [
        { id: "GTC", nameId: "trade.advance-condition-order.gtc-full-name", isShow: true },
        { id: "STO", nameId: "trade.advance-condition-order.sto-full-name", isShow: false },
        { id: "SEO", nameId: "trade.advance-condition-order.seo-full-name", isShow: false },
        { id: "SO", nameId: "trade.advance-condition-order.so-full-name", isShow: false },
        { id: "OCO", nameId: "trade.advance-condition-order.oco-full-name", isShow: false },
        { id: "MCO", nameId: "trade.advance-condition-order.mco-full-name", isShow: false },
        { id: "OTO", nameId: "trade.advance-condition-order.oto-full-name", isShow: false },
        { id: "ICO", nameId: "trade.advance-condition-order.ico-full-name", isShow: false },
        { id: "PCO", nameId: "trade.advance-condition-order.pco-full-name", isShow: false },
        { id: "CPO", nameId: "trade.advance-condition-order.cpo-full-name", isShow: false },
        { id: "TSO", nameId: "trade.advance-condition-order.tso-full-name", isShow: false }
    ],

    // Cấu hình index hiển thị (department: index thuộc sở HOSE/HNX)
    ALL_INDEXES: [
        { title: 'VN-INDEX', code: 'HOSE', department: 'HOSE', collectionId: 'HOSE', searchKey: 'VNINDEX' },
        { title: 'VN30-INDEX', code: '30', department: 'HOSE', collectionId: 'VN30', searchKey: 'VN30' },
        { title: 'HNX-INDEX', code: 'HNX', department: 'HNX', collectionId: 'HNX', searchKey: 'HNXINDEX' },
        // { title: 'HNX30-INDEX', code: 'HNX30', department: 'HNX', collectionId: 'HNX30', searchKey: 'HNX30' },
        { title: 'UPCOM', code: 'UPCOM', department: 'HNX', collectionId: 'UPCOM', searchKey: 'UPCOM' },
        // { title: 'VNMID-INDEX', code: 'MID', department: 'HOSE', collectionId: 'MID', searchKey: 'VNMID' },
        { title: 'VN100-INDEX', code: '100', department: 'HOSE', collectionId: '100', searchKey: 'VN100' },
        // { title: 'VNSML-INDEX', code: 'SML', department: 'HOSE', collectionId: 'SML', searchKey: 'VNSML' },
        { title: 'VNXALL-INDEX', code: 'XALL', department: 'HOSE', collectionId: 'XALL', searchKey: 'VNXALL' },
        // { title: 'VNX50-INDEX', code: 'X50', department: 'HOSE', collectionId: 'X50' },
        // { title: 'VNSI-INDEX', code: 'SI', department: 'HOSE', collectionId: 'SI' },
        // { title: 'VNX200-INDEX', code: 'X200', department: 'HOSE', collectionId: 'X200' },
        // { title: 'VNALL-INDEX', code: 'ALL', department: 'HOSE', collectionId: 'ALL', searchKey: 'VNALL' },
        // { title: 'DIAMOND-INDEX', code: 'DIAMOND', department: 'HOSE', collectionId: 'DIAMOND', searchKey: 'VNDIAMOND' },
        // { title: 'FINLEAD-INDEX', code: 'FINLEAD', department: 'HOSE', collectionId: 'FINLEAD', searchKey: 'VNFINLEAD' },
        // { title: 'FINSELECT-INDEX', code: 'FINSELECT', department: 'HOSE', collectionId: 'FINSELECT', searchKey: 'VNFINSELECT' },
        // { title: 'HOSE-NEW-INDEX', code: 'NEW', department: 'HOSE', collectionId: 'HOSENEW', searchKey: 'VNNEW' }
    ],

    // Link file hướng dẫn
    // ALL_LINK: {
    //     vi: {
    //         TONG_HOP_TAI_SAN: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/2. Màn hình tổng hợp tài sản của tiểu khoản.pdf',
    //         GUI_TIEN_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.1 Gửi tiền iDeposit.pdf',
    //         RUT_TIEN_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.2 Rút tiền iDeposit.pdf',
    //         THAY_DOI_MON_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.3 Thay đổi món iDeposit.pdf',
    //         MUA_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/3.1 Mua trái phiếu.pdf',
    //         BAN_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/3.2 Bán lại và gia hạn trái phiếu.pdf',
    //         UNG_TRUOC: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.1 Ứng trước.pdf',
    //         DANG_KY_QUYEN_MUA: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.2 Đăng ký quyền mua.pdf',
    //         XAC_NHAN_LENH: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.3 Xác nhận lệnh.pdf',
    //         DANG_KY_CHUYEN_DOI_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.4 Đăng ký chuyển đổi trái phiếu.pdf'
    //     },
    //     en: {
    //         TONG_HOP_TAI_SAN: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/2. Account balance.pdf',
    //         GUI_TIEN_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.1 iDeposit depositing.pdf',
    //         RUT_TIEN_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.2 iDeposit withdrawing.pdf',
    //         THAY_DOI_MON_IDEPOSIT: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/1.3 iDeposit changing.pdf',
    //         MUA_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/3.1 Bond Purchasing.pdf',
    //         BAN_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/3.2 Bond Managing and Repurchasing.pdf',
    //         UNG_TRUOC: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.1 Cash Advance.pdf',
    //         DANG_KY_QUYEN_MUA: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.2 Purchase Right Register.pdf',
    //         XAC_NHAN_LENH: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.3 Order Confirmation.pdf',
    //         DANG_KY_CHUYEN_DOI_TRAI_PHIEU: 'https://bvsc.com.vn/Sites/QuoteVN/SiteRoot/Bwise/4.4 Convertible bonds Register.pdf'
    //     },
    // },
    ALL_LINK: {
        vi: {
            TONG_HOP_TAI_SAN: '',
            GUI_TIEN_IDEPOSIT: '',
            RUT_TIEN_IDEPOSIT: '',
            THAY_DOI_MON_IDEPOSIT: '',
            MUA_TRAI_PHIEU: '',
            BAN_TRAI_PHIEU: '',
            UNG_TRUOC: '',
            DANG_KY_QUYEN_MUA: '',
            XAC_NHAN_LENH: '',
            DANG_KY_CHUYEN_DOI_TRAI_PHIEU: '',
        },
        en: {
            TONG_HOP_TAI_SAN: '',
            GUI_TIEN_IDEPOSIT: '',
            RUT_TIEN_IDEPOSIT: '',
            THAY_DOI_MON_IDEPOSIT: '',
            MUA_TRAI_PHIEU: '',
            BAN_TRAI_PHIEU: '',
            UNG_TRUOC: '',
            DANG_KY_QUYEN_MUA: '',
            XAC_NHAN_LENH: '',
            DANG_KY_CHUYEN_DOI_TRAI_PHIEU: '',
        },
    },
    autoScrollTimePerRow: 1000 // millisecond - Thời gian scroll qua một dòng (Cảng nhỏ scroll càng nhanh)
};

const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray
var combineConfig = merge(internalConfig, config, { arrayMerge: overwriteMerge });

export default combineConfig;