import React from 'react';

import './ResizeSensor.scss';

export default class ResizeSensor extends React.Component {

    lastDimensions = {
        width: 0,
        height: 0,
        ref: null,
    };

    componentDidMount() {
        this.resetTriggers();
    }

    resetTriggers() {
        const {contract, expand} = this;
        if (!contract || !expand) return;

        contract.scrollLeft      = contract.scrollWidth;
        contract.scrollTop       = contract.scrollHeight;
        // $FlowIgnore we know it has a firstChild
        const firstChildStyle    = expand.firstChild.style;
        firstChildStyle.width    = expand.offsetWidth + 1 + 'px';
        firstChildStyle.height   = expand.offsetHeight + 1 + 'px';
        expand.scrollLeft        = expand.scrollWidth;
        expand.scrollTop         = expand.scrollHeight;
    }

    onScroll = () => {
        if (this.rafTimeout) {
            window.cancelAnimationFrame(this.rafTimeout);
        }
        this.rafTimeout = window.requestAnimationFrame(() => {
            if (!this.wrapper) return; // Unmounted
            const dimensions = this.getDimensions();

            if (dimensions && this.haveDimensionsChanged(dimensions)) {
                this.lastDimensions = dimensions;
                this.props.onResize(dimensions);
                this.resetTriggers();
            }
        });
    };

    getDimensions() {
        const el = this.wrapper;
        if (el && el.lastChild) {
            const ref = el.lastChild;
            return {
                width: ref.offsetWidth,
                height: ref.offsetHeight,
                ref // children
            };
        }
        return this.lastDimensions;
    }

    haveDimensionsChanged(dimensions) {
        return dimensions.width !== this.lastDimensions.width || dimensions.height !== this.lastDimensions.height;
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        // noinspection JSUnusedLocalSymbols
        const { onResize, ...rest } = this.props;
        const classNames = this.props.className ? ['resizesensor-wrapper', this.props.className].join(' ') : 'resizesensor-wrapper';
        return (
            <div {...rest} ref={(el) => (this.wrapper = el)} className={classNames}>
                {this.props.children}
                <div className="resizesensor-trigger">
                    <div ref={(el) => (this.expand = el)} onScroll={this.onScroll}>
                        <div />
                    </div>
                    <div className="resizeSensor-contract" ref={(el) => (this.contract = el)} onScroll={this.onScroll}>
                        <div style={{width: '200%', height: '200%'}} />
                    </div>
                </div>
            </div>
        );
    }
}
