// import { Column, LoadPanel, Pager, Paging, Scrolling, Selection, Summary, TotalItem } from "../../../services";
// import { LoadStatus, ToastUtil, LanguageUtils, activeOrderExecTypeDesc } from "../../../utils";
import DataGrid, {
    Selection, Column, Summary, TotalItem, Paging, Pager,
    Scrolling, LoadPanel, HeaderFilter
} from 'devextreme-react/data-grid';
import ScrollView from 'devextreme-react/scroll-view';
import CustomStore from 'devextreme/data/custom_store'; // https://supportcenter.devexpress.com/ticket/details/t217468/dxdatagrid-how-to-enable-data-operations-on-the-client-side
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import $ from 'jquery';
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
// import { injectIntl } from 'react-intl';
import { connect } from "react-redux";

import { LanguageUtils, Random } from 'utils';
import { emitter } from 'utils/EventEmitter';

// import RenderGridCellRealTime from '../../../components/Table/TableExtreme/RenderGridCellRealTime';
// import ElementResizeListener from './ElementResizeListener'
import RenderGridCell from './RenderGridCell';

// import { SelectBox } from 'devextreme-react/select-box';
// import themes from 'devextreme/ui/themes';
import './TableExtremeCustom2.scss'
// DOC_Themes: https://js.devexpress.com/Documentation/Guide/Themes_and_Styles/Predefined_Themes/
// import 'devextreme/dist/css/dx.common.css';
// import 'devextreme/dist/css/dx.dark.css';
// import 'devextreme/dist/css/dx.darkmoon.compact.css';
import 'devextreme/dist/css/dx.material.blue.dark.compact.css';

let dataSource = undefined;
const remoteOperationsCustoms = { groupPaging: true, paging: true, filtering: false, sorting: false, grouping: true, summary: true }
let store = undefined;
const _defaultPageSizes = 10;
const allowedPageSizes = [5, 10, 20, 50, 100, 200];
const maxTotalCount = 1000000000; // 1 tỷ row
let dataFilter = {}
let dataFilterStatus = {}
let isBlockRealtime = {}
let searchingStatus = {}
// let isAddCustomTopAndBottomSticky = false
// function addSumHtmlTop(addClass) {
//     // let _interval = setInterval(function () {
//     let _html = $('.TableDevExtreme .dx-datagrid-total-footer')
//     // //console.log('Haki.:==addSumHtml==_html', _html)
//     if (_html && _html.length > 0) {
//         _html.insertAfter('.TableDevExtreme .dx-datagrid-headers')
//         // _interval && clearInterval(_interval)
//         return null
//         // }
//     }
//     // }, 10)
// }

function fixCssWhenUsingFilter() {
    // dx-datagrid-rowsview dx-datagrid-nowrap dx-scrollable dx-visibility-change-handler dx-scrollable-both dx-scrollable-simulated dx-fixed-columns"
    $('.dx-datagrid-rowsview').removeClass('dx-fixed-columns')
}

function addSumHtmlTopAndBottomSticky(addClass) {
    // let table = $(`.${addClass} .dx-scrollable-wrapper tbody`)
    // set top
    let _htmlTop = $(`.${addClass} .dx-scrollable-wrapper tr.sticky-0`)
    _htmlTop.remove();
    let trTop = $(`.${addClass} .dx-scrollable-wrapper tbody tr.dx-row-lines:nth-child(1)`)
    _htmlTop.insertBefore(trTop);
    // _htmlTop.insertBefore(table);

    // set bottom
    let _htmlBottom = $(`.${addClass} .dx-scrollable-wrapper tr.sticky-1`)
    _htmlBottom.remove();
    let trBottom = $(`.${addClass} .dx-scrollable-wrapper tbody tr.dx-freespace-row`)
    _htmlBottom.insertBefore(trBottom);
    // _htmlBottom.insertAfter(table);
    return null;
}

function enadbleActionPager(status, addClass) {
    // Chặn click liên tục khi chưa load xong data
    if (status) {
        $(`.${addClass} .dx-datagrid-pager`).css({ 'pointer-events': 'inherit', 'cursor': 'inherit' });
        $(`.${addClass} .dx-next-button`).css({ 'pointer-events': 'inherit', 'cursor': 'inherit' });
    } else {
        $(`.${addClass} .dx-datagrid-pager`).css({ 'pointer-events': 'none', 'cursor': 'not-allowed' });
        $(`.${addClass} .dx-next-button`).css({ 'pointer-events': 'none', 'cursor': 'not-allowed' });
    }
}

// function enadbleLoadingCustom(addClass) {
//     $(`.${addClass} .dx-datagrid-headers .dx-datagrid-scrollable-simulated:first-child`).addClass('loading');
//     let timer = setTimeout(function () {
//         $(`.${addClass} .dx-datagrid-headers .dx-datagrid-scrollable-simulated:first-child`).removeClass('loading');
//         clearTimeout(timer) 
//     }, 100)
// }

// let check = false
// let count2 = 0
// function addSumHtmla() {
//     let _interval = setInterval(function () {
//         let _html = $('.dx-datagrid-filter-row .dx-command-select')
//         let _htmlCheck = $('.TableDevExtreme .dx-datagrid-total-footer')
//         // //console.log('Haki.:==addSumHtml==_html', _html)
//         if (_html && _html.length > 0 && _htmlCheck && _htmlCheck.length > 0) {
//             // let a = document.getElementsByClassName('dx-command-select')
//             //console.log("binh----_html", _html)
//             // //console.log("binh----_html--a", a)
//             const para = document.createElement("p");
//             para.innerHTML = "This is a paragraph.";
//             _html[0].appendChild(para);
//             _interval && clearInterval(_interval)
//             // if (count2 > 20) _interval && clearInterval(_interval)
//             // count2++
//             return null
//             // }

//         }
//     }, 10)
// }

function getHeight(addClass) {
    let height = $(`.${addClass}-tableEx`).height()
    if (height != 0 && height < 294) {
        height = 294
    }
    return height
}
function getWidth(addClass) {
    let width = $(`.${addClass}-tableEx`).width()
    return width
}

function fakeApiLoad() {
    // fake call api
    return Promise.resolve({ data: [], totalCount: 0 });
    // return Promise.resolve({ data: [] });
}

function apiLoad(records) {
    // fake call api
    if (records && records.length > 0) {
        return Promise.resolve({ data: records, totalCount: records.length }) // bắt buộc set fix lỗi pre pages });
    }
    else {
        return Promise.resolve({ data: [], totalCount: 0 });
        // return Promise.resolve({ data: [] });
    }
}
let selectAllCheckBox = null
let checkBoxUpdating = false
const globalVar = window._env_
const TABLE_EX_CONFIG = globalVar.app.TABLE_EX_CONFIG
const pushAggregationTimeout = TABLE_EX_CONFIG.pushAggregationTimeout || undefined
const scroll_realtime = TABLE_EX_CONFIG.scroll_realtime || "standard"
class TableExtremeCustom2 extends Component {
    ORDER_ID_LENGTH = 6;
    /**
    * @isForBroker: thuoc tinh quyet dinh xem co phai so lenh moi gioi khong
    */
    props = {
        ...this.props,
    };
    initialState = {
        width: undefined,
        height: undefined,
        columns: undefined,
        dataSource: undefined,
        store: undefined,
        keyRealTimeRender: undefined,
        pageSizes: undefined,
        highlightChanges: false,
        isReloadDataTableEX: true,
        // selectAllCheckBox: null,
        // checkBoxUpdating: false
    }
    state = {
        ...this.initialState
    };
    constructor(props) {
        super(props);
        this.quantityRef = React.createRef();
        this.gridRef = React.createRef();
        this.idTableEx = Random.randomComponentId();
        this.listenToTheEmitter();
    };
    contentRef = React.createRef();


    callback = (obj) => {

        // console.debug("tableEx.:isBlockRealtime.:3", isBlockRealtime[this.idTableEx], obj)
        if (isBlockRealtime[this.idTableEx]) {
            return null;
        } else {
            this.updateRealTime(obj ? obj : '');
        }
    }

    insertDatxRealTime = (obj) => {
        const { data, action } = obj;
        // console.log('laojahackgame=======> SIGNAL RECEIVE 123',obj);
        const self = this;
        let { store } = this.state
        let allRecords = dataFilter[self.idTableEx];
        if (store && store.push) {
            if (action === 'open') {
                dataFilter[self.idTableEx].push(data); // Cập nhật lại dataFilter để tính toán lại lúc cập nhật giá thị trường
                store.push([
                    { type: "insert", data, index: 0 }
                ]);
            } else {
                // store.push([
                //     { type: "update", key: data.id, data }
                // ]);
                // Xóa dòng trong data và insert lại lên đầu
                let findIndex = _.findIndex(allRecords, item => { return item.id === data.id });
                if (findIndex >= 0) {
                    dataFilter[self.idTableEx][findIndex] = data; // Cập nhật lại dataFilter để tính toán lại lúc cập nhật giá thị trường
                }
                store.push([
                    { type: "remove", key: data.id },
                    { type: "insert", data, index: 0 }
                ]);
            }
        }

    }


    insertDatx = (obj) => {
        // if (isBlockRealtime[this.idTableEx]) {
        //     return null;
        // } else {
        this.insertDatxRealTime(obj);
        // }
    }

    callbackNoCheck = (obj) => { // Tạm thêm để fix Datx nhận signal
        this.updateRealTime(obj ? obj : '');
    }


    inserRealtimeTrade = (trade, count) => {
        if (trade) {
            this.insertRealTime(trade, count);
        }
    }

    // Chỉ update lại các dòng có chứa key cần cập nhật và có trạng thái khác đã bán do với các tín hiệu đã bán sẽ không có giá trị trường và không phải cập nhật(VD: theo symbol)
    updateRealtimeByKey = (obj) => {
        if (obj) {
            let { instrument } = obj
            let { keyStoreUpdate } = instrument;
            let self = this
            let { keyRealTime, convertDataRealTime } = this.props
            let { store } = this.state

            let allRecords = dataFilter[self.idTableEx];
            // console.log('laojahackgame=====> UPDATE INSTRUMENTS 123456', { allRecords })
            let dataPush = [];
            if (allRecords && allRecords.length > 0) {
                let recordsToUpdate = _.filter(allRecords, item => {
                    // return (item[`${keyRealTime}`] === keyStoreUpdate && item['status'] !== 'sold')  // Cập nhật cho các tín hiệu có trạng thái khác đã bán
                    return (item[`${keyRealTime}`] === keyStoreUpdate)
                });
                // console.log('laojahackgame====> UPDATE BY KEY', { recordsToUpdate, keyRealTime, keyStoreUpdate });
                if (recordsToUpdate && recordsToUpdate.length > 0) {
                    // Tính toán và gán lại giá trị giá thị trường ở đây
                    recordsToUpdate = convertDataRealTime(recordsToUpdate, instrument);
                    // recordsToUpdate.forEach(item => {
                    //     item['market'] = instrument['CP'];
                    // })
                    dataPush = recordsToUpdate.map(item => {
                        return { type: 'update', key: item.id, data: item }
                    })
                }

            }

            if (store && store.push && dataPush && dataPush.length > 0) {
                // console.log('laojahackgame====> UPDATE BY KEY PUSH', { rowsUpdate: dataPush.length, keyStoreUpdate });
                store.push(dataPush);
            }

        }
    }

    listenToTheEmitter() {
        // emitter.on("UPDATE_REALTIME_EXTREME", this.callback);
        const { emitType } = this.props;
        if (emitType && emitType.length > 0) {
            if (emitType.includes('DATX_INSERT')) {
                emitter.on("DATX_INSERT", this.insertDatx);
                emitter.on("UPDATE_REALTIME_EXTREME", this.updateRealtimeByKey);
            }
            if (emitType.includes('UPDATE_REALTIME_EXTREME')) {
                emitter.on("UPDATE_REALTIME_EXTREME", this.callback);
            }
            if (emitType.includes('TRADEEXCHANGE')) {
                let count = 1;
                emitter.on('TRADEEXCHANGE', trade => {
                    count++;
                    this.inserRealtimeTrade(trade, count);
                });
            }
        }
    }

    resize = () => {
        let { addClass } = this.props
        if (this.contentRef.current) {
            let self = this
            let timer = setTimeout(function () {
                self._setState({ height: getHeight(addClass), width: getWidth(addClass) })
                clearTimeout(timer)
            }, 50)
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted || this.willMount) {
            this.setState(obj, callback);
        }
    }

    UNSAFE_componentWillMount() {
        this.willMount = true
        // this.loadData();
        let { defaultPageSizes } = this.props
        let pageSizes = defaultPageSizes ? defaultPageSizes : _defaultPageSizes
        // let timer = setTimeout(function () {
        this._setState({ pageSizes: pageSizes }) // tạm sửa do state bị set chậm trong didmount (pageSizes từ state bị undefined trong createTableEx) 
    }

    getWidth = (num) => {
        num = Math.round(num * 100) / 100
        return num
    }

    onSizeColumn = (columns) => {
        let widthTable = getWidth(this.props.addClass)
        // let widthTable = getWidth(this.props.addClassParent)
        widthTable = widthTable - 2.5 // Hardcode - 2.5 fix loi scroll ngang man hinh rong
        if (!widthTable) {
            //console.log('onSizeColumn==0===', columns, widthTable)
            return columns
        }
        //console.log('onSizeColumn==0.1===', columns, widthTable)
        let self = this;
        let sumMinWidth = 0
        let sumWidth = 0
        let countColumnVisible = 0
        _.map(columns, function (e, i) {
            if (!e.listColumnGroup && e.minWidth) {
                sumMinWidth = self.getWidth(sumMinWidth) + self.getWidth(e.minWidth)
                sumWidth = self.getWidth(sumWidth) + self.getWidth(e.width)
            }
            if (e.listColumnGroup) {
                let isFirstVisible = false
                e.listColumnGroup = _.map(e.listColumnGroup, function (e2) {
                    sumMinWidth = self.getWidth(sumMinWidth) + self.getWidth(e2.minWidth)
                    sumWidth = self.getWidth(sumWidth) + self.getWidth(e2.width)
                    if (e2.visible) {
                        if (isFirstVisible) countColumnVisible++
                        isFirstVisible = true
                    }
                    return e2
                })

            }
            if (e.visible) {
                countColumnVisible++
            }
            return e
        })
        // alert(this.props.addClassParent + '+' +   countColumnVisible)
        let h = self.getWidth(widthTable - sumMinWidth);;
        //console.log('onSizeColumn==1===', h, widthTable, sumMinWidth, sumWidth)
        if (h <= 0) {
            // set width = minWith
            _.map(columns, function (e, i) {
                if (!e.listColumnGroup && e.minWidth) {
                    e.width = e.minWidth
                }
                if (e.listColumnGroup) {
                    e.listColumnGroup = e.listColumnGroup.map(function (e2) {
                        e2.width = e2.minWidth
                        return e2
                    })
                }
                return e
            })
            return columns
        }
        h = self.getWidth(h / countColumnVisible);
        //console.log('onSizeColumn==2===', h, widthTable, sumMinWidth, columns)
        _.map(columns, function (e, i) {
            if (!e.listColumnGroup && e.minWidth) {
                // e.minWidth = self.getWidth(e.minWidth) + self.getWidth(h)
                // //console.log('onSizeColumn==3===', columns, columns, e.listColumnGroup)
                e.width = self.getWidth(e.minWidth) + self.getWidth(h)
                // e.width = self.getWidth(widthTable/15)
            }
            if (e.listColumnGroup) {
                //console.log('onSizeColumn==4===', columns, columns, e.listColumnGroup)
                e.listColumnGroup = _.map(e.listColumnGroup, function (e2, i2) {
                    e2.width = self.getWidth(e2.minWidth) + self.getWidth(h)
                    return e2
                    // }
                })

            }
            return e
            // //console.log('onSizeColumn==_columns====', sumWith)
            // _columns.push(e)
        })
        //console.log('onSizeColumn==5===', columns, sumMinWidth, widthTable)
        return columns
    }


    resetVerticalScroll = () => {
        if (this.gridRef) {
            this.gridRef.current.instance && this.gridRef.current.instance.getScrollable() && this.gridRef.current.instance.getScrollable().scrollTo({ top: 0 });
        }
    };

    loadData = (dt) => {
        let self = this
        let { userInfo } = this.props;
        let storageKey = 'LookUpOrderNew-' + userInfo.username
        // storageKey = storageKey && storageKey.columns
        if (dt) {
            // BUILD LẠI CẤU TRÚC columns THEO CẤU TRÚC CỦA columnsDefault
            let newColumns = []
            let _newColumns = []
            Object.keys(dt).forEach((key) => {
                let item = this.props.columnsDefault.find((element) => element.dataField === dt[key].dataField)
                if (item) {
                    let newColumn = { ...item, fixed: dt[key].fixed, visible: dt[key].visible, visibleIndex: dt[key].visibleIndex }
                    newColumns.push(newColumn)
                    _newColumns.push(dt[key])
                }
            })
            if (self.dataGrid) {
                let oldColumns = self.dataGrid._controllers.columns._columns
                let newColumnsRender = []
                Object.keys(dt).forEach((key, index) => {
                    let item = oldColumns.find((element) => element.dataField === dt[key].dataField)
                    if (item) {
                        let newColumn = { ...item, fixed: dt[key].fixed, visible: dt[key].visible, visibleIndex: dt[key].visibleIndex }
                        newColumnsRender.push(newColumn)
                    }
                })
                self.dataGrid._controllers.columns._columns = newColumnsRender
                self.dataGrid._controllers.columns._columnsUserState = newColumns
                self.dataGrid._controllers.adaptiveColumns._columns = newColumnsRender
                self.dataGrid._controllers.adaptiveColumns._columnsController._columnsUserState = newColumns
                this.props.reloadData()
            }
        }
        else {
            let data = localStorage.getItem(storageKey)
            if (data) {
                let columns = data && data.columns ? JSON.parse(data).columns : []
                // BUILD LẠI CẤU TRÚC columns THEO CẤU TRÚC CỦA columnsDefault
                let columnsSort = _.orderBy(columns, ['visibleIndex'], ['asc']);
                let newColumns = []
                Object.keys(columnsSort).forEach((key) => {
                    let item = this.props.columnsDefault.find((element) => element.dataField === columnsSort[key].dataField)
                    if (item) {
                        let newColumn = { ...item, fixed: columnsSort[key].fixed, visible: columnsSort[key].visible }
                        newColumns.push(newColumn)
                    }
                }
                );
                self._setState({ columns: newColumns })
                // self._setState({ columns: columnsDefault2 })
            }
            else {
                self._setState({ columns: this.props.columnsDefault })
                // localStorage.setItem(storageKey, JSON.stringify(columnsDefault))
            }
        }
    }


    addBtnCancel = () => {
        const { onHandleCancel } = this.props
        // let _interval = setInterval(function () {
        let _getclass = $('.dx-datagrid-filter-row .dx-command-select')
        if (_getclass && _getclass.length > 0) {
            _getclass[0].innerHTML = "Hủy";
            _getclass[0].onclick = onHandleCancel
            // _interval && clearInterval(_interval)
            return null
        }
        // }, 200)
    }

    clearDataSource = () => {
        // return null; // bỏ vì phát sinh vấn đề hiệu năng.
        if (this.dataGrid) {
            this.dataGrid.refresh(); // off do gọi  this.dataGrid.option('dataSource', []) là table cũng tự refresh
            this.dataGrid.option('dataSource', []); // fix để [] thay vì để null thì bị lỗi gen lệch cột dữ liệu khi spam tìm kiếm
            if (this.props.isPager) {
                this.dataGrid.pageIndex(0);
            }
        }
    }

    clearSelection = () => {
        if (this.dataGrid) {
            this.dataGrid.clearSelection();
        }
    }


    apiLoadAllByPagingResLoop = async (obj, records = []) => {
        //console.log("apiLoadAllByPagingResLoop START=", obj, records)
        let self = this
        let { apiLoadAllByPagingRes } = this.props
        return await apiLoadAllByPagingRes(obj).then(async (data) => {
            // data = JSON.stringify(data)
            // data = JSON.parse(data)
            // //console.log('createTableEx.:apiPaging.:2=', data)
            // if (data && data.d && data.d.length > 0) {
            //     data = data.d
            // }
            if (data && data.d && data.d.length > 0) {
                records = [...records, ...data.d]
            }
            if (data && data.nextIndex > -1) {
                let _obj = {
                    page: data.nextIndex,
                    limitRow: obj.limitRow
                }
                // add data
                //console.log("apiLoadAllByPagingResLoop", records)
                return await self.apiLoadAllByPagingResLoop(_obj, records)
            } else {
                return records
            }
        })
        // return records
    }

    changeTableEx = (onReloadData, isFirstCreate) => {
        const { keyStoreUpdate, isInsertNewRows, keyRealTime, reshapeOnPush } = this.props;
        const { store } = this.state;
        this.dataGrid.clearSorting();
        //VIXEXT-1362 // Fix lỗi các bảng có phân trang khi đang nhập filter trên header ==> nhấn tìm kiếm thì bảng bị clear không ra dữ liệu
        // Đã test ảnh hưởng với iss VIXEXT-967 không ảnh hưởng
        dataFilter[this.idTableEx] = []  // reset dataFilter  
        dataFilterStatus[this.idTableEx] = false
        if (this.dataGrid) {
            this.dataGrid.pageIndex(0);
        }
        dataSource = new DataSource({
            key: keyStoreUpdate,
            store: store,
            reshapeOnPush: (isInsertNewRows || reshapeOnPush === false) ? false : true,// Tính tổng realTime
            pushAggregationTimeout: (keyRealTime || reshapeOnPush === false) ? pushAggregationTimeout : undefined // Thời gian tổng hợp trước khi push (https://js.devexpress.com/jQuery/Documentation/ApiReference/Data_Layer/DataSource/Configuration/#pushAggregationTimeout)
            // cacheRawData: false,
            // paginate: false,
            // pageSize: 1000,
        });
        this._setState({ dataSource: dataSource });

        return null;
    }

    createTableEx = async (onReloadData, isFirstCreate, data = []) => {
        // if (!isFirstCreate) {
        // this.clearDataSource();
        // }
        // isAddCustomTopAndBottomSticky = false
        let self = this;
        // alert('createTableEx')
        //temp_Realtime socket: https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/SignalRService/React/Light/
        //temp_CustomStore : https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CustomDataSource/React/Light/
        const { keyStoreUpdate, apiLoadAll, apiPaging, apiLoadAllByPagingRes, apiPagingReturnTotal, convertData, defaultPageSizes, addClass, isInsertNewRows, reshapeOnPush, keyRealTime, storeType } = this.props;
        const { pageSizes } = this.state;
        //console.log('createTableEx.:START.:onReloadData=', onReloadData, self.props.onReloadData)
        dataFilter[self.idTableEx] = []  // reset dataFilter
        if (storeType == "ArrayStore" && keyRealTime) {
            if (onReloadData !== "UPDATE_BY_NEW_DATA" && onReloadData !== "NOT_CALL_API") {
                let obj = {}
                data = await apiLoadAll(obj)
            }
            isBlockRealtime[this.idTableEx] = true
            store = new ArrayStore({
                key: keyStoreUpdate,
                data: data,
            });
            dataSource = new DataSource({
                key: keyStoreUpdate,
                store: store,
                reshapeOnPush: (isInsertNewRows || reshapeOnPush === false) ? false : true,// Tính tổng realTime
                pushAggregationTimeout: (keyRealTime || reshapeOnPush === false) ? pushAggregationTimeout : undefined // Thời gian tổng hợp trước khi push (https://js.devexpress.com/jQuery/Documentation/ApiReference/Data_Layer/DataSource/Configuration/#pushAggregationTimeout)
                // cacheRawData: false,
                // paginate: false,
                // pageSize: 1000,
            });
            let isReloadDataTableEX = !this.state.isReloadDataTableEX
            isBlockRealtime[this.idTableEx] = false
            this._setState({ dataSource: dataSource, store: store, isReloadDataTableEX });
            return null;
        }
        if (apiLoadAll) {
            //console.log('createTableEx.:apiLoadAll.:0=')
            // new CustomStore co ve bi cham nen chi chay createTableEx() 1 lan
            store = new CustomStore({
                // loadMode: "processed",
                key: keyStoreUpdate,
                load: async (loadOptions) => {
                    loadOptions.requireTotalCount = false // yêu cầu trả ra tổng row
                    //console.log('createTableEx.:apiLoadAll.:1=', onReloadData, self.props.onReloadData)
                    if (self.props.onReloadData == "NOT_CALL_API") {
                        return fakeApiLoad()
                    }
                    // enadbleActionPager(false, addClass)
                    let obj = {}
                    return apiLoadAll(obj)
                        // .then((response) => response.json())
                        .then(async (data) => {
                            //console.log('createTableEx.:apiLoadAll.:1=1', data)
                            // data = JSON.stringify(data)
                            // data = JSON.parse(data)
                            //console.log('createTableEx.:apiLoadAll.:1=2', data)
                            // if (data && data.d) {
                            //     //console.log('createTableEx.:apiLoadAll.:2=')
                            //     if (data.d.length > 0) {
                            //         //console.log('createTableEx.:apiLoadAll.:3=')

                            //         //console.log("apiLoadAll---: ", data)
                            //         data = data.d
                            //     }
                            // }
                            //console.log('createTableEx.:apiLoadAll.:1=3', data)
                            // enadbleActionPager(true, addClass)
                            // data = (convertData ? convertData(data) : data) || []
                            // dataFilter[self.idTableEx] = [...dataFilter[self.idTableEx], ...data] //
                            dataFilter[self.idTableEx] = data;
                            return ({
                                data: data,
                                totalCount: data.length
                            })
                        }).catch(() => { throw new Error('apiLoadAll Loading Error'); });
                },
                onLoading: function (loadOptions) {
                    // console.debug("tableEx.:onLoading" )
                    isBlockRealtime[self.idTableEx] = true
                },
                onLoaded: function (result) {
                    // console.debug("tableEx.:onLoaded" )
                    isBlockRealtime[self.idTableEx] = false
                },
                // onPush: (changes) => {
                //     // console.debug("tableEx.:onPush" )
                // },
                // onUpdated: function (key, values) {
                //     // console.debug("tableEx.:onUpdated" )
                //     // Your code goes here
                // },
                // onUpdating: function (key, values) {
                //     // Your code goes here
                //     // console.debug("tableEx.:onUpdating" )
                // }

            });
        }
        if (apiPaging) {
            //console.log('createTableEx.:apiPaging.:0=')
            store = new CustomStore({
                // loadMode: "processed",
                key: keyStoreUpdate,
                load(loadOptions) {
                    loadOptions.requireTotalCount = false // k yêu cầu trả ra tổng row
                    //console.log('createTableEx.:apiPaging.:1=', onReloadData, self.props.onReloadData)
                    if (self.props.onReloadData == "NOT_CALL_API") {
                        enadbleActionPager(false, addClass)
                        return fakeApiLoad()
                    }
                    if (searchingStatus[self.idTableEx] === false || !searchingStatus[self.idTableEx]) {
                        if (dataFilterStatus[self.idTableEx] == false && (loadOptions.skip == undefined || loadOptions.take == undefined)) {
                            dataFilterStatus[self.idTableEx] = true
                            // tìm kiếm lấy từ Memmory dataFilter
                            return (dataFilter[self.idTableEx] || [])
                        }
                        else {
                            if (dataFilterStatus[self.idTableEx] == true) {
                                // Xóa điều kiện lọc
                                dataFilterStatus[self.idTableEx] = false
                                // xóa điều kiện tìm kiếm lọc dataFilter theo số lượng loadOptions.take
                                // reset số lượng dataFilter về take page đầu tiên tránh trường hợp api bị gọi lại khi thay đổi page Size
                                dataFilter[self.idTableEx] = dataFilter[self.idTableEx].slice(0, loadOptions.take)
                                if (loadOptions.take) {
                                    return dataFilter[self.idTableEx] || []
                                }
                                return (dataFilter[self.idTableEx] || [])
                            }
                        }
                    } else {
                        searchingStatus[self.idTableEx] = false;
                        dataFilter[self.idTableEx] = []  // reset dataFilter  
                        dataFilterStatus[self.idTableEx] = false
                    }
                    let page = loadOptions.skip ? loadOptions.skip : 0;
                    let limitRow = loadOptions.take ? loadOptions.take : pageSizes;

                    // console.log('laojahackgame======> CALL API paging', { page, limitRow })

                    //console.log('createTableEx.:apiPaging.:1.1=', loadOptions, page, limitRow)
                    // currLimitRow = limitRow

                    // if (loadOptions.take != currLimitRow) {
                    //     loadOptions.skip = 0
                    //     page = 1
                    // }
                    // else {
                    //     if (page >= maxIndex[loadOptions.take] && maxIndex[loadOptions.take]) {
                    //         return { data: [] }
                    //     }
                    // }
                    enadbleActionPager(false, addClass)
                    let obj = {
                        page,
                        limitRow,
                        sort: loadOptions['sort']
                    }
                    //console.log('createTableEx.:apiPaging.:1.5=', onReloadData);
                    return apiPaging(obj)
                        .then((data) => {
                            // data = JSON.stringify(data)
                            // data = JSON.parse(data)
                            if (data && data.d && data.d.length > 0) {
                                data = data.d
                            }
                            enadbleActionPager(true, addClass)
                            // //console.log('createTableEx.:apiPaging.:3=', convertData(data))
                            // //console.log('createTableEx.:apiPaging.:3=', _maxTotalCount)
                            //data = (convertData ? convertData(data) : data) || []
                            dataFilter[self.idTableEx] = [...dataFilter[self.idTableEx], ...data]
                            // dataFilter[self.idTableEx] = _.unionBy(dataFilter[self.idTableEx], data, keyStoreUpdate);
                            return ({
                                data: data,
                                totalCount: maxTotalCount // bắt buộc set fix lỗi pre pages
                            })
                        }).catch(() => {
                            throw new Error('apiPaging Loading Error');
                        });

                },
                // onLoading: function (loadOptions) {
                //     // console.debug("tableEx.:onLoading" )
                //     isBlockRealtime[self.idTableEx] = true
                // },
                // onLoaded: function (result) {
                //     // console.debug("tableEx.:onLoaded" )
                //     isBlockRealtime[self.idTableEx] = false
                // },
            });
        }

        if (apiLoadAllByPagingRes) {
            //console.log('createTableEx.:apiLoadAllByPagingRes.:0=')
            store = new CustomStore({
                // loadMode: "processed",
                key: keyStoreUpdate,
                load: async (loadOptions) => {
                    loadOptions.requireTotalCount = true // yêu cầu trả ra tổng row
                    //console.log('createTableEx.:apiLoadAllByPagingRes.:1=', onReloadData, self.props.onReloadData)
                    if (self.props.onReloadData == "NOT_CALL_API") {
                        enadbleActionPager(false, addClass)
                        return fakeApiLoad()
                    }

                    // if (loadOptions.skip == undefined || loadOptions.take == undefined) {
                    //     return dataFilter[self.idTableEx]
                    // }

                    let page = loadOptions.skip ? loadOptions.skip : 0;
                    let limitRow = loadOptions.take ? loadOptions.take : pageSizes;
                    // //console.log('createTableEx.:apiLoadAllByPagingRes.:1=', loadOptions, page, limitRow)
                    enadbleActionPager(false, addClass)
                    let obj = {
                        page,
                        limitRow: limitRow // Tạm Hardcode max dữ liệu để giảm số lượng Api
                    }
                    //console.log('createTableEx.:apiLoadAllByPagingRes.:1.5=', obj)
                    let records = []
                    records = await self.apiLoadAllByPagingResLoop(obj, records)
                    enadbleActionPager(true, addClass)
                    //console.log('createTableEx.:apiLoadAllByPagingRes.:1.6=', records)
                    dataFilter[self.idTableEx] = [...dataFilter[self.idTableEx], ...records]
                    // dataFilter[self.idTableEx] = _.unionBy(dataFilter[self.idTableEx], data, keyStoreUpdate);
                    return apiLoad(records)
                },
            });
        }

        if (apiPagingReturnTotal) {
            store = new CustomStore({
                // loadMode: "processed",
                key: keyStoreUpdate,
                load(loadOptions) {
                    loadOptions.requireTotalCount = true // yêu cầu trả ra tổng row
                    //console.log('createTableEx.:apiPagingReturnTotal.:0=', onReloadData, self.props.onReloadData)
                    if (self.props.onReloadData == "NOT_CALL_API") {
                        enadbleActionPager(false, addClass)
                        return fakeApiLoad()
                    }


                    if (dataFilterStatus[self.idTableEx] == false && (loadOptions.skip == undefined || loadOptions.take == undefined)) {
                        dataFilterStatus[self.idTableEx] = true
                        return ({
                            data: dataFilter[self.idTableEx] || [],
                            totalCount: dataFilter[self.idTableEx] ? dataFilter[self.idTableEx].length : 0 // bắt buộc set fix lỗi pre pages
                        })
                    }
                    else {

                        if (dataFilterStatus[self.idTableEx] == true) {
                            dataFilterStatus[self.idTableEx] = false
                            // xóa điều kiện tìm kiếm lọc dataFilter theo số lượng loadOptions.take
                            // reset số lượng dataFilter về take page đầu tiên tránh trường hợp api bị gọi lại khi thay đổi page Size
                            dataFilter[self.idTableEx] = dataFilter[self.idTableEx].slice(0, loadOptions.take)
                            if (loadOptions.take) {
                                return ({
                                    data: dataFilter[self.idTableEx] || [],
                                    totalCount: dataFilter[self.idTableEx] ? dataFilter[self.idTableEx].length : 0 // bắt buộc set fix lỗi pre pages
                                })
                            }
                            return ({
                                data: dataFilter[self.idTableEx] || [],
                                totalCount: dataFilter[self.idTableEx] ? dataFilter[self.idTableEx].length : 0 // bắt buộc set fix lỗi pre pages
                            })
                        }
                    }
                    let page = loadOptions.skip ? loadOptions.skip + 1 : 0;
                    let limitRow = loadOptions.take ? loadOptions.take : pageSizes;
                    //console.log('createTableEx.:apiPagingReturnTotal.:1=', loadOptions, page, limitRow)
                    enadbleActionPager(false, addClass)
                    let obj = {
                        page,
                        limitRow
                    }
                    return apiPagingReturnTotal(obj)
                        .then((data) => {
                            // data = JSON.stringify(data)
                            // data = JSON.parse(data)
                            //console.log('createTableEx.:apiPagingReturnTotal.:2=', data)
                            if (data && data.d && data.d.length > 0) {
                                data = data.d
                            }
                            enadbleActionPager(true, addClass)
                            // //console.log('createTableEx.:apiPagingReturnTotal.:3=', convertData(data))
                            // data = (convertData ? convertData(data) : data) || []
                            dataFilter[self.idTableEx] = [...dataFilter[self.idTableEx], ...data]
                            // dataFilter[self.idTableEx] = _.unionBy(dataFilter[self.idTableEx], data, keyStoreUpdate);
                            return ({
                                data: data,
                                totalCount: data.totalCount,
                                // summary: data.summary,
                                // groupCount: data.groupCount,
                            })
                        }).catch(() => { throw new Error('apiPagingReturnTotal Loading Error'); });
                },
            });
        }
        dataSource = new DataSource({
            key: keyStoreUpdate,
            store: store,
            reshapeOnPush: (isInsertNewRows || reshapeOnPush === false) ? false : true,// Tính tổng realTime
            pushAggregationTimeout: (keyRealTime || reshapeOnPush === false) ? pushAggregationTimeout : undefined // Thời gian tổng hợp trước khi push (https://js.devexpress.com/jQuery/Documentation/ApiReference/Data_Layer/DataSource/Configuration/#pushAggregationTimeout)
            // cacheRawData: false,
            // paginate: false,
            // pageSize: 1000,
        });
        this._setState({ dataSource: dataSource, store: store });

        return null;
    }

    componentDidMount() {
        this.mounted = true
        // window.addEventListener("resize", this.resize.bind(this));
        this.createTableEx("NOT_CALL_API");
        let { addClass, defaultPageSizes } = this.props
        // let pageSizes = defaultPageSizes ? defaultPageSizes : _defaultPageSizes
        // let timer = setTimeout(function () {
        this._setState({ height: getHeight(addClass), width: getWidth(addClass) })
        let isCheckIdTableEx = _.findIndex(Object.keys(isBlockRealtime), (e) => e == this.idTableEx)
        if (isCheckIdTableEx > -1) {
            this.idTableEx = Random.randomComponentId()
        }
        isBlockRealtime[this.idTableEx] = false
        dataFilterStatus[this.idTableEx] = false
        dataFilter[this.idTableEx] = []
        // clearTimeout(timer)    
        // }, 50)
    }

    componentDidUpdate(prevProps, prevState) {
        let { onReloadData, OBJLAYOUT, isInsertNewRows, keyRealTime, storeType } = this.props
        if (onReloadData !== prevProps.onReloadData) {
            if (this.props.apiPaging) {
                searchingStatus[this.idTableEx] = true;
            }
            this.resize();
            this.resetVerticalScroll();
            // let scl = $(".TableDevExtreme")[0].find('.dx-datagrid-rowsview').dxScrollable('instance');
            // scl.scrollTo({ left: 0 });
            // let instance = DataGrid.instance()
            // console.log('laojahackgame======> UPDATE', $(".TableDevExtreme").find('.dx-scrollview-content')[0]);
            // $(".TableDevExtreme").find('.dx-scrollview-content')[0].scrollLeft( -300 )

            // document.getElementsByClassName("TableDevExtreme")[0].getElementsByClassName('dx-scrollview-content')[0].scrollRight += 300;
            // this.dataGrid.scrollTo(0);

            if (this.props.isUseSelection) {
                if (!this.props.keepSelection) this.clearSelection();
                // https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/MultipleRecordSelectionAPI/React/Light/
            }
            // this.createTableEx(onReloadData); // Chi khoi tao 1 lan duy nhat
            if (storeType == "ArrayStore" && keyRealTime) {
                this.createTableEx(undefined, undefined)
            } else {
                if (keyRealTime) {
                    isBlockRealtime[this.idTableEx] = true
                    this.clearDataSource();
                } else {
                    this.changeTableEx(onReloadData); // Chi khoi tao 1 lan duy nha
                }
            }
            // isBlockRealtime = false
        }
        //console.log('componentDidUpdate.: OBJLAYOUT=', OBJLAYOUT, prevProps.OBJLAYOUT, OBJLAYOUT !== prevState.OBJLAYOUT)
        if (OBJLAYOUT !== prevState.OBJLAYOUT) {
            if (this.props.isUseSelection) {
                if (!this.props.keepSelection) this.clearSelection();
                // https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/MultipleRecordSelectionAPI/React/Light/
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // Chỉ reload lại tableEx nếu onReloadData có sự thay đổi
        const props = this.props;
        const state = this.state;
        //console.log('shouldComponentUpdate.: 1', props.addClass, state.width, getWidth(props.addClass))
        //console.log('shouldComponentUpdate.: 2', props.addClass, state.height, getHeight(props.addClass))

        // nextState.width, getWidth(props.addClass), getHeight(props.addClass))
        let checkRender = props.onReloadData !== nextProps.onReloadData
            || props.addClass !== nextProps.addClass
            // || state.width !== getWidth(props.addClass)
            // || state.height !== getHeight(props.addClass)
            || props.OBJLAYOUT !== nextProps.OBJLAYOUT
            || props.keyRealTime !== nextProps.keyRealTime
            || state.isReloadDataTableEX !== nextState.isReloadDataTableEX
        // || props.isActiveTabBrowser !== nextProps.isActiveTabBrowser // Bỏ do Gây chậm load giao diện
        // || state.keyRealTimeRender !== nextState.keyRealTimeRender
        // || this.props.keyRealTime
        //console.log('shouldComponentUpdate.:0', checkRender, props.onReloadData !== nextProps.onReloadData, props.addClass !== nextProps.addClass, state.width !== getWidth(props.addClass), state.height !== getHeight(props.addClass), props.OBJLAYOUT !== nextProps.OBJLAYOUT, this.props.keyRealTime)
        return checkRender
    }
    componentWillUnmount() {
        delete isBlockRealtime[this.idTableEx] // leak Ram
        delete dataFilter[this.idTableEx] // leak Ram
        delete dataFilterStatus[this.idTableEx] // leak Ram
        delete searchingStatus[this.idTableEx] // leak Ram
        this.idTableEx = undefined
        emitter.removeListener("UPDATE_REALTIME_EXTREME", this.callback);
        // window.removeEventListener("resize", this.resize.bind(this));
        clearInterval(this.interval);
        this.clearDataSource()
        store = undefined
        dataSource = undefined
        this.quantityRef = undefined;
        this.gridRef = undefined;
        this._setState({ ...this.initialState })
        this.mounted = false
    }

    get dataGrid() {
        // // //console.log('Haki123.:dataGrid=', this.gridRef, this.gridRef.current)
        return this.gridRef.current && this.gridRef.current.instance;
    }

    setReloadDataBySocket = (newRecords) => {
        let { keyStoreUpdate, keyRealTime } = this.props
        // console.debug("setReloadDataBySocket.: statt=", newRecords, store._items)
        let { dataSource, store } = this.state
        if (newRecords && store && store.push && dataSource) {
            let oldData = dataSource._items || []
            // if (newRecords.length == 0) {
            //     _.map(oldData, (item) => {
            //         let _item = _.cloneDeep(item)
            //         let key = _item[keyStoreUpdate]
            //         store.push([
            //             { type: "remove", key: key }
            //         ]);
            //     })
            //     return null;
            // }
            let listDT_Insert = []
            let listDT_Remove = []
            listDT_Insert = _.differenceBy(newRecords, oldData, keyStoreUpdate);
            listDT_Remove = _.differenceBy(oldData, newRecords, keyStoreUpdate);

            // 
            if ((listDT_Insert && listDT_Insert.length > 0) || (listDT_Remove && listDT_Remove.length > 0)) {
                // reload 1 lần nữa khi có thay đổi về key(Do không tạo dược logic sắp xếp đúng theo thứ tụ api )
                // case này ít xảy ra cho màn DMDT
                // console.debug("setReloadDataBySocket.: clearDataSource=", oldData, newRecords, listDT_Insert, listDT_Remove)
                // this.clearDataSource(newRecords);
                this.createTableEx("UPDATE_BY_NEW_DATA", undefined, newRecords); // load lai all du lieu theo newRecords
                return null;
            }

            // if (listDT_Remove) {
            //     _.map(listDT_Remove, (item, index) => {
            //         let _item = _.cloneDeep(item)
            //         let key = _item[keyStoreUpdate]
            //         store.push([
            //             { type: "remove", key: key }
            //         ]);
            //     })
            // }

            // console.debug("setReloadDataBySocket.: update=", oldData, newRecords)
            // insert or update
            let _dataPush = []
            _.map(newRecords, (item, index) => {
                let _item = _.cloneDeep(item)
                let key = _item[keyStoreUpdate]
                let data = _item
                // store.push([{ type: 'update', key: key, data }]);
                _dataPush.push({ type: 'update', key: key, data })
                // let isInsert = _.findIndex(listDT_Insert, (e) => e[keyStoreUpdate] == key)
                // if (isInsert >= 0) {
                //     store.push([
                //         { type: "insert", data: data, index  } // index không hoạt động do reshapeOnPush : true để tính tổng realtime.
                //     ]);
                // } else {
                // store.push([{ type: 'update', key: key, data }]);s
                // }

            })
            if (_dataPush && _dataPush.length > 0) {
                store.push(_dataPush);
            }
        } else {
        }
    }

    updateRealTime = (obj) => {
        // console.debug("tableEx.:isBlockRealtime.:1")
        let { storeType, keyRealTime } = this.props
        if (storeType == "ArrayStore" && keyRealTime) {
            if (isBlockRealtime[this.idTableEx]) {
                return null;
            }
        }
        if (obj) {
            let { instrument, isInsert } = obj
            let self = this
            // //console.log('RealTime.:updateRealTime.:data=======1', obj, oldData.closePrice, data.CP)
            let { keyStoreUpdate, convertDataRealTime } = this.props
            // data = convertDataRealTime ? convertDataRealTime(oldData, data) : data
            let { store } = this.state
            // //console.log("updateRealTime_start", instrument)
            let _newRecords = convertDataRealTime ? convertDataRealTime(instrument) : []
            let newRecords = _.cloneDeep(_newRecords)

            // //console.log("updateRealTime_newRecords", newRecords)
            if (newRecords && store && store.push) {
                // thêm  keyStoreUpdate cho data
                // //console.log("updateRealTime_3", data[keyStoreUpdate])
                let _dataPush = []
                _.map(newRecords, (item, index) => {
                    // //console.log("updateRealTime_map", item, item[keyStoreUpdate], keyStoreUpdate)
                    let _item = _.cloneDeep(item)
                    let key = _item[keyStoreUpdate]
                    let data = _item
                    // if (isBlockRealtime[self.idTableEx]) {
                    //     // console.debug("tableEx.:isBlockRealtime.:2")
                    //     return null;
                    // }
                    if (isInsert === true) {
                        // store.push([
                        //     { type: "insert", data: data, index: 0 }
                        // ]);
                        _dataPush.push({ type: "insert", data: data, index: 0 })

                    } else {
                        // store.push([{ type: 'update', key: key, data }]);
                        _dataPush.push({ type: 'update', key: key, data })

                    }
                    // store.push([{ type: 'update', key: key, data }]);
                    // store.push([{ type: 'update', key: item[keyStoreUpdate], item }]);
                })
                if (_dataPush && _dataPush.length > 0) {
                    store.push(_dataPush);
                }
                // //console.log('RealTime===>updateRealTime.:dataSource', keyStoreUpdate, data[keyStoreUpdate], data, oldData)

            } else {
                //console.log('updateRealTime.:Error.:dataSource not push data!')
            }
        }
    }

    updateRealTime_test = () => {
        let self = this
        //console.log('dataSource.:', dataSource, self.dataGrid)
        let data = {
            "symbol": "AAA",
            "basicPrice": 66666666666666666666666666666666666,
            "basicPriceAmt": 1000,
            "price": 123456789,
            "dayMax": 21.040537,
            "dayMin": 20.2174,
            "dayOpen": 17.9,
            "lastUpdate": "2022-09-28T00:37:58.4961781-07:00",
            "change": 2.944552,
            "percentChange": 16.45
        }
        if (store && store.push) {
            store.push([{ type: 'update', key: 'AAA', data }]);
            let keyRealTimeRender = 'AAA' + new Date().getTime()
            self._setState({ keyRealTimeRender: keyRealTimeRender })
        }
        // this.dataGrid.refresh(true)
    }

    // renderSwitch = () => {
    //     let { keyRealTime } = this.props
    //     //console.log("renderSwitch")
    //     return (e) => <RenderGridCellRealTime
    //         keyRealTime={keyRealTime}
    //         data={e.row.data}
    //         updateRealTime={this.updateRealTime}
    //     />;
    // }

    insertRealTime = (data, count) => {
        let self = this
        //console.log('RealTime.:insertRealTime.:data=======1', data)
        // data = convertDataRealTime ? convertDataRealTime(oldData, data) : data
        let { store, pageSizes } = this.state
        //data.SB = data.count + "__" + data.SB;
        data.count = count;
        data.KEYTRANSLOG = count;
        //data.count = data.SB + new Date().getTime();

        if (store && store.push) {
            store.push([
                { type: "insert", data: data, index: 0 }
            ]);
            let keyRemove = parseInt(data.count) - parseInt(pageSizes)
            //console.log('RealTime.:insertRealTime.:data=======2', keyRemove)
            if (keyRemove > 0) {
                // alert(data.count+"__"+pageSizes)
                store.push([
                    { type: "remove", key: keyRemove }
                ]);
            }
            let keyRealTimeRender = data.SB + new Date().getTime();
            //console.log("ptv====>keyRealTimeRender", keyRealTimeRender, store);
            // self._setState({ keyRealTimeRender: keyRealTimeRender })
        }

    }
    renderSwitchInsert = () => {
        // let { getDataInsertNewRow } = this.props
        // //console.log("ptv===>123123")
        // return (e) => <RenderGridCellInsertRealTime
        //     data={e.row.data}
        //     insertRealTime={this.insertRealTime}
        //     //getDataInsertNewRow = {getDataInsertNewRow}
        // />;
    }

    // tmp_Selection.: https://github.com/DevExpress-Examples/devextreme-datagrid-disable-selecting-certain-rows/blob/19.2.4%2B/React/src/App.js
    isSelectable(item) {
        const { keyDisableCheckBox, disableConFunc } = this.props
        //console.log('isSelectable.:', item)
        // return true

        let disableByConditionFunc = true;

        if (disableConFunc) {
            disableByConditionFunc = disableConFunc(item);
        }

        let disableByKey = keyDisableCheckBox ? item[keyDisableCheckBox] : true;
        return disableByConditionFunc && disableByKey;
        // return keyDisableCheckBox
    }

    isSelectAll = (dataGrid) => {
        // return false;
        let items = [];
        // if (dataGrid && dataGrid.getDataSource()) {
        //     dataGrid.getDataSource().store().load().done(function (data) {
        //         items = data; // items không được đợi => lỗi items = []
        //     });
        // }
        if (dataGrid && dataGrid._controllers && dataGrid._controllers.data && dataGrid._controllers.data._dataSource) {
            items = dataGrid._controllers.data._dataSource._items
            // //console.log('isSelectAll.:1.2', items, dataGrid._controllers.data._dataSource._items)
        }
        // //console.log('isSelectAll.:1.3', items)
        if (items && items.length > 0) {
            let selectableItems = items.filter(e => this.isSelectable(e));
            let selectedRowKeys = dataGrid.option("selectedRowKeys");
            if (!selectedRowKeys.length) {

                return false;
            }
            //console.log('isSelectAll.:2', items, selectedRowKeys.length, selectableItems.length)
            //kết hợp aria-checked=mixed => css để ẩn giao diện [-] nếu return false
            return selectedRowKeys.length >= selectableItems.length ? true : undefined;
        }
        else {
            return false;
        }
    }
    onSelectionChanged = (e) => {
        let deselectRowKeys = [];
        e.selectedRowsData.forEach((item) => {
            if (!this.isSelectable(item))
                deselectRowKeys.push(e.component.keyOf(item));
        });
        if (deselectRowKeys.length) {
            e.component.deselectRows(deselectRowKeys);
        }
        //console.log('onSelectionChanged.:', { e, selectAllCheckBox, value: this.isSelectAll(e.component), selectedRowsData: e.selectedRowsData });
        // this._setState({
        //     'checkBoxUpdating': true
        // });
        checkBoxUpdating = true
        selectAllCheckBox && selectAllCheckBox.option("value", this.isSelectAll(e.component));
        checkBoxUpdating = false
        // this.state.selectAllCheckBox && this.state.selectAllCheckBox.option("value", this.isSelectAll(e.component));
        // this._setState({
        //     'checkBoxUpdating': false
        // });
        // truyền list ds selectedRowKeys theo keyStoreUpdate ra parent để xử lý submit()
        if (this.props.onSelectionChanged && deselectRowKeys.length === 0) {
            let obj = {
                selectedRowKeys: e.selectedRowKeys,
                selectedRowsData: e.selectedRowsData
            }
            let { addClass } = this.props
            let classTable = addClass && `${addClass}-tableEx`
            this.props.onSelectionChanged(obj, classTable, this.dataGrid, selectAllCheckBox)
        }
    }
    onEditorPreparing = (e) => {
        // //console.log('onEditorPreparing', e)
        let dataGrid = e.component;
        if (e.command === "select") {
            if (e.parentType === "dataRow" && e.row) {
                if (!this.isSelectable(e.row.data))
                    e.editorOptions.disabled = true;
            } else if (e.parentType === "headerRow") {
                e.editorOptions.onInitialized = (e) => {
                    selectAllCheckBox = e.component
                    // this._setState({
                    //     'selectAllCheckBox': e.component
                    // })
                };
                e.editorOptions.value = this.isSelectAll(dataGrid);
                e.editorOptions.onValueChanged = (e) => {
                    if (!e.event) {
                        if (e.previousValue && !checkBoxUpdating) {
                            e.component.option("value", e.previousValue);
                        }
                        // if (e.previousValue && !this.state.checkBoxUpdating) {
                        //     e.component.option("value", e.previousValue);
                        // }
                        return;
                    }
                    if (this.isSelectAll(dataGrid) === e.value) {
                        return;
                    }
                    e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
                    e.event.preventDefault();
                }
            }
        }
    }

    onOptionChanged = (e) => {
        if (e.fullName == 'paging.pageSize' && e.name == 'paging') {
            // thay đổi pageSize thì set mặc định pageIndex = 0 (về trang 1)
            let currentTake = e.previousValue || undefined
            if (e.value !== currentTake) {
                if (this.dataGrid) {
                    this.dataGrid.option('paging.pageIndex', 0);
                }
                currentTake = undefined
            }
        }
    }
    // onRowPrepared = (e, addClass) => {
    //     //console.log('onRowPrepared==', e)
    //     enadbleLoadingCustom(addClass)
    // }


    addActionToSelection = () => {
        // let { dataSource } = this.state
        //console.log('addActionToSelection.:', this.dataGrid, dataSource)
        let dataGrid = this.dataGrid
        if (dataGrid && dataGrid._controllers && dataGrid._controllers.data && dataGrid._controllers.data._dataSource) {
            if (dataGrid._controllers.data._dataSource._items && dataGrid._controllers.data._dataSource._items.length > 0) {
                let { addClass } = this.props
                let classTable = addClass && `${addClass}-tableEx`
                this.props.addActionToSelection && this.props.addActionToSelection(classTable)
            }
        }
    }

    onContentReady = (e) => {
        //console.log('onContentReady==', e)
        this.addActionToSelection()
        // this.addBtnCancel()
        // addSumHtmlTop() // Chuyển sum lên sau header&filter
        // let { addClass, isCustomTopAndBottomSticky } = this.props
        // if (isCustomTopAndBottomSticky && !isAddCustomTopAndBottomSticky) {
        // addSumHtmlTopAndBottomSticky(e, addClass) // add thêm Top và Bottom Sticky. VD: màn hình sao kê tiền
        // }
        if (this.props.isCustomTopAndBottomSticky) {
            addSumHtmlTopAndBottomSticky(this.props.addClass) // add thêm Top và Bottom Sticky. VD: màn hình sao kê tiền
        }
        this.props.contentReadyCallback && this.props.contentReadyCallback();
    }

    customizePriceColor(data) {
        let price = RenderGridCell.renderGridCellPrice("10000", 'PRICE', true)
        return price
    }

    onCellPrepared = (e) => {
        fixCssWhenUsingFilter()
        if (this.props.customStyleTotalColumn) {
            this.props.customStyleTotalColumn(e);
        }
    }

    onRowPrepared = (e) => {
        if (this.props.customStyleTopAndBottomSticky) {
            this.props.customStyleTopAndBottomSticky(e);
        }
    }

    onRowDblClick = (e) => {
        if (this.props.onRowDblClick) {
            this.props.onRowDblClick(e);
        }
    }

    onRowClick = (e) => {
        if (this.props.onRowClick) {
            this.props.onRowClick(e);
        }
    }

    renderColumns = (_columns) => {
        const { addClass, totalDefault, isPager, apiLoadAll, apiPaging,
            apiPagingReturnTotal, keyRealTime, isLoadPanel, focusedRowEnabled,
            defaultPageSizes, isShowLoadingCustom, showFilter, isCustomTopAndBottomSticky, isDeskTop } = this.props
        let self = this
        return (
            // <>
            _columns && _.map(_columns, item => {
                // _columns && _columns.map((item, i) => {
                let renderSwitch, isRealTime
                if (item.listColumnGroup && item.listColumnGroup.length > 0) { //group column
                    isRealTime = keyRealTime === item.dataField
                    // //console.log('isRealTime', isRealTime, keyRealTime, item.dataField)
                    renderSwitch = self.props.renderSwitch
                    return (
                        <Column
                            dataField={item.dataField}
                            caption={LanguageUtils.getMessageByKey(this.props.returnCaption(item.dataField), this.props.language)}
                            //caption={this.props.returnCaption(item.dataField)}
                            //https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/columns/#cellTemplate
                            cellRender={item.isCellRaw == true ? null : renderSwitch(item.dataField)}
                            // cellRender={this.props.renderSwitch(item.dataField)}
                            fixed={item.fixed}
                            fixedPosition={item.fixedPosition} // Cố định cột
                            allowReordering={item.allowReordering}  // không cho di chuyển
                            width={item.width}
                            alignment={item.alignment}
                            visible={item.visible}
                            visibleIndex={item.visibleIndex}
                            headerFilter={{
                                dataSource: dataFilter[self.idTableEx]
                            }}
                            format={item.isCellRaw == true && item.format}
                        >
                            {item.listColumnGroup && _.map(item.listColumnGroup, itemGroup => {
                                // {item.listColumnGroup.map((itemGroup, indexGroup) => {
                                isRealTime = keyRealTime === item.dataField
                                renderSwitch = self.props.renderSwitch
                                return (
                                    <Column
                                        dataField={itemGroup.dataField}
                                        caption={LanguageUtils.getMessageByKey(this.props.returnCaption(itemGroup.dataField), this.props.language)}
                                        //caption={this.props.returnCaption(itemGroup.dataField)}
                                        // cellRender={this.props.renderSwitch(itemGroup.dataField)}
                                        // cellRender={renderSwitch(itemGroup.dataField)}
                                        cellRender={itemGroup.isCellRaw == true ? null : renderSwitch(itemGroup.dataField)}
                                        fixed={isDeskTop ? item.fixed : false}
                                        fixedPosition={itemGroup.fixedPosition} // Cố định cột
                                        allowReordering={itemGroup.allowReordering}  // không cho di chuyển
                                        width={itemGroup.width}
                                        alignment={itemGroup.alignment}
                                        visible={itemGroup.visible}
                                        visibleIndex={itemGroup.visibleIndex}
                                        filterOperations={['contains', '=']}  // Mặc định điều kiện search like
                                        allowFiltering={item.allowFiltering == false ? false : true}
                                        headerFilter={{
                                            dataSource: dataFilter[self.idTableEx]
                                        }}
                                        format={itemGroup.isCellRaw == true && itemGroup.format}
                                    />
                                )
                            })}
                        </Column>
                    )
                } else {
                    isRealTime = keyRealTime === item.dataField
                    // //console.log('isRealTime', isRealTime, keyRealTime, item.dataField)
                    renderSwitch = self.props.renderSwitch
                    let captionHeader = this.props.returnCaption(item.dataField)
                    if (typeof captionHeader === 'function') {
                        return (
                            <Column
                                dataField={item.dataField}
                                // caption={LanguageUtils.getMessageByKey(this.props.returnCaption(item.dataField), this.props.language)}
                                headerCellRender={captionHeader}
                                //aption={this.props.returnCaption(item.dataField)}
                                cellRender={item.isCellRaw == true ? null : renderSwitch(item.dataField)}
                                fixed={isDeskTop ? item.fixed : false} fixedPosition={item.fixedPosition} // Cố định cột
                                allowReordering={item.allowReordering}  // không cho di chuyển
                                width={item.width}
                                alignment={item.alignment}
                                visible={item.visible}
                                visibleIndex={item.visibleIndex}
                                filterOperations={['contains', '=']}  // Mặc định điều kiện search like
                                allowFiltering={item.allowFiltering == false ? false : true}
                                allowHeaderFiltering={item.allowHeaderFiltering == false ? false : true}
                                allowSorting={item.allowSorting == false ? false : true}
                                headerFilter={{
                                    dataSource: dataFilter[self.idTableEx]
                                }}
                                format={item.isCellRaw == true && item.format}
                            >
                                <HeaderFilter allowSelectAll={false}>
                                </HeaderFilter>
                            </Column>
                        )
                    } else {
                        return (
                            <Column
                                dataField={item.dataField}
                                caption={LanguageUtils.getMessageByKey(captionHeader, this.props.language)}
                                //aption={this.props.returnCaption(item.dataField)}
                                cellRender={item.isCellRaw == true ? null : renderSwitch(item.dataField)}
                                fixed={isDeskTop ? item.fixed : false} fixedPosition={item.fixedPosition} // Cố định cột
                                allowReordering={item.allowReordering}  // không cho di chuyển
                                width={item.width}
                                alignment={item.alignment}
                                visible={item.visible}
                                visibleIndex={item.visibleIndex}
                                filterOperations={['contains', '=']}  // Mặc định điều kiện search like
                                allowFiltering={item.allowFiltering == false ? false : true}
                                allowHeaderFiltering={item.allowHeaderFiltering == false ? false : true}
                                allowSorting={item.allowSorting == false ? false : true}
                                headerFilter={{
                                    dataSource: dataFilter[self.idTableEx]
                                }}
                                format={item.isCellRaw == true && item.format}

                            >
                                <HeaderFilter allowSelectAll={false}>
                                </HeaderFilter>
                            </Column>
                        )
                    }
                }
            }
            )
            // </>
        )
    }


    renderSumCustomByPnlrate = () => {
        const { renderSumCustomExt } = this.props
        if (renderSumCustomExt) return renderSumCustomExt("pnlrate", this.dataGrid)
    }

    renderOneColumnTotal = () => {
        const sefl = this
        return (
            <Summary
            >
                <TotalItem
                    name={this.props.columnNameCount}
                    column={this.props.columnNameCount}
                    summaryType="count"
                    alignment="left"
                    customizeText={sefl.renderOneColumnTotalCustomizeText} />
            </Summary>

        )
    }


    renderOneColumnTotalCustomizeText = () => {
        const { language, idLabelForColumnTotal } = this.props
        const sefl = this
        if (sefl.dataGrid) {
            const txt = LanguageUtils.getMessageByKey(idLabelForColumnTotal, language)
            const count = this.dataGrid.getTotalSummaryValue(this.props.columnNameCount)
            return txt + ' ' + count
        } else {
            return null
        }
    }

    calculateCustomSummary = (options) => {
        // const { listData } = this.props
        // //console.log("calculateCustomSummary.:BEGIN== 0",options.component.getDataSource(),options,options.name,options.summaryProcess,options.component.getTotalSummaryValue("pnlamt"), options.component.getTotalSummaryValue("costPriceAmt"))
        // //console.log("calculateCustomSummary.:BEGIN== 1",options.component.getTotalSummaryValue("pnlamt"), options.component.getTotalSummaryValue("costPriceAmt"))
        // Calculating "customSummary1"
        if (options.name == "sumPnlrate") {
            switch (options.summaryProcess) {
                case "start":
                    // //console.log("calculateCustomSummary.:start==", options.totalValue)
                    options.totalValue = 0;
                    options.costPriceAmt = 0;
                    options.pnlamt = 0;
                    options.totalpnl = 0;
                    break;
                case "calculate":
                    // sumPnlrate: item.costPrice + "|" + item.pnlamt
                    let a = options.value.split("|")
                    let costPriceAmt = a[0]
                    let pnlamt = a[1]
                    let totalpnl = a[2]

                    options.totalpnl += Number(totalpnl || 0)
                    options.costPriceAmt += Number(costPriceAmt || 0)
                    options.pnlamt += Number(pnlamt || 0)
                    // //console.log("calculateCustomSummary.:calculate==", options.pnlamt, options.costPriceAmt)
                    break;
                case "finalize":
                    // if (listData && listData.length > 0) {
                    if (options.costPriceAmt) {
                        options.totalValue = Number(options.pnlamt / options.costPriceAmt) * 100
                    } else {
                        if (options.totalpnl != 0) {
                            options.totalValue = 100
                        } else {
                            options.totalValue = 0
                        }
                    }
                    // return "999"
                    // Assigning the final value to "totalValue" here
                    // //console.log("calculateCustomSummary.:finalize==", options.pnlamt, options.costPriceAmt, options.totalValue)
                    break;
                // }
            }
            // let _sumPnlamt = options.component.getTotalSummaryValue("pnlamt");
            // let _sumCostPriceAmt = options.component.getTotalSummaryValue("costPriceAmt");

            // if (_sumPnlamt != undefined && _sumCostPriceAmt!= undefined){
            //     options.totalValue = Number(_sumPnlamt) /Number(_sumCostPriceAmt)
            //     //console.log("calculateCustomSummary.:BEGIN== 2",options.summaryProcess,_sumPnlamt, _sumCostPriceAmt,  options.totalValue)
            // }
            // //console.log("calculateCustomSummary.:BEGIN== 3",options.summaryProcess,_sumPnlamt, _sumCostPriceAmt,  options.totalValue)
            // if (!options.summaryProcess){
            //     options.totalValue = "99999"
            // }

        }
    }

    renderSummary(totalDefault) {
        const { language } = this.props
        let self = this
        return (
            totalDefault && <Summary
                recalculateWhileEditing={true}
                calculateCustomSummary={this.calculateCustomSummary}
            >
                {_.map(totalDefault, item => {
                    if (item.isSumCustom) { // Sửa tạm custom sum
                        if (item.column == "sumPnlrate") {
                            return (
                                <TotalItem
                                    name={item.column}
                                    column={item.column}
                                    showInColumn={item.showInColumn || item.column}
                                    summaryType={item.summaryType}
                                    displayFormat={item.displayFormat === "total" ? language === "en" ? "Total" : "Tổng" : item.displayFormat}
                                    valueFormat={item.valueFormat}
                                    // customizeText={self.renderSumCustomByPnlrate}
                                    alignment={item.alignment ? item.alignment : "right"} //Cho truyền alignment từ props
                                />
                            )
                        }
                    }
                    return (
                        <TotalItem
                            name={item.column}
                            column={item.column}
                            showInColumn={item.showInColumn || item.column}
                            summaryType={item.summaryType}
                            displayFormat={item.displayFormat === "total" ? language === "en" ? "Total" : "Tổng" : item.displayFormat}
                            valueFormat={item.valueFormat}
                            alignment={item.alignment ? item.alignment : "right"} //Cho truyền alignment từ props
                        />
                    )
                })}
            </Summary>
        )
    }

    renderInfoText = (i0, i1, i2) => {
        // alert(i2)
        return (i2 != -1) ? (`Trang ${i0}/${i1} (${i2} dòng)`) :
            (`Trang ${i0}/${i1}`)
    }


    getScrolling = () => {
        const { isPager, infiniteScroll, isCustomTopAndBottomSticky, legacyMode, storeType, keyRealTime } = this.props;


        if (isPager === true) {
            return (
                <Scrolling
                    //mode="virtual"
                    //legacyMode={true} // fix lỗi scroll bị auto top https://supportcenter.devexpress.com/ticket/details/t1046754/data-grid-incorrect-scrolling-in-virtual-mode
                    useNative={true}
                    //scrollByContent={false}
                    //scrollByThumb={true}
                    showScrollbar={"onHover"}
                />
            )
        }

        if (infiniteScroll === true) {
            return (
                <Scrolling
                    mode={'infinite'}
                    // showScrollbar={"onHover"}
                    rowRenderingMode="virtual"
                    useNative={true}
                    // preloadEnabled={true}
                    legacyMode={true}
                // columnRenderingMode="virtual"
                // renderAsync={true}
                />
            )
        }

        if (storeType == "ArrayStore" && keyRealTime) {
            return (<Scrolling
                mode={scroll_realtime} // standard
                // rowRenderingMode="virtual" // enable bi loi scroll cham
                legacyMode={legacyMode === true ? true : false} // fix lỗi scroll bị auto top https://supportcenter.devexpress.com/ticket/details/t1046754/data-grid-incorrect-scrolling-in-virtual-mode
                // ẩn legacyMode => fix lỗi FLEXVIX-1232: Màn hình xác nhận lêch online cho MG khi gọi nhiều dữ liệu apiAll & không phân trang bị treo 
                //preloadEnabled={true}
                useNative={true}
            />)
        }

        if (isCustomTopAndBottomSticky === true) {
            return (<Scrolling
                // mode="virtual"
                // rowRenderingMode="virtual"
                //preloadEnabled={true}
                useNative={true}
            />)
        }

        // Tạm thời case cho các bảng loadAll bị lỗi scroll
        if (legacyMode === true) {
            return (<Scrolling
                mode="Standard"
                rowRenderingMode="virtual" // enable bi loi scroll cham
                legacyMode={true}
                // ẩn legacyMode => fix lỗi FLEXVIX-1232: Màn hình xác nhận lêch online cho MG khi gọi nhiều dữ liệu apiAll & không phân trang bị treo 
                //preloadEnabled={true}
                useNative={true}
            // showScrollbar={"onHover"}
            />)
        }

        return (<Scrolling
            mode="virtual"
            // rowRenderingMode="virtual" // enable bi loi scroll cham
            // legacyMode={legacyMode === true ? true : false} // fix lỗi scroll bị auto top https://supportcenter.devexpress.com/ticket/details/t1046754/data-grid-incorrect-scrolling-in-virtual-mode
            // ẩn legacyMode => fix lỗi FLEXVIX-1232: Màn hình xác nhận lêch online cho MG khi gọi nhiều dữ liệu apiAll & không phân trang bị treo 
            //preloadEnabled={true}
            useNative={true}
        // showScrollbar={"onHover"}
        />)

    }

    getPaging = () => {
        const { infiniteScroll, isPager, apiLoadAll, isCustomTopAndBottomSticky, isInsertNewRows, storeType, keyRealTime } = this.props;
        const { pageSizes } = this.state;
        {/* {(!isPager && !keyRealTime) && <Paging defaultPageSize={pageSizes} rowRenderingMode="virtual" />} */ }
        {/* isInsertNewRows Chỉ áp dụng trong trường hợp thêm dòng dữ liệu mới */ }
        if (isInsertNewRows || isPager) {
            return (
                <Paging defaultPageSize={pageSizes} rowRenderingMode="virtual" />
            )
        }

        if (infiniteScroll === true) {
            // return (
            //     <Paging 
            //         pageSize={pageSizes}
            //     />
            // )

            return (
                <Paging defaultPageSize={pageSizes} rowRenderingMode="virtual" />
            )
        }

        if (storeType == "ArrayStore" && keyRealTime) {
            return (
                <Paging defaultPageSize={pageSizes} pageSize={0} />
            )
        }

        {/* Khi sử dụng Scrolling "virtual": Nếu không set Paging thì bị lỗi lăn chuột => dữ liệu insert bị đẩy xuống cuối records */ }
        {/*FLEXVIX-1232: Màn hình xác nhận lêch online cho MG khi gọi nhiều dữ liệu apiAll & không phân trang bị treo 
           apiLoadAll thì Load trước  khoảng 3 đến (3+2) = 5 row để cho mượt giao diện scroll */}
        if (!isPager && apiLoadAll && !isCustomTopAndBottomSticky) {
            return (
                <Paging defaultPageSize={maxTotalCount} rowRenderingMode="virtual" />
            )
        }

        if (apiLoadAll && isCustomTopAndBottomSticky) {
            return (
                <Paging defaultPageSize={maxTotalCount} rowRenderingMode="virtual" />
            )
        }

        return null
    }

    render() {
        // //console.log('Haki.:TableDevExTreme,dataSource,')
        let { height, width, columns, dataSource, pageSizes } = this.state;
        const { remoteOperationsCustoms: propsRemoteOperationsCustoms } = this.props;
        if (!dataSource) {
            //console.log("dataSource========render fail", dataSource)
            return (
                <></>
            )
        }
        //console.log("TableDevExtreme_state", this.state)

        //console.log("TableDevExtreme_state", this.state, this.props, dataSource)
        let { addClass, totalDefault, isPager, apiLoadAll, apiPaging, apiLoadAllByPagingRes,
            apiPagingReturnTotal, keyRealTime, isLoadPanel, focusedRowEnabled,
            defaultPageSizes, isShowLoadingCustom, showFilter, isCustomTopAndBottomSticky, isDeskTop, language, isInsertNewRows, isNotLoadPanel, useHeaderFilter, storeType } = this.props
        height = $(`.${addClass}`).height()
        height = height ? height : getHeight(addClass)
        height = (!height || height == 0) ? "100%" : height
        height = isInsertNewRows ? height : "100%"
        // width = width ? width : getWidth(addClass)
        // width = (!width || width == 0) ? "100%" : width
        width = "100%"
        addClass = `${addClass}-tableEx`
        //console.log('TableDevExtreme_render.:', addClass, width, height)
        columns = this.props.columnsDefault // tạm sửa 
        let _columns = this.onSizeColumn(columns)
        // //console.log('TableDevExtreme_render.:_columns', _columns)
        let afacno_language = language === "en" ? "Command number: " : "Số lệnh: "
        let storageKey = 'LookUpOrderNew-' + this.props.userInfo.username
        // let _className = 'TableDevExtreme ' + (addClass ? addClass : " ")
        let highlightChanges = this.props.isActiveTabBrowser
        let classTable = showFilter ? "TableDevExtreme Filter " : "TableDevExtreme "
        if (isCustomTopAndBottomSticky) {
            classTable = classTable + "StickyRow ";
        }
        let _className = (width > 0 || width == "100%") ? `${classTable}` + (addClass ? addClass : " ") : `${classTable} disable ` + (addClass ? addClass : " ")
        // let hasData = _columns && _columns.length > 0
        let remoteOperationsCustomsToUse = remoteOperationsCustoms
        if (propsRemoteOperationsCustoms && !_.isEmpty(propsRemoteOperationsCustoms)) {
            remoteOperationsCustomsToUse = propsRemoteOperationsCustoms
        }

        let remoteOperations = (apiLoadAll || apiLoadAllByPagingRes) ? false : (apiPaging || apiPagingReturnTotal) ? remoteOperationsCustomsToUse : false
        let isPagerAll = apiLoadAllByPagingRes ? true : false
        if (isPagerAll) {
            _className = _className + " PagerAll"
        }
        // let _isShowLoadingCustom = isShowLoadingCustom && !keyRealTime
        this.addActionToSelection()
        // //console.log("highlightChanges.:", highlightChanges)
        return (
            <div className={_className} ref={this.contentRef}>
                {/* dùng ElementResizeListener xử lý resize giao diện */}
                {/* <ElementResizeListener onResize={(e) => this.resize(e)} /> */}
                {/* {isRender && */}
                {/* <React.Fragment> */}
                <ScrollView
                    height={height}
                    width={width}
                    useNative={true}
                // scrollByContent={true}  
                // scrollByThumb={true} 
                >
                    <DataGrid
                        ref={this.gridRef}
                        height={height}
                        // height={`cacl(${height} - 8px`}
                        width={width}
                        className='style-table'
                        dataSource={dataSource}
                        //style={{ minHeight: "300px" }}
                        // columns={columns}
                        // keyExpr="odorderid"
                        repaintChangesOnly={true} // mặc định true: để realtime dữ liệu không bị scroll top
                        highlightChanges={highlightChanges} // highlight thay đổi
                        remoteOperations={remoteOperations} // cho phép sử dụng khi call api
                        // remoteOperations={{ summary: false }} // true => Lỗi kéo scroll hiện No Data
                        // wordWrapEnabled={true}
                        filterSyncEnabled="auto"
                        filterRow={{
                            showOperationChooser: false,
                            visible: showFilter ? true : false,
                        }}
                        onRowDblClick={(e) => this.onRowDblClick(e)}
                        onRowClick={(e) => this.onRowClick(e)}

                        activeStateEnabled={true}
                        showBorders={true}
                        columnMinWidth={30}
                        allowColumnReordering={true} // Cho di chuyển cột
                        allowColumnResizing={true} // Cho phép resize cột, đang lỗi local cần kiểm tra lại
                        columnAutoWidth={true} // true => không hiển thị được scroll lề phải
                        columnResizingMode={'widget'} // kéo thả độ rộng cột mượt
                        // onRowPrepared={(e) => _isShowLoadingCustom && self.onRowPrepared(e, addClass)}
                        // rowExpanded={self.rowExpanded()}
                        // onRowExpanded={self.onInitialized()}
                        onEditorPreparing={(e) => this.onEditorPreparing(e)}
                        onSelectionChanged={(e) => this.onSelectionChanged(e)}
                        onOptionChanged={(e) => this.onOptionChanged(e)}
                        onContentReady={(e) => this.onContentReady(e)}
                        onCellPrepared={(e) => this.onCellPrepared(e)}
                        onRowPrepared={(e) => this.onRowPrepared(e)}
                        noDataText='' // Xóa text hiển thị khi noData
                    >

                        <HeaderFilter visible={useHeaderFilter} allowSelectAll={false} />
                        <LoadPanel enabled={isLoadPanel} />
                        {this.getScrolling()}
                        {this.getPaging()}
                        {/* Chỉ dùng 1 trong 2 loại Scrolling hoặc Pager */}
                        {
                            (isPager && !keyRealTime) && < Pager
                                // https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/RecordPaging/React/Light
                                visible={true} // true/false = hiện/ ẩn pager
                                allowedPageSizes={allowedPageSizes} // list pageSizes
                                displayMode='compact' // full/compact/adaptive = mode hỗ trợ hiển thị giao diện, adaptive là tự chuyển đổi dựa vào data
                                showPageSizeSelector={true} // true/false = hiện/ẩn chọn số pageSize
                            // showInfo={true} // show text info
                            // showNavigationButtons={true} // true/false = hiện/ẩn buttons next page
                            // infoText="Trang {0} / {1} (Tổng: {2})"
                            // infoText="Tổng: {2}"

                            />
                        }
                        {/* <StateStoring enabled={true}
                            type="localStorage"
                            storageKey={storageKey}
                            savingTimeout={0}
                        /> */}
                        {this.props.isUseSelection && <Selection mode="multiple" selectAllMode="allPages" />}
                        {/* Khi sử dụng Selection có điều kiện disabled 
                        checkbox thì data row cần chứa trường: enableCheckBox
                        và kèm theo func props onSelectionChanged() để xử lý submit()
                        */}
                        {/* <Column dataField="busdate" width={2} /> */}

                        {this.renderColumns(_columns)}
                        {this.renderSummary(totalDefault)}

                        {this.props.isOneColumnTotal && this.renderOneColumnTotal()}

                    </DataGrid >
                </ScrollView >
                {/* </React.Fragment> */}
                {/* <button onClick={() => { this.updateRealTime_test() }}>updateRealTime_test</button> */}
                {/* } */}
            </div >
        )
    }
};

const mapStateToProps = state => {
    return {
        isDeskTop: state.app.isDeskTop,
        language: state.app.language,
        userInfo: state.user.userInfo,
        isActiveTabBrowser: state.app.isActiveTabBrowser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TableExtremeCustom2);