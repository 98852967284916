import React, { Component } from 'react';
import moment from 'moment';

/** For valid format please see <a href="https://momentjs.com/docs/#/displaying/">Moment format options</a> */
// const dateTimeFormat = 'DD/MM/YYYY HH:mm:ss';

class FormattedDateTime extends Component {

    render() {
        const { format, value, ...otherProps } = this.props;
        let dateTimeFormat = format ? format : 'DD/MM/YYYY HH:mm:ss';
        const formattedValue = value ? moment.utc(value).format(dateTimeFormat) : null;
        return (
            <span {...otherProps}>{formattedValue}</span>
        );
    }
}

export default FormattedDateTime;