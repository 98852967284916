import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalBody, } from 'reactstrap';
import { ReactComponent as AlertLogin_Dark } from '../assets/images/AlertLogin_Dark.svg';

// import unknownDarkImage from '../assets/images/LogoBwise-01-dark.png';
// import unknownLightImage from '../assets/images/LogoBwise-01-light.png';
import './ExpiredTokenErrorScreen.scss';

class ExpiredTokenErrorScreen extends Component {

    getCurrentThemeViaBody = () => {
        let body = document.getElementsByTagName("BODY")[0];
        let className = body.classList.toString();
        return className.includes('theme-light');
    }


    render() {
        const isLightTheme = this.getCurrentThemeViaBody();
        const { messageId, btnMessageId, reloginHandler, closeAction, intl, isOpen } = this.props;
        return (
            // <div className="error-container">
            //     <DocumentTitle title={intl.formatMessage({ id: "common.logged-out-by-session-expired" })} />
            //     <div className="image">
            //         <UnknownErrorImage />
            //     </div>
            //     <div className="message">
            //         <FormattedMessage id="common.logged-out-by-session-expired" />
            //     </div>
            //     <div className="action">
            //         <button className="btn btn-action" onClick={closeAction}>
            //             <FormattedMessage id="common.close-btn" />
            //         </button>
            //         <button className="btn btn-action" onClick={reloginHandler}>
            //             <FormattedMessage id="common.login-again" />
            //         </button>
            //     </div>
            // </div>
            <Modal
                isOpen={isOpen}
                centered={true}
                className="error-container-end"
            >
                <ModalBody className="confirm-body">
                    <header>
                        <button onClick={closeAction}>
                            <i className="far fa-times" />
                        </button>
                    </header>
                    <div>
                        <AlertLogin_Dark />
                    </div>
                    <div className='headB---700-16-24 title'>
                        <FormattedMessage id="common.login-session-has-expired" />
                    </div>
                    <div className='txt---400-16-24 content'>
                        <FormattedMessage id="common.logged-out-by-session-expired" />
                    </div>
                    <div className="row gutters-5 align-items-center h-row container-btn">
                        <div className="col-12">
                            <button className="btn-refresh txt---500-16-24 btn bg-btn-login bg-primary text-custom"
                                onClick={reloginHandler}
                            >
                                <FormattedMessage id="login.title" />
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal >
        )
    }
}

export default injectIntl(ExpiredTokenErrorScreen);