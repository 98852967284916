import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

class FormattedQuantity extends Component {

    render() {
        return (
            <NumberFormat
                value={this.props.value}
                displayType={'text'}
                decimalScale={0}
                fixedDecimalScale={true}
                thousandSeparator={','}
                decimalSeparator={'.'}/>
        );
    }
}

export default FormattedQuantity;