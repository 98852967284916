import _ from 'lodash';
import * as queryString from "query-string";

import axios from 'axios';
// import http from 'http';
// import https from 'https';
import { Random } from "utils";

import { buildRefreshTokenUrl } from './auth';
import config from './combineConfig';
import reduxStore, { dispatch } from './redux';
import * as actions from './store/actions';
import { genBaseId, getValueFromLocalStorage, keyFactory, Role } from './utils'
import { emitter } from 'utils/EventEmitter';
import { TabID } from './utils/CommonUtils';
// const Agent = require('agentkeepalive');
// const optionHttp = {
//     keepAlive: true,
//     maxSockets: 1,
//     maxFreeSockets: 0,
//     timeout: 60000, // active socket keepalive for 60 seconds
//     freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
// }
// const httpAgent = new http.Agent(optionHttp);
// const httpsAgent = new https.Agent(optionHttp);
const globalVar = window._env_

// const keepaliveAgent = new Agent({
//     maxSockets: 1,
//     maxFreeSockets: 0,
//     timeout: 60000, // active socket keepalive for 60 seconds
//     freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
// });

const instance = axios.create({
    baseURL: globalVar.api.API_BASE_URL,
    withCredentials: true,
    timeout: config.api.TIME_OUT,
    // httpAgent: keepaliveAgent
    // httpAgent:httpAgent,  // httpAgent: httpAgent -> for non es6 syntax
    // httpsAgent: httpsAgent,
});
// axios.defaults.httpAgent = httpAgent
// axios.defaults.httpsAgent = httpsAgent

const createError = (httpStatusCode, statusCode, errorMessage, problems, errorCode = '', errorData, Track_log_id) => {
    const error = new Error();
    error.httpStatusCode = httpStatusCode;
    error.statusCode = statusCode;
    error.errorMessage = errorMessage;
    error.problems = problems;
    error.errorCode = errorCode + "";
    error.data = errorData
    error.Track_log_id = Track_log_id
    return error;
};

export const isSuccessStatusCode = (s) => {
    // May be string or number
    const statusType = typeof s;
    return (statusType === 'number' && s === 0) || (statusType === 'string' && s.toUpperCase() === 'OK');
};

const isTokenExpiredError = (response) => {
    if (response.status !== 401) {
        return false;
    }

    if (config.switch.enableRefreshToken) {
        const data = response.data;
        if (data == null) {
            return false;
        }

        if (('string' === typeof data) && data === 'Unauthorized') {
            return true;
        }

        if (data.hasOwnProperty('d') && data['d'] === 'Access-Token is unauthorized') {
            return true;
        }
        return false;
    } else return true;
};

let isRefreshingAccessToken = false;

// This is the list of waiting requests that will retry after the token refresh complete
let subscribers = [];

const resetTokenAndReattemptRequest = (error) => {
    try {
        const { response: errorResponse } = error;
        const state = reduxStore.getState();
        const refreshToken = state.user.token != null ? state.user.token['refresh_token'] : null;
        if (!refreshToken) {
            // We can't refresh, throw the error anyway
            return Promise.reject(error);
        }

        /*
         * Proceed to the token refresh procedure. We create a new Promise that will retry the request, clone all the
         * request configuration from the failed request in the error object.
         */
        const retryOriginalRequest = new Promise((resolve, reject) => {
            /*
             * We need to add the request retry to the queue since there another request that already attempt to
             * refresh the token
             */
            addSubscriber(newToken => {
                if (newToken) {
                    errorResponse.config.headers.Authorization = 'Bearer ' + newToken;
                    resolve(instance(errorResponse.config));
                } else {
                    reject(error);
                }
            });
        });
        if (!isRefreshingAccessToken) {
            isRefreshingAccessToken = true;

            const body = queryString.stringify({
                'grant_type': 'refresh_token',
                'client_id': globalVar.api.CLIENT_ID,
                'client_secret': globalVar.api.CLIENT_SECRET,
                'refresh_token': refreshToken
            });

            axios.post(buildRefreshTokenUrl(), body, { withCredentials: false })
                .then(response => {
                    if (!response.data) {
                        return Promise.reject(error);
                    }
                    const newToken = response.data['access_token'];

                    // Save the newly refreshed token for other requests to use
                    dispatch(actions.setRefreshTokenSuccess(response.data));
                    isRefreshingAccessToken = false;
                    onRefreshTokenComplete(newToken);
                })
                .catch(() => {
                    dispatch(actions.setRefreshTokenFail());
                    isRefreshingAccessToken = false;
                    onRefreshTokenComplete(null);
                });
        }
        return retryOriginalRequest;
    } catch (err) {
        return Promise.reject(err);
    }
};

const onRefreshTokenComplete = (newToken) => {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    subscribers.forEach(callback => callback(newToken));
    subscribers = [];
};

const addSubscriber = (callback) => {
    subscribers.push(callback);
};

const parseCookiesToObj = (cookieStr) => {
    let output = {}
    cookieStr.split(";").forEach(function (pair) {
        pair = pair.split("=");
        output[pair[0].trim()] = pair.splice(1).join('=');
    });
    return output;
}

instance.interceptors.request.use((request) => {
    const state = reduxStore.getState();
    const unixtiem = new Date().getTime();
    const trackLogId = Random.randomRequestIdToServer() + "_" + unixtiem + "_" + TabID;
    if (request.baseURL === globalVar.api.API_BASE_URL) {

        const token = state.user.token != null ? state.user.token['access_token'] : null;

        //voi moi gioi thi truyen kenh la F
        if (state && state.user && state.user.userInfo && state.user.userInfo.role === Role.BROKER) {
            request.headers.common['x-via'] = 'F';
        } else {
            request.headers.common['x-via'] = 'O';
        };

        if (state && state.user && state.user.userInfo && state.user.userInfo.username) {
            request.headers.common['x-username'] = state.user.userInfo.username;
        }

        request.headers.common['x-clientid'] = globalVar && globalVar.api && globalVar.api.CLIENT_ID ? globalVar.api.CLIENT_ID : '';

        request.headers.common['track_log_id'] = trackLogId;
        request.headers.common['x-username'] = state && state.user && state.user.userInfo && state.user.userInfo.username;
        let cookie = parseCookiesToObj(document.cookie);
        let currentBaseID = getValueFromLocalStorage(keyFactory.baseid);
        if (!currentBaseID) currentBaseID = genBaseId();
        // request.headers.common['baseid'] = currentBaseID;
        // request.headers.common['user'] = cookie.ssouserid;
        // request.headers.common['x-device'] = cookie['fs2prosid'] ? cookie['fs2prosid'] : '';
        request.headers.common['x-devicetype'] = 'WEB';
        request.headers.common['x-lang'] = state.app.language;

        if (token) {
            request.headers.Authorization = `Bearer ${token}`;
        }

        if (request.method === 'post' && typeof request.data === 'string') {
            request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
    }
    return request;
});

instance.interceptors.response.use(
    (response) => {
        // Thrown error for request with OK status code
        const { data, headers, status } = response;
        // let { data, headers, status, config } = response;
        // if (config.url == "https://xpower.vixs.vn/datafeed/instruments?exchange=XHNF") {
        //     status = 530
        // }
        if (status && status == 530) {
            // console.debug("response.:1",config.url, response)
            emitter.emit("waitingUpdateSystem", { status })
            return null;
        }

        // if (data.hasOwnProperty('s') && !isSuccessStatusCode(data['s']) && data.hasOwnProperty('errmsg')) {
        //     return Promise.reject(createError(response.status, data['s'], data['errmsg'], null, data['errcode'] ? data['errcode'] : ""));
        // }
        if (data.hasOwnProperty('s') && !isSuccessStatusCode(data['s']) && data.hasOwnProperty('ec')) {
            //console.log('response---response axios res 1: ', headers.track_log_id)
            return Promise.reject(createError(status, data['s'], data['em'], null, data['ec'] ? data['ec'] : "", data['d'] || {}, headers.track_log_id ? headers.track_log_id : ""));
        }

        // Return direct data to callback
        if (data.hasOwnProperty('s') && data.hasOwnProperty('d')) {
            //console.log('response---response axios res 2: ', data)
            return data['d'];
        }
        // Handle special case
        if (data.hasOwnProperty('s') && _.keys(data).length === 1) {
            //console.log('response---response axios res 3: ', data)
            return null;
        }
        return response.data;
    },
    async (error) => {
        const { response } = error;
        if (response == null) {
            return Promise.reject(error);
        }

        const { data } = response;
        if (isTokenExpiredError(response)) {
            if (config.switch.enableRefreshToken) {
                return resetTokenAndReattemptRequest(error);
            } else {
                // dispatch(actions.setRefreshTokenFail());
                // isRefreshingAccessToken = false;
                // onRefreshTokenComplete(null);
                await dispatch(actions.logoutExpiredToken())
                await dispatch(actions.setIsOpenExpiredTokenErrorScreen(true))
                await dispatch(actions.logoutBySessionExpired())
                if (data.hasOwnProperty('s') && data.hasOwnProperty('em')) {
                    return Promise.reject(createError(response.status, data['s'], data['em']));
                }
                // return;
            }
        }

        // if (data.hasOwnProperty('s') && data.hasOwnProperty('errmsg')) {
        //     return Promise.reject(createError(response.status, data['s'], data['errmsg']));
        // }

        if (data.hasOwnProperty('s') && data.hasOwnProperty('em')) {
            //console.log('response---response axios 1: ')
            return Promise.reject(createError(response.status, data['s'], data['em']));
        }

        if (data.hasOwnProperty('code') && data.hasOwnProperty('message')) {
            //console.log('response---response axios 2: ')
            return Promise.reject(createError(response.status, data['code'], data['message'], data['problems']));
        }
        //console.log('response---response axios 3: ')
        return Promise.reject(createError(response.status));
    }
);

export default instance;