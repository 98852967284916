import React, { Component } from "react";

const MIN_FONT_SIZE = 20;
const MAX_FONT_SIZE = 100;
const RESOLUTION = 5;

class FitText extends Component {

    ref = React.createRef();

    initialState = {
        fontSize: MAX_FONT_SIZE,
        fontSizePrev: MIN_FONT_SIZE,
        fontSizeMax: MAX_FONT_SIZE,
        fontSizeMin: MIN_FONT_SIZE
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    }

    calcFontSize = () => {
        const { fontSize, fontSizeMax, fontSizeMin, fontSizePrev } = this.state;
        const ref = this.ref;
        const isDone = Math.abs(fontSize - fontSizePrev) <= RESOLUTION;
        const isOverflow =
            !!ref.current &&
            (ref.current.scrollHeight > (ref.current.offsetHeight + 1) ||
                ref.current.scrollWidth > (ref.current.offsetWidth + 1));
        const isAsc = fontSize > fontSizePrev;

        // Return if the font size has been adjusted "enough" (change within RESOLUTION)
        // reduce font size by one increment if it's overflowing
        if (isDone) {
            if (isOverflow) {
                const fontSizeNew = fontSizePrev < fontSize ? fontSizePrev : fontSize - (fontSizePrev - fontSize);
                this._setState({
                    fontSize: fontSizeNew,
                    fontSizeMax,
                    fontSizeMin,
                    fontSizePrev,
                });
            }
            return;
        }

        // Binary search to adjust font size
        let delta;
        let newMax = fontSizeMax;
        let newMin = fontSizeMin;
        if (isOverflow) {
            delta = isAsc ? fontSizePrev - fontSize : fontSizeMin - fontSize;
            newMax = Math.min(fontSizeMax, fontSize);
        } else {
            delta = isAsc ? fontSizeMax - fontSize : fontSizePrev - fontSize;
            newMin = Math.max(fontSizeMin, fontSize);
        }
        this._setState({
            fontSize: fontSize + delta / 2,
            fontSizeMax: newMax,
            fontSizeMin: newMin,
            fontSizePrev: fontSize,
        });
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        this.calcFontSize();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.fontSize !== this.state.fontSize
            || prevState.fontSizeMax !== this.state.fontSizeMax
            || prevState.fontSizeMin !== this.state.fontSizeMin
            || prevState.fontSizePrev !== this.state.fontSizePrev) {
            this.calcFontSize();
        }
    }

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    render() {
        return (
            <div ref={this.ref} style={{ fontSize: this.state.fontSize + '%' }}>
                {this.props.children}
            </div>
        );
    }
}

export default FitText;
