// import { Item } from 'devextreme-react/data-grid';
import React, { useRef, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import { FormattedMessage } from 'react-intl';
import { batch, useDispatch, useSelector } from 'react-redux';
// import WidgetIcons from '../../containers/LayoutPage/Widget/WidgetIcons';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { emitter } from 'utils/EventEmitter';
import { ReactComponent as CloseIconDark } from '../../assets/icons/header/CloseIcon_Dark.svg';
import { ReactComponent as CloseIconLight } from '../../assets/icons/header/CloseIcon_Light.svg';
import { ReactComponent as EditLayoutDark } from '../../assets/icons/header/edit_layout_dark.svg';

import Line from '../../assets/images/line.png';
import { LanguageLayout } from '../../containers/MenuSideBar/listMapMenuSideBar';
import * as actions from "../../store/actions";
// import { Form, FormGroup, Input } from 'reactstrap';
import { CommonUtils, CommonWidgetUtils, LAYOUT_STATUS } from "../../utils";

// import * as LayoutUtils from '../../containers/LayoutPage/LayoutUtils';
// import * as LayoutDesktop from "../../containers/LayoutPage/LayoutDesktop";
import LayoutPageSetting from './LayoutPageSetting'
import { ReactComponent as IconSaveDark } from '../../assets/icons_new/icon_save_dark.svg'

import './LayoutPage.scss';
// Define a default UI for filtering

function getTitle() {
    let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent();
    let layoutCurrent = getLayoutPageCurrent.layoutCurrent;
    let layoutName = layoutCurrent && layoutCurrent.layoutName;
    return layoutName;
}


function LayoutPage(props) {
    const { item, data, isBroker, isDeskTop, widthStyle, lengthLayoutPage, layoutsConfig, layoutsCustom, isLoggedIn, } = props
    const { isEditLayout } = data
    // //console.log('LayoutPage.:', item, data)
    const dispatch = useDispatch();
    // const layoutPage = useSelector(state => state.layout.layoutPage)
    const currentLayoutPageActive = useSelector(state => CommonWidgetUtils.getReplaceLayoutPageActive())
    const OBJLAYOUT = currentLayoutPageActive
    // const [style, setStyle] = useStateIfMounted({ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", textAlign: "center", width: "150px", height: "100%", background: "#645848", paddingTop: "5px", borderRadius: "5px", color: "white", marginRight: "5px", })
    const { defaultTheme } = useSelector((state) => state.user.userInfo);
    const { } = useSelector((state) => state.user.userInfo);
    const [isShowSetting, setShowSetting] = useStateIfMounted(false)
    const [isResize, setIsResize] = useStateIfMounted(false)
    const layoutItemRef = useRef(null);

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    // alert("You clicked outside of me!");
                    setShowSetting(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const callback = () => {
        setShowSetting(true)
    }

    useEffect(() => {
        listenToTheEmitter()
        setIsResize(!isResize)
        return () => {
            emitter.removeListener("onShowLayoutWidgetSetting", callback);
        }
    }, []);

    const listenToTheEmitter = () => {
        emitter.on("onShowLayoutWidgetSetting", callback);
    }

    const onChangeMenuActiveSideBar = (e, keyName, activeURL) => {
        // if (data.keyName !== "CS#PRICEBOARD#CB" && data.keyName !== "CS#SYMBOL_DETAIL") {
        if (CommonWidgetUtils.onCheckPermistionKeyLayout(data.keyName)) {
            let isLoggedIn = CommonUtils.checkLogined()
            if (!isLoggedIn) {
                return;
            }
        }
        e && e.stopPropagation();
        batch(() => {
            dispatch(actions.onChangeMenuActiveSideBar(data.keyName))
            dispatch(actions.onChangeActiveURLSideBar(data.activeURL))
            //console.log("item---: ", item)
            dispatch(actions.changeLayoutPageActive(CommonWidgetUtils.onCheckLayoutToChangActive(item)))
            // dispatch(actions.setCurrentCustomer((data && data.accountInfo) && data.accountInfo.curCustomer))
            dispatch(actions.onChangeStatusMenuSideBar(0))
            dispatch(actions.setIsOpenModalHaveData("QuickOrder", {
                isOpenQuickOrder: false,
            }))
            dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
                isOpenScreenModal: false,
                curScreenModalActive: ""
            }))
        });
    }

    const onDelete = () => {
        let key = isBroker ? "B#CS#PRICEBOARD#CB" : "C#CS#PRICEBOARD#CB"
        if (isLayoutDefault) {
            batch(() => {
                //console.log("abc---x", item)
                dispatch(actions.hideLayoutPage(item))
            });
        } else {
            batch(() => {
                dispatch(actions.deleteLayoutPage(item))
            });
        }
    }


    const onDeleteLayoutSave = () => {
        const onCallbackUpdateLayoutCustom = () => {
            let layoutConfig = layoutsConfig.find(item => (item.id == currentLayoutPageActive))
            let _layoutsCustom = layoutsCustom

            if (layoutConfig) {
                _layoutsCustom = layoutsCustom.map((item, index) => {
                    if (item.id == currentLayoutPageActive) {
                        return layoutConfig
                    } else {
                        return item
                    }
                })
            } else {
                _layoutsCustom = layoutsCustom.filter((item, index) => {
                    if (item.id.includes("$TC_TEMP") && (item.id == currentLayoutPageActive + "$TC_TEMP" || item.id == currentLayoutPageActive)) {
                        return false
                    } else {
                        return true
                    }
                })
            }

            //console.log("binh__layoutsCustom", { layoutsConfig, layoutConfig, _layoutsCustom, currentLayoutPageActive })
            dispatch(actions.updateLayoutCustomData(_layoutsCustom))
        }

        batch(() => {
            dispatch(actions.changeLayoutPageActive(CommonWidgetUtils.onCheckLayoutToChangActive(item)))
            dispatch(actions.setIsOpenModalHaveData("CreatLayout", {
                isOpenModalCreatLayout: true,
                data: {
                    from: "CreatLayout_btn_save_delete",
                    onCallbackUpdateLayoutCustom,
                    onCallbackHideLayoutPage: () => { dispatch(actions.hideLayoutPage(item)) }
                }
            }))

        });
    }

    const onClickCloseLayout = (isDelete) => { //isDelete = true tắt layout, isDelete= false active tab tắt và mở popup lưu
        if (isDelete) {
            onDelete()
        } else {
            onDeleteLayoutSave()
        }
    }

    const onSetting = () => {
        // dispatch(actions.deleteLayoutPage(item))
        setShowSetting(!isShowSetting)
    }

    const returnLanguage = (keyName) => {
        const find = LanguageLayout.find(i => i.keyLayout === keyName);
        //console.log("keyName---: ", { keyName, find });
        return find && find.keyLanguage
    }




    const genderIcon = (keyIcon) => {
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < CloseIconDark /> : < CloseIconLight />
            case 2:
                // return defaultTheme === 'dark' ? <IconSaveDark /> : <IconSaveLight />
                return <IconSaveDark />
            default:
                break;
        }
    }

    const renderBtnCloseSave = (isLayoutDefault) => {
        let flag = true

        //console.log("binh_renderBtnCloseSave", { layoutsCustom, data, item })
        // let layoutCustom = layoutsCustom && layoutsCustom.findLast(el => el.id === item || el.id === item + "$TC_TEMP");
        let layoutCustom = layoutsCustom && layoutsCustom.find(el => el.id === item + "$TC_TEMP");
        if (!layoutCustom) {
            layoutCustom = layoutsCustom && layoutsCustom.find(el => el.id === item);
        }
        if (layoutCustom && layoutCustom["layoutStatus"] && layoutCustom["layoutStatus"] === LAYOUT_STATUS.UPDATE) flag = false;
        //console.log("binh_renderBtnCloseSave_1", { layoutsCustom, data, item, flag })

        return (

            <div className={"icon icon-close active"} onClick={() => onClickCloseLayout(flag)}>
                <i class="fal fa-times"></i>
            </div>

        )
    }


    const saveActiveLayout = () => {
        onChangeMenuActiveSideBar()
        let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent();
        let currentLayoutPageActive = getLayoutPageCurrent.currentLayoutPageActive
        let OBJLAYOUT = currentLayoutPageActive.split('$TC_TEMP')
        OBJLAYOUT = OBJLAYOUT[0]
        let OBJLAYOUT_CUSTOM = CommonWidgetUtils.genNewObjLayoutCustom(OBJLAYOUT)
        //console.log("vinh_OBJLAYOUT_CUSTOM", OBJLAYOUT_CUSTOM, OBJLAYOUT)

        dispatch(actions.setIsOpenModalHaveData("CreatLayout", {
            isOpenModalCreatLayout: true,
        }))
    }

    const onCheckShowSaveLayout = () => {
        let flag = false
        let layoutCustom = layoutsCustom && layoutsCustom.find(el => el.id === item + "$TC_TEMP");
        if (!layoutCustom) {
            layoutCustom = layoutsCustom && layoutsCustom.find(el => el.id === item);
        }
        //console.log("binh_onCheckShowSaveLayout", { layoutsCustom, layoutCustom, item })
        if (layoutCustom && layoutCustom["layoutStatus"] && layoutCustom["layoutStatus"] === LAYOUT_STATUS.UPDATE) flag = true;
        return flag
    }


    const onChangeEditLayout = () => {
        CommonWidgetUtils.toggleEnableEditLayout()
    }

    const isActive = currentLayoutPageActive === item || currentLayoutPageActive === item + "$TC_TEMP"
    const isLayoutSetting = isDeskTop && isActive && (data.typeLayout && (data.typeLayout.indexOf("C") > -1 || data.typeLayout.indexOf("DC") > -1));
    const isLayoutClose = item.indexOf("PRICEBOARD") < 0;
    const isLayoutDefault = (data.typeLayout === "D" || data.typeLayout === "DC");
    const isLayoutCustom = data.typeLayout === "C";
    const isBtnSave = isLoggedIn && onCheckShowSaveLayout() ? true : false
    let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent(false, data.keyName);
    let layoutCurrent = getLayoutPageCurrent.layoutCurrent;
    let layoutName = layoutCurrent && layoutCurrent.layoutName;
    let _classParent = "layout-top-item "
    if (isActive) {
        _classParent = _classParent + "layout-top-active "
    } else {
        _classParent = _classParent + "layout-top-unactive "
    }
    // //console.log('123=', getLayoutPageCurrent, data.keyName)
    // let count = currentLayoutPageActive.split('&')
    // count = (count && count.length > 1) && (count[1]+'')
    // let title = LanguageUtils.getMessageByKey(returnLanguage(data.keyName), "vi") + count
    // //console.log("layoutItemRef_1", layoutItemRef, layoutItemRef.current)
    if (layoutItemRef && layoutItemRef.current) {
        let clientWidth = layoutItemRef.current.clientWidth
        if (clientWidth < 60) {
            _classParent = _classParent + "layout-top-item-custom "
        }
        // //console.log("layoutItemRef", clientWidth)
    }

    return (
        <div ref={layoutItemRef} className={_classParent} style={widthStyle}>
            {/* <img style={{ paddingTop: "10px", paddingBottom: "10px" }} src={Line} alt="LOGO" /> */}
            <div ref={wrapperRef}>
                {
                    (isLayoutSetting && isShowSetting && isEditLayout) &&
                    <LayoutPageSetting
                        item={item}
                        onChangeMenuActiveSideBar={onChangeMenuActiveSideBar}
                    />
                }
            </div>
            <div className="d-flex item-center container-block" >
                <div className="container-title">
                    <Link to={data.activeURL} onClick={(e) => onChangeMenuActiveSideBar(e)} >
                        <div className='title txt---400-14-20'>
                            {/* {title} */}

                            {isLayoutCustom ? layoutName : <FormattedMessage id={returnLanguage(data.keyName) || ""} />}
                        </div>
                    </Link>
                </div>
                <div className="container-icon">
                    {(isLayoutSetting) &&
                        <div className="icon item-center">
                            {isEditLayout ?
                                <span className="icon item-center"><i style={{ cursor: 'pointer' }} className="far fa-ellipsis-v" onClick={() => onSetting()} /></span>
                                :
                                <span className="icon item-center" style={isBtnSave ? { marginRight: '-5px' } : {}} onClick={() => { onChangeEditLayout() }}><EditLayoutDark /></span>
                            }
                        </div>
                    }
                    {/* <span onClick={() => { onChangeEditLayout() }}>a</span> */}
                    {(isEditLayout && isBtnSave) &&
                        <div style={{ cursor: 'pointer' }} className="icon item-center" onClick={saveActiveLayout}>
                            <IconSaveDark />
                        </div>
                    }
                    {isLayoutClose &&
                        (renderBtnCloseSave(isLayoutDefault))
                    }
                </div>
            </div>
            <img style={{ paddingTop: "10px", paddingBottom: "10px" }} src={Line} alt="LOGO" />
        </div >
    )
}



const mapStateToProps = state => {
    const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    const layoutsCustom = state.layout.layoutsCustom
    const layoutsConfig = state.layout.layoutsConfig
    let isDeskTop = state.app.isDeskTop
    return {
        currentLayoutPageActive: currentLayoutPageActive,
        layoutsCustom: layoutsCustom,
        layoutsConfig: layoutsConfig,
        role: state.user.userInfo.role,
        isDeskTop: isDeskTop,
        isLoggedIn: state.user.isLoggedIn,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};
// export default LayoutPage
export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage);

