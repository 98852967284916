import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

import './ButtonTooltip.scss';


class TextTooltip extends Component {

    initialState = {
        tooltipOpen: false
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
    componentDidMount() {
        this.mounted = true
	}	
    
    componentWillUnmount() {
        this._setState({ ...this.initialState })
        this.mounted = false
    }

    toggle = () => {
        this._setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    };

    render() {
        const { tooltipText, tooltipClassName, targetID, trigger, innerClassName, placement, popperClassName } = this.props;
        const { tooltipOpen } = this.state;

        // Truyền trigger ='' để không hiển thị tooltip
        let ownTrigger = "";
        if (trigger || trigger === '') {
            ownTrigger = trigger;
        } else {
            ownTrigger = "click hover"
        }

        return (
            <Tooltip
                className={tooltipClassName ? tooltipClassName : ''}
                popperClassName={popperClassName ? popperClassName : ''}
                innerClassName={innerClassName ? innerClassName : ''}
                hideArrow={true}
                trigger={ownTrigger}
                isOpen={tooltipOpen}
                autohide={true}
                target={targetID}
                toggle={this.toggle}
                placement={placement ? placement : 'auto'}
            >
                {tooltipText}
            </Tooltip>
        );
    }
}

export default TextTooltip;