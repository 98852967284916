import React, { Component, Fragment } from 'react';
import NumberFormat from 'react-number-format';

import { CommonUtils } from "../../utils";

export const getDiffColorClassName = (diff) => {
    if (diff == null) {
        return '';
    }

    if (diff < 0) {
        return 'text-red';
    }
    if (diff > 0) {
        return 'text-green';
    }
    if (diff === 0) {
        return 'text-ref-price'
    }

    return '';
};

export const getPriceColorClassName = (price, instrument) => {
    if (price == null || instrument == null) {
        return '';
    }

    if (CommonUtils.isContainLetter(price)) {
        return '';
    }

    price = CommonUtils.convertToNumber(price);
    const reference = CommonUtils.convertToNumber(instrument.RE);

    if (price == reference || price == 0) {
        return 'text-ref-price'
    }
    const ceil = CommonUtils.convertToNumber(instrument.CL);
    if (price == ceil) {
        return 'text-ceil-price';
    }
    const floor = CommonUtils.convertToNumber(instrument.FL);
    if (price == floor) {
        return 'text-floor-price';
    }
    if (reference) {
        if (price < reference) {
            return 'text-red';
        }
        if (price > reference) {
            return 'text-green';
        }
    }

    return '';
};


class NumberFormatCustom extends Component {

    // static defaultProps = {
    //     withIntrinsicColor: false
    // };

    // renderfitText = (formattedValue) => {
    //     const { value, withIntrinsicColor, instrument, colorCalcValue } = this.props;
    //     return (
    //         <span className={withIntrinsicColor ? getDiffColorClassName(value) : getPriceColorClassName(colorCalcValue != null ? colorCalcValue : value, instrument)}>
    //             {/* Giá trị chỉ được clear khi value = null (Nếu value= undefined ===> formattedVolume là giá trị cũ) */}
    //             {/*formattedVolume && value && value !== 0 ? formattedVolume.substr(0, formattedVolume.length - 1) : null*/}
    //             <FitText>
    //                 {formattedValue}
    //             </FitText>
    //         </span>
    //     );
    // };

    getValue = (value) => {
        const { shortForm, decimalScale, digit, isFormatZero, isNotThousand, dataField } = this.props;
        let returnValue = value ? value : 0;
        if (value) {
            // returnValue = decimalScale && value || (shortForm && value ? (value / 1000).toFixed(digit ? digit : 2) : value)
            let numb = parseFloat(value);
            if (shortForm && numb) { //shortForm hiển thị dạng rút gọn hay không (Mặc định lấy 2 số sau dấu ,)
                if (isNotThousand) { // Nếu không phải đơn vị nghìn ==> Không phải chia 1000
                    numb = +numb.toFixed(decimalScale || decimalScale === 0 ? decimalScale : 2);
                } else {
                    numb = numb / 1000;
                    numb = +numb.toFixed(decimalScale || decimalScale === 0 ? decimalScale : 2);
                }
            }
            if (!shortForm && numb) { // shortForm hiển thị dạng rút gọn hay không (Mặc định không lấy số sau dấu ,)
                numb = +numb.toFixed(decimalScale || decimalScale === 0 ? decimalScale : 0);
            }
            returnValue = numb;
        } else if (isFormatZero) {
            // returnValue = 0
            // returnValue = decimalScale || (shortForm && returnValue ? (returnValue / 1000).toFixed(digit ? digit : 2) : returnValue.toFixed(digit ? digit : 2))
            returnValue = '0.00'
        }
        return returnValue;
    }

    render() {
        const { value, withIntrinsicColor, instrument, colorCalcValue, shortForm, decimalScale, digit, suffix, prefix, isFormatZero, fitText, renderfitText, dataField } = this.props;
        if (CommonUtils.isContainLetter(value)) {
            return (<span>{value}</span>); // ATC, ATO với giá
        }

        // if (!value && isFormatZero) {
        //     return (<span>{'0.00'}</span>);
        // }

        // if (instrument && instrument['Id'] === 'GB10F2312' && dataField === 'CH') {
        // if ( dataField === 'CH') {
        //     //console.log('laojahackgame========> CHECK DATA AAAA', { Id:instrument['Id'],dataField, decimalScale, shortForm, value });
        // }

        // if (this.props.fromComp) {
        //     //console.log('laojahackgame========> CHECK DATA AAAA', { className: withIntrinsicColor ? getDiffColorClassName(value) : getPriceColorClassName(colorCalcValue != null ? colorCalcValue : value, instrument), value, instrument, colorCalcValue, withIntrinsicColor });
        // }

        return (
            <Fragment>
                <NumberFormat
                    value={this.getValue(value)}
                    displayType={'text'}
                    renderText={fitText && renderfitText ? renderfitText : null}
                    decimalScale={decimalScale && decimalScale || (shortForm ? 3 : 2)}
                    fixedDecimalScale={false}
                    thousandSeparator={','}
                    decimalSeparator={'.'}
                    suffix={suffix ? suffix : ""}
                    prefix={prefix ? prefix : ""}
                    className={withIntrinsicColor ? getDiffColorClassName(value) : getPriceColorClassName(colorCalcValue != null ? colorCalcValue : value, instrument)}
                />
            </Fragment>
        );
    }
}

export default NumberFormatCustom;
