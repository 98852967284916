import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage,injectIntl } from 'react-intl';

import { ReactComponent as UnknownErrorImage } from '../assets/images/unknown-error.svg';

// import unknownDarkImage from '../assets/images/LogoBwise-01-dark.png';
// import unknownLightImage from '../assets/images/LogoBwise-01-light.png';
import './ErrorScreen.scss';

class ErrorScreen extends Component {

    getCurrentThemeViaBody = () => {
        let body = document.getElementsByTagName("BODY")[0];
        let className = body.classList.toString();
        return className.includes('theme-light');
    }

    render() {
        const isLightTheme = this.getCurrentThemeViaBody();
        const { messageId, btnMessageId, action, intl } = this.props;
        return (
            <div className="error-container">
                {/* <DocumentTitle title={this.props.intl.formatMessage({ id: 'sso.error.message' })} /> */}
                <DocumentTitle title={intl.formatMessage({ id: messageId })} />
                <div className="image">
                    <UnknownErrorImage />
                    {/*<img src={isLightTheme ? unknownLightImage : unknownDarkImage} alt={ this.props.intl.formatMessage({id: 'not-found.message'}) }/>*/}
                </div>
                <div className="message">
                    {/* <FormattedMessage id="sso.error.message" /> */}
                    <FormattedMessage id={messageId} />
                </div>
                <div className="action">
                    {/* <Link className="btn btn-primary" to="/login">
                        <FormattedMessage id="sso.error.try-again" />
                    </Link> */}
                    <button className="btn btn-action" onClick={action}>
                        {/* <FormattedMessage id="sso.error.try-again" /> */}
                        <FormattedMessage id={btnMessageId} />
                    </button>
                </div>
            </div>
        )
    }
}

export default injectIntl(ErrorScreen);