import React, { Component } from 'react';


class OtpInput extends Component {

    static noop() { }

    static defaultProps = {
        onKeyDown: OtpInput.noop
    };

    constructor(props) {
        super(props);
        this.state = {
            otpValue: ''
        }
    }

    input = null;

    ref = element => {
        this.input = element;
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    handleValueChange = (event) => {
        // const correctedValue = (event.target.value || "").replace(/[^a-zA-Z0-9]/gi, '');
        // const needCorrect = event.target.value !== correctedValue;

        // if (needCorrect) {
        //     this.input.value = correctedValue;
        // }

        // if (this.props.value !== correctedValue) {
        //     this.props.onChange(correctedValue)
        // }
        let convertedValue = event.target.value;
        this._setState({
            otpValue: convertedValue
        })
        if (this.props.onChange) {
            this.props.onChange(convertedValue);
        }
    };

    componentDidMount() {
        this.mounted = true
        const { disabled, autoFocus } = this.props;
        if (!disabled && autoFocus) {
            this.input.focus();
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        // noinspection JSUnusedLocalSymbols
        const { onKeyDown, onChange, ref, ...otherProps } = this.props;

        return (
            <input
                ref={this.ref}
                onChange={this.handleValueChange}
                value={this.props.disabled !== null ? (!this.props.disabled ? this.state.pinValue : "•••••••") : this.state.pinValue}
                {...otherProps}
            />
        );
    }

}

export default OtpInput;