export function setSesstionStorage(key, value) {
    try {
        sessionStorage.setItem(key, JSON.stringify(value));
    } catch (err) { }
}

export function getSessionStorage(key) {
    return JSON.parse(sessionStorage.getItem(key, ''));
}

export function destroySessionStorage(key) {
    sessionStorage.removeItem(key);
}
