import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

class FormattedPercentage extends Component {

    render() {
        const { decimalScale } = this.props;
        return (
            <NumberFormat
                value={this.props.value}
                displayType={'text'}
                decimalScale={decimalScale ? decimalScale : 2}
                fixedDecimalScale={true}
                thousandSeparator={','}
                decimalSeparator={'.'} />
        );
    }
}

export default FormattedPercentage;