import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";

import DraggableModal from '../../components/Modal/DraggableModal';
import * as actions from "../../store/actions";
import { ReactComponent as AlertLogin_Dark } from '../../assets/images/AlertLogin_Dark.svg';

import './AdvertisingModal.scss';

class AdvertisingModal extends Component {

    state = {
        notShowNextTime: false
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
    componentDidMount() {
        this.mounted = true
	}	
		
	componentWillUnmount() {
        this.mounted = false
    }
	

    setShowAdvertising = (e) => {
        this._setState({ notShowNextTime: !this.state.notShowNextTime });
    }

    onClose = () => {
        const { onClose } = this.props;
        localStorage.setItem('notshowAd', this.state.notShowNextTime.toString());
        sessionStorage.setItem('showedAd', 'true');
        onClose && onClose();
    }

    render() {
        const { isOpen, toggle, onClose } = this.props;
        const { notShowNextTime } = this.state;

        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={toggle}
                onClose={this.onClose}
                className={'advertising-modal'}
                titleId={' '}
            >
                {/*<div className="body">
                    <div className="content text-center">
                        <FormattedMessage id="common.noti-beta" />
                    </div>
                    <div className="checkbox-container">
                        <input type="checkbox"
                            onChange={this.setShowAdvertising}
                            checked={notShowNextTime}
                        />
                        <FormattedMessage id="common.not-show-next-time" />
                    </div>

                    <div className="btn-container">
                        <button className="btn-refresh txt---500-16-24" onClick={this.onClose} >
                            <FormattedMessage id="common.btn-close" />
                        </button>
                    </div>

                </div>*/}
                <div className="confirm-body">
                    <div>
                        <AlertLogin_Dark />
                    </div>
                    <div className='headB---700-16-24 title'>
                        <FormattedMessage id="common.notice" />
                    </div>
                    <div className='txt---400-16-24 content'>
                        <FormattedMessage id="common.noti-beta" />
                    </div>
                    <div className="checkbox-container">
                        <input type="checkbox"
                            onChange={this.setShowAdvertising}
                            checked={notShowNextTime}
                        />{' '}
                        <FormattedMessage id="common.not-show-next-time" />
                    </div>
                    <div className="row gutters-5 align-items-center h-row container-btn">
                        <div className="col-12">
                            <button className="btn-refresh txt---500-16-24 btn bg-btn-login"
                                onClick={this.onClose}
                            >
                                <FormattedMessage id="common.btn-close" />
                            </button>
                        </div>
                    </div>
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = state => {
    return {
        confirmModalProps: state.app.confirmModalProps,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisingModal);