import React, { Component, Fragment } from 'react';

import {JsxUtils } from '../../utils';

import "./PinInput.scss"

class PinInput extends Component {

    static noop() { }

    static defaultProps = {
        onKeyDown: PinInput.noop
    };

    constructor(props) {
        super(props);
        this.state = {
            pinValue: ''
        }
    }

    input = null;

    ref = element => {
        this.input = element;
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    handleValueChange = (event) => {
        // const correctedValue = (event.target.value || "").replace(/[^a-zA-Z0-9!@#$%^&*)(+=._-]/gi, '');
        // const needCorrect = event.target.value !== correctedValue;

        // if (needCorrect) {
        //     this.input.value = correctedValue;
        // }

        // if (this.props.value !== correctedValue) {
        //     this.props.onChange(correctedValue)
        // }
        let convertedValue = event.target.value;
        this._setState({
            pinValue: convertedValue
        })
        if (this.props.onChange) {
            this.props.onChange(convertedValue);
        }
    };

    componentDidMount() {
        this.mounted = true
        const { disabled, autoFocus } = this.props;
        if (!disabled && autoFocus) {
            this.input.focus();
        }
    }
    
    componentWillUnmount() {
        this.mounted = false
    }

    render() {
        // noinspection JSUnusedLocalSymbols
        const { onKeyDown, onChange, ref, className, toggleDisplayPin, showPin, ...otherProps } = this.props;

        //huy.quang: custom input kieu text ----> input kieu password
        const baseClass = showPin ? 'pin-input' : 'key pin-input';
        const inputClassName = JsxUtils.joinClass(baseClass, className);
        return (
            <Fragment>
                <input
                    autoComplete="off"
                    className={inputClassName}
                    ref={this.ref}
                    onChange={this.handleValueChange}
                    onKeyDown={onKeyDown}
                    value={this.props.disabled !== null ? (!this.props.disabled ? this.state.pinValue : "•••••••") : this.state.pinValue}
                    {...otherProps}
                />
                {(!this.props.disabled) && (
                    <Fragment>
                        <button tabIndex={-1} className="btn-edit text-center" onClick={toggleDisplayPin}><i className="pin-color icon-eye fas fa-eye" /></button>
                        <button tabIndex={-1} className="btn-save text-center" onClick={toggleDisplayPin}><i className="pin-color icon-eye fas fa-eye-slash" /></button>
                    </Fragment>
                )}
            </Fragment >
        );
    }

}

export default PinInput;