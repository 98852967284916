import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import KeyCode from 'rc-util/lib/KeyCode';
import InputHandler from './InputHandler';
import { connect } from 'react-redux';
import { ReactComponent as ICON_PLUS_DARK } from '../../assets/icons_new/icon_plus_dark.svg'
import { ReactComponent as ICON_MINUS_DARK } from '../../assets/icons_new/icon_minus_dark.svg'
import { ReactComponent as ICON_PLUS_LIGHT } from '../../assets/icons_new/icon_plus_light.svg'
import { ReactComponent as ICON_MINUS_LIGHT } from '../../assets/icons_new/icon_minus_light.svg'
import { KeyCodeUtils, fullSubOrderTypes, EXCHANGE, STOCKTYPE, CommonUtils } from "../../utils";
import { isIOS } from "react-device-detect";

import './PriceInput.scss';
import _ from 'lodash';
// Haki.: Fix theo src https://github.com/react-component/input-number/blob/4.5.x/src/index.js
function noop() {
}

function preventDefault(e) {
    e.preventDefault();
}

function insertEscapeIfNeed(separator) {
    if (separator === '.') {
        return '\\.';
    }
    return separator;
}

function defaultFormatter(value, decimalSeparator, groupSeparator) {
    if (value == null || value === '') {
        return '';
    }

    if ("string" !== typeof value) {
        value = value.toString();
    }

    const parts = value.split(/[.]/gi);
    const hasDecimalSeparator = value.indexOf('.') >= 0;

    let result = parts[0].replace(/\B(?=(\d{3})+(?!\d))/gi, groupSeparator);
    if (hasDecimalSeparator) {
        result += decimalSeparator;
        if (parts.length > 1) {
            result += parts[1];
        }
    }
    return result;
}

function defaultParser(input, decimalSeparator, groupSeparator, allowDecimal, allowNegative) {
    const unusedRegex = new RegExp('[^\\d-' + insertEscapeIfNeed(decimalSeparator) + insertEscapeIfNeed(groupSeparator) + ']+', 'gi');

    let result = input;

    // Remove any unrelated char
    result = result.replace(unusedRegex, '');

    // Remove dash if not allowNegative
    if (!allowNegative) {
        const dashRegex = /[-]+/i;
        result = result.replace(dashRegex, '');
    }

    // Remove group separator
    if (groupSeparator != null) {
        const groupSeparatorRegex = new RegExp('[' + insertEscapeIfNeed(groupSeparator) + ']', 'gi');
        result = result.replace(groupSeparatorRegex, '');
    }

    // Convert custom decimalSeparator to .
    if (allowDecimal) {
        const decimalSeparatorRegex = new RegExp('[' + insertEscapeIfNeed(decimalSeparator) + ']', 'gi');
        result = result.replace(decimalSeparatorRegex, '.');
    } else {
        // Remove decimal part
        const decimalIndex = result.indexOf(decimalSeparator);
        if (decimalIndex >= 0) {
            result = result.substring(0, decimalIndex);
        }
    }
    return result;
}

/**
 * When click and hold on a button - the speed of auto changin the value.
 */
const SPEED = 200;

/**
 * When click and hold on a button - the delay before auto changin the value.
 */
const DELAY = 600;

/**
 * Max Safe Integer -- on IE this is not available, so manually set the number in that case.
 * The reason this is used, instead of Infinity is because numbers above the MSI are unstable
 */
const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || Math.pow(2, 53) - 1;

const isValidProps = value => value !== undefined && value !== null;

class PriceInput extends React.Component {
    static propTypes = {
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        defaultValue: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        focusOnUpDown: PropTypes.bool,
        autoFocus: PropTypes.bool,
        onChange: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        prefixCls: PropTypes.string,
        tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        disabled: PropTypes.bool,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        readOnly: PropTypes.bool,
        max: PropTypes.number,
        min: PropTypes.number,
        step: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        upHandler: PropTypes.node,
        downHandler: PropTypes.node,
        useTouch: PropTypes.bool,
        formatter: PropTypes.func,
        parser: PropTypes.func,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        onMouseOver: PropTypes.func,
        onMouseOut: PropTypes.func,
        onMouseUp: PropTypes.func,
        precision: PropTypes.number,
        required: PropTypes.bool,
        pattern: PropTypes.string,
        decimalSeparator: PropTypes.string,
        groupSeparator: PropTypes.string,
        allowDecimal: PropTypes.bool,
        allowNegative: PropTypes.bool,


        subOrderType: '',   //giang.ngo: biến lưu loại lệnh ATO, ATC ...
    };

    static defaultProps = {
        focusOnUpDown: true,
        useTouch: false,
        prefixCls: 'rc-input-number-price',
        min: -MAX_SAFE_INTEGER,
        step: 1,
        style: {},
        onChange: noop,
        onKeyDown: noop,
        onFocus: noop,
        onBlur: noop,
        formatter: defaultFormatter,
        parser: defaultParser,
        required: false,
        autoComplete: 'off',
        decimalSeparator: '.',
        groupSeparator: ',',
        allowDecimal: true,
        allowNegative: true
    };

    ITEM_HEIGHT = 26;

    constructor(props) {
        super(props);
        this.composing = undefined
        this.composingCheck = undefined
        let value;
        if ('value' in props) {
            value = props.value;
        } else {
            value = props.defaultValue;
        }
        this.state = {
            focused: props.autoFocus,
        };
        const validValue = this.getValidValue(this.toNumber(value));
        this.state = {
            ...this.state,
            inputValue: this.toPrecisionAsStep(validValue),
            value: validValue,
            subOrderType: fullSubOrderTypes.LO,
            displaySubOrderTypes: [],
            currentActiveIndex: -1,// -1 khi không có loại lệnh nào được chọn
            putTroughInput: ''
        };
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        if (this.props.value) {
            this._setState({
                value: CommonUtils.convertPriceValueForPriceInput(this.props.value),
                inputValue: CommonUtils.convertPriceValueForPriceInput(this.props.value),
            });
            this.validateValue(this.props);
        }
        // this.componentDidUpdate();
    }

    //lay cac values tac dong den orderinput tu props
    getValuesFromProps = (props) => {
        var isValid = this.validate(props.value)
        let isValidSubOrderType = props.subOrderTypes.indexOf(props.subOrderType) > -1
        var isMarketPrice = isValidSubOrderType && props.subOrderType !== fullSubOrderTypes.LO
        !isValidSubOrderType && props.quote && (isValid = isValid && this.validatPriceCeilFloor(props.value, props.quote.ceiling, props.quote.floor))

        const isValidStep = this.validateStepPrice(props.value, props.quote);

        const valueToValidate = isMarketPrice ? props.subOrderType : props.value;

        // //console.log('laojahackgame ====> AAAA', valueToValidate, props.value);

        return {
            isValid,
            isValidSubOrderType,
            isMarketPrice,
            isValidStep,
            valueToValidate
        }
    };

    validateValue = (props) => {
        const {
            isValid,
            isValidStep,
            valueToValidate
        } = this.getValuesFromProps(props);

        if (props.onChange != null) {
            // console.log('laojahackgame=====> ONCHANGE SET validateValue 1', valueToValidate);
            props.onChange(valueToValidate, isValid, isValidStep);
        };

        // if (props.valid !== isValid && props.onChange != null) {
        //     // console.log('laojahackgame=====> ONCHANGE SET validateValue 1', valueToValidate);
        //     props.onChange(valueToValidate, isValid, isValidStep);
        // };

        // if (props.validStep !== isValidStep && props.onChange != null) {
        //     // console.log('laojahackgame=====> ONCHANGE SET validateValue 2', valueToValidate);
        //     props.onChange(valueToValidate, isValid, isValidStep);
        // };
    };

    componentWillUpdate(nextProps, nextState) {
        const { typingValue } = this.state;
        const { typingValue: nextTypingValue } = nextState;
        const {
            isValid,
            isValidStep,
            valueToValidate
        } = this.getValuesFromProps(nextProps);
        //trường hợp ô input bị clear khi xóa giá ATO, ATC ---> validate giá thị trường = true
        if (typingValue !== nextTypingValue && nextTypingValue.length === 0) {
            // //console.log('laojahackgame=====> ONCHANGE SET componentWillUpdate', { valueToValidate, typingValue, nextTypingValue });
            nextProps.onChange(valueToValidate, isValid, isValidStep, true);
        };
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.subOrderTypes, nextProps.subOrderTypes)) {
            var displaySubOrderTypes = this.getDisplaySubOrderTypes(this.state.inputValue, nextProps.subOrderTypes)
            this._setState({ displaySubOrderTypes })
        }
        if (this.props.subOrderType && this.props.subOrderType.length > 0 && this.props.subOrderType !== nextProps.subOrderType || this.props.subOrderTypes !== nextProps.subOrderTypes) {
            this._setState({
                subOrderType: nextProps.subOrderTypes.indexOf(nextProps.subOrderType) > -1 ? nextProps.subOrderType : "",
                typingValue: "",
            });
        }
        var oldQuoteSymbol = this.props.quote ? this.props.quote.symbol : ""
        var quoteSymbol = nextProps.quote ? nextProps.quote.symbol : ""
        if (oldQuoteSymbol !== quoteSymbol) {
            nextProps.isEdittingOrder && this._setState({
                subOrderType: fullSubOrderTypes.LO,
                typingValue: "",
            })
        }
        if (this.props.quote !== nextProps.quote) {
            this.validateValue(nextProps);
        };

        //vaidate lai gia & buoc gia khi value thay doi
        if ('value' in nextProps && nextProps.value !== this.props.value) {
            const value = this.state.focused
                ? nextProps.value : this.getValidValue(nextProps.value, nextProps.min, nextProps.max);
            let nextInputValue;
            if (this.pressingUpOrDown) {
                nextInputValue = value;
                nextInputValue = CommonUtils.convertPriceValueForPriceInput(nextInputValue);
            } else if (this.inputting) {
                if (!parseFloat(this.state.inputValue) && !CommonUtils.convertPriceValueForPriceInput(value)) {
                    nextInputValue = this.state.inputValue;
                } else {
                    nextInputValue = parseFloat(this.state.inputValue) === CommonUtils.convertPriceValueForPriceInput(value) ? this.state.inputValue : value;
                }
            } else {
                nextInputValue = this.toPrecisionAsStep(value);
                nextInputValue = CommonUtils.convertPriceValueForPriceInput(nextInputValue);
            }
            // console.log('laojahackgame=====> NEW VALUE', { nextValue: nextProps.value, nextInputValue, focused: this.state.focused, inputValue: this.state.inputValue, inputting: this.inputting, value, nextValue: nextProps.value, convertValue: CommonUtils.convertPriceValueForPriceInput(value) });
            this._setState({
                value: CommonUtils.convertPriceValueForPriceInput(value),
                inputValue: nextInputValue,
            });
            this.validateValue(nextProps);
        }

        // Trigger onChange when max or min change
        // https://github.com/ant-design/ant-design/issues/11574
        const nextValue = 'value' in nextProps ? nextProps.value : this.state.value;
        const { onChange, max, min } = this.props;
        // ref: null < 20 === true
        // https://github.com/ant-design/ant-design/issues/14277

        if ('max' in nextProps &&
            nextProps.max !== max &&
            typeof nextValue === 'number' &&
            nextValue > nextProps.max &&
            onChange) {
            var isValid = this.validate(nextProps.max)
            nextProps.quote && (isValid = isValid && this.validatPriceCeilFloor(nextProps.max, nextProps.quote.ceiling, nextProps.quote.floor))
            onChange(nextProps.max, isValid, this.validateStepPrice(nextProps.max, nextProps.quote));
        }
        if ('min' in nextProps &&
            nextProps.min !== min &&
            typeof nextValue === 'number' &&
            nextValue < nextProps.min &&
            onChange) {
            var isValid = this.validate(nextProps.min)
            nextProps.quote && (isValid = isValid && this.validatPriceCeilFloor(nextProps.min, nextProps.quote.ceiling, nextProps.quote.floor))
            onChange(nextProps.min, isValid, this.validateStepPrice(nextProps.min, nextProps.quote));
        }
    };

    componentDidUpdate(prevProps) {
        // Restore cursor 
        try {
            // Firefox set the input cursor after it get focused.
            // This caused that if an input didn't init with the selection,
            // set will cause cursor not correct when first focus.
            // Safari will focus input if set selection. We need skip this.
            if (this.cursorStart !== undefined && this.state.focused) {
                // In most cases, the string after cursor is stable.
                // We can move the cursor before it

                if (
                    // If not match full str, try to match part of str
                    !this.partRestoreByAfter(this.cursorAfter) && this.state.value !== this.props.value
                ) {
                    // If not match any of then, let's just keep the position
                    // TODO: Logic should not reach here, need check if happens
                    let pos = this.cursorStart + 1;

                    // If not have last string, just position to the end
                    if (!this.cursorAfter) {
                        pos = this.input.value.length;
                    } else if (this.lastKeyCode === KeyCode.BACKSPACE) {
                        pos = this.cursorStart - 1;
                    } else if (this.lastKeyCode === KeyCode.DELETE) {
                        pos = this.cursorStart;
                    }
                    this.fixCaret(pos, pos);
                } else if (this.currentValue === this.input.value) {
                    // Handle some special key code
                    switch (this.lastKeyCode) {
                        case KeyCode.BACKSPACE:
                            this.fixCaret(this.cursorStart - 1, this.cursorStart - 1);
                            break;
                        case KeyCode.DELETE:
                            this.fixCaret(this.cursorStart + 1, this.cursorStart + 1);
                            break;
                        default:
                        // Do nothing
                    }
                }
            }
        } catch (e) {
            // Do nothing
        }

        // Reset last key
        this.lastKeyCode = null;

        // pressingUpOrDown is true means that someone just click up or down button
        if (!this.pressingUpOrDown) {
            return;
        }
        if (this.props.focusOnUpDown && this.state.focused) {
            if (document.activeElement !== this.input) {
                this.focus();
            }
        }

        this.pressingUpOrDown = false;
    }

    componentWillUnmount() {
        this.stop();
        this.mounted = false
    }

    onKeyDown = (e, ...args) => {
        const { onKeyDown, decimalSeparator } = this.props;
        if (parseFloat(e.target.value)) {
            if (e.keyCode === KeyCode.UP) {
                const ratio = this.getRatio(e);
                this.up(e, ratio);
                this.stop();
            } else if (e.keyCode === KeyCode.DOWN) {
                const ratio = this.getRatio(e);
                this.down(e, ratio);
                this.stop();
            }
        } else {
            this.handleBodyKeyDown(e);
        }

        // Trigger user key down
        this.recordCursorPosition();
        this.lastKeyCode = e.keyCode;
        if (onKeyDown) {
            onKeyDown(e, ...args);
        }

        const key = e.which || e.keyCode;
        const numberString = "string" === typeof e.target.value ? e.target.value : "0";
        const isNavigationKey = KeyCodeUtils.isNavigation(key);
        const isDecimalKey = KeyCodeUtils.isDecimal(key) && e.key === decimalSeparator;
        const isNumericKey = KeyCodeUtils.isNumeric(key);
        const isDashKey = KeyCodeUtils.isDash(key);
        const isNotContainDecimal = !(new RegExp('[\\' + decimalSeparator + ']').test(numberString));
        const isAlphabetic = KeyCodeUtils.isAlphabetic(key)
        if (!isDecimalKey || (this.props.allowDecimal && isNotContainDecimal)) {
            if (!this.props.allowNegative && isDashKey) {
                e.preventDefault();
            } else if (isDashKey && Number(numberString) < 0) {
                e.preventDefault();
            } else {
                const keyAllowed = e.ctrlKey || e.metaKey || isNumericKey || isNavigationKey || isDecimalKey || isDashKey || isAlphabetic;
                if (!keyAllowed) {
                    e.preventDefault();
                }
            }
        } else {
            e.preventDefault();
        }
    };

    onKeyUp = (e, ...args) => {
        const { onKeyUp } = this.props;

        this.stop();

        this.recordCursorPosition();

        // Trigger user key up
        if (onKeyUp) {
            onKeyUp(e, ...args);
        }
    };

    onChange = (e) => {
        const {
            isPut, onChange, decimalSeparator, groupSeparator, allowDecimal, allowNegative, subOrderTypes, validateTypingMarketPrice, decimalScale
        } = this.props;
        const { putTroughInput } = this.state
        this._setState({
            putTroughInput: e.target.value
        })
        if (this.state.focused) {
            this.inputting = true;
        };
        let typingValue = this.getValueFromEvent(e);
        let putThrough = typingValue * 1000
        this.rawInput = this.props.parser(typingValue, decimalSeparator, groupSeparator, allowDecimal, allowNegative);
        let _decimal = this.rawInput.split('.')
        if (_decimal.length >= 2) {
            // Haki.: Fix giá theo decimalScale
            if (_decimal[1].length >= decimalScale) {
                this.rawInput = parseFloat(this.rawInput).toFixed(decimalScale);
            }
        }
        let isValidSubOrderType = subOrderTypes.indexOf(typingValue) > -1
        let num = this.toNumber(this.rawInput);
        // Haki.: Check Min Max
        num = this.getValidValue(num);
        var displaySubOrderTypes = this.getDisplaySubOrderTypes(typingValue, this.props.subOrderTypes);

        //check nhap sai gia thi truong: co value, gia tri nhap ko phai so & ko nam trong cac gia thi truong ---> false
        //ko phai so <---> gia tri nhap vao qua format --> rong: this.rawInput.length === 0
        var isValidMarketPrice = !(typingValue != null && typingValue.length > 0 && this.rawInput.length === 0 && !isValidSubOrderType);
        let isCheckFix = this.rawInput && (this.rawInput.indexOf('.') < 0)
        this._setState({
            // inputValue: this.rawInput,
            inputValue: isCheckFix ? this.rawInput + ' ' : this.rawInput, // Haki.:Fix lỗi cho VI-Telex
            subOrderType: isValidSubOrderType ? typingValue : "",
            typingValue,
            displaySubOrderTypes
        }, () => {
            // if (!this.composing) {
            if (num.length === 0)  // giang.ngo: case nhập giá thị trường hợp lệ
            {
                // //console.log('laojahackgame=====> ONCHANGE SET onChange 1', typingValue, isValidSubOrderType);
                onChange(isPut ? putThrough : typingValue, true, true, isValidSubOrderType);
            } else {
                let convertedValue = CommonUtils.convertPriceValueForValidate(num);
                var isValid = this.validate(convertedValue)
                this.props.quote && (isValid = isValid && this.validatPriceCeilFloor(convertedValue, this.props.quote.ceiling, this.props.quote.floor))
                // //console.log('laojahackgame=====> ONCHANGE SET onChange 2', convertedValue);
                onChange(isPut ? putThrough : convertedValue, isValid, this.validateStepPrice(convertedValue, this.props.quote), isValidMarketPrice); // valid number or invalid string
            }
            (this.rawInput && this.rawInput.replace) && this._setState({
                inputValue: this.rawInput && this.rawInput.replace(' ', ''), // Haki.:[END].:Fix lỗi cho VI-Telex: Tự động xóa dấu space
            });
            // }
        });
    };

    onMouseUp = (...args) => {
        const { onMouseUp } = this.props;

        this.recordCursorPosition();

        if (onMouseUp) {
            onMouseUp(...args);
        }
    };

    onFocus = (...args) => {
        this.focusFirstTime = false;

        if (this.composingCheck != undefined) {
            this.composing = true;
        }
        this.state.focused = true
        var displaySubOrderTypes = this.getDisplaySubOrderTypes(this.state.inputValue, this.props.subOrderTypes)

        this._setState({
            focused: this.state.focused,
            displaySubOrderTypes
        });
        this.props.onFocus(...args);
    };

    onBlur = (...args) => {
        this.focusFirstTime = false;
        if (this.composingCheck != undefined) {
            this.composing = false;
        }
        const { onBlur } = this.props;
        this.inputting = false;
        this.state.focused = false;
        this._setState({
            focused: this.state.focused,
            displaySubOrderTypes: [],
            currentActiveIndex: -1
        });
        const value = this.getCurrentValidValue(this.state.inputValue) || 0;
        // //console.log('laojahackgame======> VALUE', value, this.state.inputValue);
        const newValue = this.setValue(value);

        if (onBlur) {
            const originValue = this.input.value;
            const inputValue = this.getInputDisplayValue({ focus: false, value: newValue });
            this.input.value = inputValue;
            onBlur(...args);
            this.input.value = originValue;
        }
    }

    onComposition = (e) => {
        // //console.log('Haki1.:onComposition 1=', e.type, e.data, e.target.value)
        this.composingCheck = true;
        if (e.type === 'compositionstart' || e.type === 'compositionupdate') {
            this.composing = true;
        } else if (e.type === 'compositionend') {
            this.composing = false;
            // this.onChange(e);
        }
    }
    onKeyPressDiv = () => {
        // //console.log('Haki.:onKeyPressDiv')
        this.composing = undefined;
        this.composingCheck = undefined;
    }

    getCurrentValidValue(value) {
        let val = value;
        if (val === '') {
            val = '';
        } else if (!this.isNotCompleteNumber(parseFloat(val, 10))) {
            val = this.getValidValue(val);
        } else {
            val = this.state.value;
        }
        return this.toNumber(val);
    }

    getRatio(e) {
        let ratio = 1;
        if (e.metaKey || e.ctrlKey) {
            ratio = 0.1;
        } else if (e.shiftKey) {
            ratio = 10;
        }
        return ratio;
    }

    getValueFromEvent(e) {
        // optimize for chinese input expierence
        // https://github.com/ant-design/ant-design/issues/8196
        var value = e.target.value ? e.target.value.toUpperCase() : e.target.value
        return value.trim().replace(/。/g, '.');
    }

    getValidValue(value, min = this.props.min, max = this.props.max) {
        let val = parseFloat(value, 10);
        // https://github.com/ant-design/ant-design/issues/7358
        if (isNaN(val)) {
            return value;
        }
        if (val < min) {
            val = min;
        }
        if (val > max) {
            val = max;
        }
        return val;
    }

    setValue(v, callback) {
        // trigger onChange
        const newValue = this.isNotCompleteNumber(parseFloat(v, 10)) ? v : parseFloat(v, 10);
        const { value = null, inputValue = null } = this.state;
        // https://github.com/ant-design/ant-design/issues/7363
        const changed = newValue !== value || `${newValue ? newValue : ""}` !== `${inputValue ? inputValue : ""}`;

        // if (!('value' in this.props)) {
        //     this.state.inputValue = this.toPrecisionAsStep(v)
        //     this._setState({
        //         value: newValue,
        //         subOrderType: changed ? fullSubOrderTypes.LO : this.state.subOrderType, //nếu value thay đổi thì sửa lại loại lệnh về LO
        //         inputValue: this.state.inputValue,
        //     }, callback);
        // } else {
        //     this.state.inputValue = this.toPrecisionAsStep(this.state.value)
        //     // always set input value same as value
        //     this._setState({
        //         inputValue: this.state.inputValue,
        //         subOrderType: changed ? fullSubOrderTypes.LO : this.state.subOrderType, //nếu value thay đổi thì sửa lại loại lệnh về LO
        //     }, callback);
        // }
        this.state.inputValue = this.toPrecisionAsStep(v)
        var displaySubOrderTypes = this.getDisplaySubOrderTypes(this.state.inputValue, this.props.subOrderTypes)
        // //console.log('laojahackgame=====> ONCHANGE SET setValue', { changed, inputValue: this.state.inputValue });


        this._setState({
            value: newValue,
            subOrderType: changed ? fullSubOrderTypes.LO : this.state.subOrderType, //nếu value thay đổi thì sửa lại loại lệnh về LO
            inputValue: this.state.inputValue,
            displaySubOrderTypes
        }, callback);
        if (changed) {
            let convertedValue = CommonUtils.convertPriceValueForValidate(newValue);
            var isValid = this.validate(convertedValue)
            this.props.quote && (isValid = isValid && this.validatPriceCeilFloor(convertedValue, this.props.quote.ceiling, this.props.quote.floor))
            // //console.log('laojahackgame=====> ONCHANGE SET setValue', { convertedValue, inputValue, newValue, v, value });
            this.props.onChange(convertedValue, isValid, this.validateStepPrice(convertedValue, this.props.quote));
        }
    }

    validate = (value) => {

        if (this.props.subOrderTypes.indexOf(this.state.subOrderType) > -1) return true

        if (value == null) {
            return false;
        }

        if ("string" !== typeof value) {
            value = value.toString();
        }
        const props = this.props;
        const numberValue = parseFloat(value);

        const valueInvalid = !value.length || value.split(".").length > 2
            || (!props.allowZero && numberValue === 0)
            || (!props.allowDecimal && numberValue !== parseInt(value, 10));

        let validRes = !valueInvalid;
        return validRes;
    };

    validatPriceCeilFloor = (value, ceiling, floor) => {
        let pr = parseInt(value, 10);
        var valid = (pr >= floor && pr <= ceiling);
        return valid
    };

    validateStepPrice = (value, quote) => {
        let parsedPrice = parseInt(value);
        if (quote && parsedPrice) {
            if (quote.StockType === STOCKTYPE.ETF) {
                if (quote.exchange === EXCHANGE.HOSE) {
                    return parsedPrice % 10 === 0
                } else {
                    return parsedPrice % 1 === 0
                }
            } else {
                if (quote.exchange === EXCHANGE.HNX) {
                    if (quote.StockType === STOCKTYPE.BOND || quote.StockType === STOCKTYPE.TPDN) {
                        return parsedPrice % 1 === 0
                    } else {
                        return parsedPrice % 100 === 0
                    }
                } else if (quote.exchange === EXCHANGE.UPCOM) {
                    return parsedPrice % 100 === 0
                } else if (quote.exchange === EXCHANGE.HOSE && quote.StockType.toString() !== STOCKTYPE.CW) {
                    if (parsedPrice > 0 && parsedPrice < 10000) {
                        return parsedPrice % 10 === 0
                    } else if (parsedPrice >= 10000 && parsedPrice < 50000) {
                        return parsedPrice % 50 === 0
                    } else {
                        return parsedPrice % 100 === 0
                    }
                } else if (quote.exchange === EXCHANGE.HOSE && quote.StockType.toString() === STOCKTYPE.CW) {
                    return parsedPrice % 10 === 0
                } else if (quote.exchange === EXCHANGE.TPRL) {
                    // Bước giá:
                    // Trái phiếu mệnh giá dưới 100 triệu đồng: bước giá 01 đồng
                    // Trái phiếu mệnh giá từ 100 triệu đồng trở lên: bước giá 10 đồng
                    if (parsedPrice < 100000000) {
                        return parsedPrice % 1 === 0
                    } else {
                        return parsedPrice % 10 === 0
                    }
                }
            }
        }
        return true
    };
    getStepPrice = (value) => {
        let parsedPrice = parseInt(value);
        let quote = this.props.quote;
        if (quote) {
            if (quote.StockType === STOCKTYPE.ETF) {
                if (quote.exchange === EXCHANGE.HOSE) {
                    return 10
                } else {
                    return 1
                }
            } else {
                if (quote.exchange === EXCHANGE.HNX) {
                    if (quote.StockType === STOCKTYPE.BOND || quote.StockType === STOCKTYPE.TPDN) {
                        return 1
                    } else {
                        return 100
                    }
                } else if (quote.exchange === EXCHANGE.UPCOM) {
                    return 100
                } else if (quote.exchange === EXCHANGE.HOSE && quote.StockType.toString() !== STOCKTYPE.CW) {
                    if (parsedPrice && parsedPrice > 0 && parsedPrice < 10000) {
                        return 10
                    } else if (parsedPrice && parsedPrice >= 10000 && parsedPrice < 50000) {
                        return 50
                    } else {
                        return 100
                    }
                } else if (quote.exchange === EXCHANGE.HOSE && quote.StockType.toString() === STOCKTYPE.CW) {
                    return 10
                }
            }
        } else {
            return (quote && quote.exchange === EXCHANGE.HOSE) ? 10 : 100
        }
    };
    getPrecision(value = 0) {
        if (isValidProps(this.props.precision)) {
            return this.props.precision;
        }
        const valueString = value.toString();
        if (valueString.indexOf('e-') >= 0) {
            return parseInt(valueString.slice(valueString.indexOf('e-') + 2), 10);
        }
        let precision = 0;
        if (valueString.indexOf('.') >= 0) {
            precision = valueString.length - valueString.indexOf('.') - 1;
        }
        return precision;
    }

    // step={1.0} value={1.51}
    // press +
    // then value should be 2.51, rather than 2.5
    // if this.props.precision is undefined
    // https://github.com/react-component/input-number/issues/39
    getMaxPrecision(currentValue, ratio = 1) {
        if (isValidProps(this.props.precision)) {
            return this.props.precision;
        }
        const num = this.state ? this.toNumber(this.state.inputValue) : '';
        const numberForValidate = CommonUtils.convertPriceValueForValidate(num);
        const step = this.getStepPrice(numberForValidate);
        const ratioPrecision = this.getPrecision(ratio);
        const stepPrecision = this.getPrecision(step);
        const currentValuePrecision = this.getPrecision(currentValue);
        if (!currentValue) {
            return ratioPrecision + stepPrecision;
        }
        return Math.max(currentValuePrecision, ratioPrecision + stepPrecision);
    }

    getPrecisionFactor(currentValue, ratio = 1) {
        const precision = this.getMaxPrecision(currentValue, ratio);
        return Math.pow(10, precision);
    }

    focusFirstTime = false;

    getInputDisplayValue = (state) => {
        const { focused, inputValue, value } = state || this.state;
        const { readOnly } = this.props;

        let isFocused = focused;
        // Focused bị set sai khi popup đặt lệnh xong bị tắt
        if (this.props.id && document.activeElement && document.activeElement.id === this.props.id) {
            isFocused = true;
            // this._setState({ focused: true });
        }

        let inputDisplayValue;
        if (isFocused) {
            inputDisplayValue = !readOnly ? inputValue : 0;
        } else {
            inputDisplayValue = value ? this.toPrecisionAsStep(value) : 0;
        }
        // //console.log('laojahackgame ======> GET DISPLAY INPUT', { inputDisplayValue, value, readOnly, focused, isFocused, active: document.activeElement });

        if (inputDisplayValue === undefined || inputDisplayValue === null) {
            inputDisplayValue = '';
        }

        // Focus vào nếu có giá trị 0 ===> Clear về rỗng focus lần đầu
        if (isFocused && (inputDisplayValue === 0 || inputDisplayValue === '0')) {
            if (!this.focusFirstTime) {
                inputDisplayValue = '';
                this.focusFirstTime = true;
            }
        }

        let inputDisplayValueFormat = this.formatWrapper(inputDisplayValue);
        if (isValidProps(this.props.decimalSeparator)) {
            inputDisplayValueFormat = inputDisplayValueFormat
                .toString()
                .replace('.', this.props.decimalSeparator);
        }
        // console.log('laojahackgame======> DISPLAY VALUE 123', { inputDisplayValue, inputDisplayValueFormat, inputValue, value });

        return inputDisplayValueFormat;
    };

    recordCursorPosition = () => {
        // Record position
        try {
            this.cursorStart = this.input.selectionStart;
            this.cursorEnd = this.input.selectionEnd;
            this.currentValue = this.input.value;
            this.cursorBefore = this.input.value.substring(0, this.cursorStart);
            this.cursorAfter = this.input.value.substring(this.cursorEnd);
        } catch (e) {
            // Fix error in Chrome:
            // Failed to read the 'selectionStart' property from 'HTMLInputElement'
            // http://stackoverflow.com/q/21177489/3040605
        }
    };

    fixCaret(start, end) {
        if (start === undefined || end === undefined || !this.input || !this.input.value) {
            return;
        }

        try {
            const currentStart = this.input.selectionStart;
            const currentEnd = this.input.selectionEnd;

            if (start !== currentStart || end !== currentEnd) {
                this.input.setSelectionRange(start, end);
            }
        } catch (e) {
            // Fix error in Chrome:
            // Failed to read the 'selectionStart' property from 'HTMLInputElement'
            // http://stackoverflow.com/q/21177489/3040605
        }
    }

    restoreByAfter = (str) => {
        if (str === undefined) return false;

        const fullStr = this.input.value;
        const index = fullStr.lastIndexOf(str);

        if (index === -1) return false;

        const prevCursorPos = this.cursorBefore.length;
        if (this.lastKeyCode === KeyCode.DELETE &&
            this.cursorBefore.charAt(prevCursorPos - 1) === str[0]) {
            this.fixCaret(prevCursorPos, prevCursorPos);
            return true;
        }
        if (index + str.length === fullStr.length) {
            this.fixCaret(index, index);

            return true;
        }
        return false;
    };

    partRestoreByAfter = (str) => {
        if (str === undefined) return false;

        // For loop from full str to the str with last char to map. e.g. 123
        // -> 123
        // -> 23
        // -> 3
        return Array.prototype.some.call(str, (_, start) => {
            const partStr = str.substring(start);

            return this.restoreByAfter(partStr);
        });
    };

    focus() {
        this.input.focus();
        this.recordCursorPosition();
    }

    blur() {
        this.input.blur();
    }

    formatWrapper(num) {
        // http://2ality.com/2012/03/signedzero.html
        // https://github.com/ant-design/ant-design/issues/9439
        const {
            formatter, decimalSeparator, groupSeparator
        } = this.props;

        if (formatter) {
            return formatter(num, decimalSeparator, groupSeparator);
        }
        return num;
    }

    toPrecisionAsStep(num) {
        if (this.isNotCompleteNumber(num) || num === '') {
            return num;
        }
        const precision = Math.abs(this.getMaxPrecision(num));
        if (precision === 0) {
            return num.toString();
        }
        if (!isNaN(precision)) {
            return Number(num).toFixed(precision);
        }
        return num.toString();
    }

    // '1.' '1x' 'xx' '' => are not complete numbers
    isNotCompleteNumber(num) {
        return (
            isNaN(num) ||
            num === '' ||
            num === null ||
            (num && num.toString().indexOf('.') === num.toString().length - 1)
        );
    }

    toNumber(num) {
        const { precision } = this.props;
        const { focused } = this.state;
        // num.length > 16 => This is to prevent input of large numbers
        const numberIsTooLarge = num && num.length > 16 && focused;
        if (this.isNotCompleteNumber(num) || numberIsTooLarge) {
            return num;
        }
        if (isValidProps(precision)) {
            return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
        }
        return Number(num);
    }

    upStep(val, rat) {
        const { allowDecimal } = this.props;
        const num = this.state ? this.toNumber(this.state.inputValue) : '';
        const numberForValidate = CommonUtils.convertPriceValueForValidate(num);
        const step = CommonUtils.convertPriceValueForPriceInput(this.getStepPrice(numberForValidate));
        const precisionFactor = this.getPrecisionFactor(val, rat);
        const precision = Math.abs(this.getMaxPrecision(val, rat));
        const result = allowDecimal ?
            ((precisionFactor * val + precisionFactor * step * rat) /
                precisionFactor) + "" :
            ((precisionFactor * val + precisionFactor * step * rat) /
                precisionFactor).toFixed(precision);
        return this.toNumber(result);
    }

    downStep(val, rat) {
        const { allowDecimal } = this.props;
        const num = this.state ? this.toNumber(this.state.inputValue) : '';
        const numberForValidate = CommonUtils.convertPriceValueForValidate(num);
        const step = CommonUtils.convertPriceValueForPriceInput(this.getStepPrice(numberForValidate));
        const precisionFactor = this.getPrecisionFactor(val, rat);
        const precision = Math.abs(this.getMaxPrecision(val, rat));
        const result = allowDecimal ?
            ((precisionFactor * val - precisionFactor * step * rat) /
                precisionFactor) + "" : ((precisionFactor * val - precisionFactor * step * rat) /
                    precisionFactor).toFixed(precision);
        return this.toNumber(result);
    }

    step(type, e, ratio = 1, recursive) {
        this.stop();
        if (e) {
            e.persist();
            e.preventDefault();
        }
        const props = this.props;
        if (props.disabled) {
            return;
        }
        const value = this.getCurrentValidValue(this.state.inputValue) || 0;
        if (this.isNotCompleteNumber(value)) {
            return;
        }
        let val = this[`${type}Step`](value, ratio);
        const outOfRange = val > props.max || val < props.min;
        if (val > props.max) {
            val = props.max;
        } else if (val < props.min) {
            val = props.min;
        }
        this.setValue(val);
        this._setState({
            focused: true,
        });
        if (outOfRange) {
            return;
        }
        this.autoStepTimer = setTimeout(() => {
            this[type](e, ratio, true);
            clearTimeout(this.autoStepTimer)
            this.autoStepTimer = null
        }, recursive ? SPEED : DELAY);
    }

    stop = () => {
        if (this.autoStepTimer) {
            clearTimeout(this.autoStepTimer);
        }
    };

    down = (e, ratio, recursive) => {
        this.pressingUpOrDown = true;
        const { value, inputValue, subOrderType, typingValue } = this.state;
        const { quote } = this.props;
        // //console.log('laojahackgame=====> VALUE TO SET DOWN', value)
        if (quote && (value === 0 || value === null || value === "")) {
            let _value = CommonUtils.convertPriceValueForPriceInput(quote.floor, quote.exchange)
            this.setValue(_value)
            return
        }
        this.step('down', e, ratio, recursive);
    };

    up = (e, ratio, recursive) => {
        this.pressingUpOrDown = true;
        const { value, inputValue, subOrderType, typingValue } = this.state;
        const { quote } = this.props;
        // //console.log('laojahackgame=====> VALUE TO SET UP', { check: quote && (value === 0 || value === null), quote, value })
        if (quote && (value === 0 || value === null || value === "")) {
            let _value = CommonUtils.convertPriceValueForPriceInput(quote.ceiling, quote.exchange)
            this.setValue(_value)
            return
        }
        this.step('up', e, ratio, recursive);
    };

    saveUp = (node) => {
        this.upHandler = node;
    };

    saveDown = (node) => {
        this.downHandler = node;
    };

    saveInput = (node) => {
        this.input = node;
    };

    getDisplaySubOrderTypes = (inputValue, subOrderTypes) => {
        // giang.ngo: lấy các loại lệnh để gợi ý
        const {
            decimalSeparator, groupSeparator, allowDecimal, allowNegative
        } = this.props;
        var rawInput = inputValue && inputValue.length > 0 ? this.props.parser(inputValue, decimalSeparator, groupSeparator, allowDecimal, allowNegative) : inputValue;

        const num = this.toNumber(rawInput);
        let isValidSubOrderType = subOrderTypes.indexOf(inputValue) > -1

        var displaySubOrderTypes = []
        if (this.state.focused      //ô input giá phải đang có focus
            && !isValidSubOrderType    //nếu ô input giá phải đang chưa điền sẵn 1 loại lệnh hợp lệ
            && (inputValue && inputValue.length == 0 || !num || num.length === 0))  //nếu ô input giá để trống hoặc đang nhập giá trị số
            displaySubOrderTypes = [...subOrderTypes]

        // //console.log('laojahackgame=======> SUBORDERTYPES', { displaySubOrderTypes, isValidSubOrderType, inputValue })

        return displaySubOrderTypes
    }
    renderPriceType = () => {
        var { displaySubOrderTypes, currentActiveIndex } = this.state;
        if (displaySubOrderTypes && displaySubOrderTypes.length > 0) {
            return (
                <div id='suggestion-pricetype-box' className={"no-label-suggest-box"}>
                    {displaySubOrderTypes.map((element, index) => {
                        let isActive = currentActiveIndex === index; // is active here
                        return (
                            <div key={element} className={"col-12 suggest-item " + (isActive ? "suggest-active" : "")}
                                onMouseDown={this.onSelectPriceSuggestion.bind(this, element)}
                            >
                                {element}
                            </div>
                        )
                    })}
                </div>
            )
        }
    };


    onSelectPriceSuggestion = (subOrderType) => {
        const { onChange } = this.props;

        this._setState({
            subOrderType
        });
        // //console.log('laojahackgame=====> ONCHANGE SET onSelectPriceSuggestion', subOrderType);

        onChange(subOrderType, true, true);
    }

    scrollToIndex = (index) => {
        let suggestBox = document.getElementById('suggestion-pricetype-box');
        suggestBox && suggestBox.scrollTo(0, index * this.ITEM_HEIGHT)
    };

    handleBodyKeyDown = async (event) => {
        const { currentActiveIndex, displaySubOrderTypes, onMouseOver } = this.state;
        const { onChange } = this.props;
        const keyCode = event.which || event.keyCode;
        if (!onMouseOver) {
            if (keyCode === KeyCodeUtils.DOWN) {
                event.preventDefault();
                let newActiveIndex = currentActiveIndex + 1;
                if (newActiveIndex <= displaySubOrderTypes.length - 1) {
                    this._setState({
                        currentActiveIndex: newActiveIndex,
                        //ownCurrentSymbol: filteredSymbols[newActiveIndex]["id"]
                    }, () => {
                        this.scrollToIndex(newActiveIndex);
                    });
                };
            } else if (keyCode === KeyCodeUtils.UP) {
                event.preventDefault();
                let newActiveIndex = currentActiveIndex - 1;
                if (newActiveIndex >= 0) {
                    this._setState({
                        currentActiveIndex: newActiveIndex,
                        //ownCurrentSymbol: filteredSymbols[newActiveIndex]["id"]
                    }, () => {
                        this.scrollToIndex(newActiveIndex);
                    });
                };
            };
        };

        if (keyCode === KeyCodeUtils.ENTER || keyCode === KeyCodeUtils.TAB) {
            keyCode !== KeyCodeUtils.TAB && event.preventDefault();
            if (currentActiveIndex !== -1 && displaySubOrderTypes.length > 0 && displaySubOrderTypes[currentActiveIndex]) {
                let self = this;
                let timer = setTimeout(function () { self.onBlur(); clearTimeout(timer) }, 50) // Haki.: Sửa lỗi ENTER || TAB không enable btn Đặt lệnh!
                this.onSelectPriceSuggestion(displaySubOrderTypes[currentActiveIndex]);
                // this.onBlur();
                this._setState({
                    focused: true // sau khi chọn bằng ENTER => Vẫn đang focus vào giá
                })
            }
        };
    };

    /**
     * KiểM tra disable + - nếu giá lớn hơn trần hoặc nhỏ hơn sàn
     * @returns 
     */
    disableUpDownByQuote = () => {
        const { value, quote } = this.props;
        let result = {
            disableUp: false,
            disableDown: false
        }
        if (value && parseFloat(value)) {
            const floorPrice = quote && quote['floor'] ? quote['floor'] : null;
            const ceilPrice = quote && quote['ceiling'] ? quote['ceiling'] : null;
            // //console.log('laojahackgame=====> PRICE TO COMPARE', { floorPrice, ceilPrice, value, propsValue: this.props.value });
            if (parseFloat(floorPrice) && value > 0) {
                if (value <= parseFloat(floorPrice)) result['disableDown'] = true;
            }

            if (parseFloat(ceilPrice) && value > 0) {
                if (value >= parseFloat(ceilPrice)) result['disableUp'] = true;
            }

        } else {
            result = {
                disableUp: false,
                disableDown: false
            }
        }

        return result;
    }


    render() {
        const props = { ...this.props };
        const {
            prefixCls, disabled, readOnly, useTouch, autoComplete,
            upHandler, downHandler, inputClassName, forwardRef, defaultTheme
        } = props;
        const classes = classNames({
            [prefixCls]: true,
            [props.className]: !!props.className,
            [`${prefixCls}-disabled`]: disabled,
            [`${prefixCls}-focused`]: this.state.focused,
        });
        let upDisabledClass = '';
        let downDisabledClass = '';
        const { value, inputValue, subOrderType, typingValue } = this.state;
        if (value || value === 0) {
            if (!isNaN(value)) {
                const val = Number(value);
                if (this.props.quote) {
                    let resultDisableByQuote = this.disableUpDownByQuote();

                    if (resultDisableByQuote['disableUp']) upDisabledClass = `${prefixCls}-handler-up-disabled`;

                    if (resultDisableByQuote['disableDown']) downDisabledClass = `${prefixCls}-handler-down-disabled`;
                }

                if (val >= props.max) {
                    upDisabledClass = `${prefixCls}-handler-up-disabled`;
                }
                if (val <= props.min) {
                    downDisabledClass = `${prefixCls}-handler-down-disabled`;
                }
            } else {
                upDisabledClass = `${prefixCls}-handler-up-disabled`;
                downDisabledClass = `${prefixCls}-handler-down-disabled`;
            }
        }

        const dataOrAriaAttributeProps = {};
        for (const key in props) {
            if (
                props.hasOwnProperty(key) &&
                (
                    key.substr(0, 5) === 'data-' ||
                    key.substr(0, 5) === 'aria-' ||
                    key === 'role'
                )
            ) {
                dataOrAriaAttributeProps[key] = props[key];
            }
        }

        const editable = !props.readOnly && !props.disabled;

        // focus state, show input value
        // unfocus state, show valid value
        let inputDisplayValue = this.props.isPut ? this.state.putTroughInput : (this.composing ? inputValue : this.getInputDisplayValue());

        let upEvents;
        let downEvents;
        if (useTouch) {
            upEvents = {
                onTouchStart: (editable && !upDisabledClass) ? this.up : noop,
                onTouchEnd: this.stop,
            };
            downEvents = {
                onTouchStart: (editable && !downDisabledClass) ? this.down : noop,
                onTouchEnd: this.stop,
            };
        } else {
            upEvents = {
                onMouseDown: (editable && !upDisabledClass) ? this.up : noop,
                onMouseUp: this.stop,
                onMouseLeave: this.stop,
            };
            downEvents = {
                onMouseDown: (editable && !downDisabledClass) ? this.down : noop,
                onMouseUp: this.stop,
                onMouseLeave: this.stop,
            };
        }

        if (!inputDisplayValue || inputDisplayValue.length == 0) {
            if (typingValue && typingValue.length > 0) {
                inputDisplayValue = typingValue
            }
            else if (subOrderType && subOrderType.length > 0 && subOrderType !== fullSubOrderTypes.LO) {
                inputDisplayValue = subOrderType
            }
        };
        const isUpDisabled = !!upDisabledClass || disabled || readOnly;
        const isDownDisabled = !!downDisabledClass || disabled || readOnly;
        // ref for test
        return (
            <Fragment>
                <div className="pricetype-select-input-suggest">
                    <div
                        className={classes}
                        style={{ ...props.style, ...{ position: 'relative' } }}
                        title={props.title}
                        onMouseEnter={props.onMouseEnter}
                        onMouseLeave={props.onMouseLeave}
                        onMouseOver={props.onMouseOver}
                        onMouseOut={props.onMouseOut}
                        onCompositionStart={this.onComposition}
                        onCompositionEnd={this.onComposition}
                        onCompositionUpdate={this.onComposition}
                        onKeyPress={this.onKeyPressDiv}
                    >
                        <InputHandler
                            ref={this.saveDown}
                            disabled={isDownDisabled}
                            prefixCls={prefixCls}
                            unselectable="unselectable"
                            {...downEvents}
                            role="button"
                            aria-label="Decrease Value"
                            aria-disabled={!!isDownDisabled}
                            className={`${prefixCls}-handler ${prefixCls}-handler-down ${downDisabledClass} btn-icon-fm btn-minus`}
                        >
                            {downHandler || (
                                <span
                                    unselectable="unselectable"
                                    className={`${prefixCls}-handler-down-inner`}
                                    onClick={preventDefault}
                                >
                                    {defaultTheme == "dark" && <ICON_MINUS_DARK />}
                                    {defaultTheme == "light" && <ICON_MINUS_LIGHT />}
                                </span>
                            )}
                        </InputHandler>
                        <InputHandler
                            ref={this.saveUp}
                            disabled={isUpDisabled}
                            prefixCls={prefixCls}
                            unselectable="unselectable"
                            {...upEvents}
                            role="button"
                            aria-label="Increase Value"
                            aria-disabled={!!isUpDisabled}
                            className={`${prefixCls}-handler ${prefixCls}-handler-up ${upDisabledClass} btn-icon-fm btn-plus`}
                        >
                            {upHandler || (
                                <span
                                    unselectable="unselectable"
                                    className={`${prefixCls}-handler-up-inner`}
                                    onClick={preventDefault}
                                >
                                    {defaultTheme == "dark" && <ICON_PLUS_DARK />}
                                    {defaultTheme == "light" && <ICON_PLUS_LIGHT />}
                                </span>
                            )}
                        </InputHandler>
                        <div
                            className={`${prefixCls}-input-wrap`}
                            role="spinbutton"
                            aria-valuemin={props.min}
                            aria-valuemax={props.max}
                            aria-valuenow={value}
                        // style={{ marginRight: '20px' }}
                        >
                            <input
                                required={props.required}
                                type="text"
                                // inputMode="numeric"
                                inputMode={isIOS ? "" : "decimal"} // case cho IOS do chưa hỗ trợ nhập dấu .
                                placeholder={props.placeholder}
                                onClick={props.onClick}
                                onMouseUp={this.onMouseUp}
                                className={`${prefixCls}-input` + (inputClassName ? ' ' + inputClassName : '')}
                                tabIndex={props.tabIndex}
                                autoComplete={autoComplete}
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onKeyDown={editable ? this.onKeyDown : noop}
                                onKeyUp={editable ? this.onKeyUp : noop}
                                autoFocus={props.autoFocus}
                                maxLength={props.maxLength}
                                readOnly={props.readOnly}
                                disabled={props.disabled}
                                max={props.max}
                                min={props.min}
                                step={props.step}
                                name={props.name}
                                id={props.id}
                                onChange={this.onChange}
                                ref={this.saveInput}
                                value={inputDisplayValue}
                                // pattern="[-]?[0-9.,]*[.,]?[0-9]+"
                                pattern="[0-9.,]*"
                                {...dataOrAriaAttributeProps}
                            />
                        </div>
                    </div >
                    {/*this.renderPriceType()*/}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        defaultTheme: defaultTheme,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PriceInput);