import React, { Component, Fragment } from 'react';

import {JsxUtils } from '../../utils';

import "./TokenInput.scss"

class TokenInput extends Component {

    static noop() { }

    static defaultProps = {
        onKeyDown: TokenInput.noop
    };

    constructor(props) {
        super(props);
        this.state = {
            tokenValue: ''
        }
    }

    input = null;

    ref = element => {
        this.input = element;
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
		
	componentWillUnmount() {
        this.mounted = false
    }
	

    handleValueChange = (event) => {
        let convertedValue = event.target.value;
        this._setState({
            tokenValue: convertedValue
        })
        if (this.props.onChange) {
            this.props.onChange(convertedValue);
        }
    };

    componentDidMount() {
        this.mounted = true
        const { disabled, autoFocus } = this.props;
        if (!disabled && autoFocus) {
            this.input.focus();
        }
    }

    render() {
        // noinspection JSUnusedLocalSymbols
        const { onKeyDown, onChange, ref, className, toggleDisplayToken, showToken, ...otherProps } = this.props;

        //huy.quang: custom input kieu text ----> input kieu password
        const baseClass = showToken ? 'pin-input' : 'key pin-input';
        const inputClassName = JsxUtils.joinClass(baseClass, className);
        return (
            <Fragment>
                <input
                    autoComplete="off"
                    className={inputClassName}
                    ref={this.ref}
                    onChange={this.handleValueChange}
                    onKeyDown={onKeyDown}
                    value={this.props.disabled !== null ? (!this.props.disabled ? this.state.tokenValue : "•••••••") : this.state.tokenValue}
                    {...otherProps}
                />
                {(!this.props.disabled) && (
                    <Fragment>
                        <button tabIndex={-1}  className="btn-edit text-center" onClick={toggleDisplayToken}><i className="pin-color icon-eye fas fa-eye" /></button>
                        <button tabIndex={-1}  className="btn-save text-center" onClick={toggleDisplayToken}><i className="pin-color icon-eye fas fa-eye-slash" /></button>
                    </Fragment>
                )}
            </Fragment >
        );
    }

}

export default TokenInput;