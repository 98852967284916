import React, { Component, Fragment } from 'react';
import { connect, batch } from 'react-redux';
import * as actions from "../../store/actions";
import * as LayoutUtils from '../../containers/LayoutPage/LayoutUtils';
import { dispatch } from '../../redux';
import config from '../../combineConfig';
import { CommonUtils, Role } from 'utils';
import { menuBroker } from 'containers/MenuSideBar/MenuSideBarConfig';

import './SymbolDetailAction.scss';

class SymbolDetailAction extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    onChangeMenuActive = (keyName) => {
        let _OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#' + keyName) // Mặc định theo role
        batch(() => {
            dispatch(actions.setIsOpenModalHaveData("QuickOrder", { isOpenQuickOrder: false }))
            dispatch(actions.onChangeMenuActive(_OBJLAYOUT))
            dispatch(actions.onChangeStatusMenuSideBar(0))
            dispatch(actions.setIsOpenModalHaveData("ScreenModal", {
                isOpenScreenModal: false,
                curScreenModalActive: ""
            }))
        });
        let key = this.isBroker() ? "B#CS#SYMBOL_DETAIL" : "C#CS#SYMBOL_DETAIL"
        CommonUtils.openLayoutByKey(key)
    }

    isCheckSymbolWFT = (symbol) => {
        const config = '_WFT'
        if (symbol.endsWith(config)) {
            return true
        }
        return false
    }

    onIndexClickHandeler = (index) => {
        const { marketInfos } = this.props;
        let marketConfigInfo = config.ALL_INDEXES.find(configItem => { return configItem.searchKey === index });
        let marketCode = marketConfigInfo['code']

        if (marketCode) {
            let marketInfo = marketInfos[`${marketCode}`] ? marketInfos[`${marketCode}`] : {};
            let result = CommonUtils.populateMarketInfo(marketInfo)
            this.onChangeMenuActive(menuBroker['SYMBOL_DETAIL'].key, menuBroker['SYMBOL_DETAIL'].path)
            let symbolObj = {
                ...result,
                FullName: marketInfo['floorCode'] || '',
                desc: marketInfo['floorCode'] || '',
                id: marketConfigInfo['searchKey'],
                symbol: marketConfigInfo['searchKey'],
                exchange: marketInfo['floorCode'],
                stockType: marketInfo['floorCode'],
                StockType: marketInfo['floorCode'],
                alertType: 'index',
                subName: marketConfigInfo['subName'],
                isDetailIndex: true
            }
            this.props.updateDataSymbolDetailLayoutPage(symbolObj);
        }
    }

    toggleDetailModal = (symbol) => {
        const { isIndex } = this.props
        if (this.isCheckSymbolWFT(symbol)) {
            return
        }
        if (isIndex) {
            this.onIndexClickHandeler(symbol)
        } else {
            let key = this.isBroker() ? "B#CS#SYMBOL_DETAIL" : "C#CS#SYMBOL_DETAIL"
            this.onChangeMenuActive(menuBroker['SYMBOL_DETAIL'].key, menuBroker['SYMBOL_DETAIL'].path)
            if (symbol) {
                this.props.updatedSymbolLayoutPage(symbol, "search_symbol_detail", key)
            }
        }
    }

    render() {
        const { symbol, isIndex } = this.props
        return (
            <Fragment>
                <div className='symbol-detail-action'
                    // onClick={() => this.toggleDetailModal(symbol)}
                >
                    {symbol}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.userInfo.role,
        marketInfos: state.marketInfoStore.marketInfos,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateDataSymbolDetailLayoutPage: (symbolObj) => dispatch(actions.updateDataSymbolDetailLayoutPage(symbolObj)),
        updatedSymbolLayoutPage: (symbolId, from, id) => dispatch(actions.updatedSymbolLayoutPage(symbolId, from, id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SymbolDetailAction);
