import React, { Component } from 'react'
import _ from 'lodash';
import { connect } from 'react-redux';

import * as actions from "../../store/actions";
import { KeyCodeUtils, Role, PREFIX_CUSTODYCD, FOCUS_KEYS } from "../../utils";

import './SelectorAccountCustomer.scss'
import ToastUtil from './../../utils/ToastUtil';
import Autosuggest from 'react-autosuggest';
import { emitter } from 'utils/EventEmitter';
import { userService } from "../../services";
const globalVar = window._env_;
class SelectorAccountCustomer extends Component {

    initialState = {
        custodycd: '',
        custodycdFake: '',// Sử dụng khi dùng phim mũi tên down, up
        savedAccountInfo: [],
        favCustomers: [], // Danh khách tìm kiếm khách hàng careby yêu thích (đối với môi giới),
        allFavCustomers: [], // Danh khách hàng careby yêu thích (Với môi giới),
        skipCheckBlur: false // true nếu muốn skip check khi mở modal tìm kiếm khách hàng careby của môi giới
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.customerAccountRef = React.createRef();
        this.inputSymbol = React.createRef();
        this.listenToTheEmitter();
    };

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    callback = data => {
        this.inputSymbol.current && this.inputSymbol.current.focus();
    }

    callback2 = data => {
        this.loadFavCustomers();
    }


    listenToTheEmitter() {
        emitter.on('onTabSymbolTrade_Broker', this.callback);
        // Case thông tin khách hàng yêu thích dược cập nhật (add hoặc xóa đi)
        if (this.isBroker()) {
            emitter.on('RELOAD_FAVORITE_CUSTOMER_LIST', this.callback2);
        }
    }

    getCustodycd = () => {
        if (this.isBroker()) {
            const { curCustomer, saveAccountInfo } = this.props
            if (curCustomer) {
                //console.log("info---alert 1")
                curCustomer && this.props.setCustodycdCustomerScreen(curCustomer['custodycd'], this.props.selectorAcountScreen, null, saveAccountInfo);
                return curCustomer['custodycd'] || PREFIX_CUSTODYCD
            } else {
                return PREFIX_CUSTODYCD
            }
        };
        return ''
    };


    handleKeyPress = (e) => {
        const inputFocus = this.inputSymbol.current ? (document.activeElement === this.inputSymbol.current ? true : false) : false;
        if (!inputFocus) {
            return
        }

        if (inputFocus) {
            this.handleChangFocus();
        }
        var keyCode = (e.which ? e.which : e.keyCode);
        if (keyCode == KeyCodeUtils.B && e.ctrlKey && inputFocus) {
            this._setState({ skipCheckBlur: true }, () => {
                this.inputSymbol.current && this.inputSymbol.current.blur();
            });
            this.props.setIsOpenModalByKey(
                "customerSelectorModalOpen",
                !this.props.customerSelectorModalOpen
            )
        }
    }

    handleChangFocus = () => {
        const { selectorAcountScreen, setCurrentTypeScreenSelectorAccount } = this.props
        setCurrentTypeScreenSelectorAccount(selectorAcountScreen)
    }


    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }
    componentDidMount() {
        this.mounted = true
        const { focusOnRender } = this.props;
        const custodycd = this.getCustodycd();
        this._setState({
            custodycd: custodycd,
        });

        // this.fetchSavedAccountInfo();
        if (this.isBroker()) {
            this.loadFavCustomers();
        }

        document.addEventListener("keydown", this.handleKeyPress);

        if (focusOnRender === FOCUS_KEYS.CUSTOMER_SELECT) {
            if (this.props.isOpenScreenConfirm === true) return;
            this.inputSymbol.current && this.inputSymbol.current.focus();
            let timer = setTimeout(() => {
                this.inputSymbol.current && this.inputSymbol.current.setSelectionRange(PREFIX_CUSTODYCD.length, custodycd.length);
                clearTimeout(timer)
            }, 100)
            // this.inputSymbol.current && this.inputSymbol.current.focus();
            // this.props.setFocusOnRenderOrder();
        }

    };

    loadFavCustomers = () => {
        userService.getBrokerFavCustomer(10).then(favCustomers => {
            let favCustomersInfo = [];
            favCustomers.forEach(customer => {
                return favCustomersInfo.push(customer.customerInfo);
            });
            this._setState({
                allFavCustomers: favCustomersInfo,
                favCustomers: favCustomersInfo
            });
        }).catch(error => {

        });
    };

    // Lấy thông tin các tài khoản đã lưu từ localstorage
    fetchSavedAccountInfo = () => {
        if (globalVar.switch.enableSaveLoginAccount) {
            const { userInfo } = this.props;
            let currentCustodycd = userInfo.username;
            let usersTokens = JSON.parse(localStorage.getItem('token-users'));
            let savedAccountInfo = usersTokens[`${currentCustodycd}`] && usersTokens[`${currentCustodycd}`]['savedAccountInfo'] ? usersTokens[`${currentCustodycd}`]['savedAccountInfo'] : [];
            this._setState({ savedAccountInfo: savedAccountInfo });
        } else {
            this._setState({ savedAccountInfo: [] });
        }
    }

    componentWillUnmount() {
        emitter.removeListener('onTabSymbolTrade_Broker', this.callback);
        // Case thông tin khách hàng yêu thích dược cập nhật (add hoặc xóa đi)
        if (this.isBroker()) {
            emitter.removeListener('RELOAD_FAVORITE_CUSTOMER_LIST', this.callback2);
        }
        document.removeEventListener('keydown', this.handleKeyPress);
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    getStateCustodycd = () => {
        return this.state.custodycd;
    }

    performSearch = () => {
        const { custodycd } = this.state;
        const { saveAccountInfo } = this.props;
        if (custodycd != null && custodycd.length > 0) {
            this.props.setCustodycdCustomerScreen(custodycd, this.props.selectorAcountScreen, null, saveAccountInfo);
        }
    }

    performClearData = () => {
        const { custodycd } = this.state;
        this.props.clearCustodycdCustomerScreen(this.props.selectorAcountScreen);
    }


    handleKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        let { custodycd } = this.state;
        let str = "000000"
        if (keyCode === KeyCodeUtils.ENTER || keyCode === KeyCodeUtils.TAB) {
            if (custodycd.indexOf(PREFIX_CUSTODYCD) !== 0) {
                if (custodycd.length <= 6) {
                    this._setState({
                        custodycd: PREFIX_CUSTODYCD + str.slice(0, -(custodycd.length)) + custodycd
                    })
                    str = "000000"
                }
                else this._setState({ custodycd: PREFIX_CUSTODYCD + custodycd.slice(0, -(custodycd.length - 6)) })
            }
            else {
                this._setState({
                    custodycd: PREFIX_CUSTODYCD + str.slice(0, -(custodycd.length - 4)) + custodycd.slice(4)
                })
                str = "000000"
            }
        }
    };

    handleBlur = () => {
        const { custodycd, custodycdFake, skipCheckBlur } = this.state;
        const { isGetValueCurCustomer } = this.props
        //console.log("handleBlur", custodycd)
        if (skipCheckBlur === true) {
            this._setState({ skipCheckBlur: false }); // set lại để check cho lần sau
            return;
        }
        let custodycdCur = custodycdFake ? custodycdFake : custodycd // Nếu người dùng dùng mũi bàn phím chọn mã và blur thì lấy symbolFake nếu k thì lấy symbl
        if (custodycdCur && custodycdCur !== null && ((0 < custodycdCur.length) && (custodycdCur.length < 10))) {
            // if (!isGetValueCurCustomer) ToastUtil.error("CM2", 'CM000002');
            // case Vẫn còn ký tự thì tự fill dạng tài khoản của khách hàng vào (VD: 5 ====> 072C000005)
            this.fillCustomerSearch(custodycdCur);
            return;
        }

        if (!custodycdCur) {
            // if (!isGetValueCurCustomer) ToastUtil.error("CM2", 'CM000002');
            return;
        }

        // case input đã nhập có giá trị và dài hơn 10 ký tự
        this._setState({ custodycd: custodycdCur }, () => {
            this.performSearch();
        });

    };

    fillCustomerSearch = (custodycd) => {
        // const { custodycd } = this.state;
        let custodycdToFill = '';
        if (custodycd.startsWith(PREFIX_CUSTODYCD)) {
            if (custodycd.length === PREFIX_CUSTODYCD.length) {
                this._setState({ custodycd: PREFIX_CUSTODYCD });
                // ToastUtil.error("CM2", 'CM000002');
                return;
            }
            let subAccountName = custodycd.substring(PREFIX_CUSTODYCD.length, custodycd.length);
            // subAccountName = subAccountName.replaceAll("(^0+)?(\d+)", "$2") // Bỏ các ký tự 0 ở đằng trước
            if (subAccountName.length > 10 - PREFIX_CUSTODYCD.length) {
                this._setState({ custodycd: PREFIX_CUSTODYCD });
                ToastUtil.error("CM2", 'CM000002');
                return;
            }

            let convertCheck = subAccountName.replaceAll("0", "");
            // Case subaccount chứa toàn số 0
            if (convertCheck.length === 0) {
                this._setState({ custodycd: PREFIX_CUSTODYCD });
                ToastUtil.error("CM2", 'CM000002');
                return;
            }
            // Số lượng số 0 cần thêm
            let numberOfZero = 10 - PREFIX_CUSTODYCD.length - subAccountName.length;
            custodycdToFill = PREFIX_CUSTODYCD;
            for (let i = 0; i < numberOfZero; i++) {
                custodycdToFill += '0';
            }
            custodycdToFill += subAccountName;
            this._setState({ custodycd: custodycdToFill }, () => {
                this.performSearch();
            });
        } else {
            if (custodycd.length > 10) {
                this._setState({ custodycd: PREFIX_CUSTODYCD });
                ToastUtil.error("CM2", 'CM000002');
                return;
            }

            let convertCheck = custodycd.replaceAll("0", "");
            // Case subaccount chứa toàn số 0
            if (convertCheck.length === 0) {
                this._setState({ custodycd: PREFIX_CUSTODYCD });
                // ToastUtil.error("CM2", 'CM000002');
                return;
            }

            // Số lượng số 0 cần thêm
            let numberOfZero = 10 - PREFIX_CUSTODYCD.length - custodycd.length;
            custodycdToFill = PREFIX_CUSTODYCD;
            for (let i = 0; i < numberOfZero; i++) {
                custodycdToFill += '0';
            }
            custodycdToFill += custodycd;
            this._setState({ custodycd: custodycdToFill }, () => {
                this.performSearch();
            });
        }
    }

    onCustodycdChanged = (e) => {
        const { isGetValueCurCustomer, setCustodycdParam } = this.props
        const custodycd = e.target.value || '';
        let re = /[^A-Za-z0-9]+/g; // Chỉ cho phép chữ và số
        let isValid = !re.test(custodycd);
        if (custodycd.length <= 10 && isValid) {
            if (isGetValueCurCustomer) setCustodycdParam(custodycd)
            this._setState({
                custodycd: custodycd != null ? custodycd.toUpperCase() : ''
            }, () => {
                this.performClearData()
                if (this.state.custodycd.length >= 10) {
                    this.performSearch();
                }
            });
        }
    };

    onCustodycdChangedSavedAccount = (event, { newValue, method }) => {

        if (method == 'down' || method == 'up') {
            this._setState({ custodycdFake: newValue })
            return
        } else {
            this._setState({ custodycdFake: '' });
        }


        const custodycd = newValue;
        let re = /[^A-Za-z0-9]+/g; // Chỉ cho phép chữ và số
        let isValid = !re.test(custodycd);
        if (custodycd.length <= 10 && isValid) {
            this._setState({
                custodycd: custodycd != null ? custodycd.toUpperCase() : ''
            }, () => {
                this.performClearData()
                if (this.state.custodycd.length >= 10) {
                    this.performSearch();
                }
            });
        }
    };

    componentDidUpdate(prevProps) {
        const { custodycd, curCustomer, focusOnRender } = this.props
        const { curCustomer: prevCurCustomer } = prevProps;

        if (this.isBroker()) {
            if (curCustomer && !_.isEqual(curCustomer, prevCurCustomer)) {
                this._setState({
                    custodycd: curCustomer['custodycd']
                },

                    // () => {
                    //     this.inputSymbol.current && this.inputSymbol.current.blur();
                    // }
                    () => {
                        if (curCustomer && focusOnRender === FOCUS_KEYS.CUSTOMER_SELECT) {
                            if (this.props.isOpenScreenConfirm === true) return;
                            this.inputSymbol.current && this.inputSymbol.current.focus();
                            let timer = setTimeout(() => {
                                this.inputSymbol.current && this.inputSymbol.current.setSelectionRange(PREFIX_CUSTODYCD.length, curCustomer.custodycd.length);
                                clearTimeout(timer)
                            }, 100)
                            this.props.setFocusOnRenderOrder();
                        }
                    }
                )
            }
        };
    }

    // sort dữ liệu và sắp xếp theo giống nhất đến không giống
    sortSuggestions = (value) => {
        // let usersTokens = JSON.parse(localStorage.getItem('token-users'));
        // let currentCustodycd = this.props.userInfo.username;
        // let savedAccountInfo = usersTokens[`${currentCustodycd}`]['savedAccountInfo'] ? usersTokens[`${currentCustodycd}`]['savedAccountInfo'] : [];

        let savedAccountInfo = this.state.allFavCustomers;
        // Thêm logic vào đây
        var suggestArr = []
        const inputValue = value.trim();
        _.filter(savedAccountInfo, (item) => {
            if (item['custodycd'].indexOf(inputValue) >= 0) {
                suggestArr.push(item)
            }
        });

        let _Results = suggestArr;
        let lengthInputValue = inputValue && inputValue.length;
        if (_Results && _Results.length > 0) {
            _Results = _Results.map(function (e) {
                let custodycd = e && e.custodycd;

                if (custodycd) {
                    if (custodycd.substring(0, lengthInputValue) == inputValue) {
                        // KQ có phần đầu giống inputValue
                        e.idsort = 0
                    }
                    else {
                        // KQ còn lại
                        e.idsort = 1
                    }
                }
                return e
            })
            // Haki.: Ưu tiên KQ có phần đầu giống inputValue và sắp xếp, phần còn lại sắp xếp theo A-Z
            _Results = _.orderBy(_Results, ['idsort', 'ts'], ['asc', 'desc']);
        }

        _Results = _.sortBy(_Results, (item) => {
            return item['custodycd'].indexOf(value);
        })


        // let result = savedAccountInfo;
        return _Results;
    }

    onSuggestionsFetchRequested = ({ value, reason }) => {
        const { allFavCustomers } = this.state;
        if (allFavCustomers && allFavCustomers.length > 0) {
            let foundIndex = _.findIndex(allFavCustomers, item => { return item.custodycd === value });
            if (foundIndex !== -1 && reason === "input-changed") {
                this.onSuggestionsClearRequested();
                return true;
            }
        }
        // sort dữ liệu và sắp xếp theo giống nhất đến không giống
        this._setState({
            // savedAccountInfo: this.sortSuggestions(value)
            favCustomers: this.sortSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this._setState({
            // savedAccountInfo: []
            favCustomers: []
        });
    };

    getSuggestionValue = suggestion => {
        let custodycd = suggestion['custodycd'];
        return custodycd;
    }

    renderSuggestion = suggestion => {
        let custodycd = suggestion['custodycd'];
        return (
            <div className="suggest-item" id={`suggestion-tooltip-${suggestion && suggestion.id}`}>
                {custodycd}
            </div>
        );
    };

    onSuggestionSelected = (event, selected) => {
        let suggestion = selected.suggestion;
        this._setState({
            custodycd: suggestion['custodycd'],
        }, () => {
            this.onSuggestionsClearRequested();
        });
    };

    shouldRenderSuggestions = value => {
        return true;
    };

    showAllSavedAccount = () => {
        const { custodycd } = this.state;
        if (custodycd && custodycd.length < 10) {
            // this.fetchSavedAccountInfo();
            this._setState({ favCustomers: this.state.allFavCustomers });
        }
    }

    // storeInputReference = autosuggest => {
    //     if (autosuggest !== null) {
    //         this.inputSearch = autosuggest.input;
    //     }
    // };

    getInputRef = () => {
        return this.inputSymbol;
    }

    render() {
        const { custodycd, savedAccountInfo, favCustomers } = this.state;
        const { userInfo, className, saveAccountInfo, editOnly } = this.props
        const disableCustomerSelector = !this.isBroker(); //case kh disable o chon

        const inputProps = {
            tabIndex: 0,
            placeholder: '',
            value: custodycd ? custodycd : "",
            onChange: this.onCustodycdChangedSavedAccount,
            onClick: () => {
                let timer = setTimeout(() => {
                    if (custodycd) {
                        this.inputSymbol.current && this.inputSymbol.current.setSelectionRange(PREFIX_CUSTODYCD.length, custodycd.length);
                    }
                    clearTimeout(timer)
                }, 100)
                this.showAllSavedAccount();
            },
            onBlur: (event, { highlightedSuggestion }) => {
                // this.handleBlurInput(event, highlightedSuggestion);
                this.handleBlur(event, highlightedSuggestion);
            },
            onFocus: (e) => {
                let timer = setTimeout(() => {
                    if (custodycd) {
                        this.inputSymbol.current && this.inputSymbol.current.setSelectionRange(PREFIX_CUSTODYCD.length, custodycd.length);
                    }
                    clearTimeout(timer)
                }, 100)
                this.showAllSavedAccount();
                // this.onInputFocus(e)
                // this.handleKeyPress(e)
            },
            ref: this.inputSymbol
        };

        let _className = "selector-account-customer account-select-block custom-form-group txt---500-14-20 " + (className ? className : "")

        if (this.isBroker()) {
            _className = _className + " isForBroker"
        }

        return (
            <div className={_className}>
                {this.isBroker() && !saveAccountInfo && !editOnly &&
                    // Chuyển sang symbolsuggest
                    <input
                        ref={this.inputSymbol}
                        type="text"
                        id='account-selector'
                        name="custodycd"
                        maxLength="10"
                        className="custom-form-control txt---500-14-20"
                        value={custodycd ? custodycd : ""}
                        onChange={this.onCustodycdChanged}
                        // onKeyDown={this.handleKeyDown}
                        onBlur={this.handleBlur}
                        disabled={disableCustomerSelector}
                        autoComplete="off"
                        tabIndex={0}
                        onClick={() => {
                            let timer = setTimeout(() => {
                                if (custodycd) {
                                    this.inputSymbol.current && this.inputSymbol.current.setSelectionRange(PREFIX_CUSTODYCD.length, custodycd.length);
                                }
                                clearTimeout(timer)
                            }, 100)
                        }}
                    />
                }



                {this.isBroker() && saveAccountInfo && !editOnly &&
                    // Chuyển sang symbolsuggest
                    <Autosuggest
                        suggestions={favCustomers} // Dữ liệu tìm kiếm [{custodycd: 002C123456, ts: 456674345},...]
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}  //cập nhật lại danh sách suggestion khi input has foc 
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested} // clear danh sách gợi ý
                        getSuggestionValue={this.getSuggestionValue} // Lấy giá trị hiển thị khi tìm kiếm
                        renderSuggestion={this.renderSuggestion} // render phần gợi ý
                        onSuggestionSelected={this.onSuggestionSelected} //  Chọn gợi ý
                        shouldRenderSuggestions={this.shouldRenderSuggestions} // Có hiển thị gợi ý không
                        highlightFirstSuggestion={false} // Highligh giá trị đầu tiên tìm kiếm được
                        inputProps={inputProps} // Giá trị đầu vào
                        // ref={this.storeInputReference} // ref
                        disabled={disableCustomerSelector}
                        id={'account-selector'} // Cần có id phòng trường hợp có nhiều suggest trên màn hình
                        tabIndex={0}
                    />
                }
                {!this.isBroker() &&
                    // <input
                    //     ref={this.customerAccountRef}
                    //     type="text"
                    //     name="custodycd"
                    //     maxLength="10"
                    //     className="custom-form-control txt---500-14-20"
                    //     value={userInfo ? userInfo.username : ""}
                    //     disabled={true}
                    //     autoComplete="off"
                    // />
                    <div tabIndex="-1" className="d-flex custom-form-control txt---500-14-20">{userInfo ? userInfo.username : ""}</div>
                }

                {this.isBroker() && editOnly === true &&
                    <div tabIndex="-1" className="d-flex custom-form-control txt---500-14-20">{custodycd ? custodycd : ""}</div>
                }
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {

    return {
        userInfo: state.user.userInfo,
        role: state.user.userInfo.role,
        focusOnRender: state.orderNew.focusOnRender,
        isOpenScreenConfirm: state.layout.listIsOpenModal["C&B"]["ScreenConfirm"].isOpenScreenConfirm,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCustodycdCustomerScreen: (custodycd, typeScreen, callback, saveCustodycd) => dispatch(actions.setCustodycdCustomerScreen(custodycd, typeScreen, callback, saveCustodycd)),
        setIsOpenModalByKey: (key, status) => dispatch(actions.setIsOpenModalByKey(key, status)),
        setCurrentTypeScreenSelectorAccount: (typeScreen) => dispatch(actions.setCurrentTypeScreenSelectorAccount(typeScreen)),
        clearCustodycdCustomerScreen: (typeScreen) => dispatch(actions.clearCustodycdCustomerScreen(typeScreen)),
        setFocusOnRenderOrder: (focusKey) => dispatch(actions.setFocusOnRenderOrder(focusKey)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SelectorAccountCustomer);