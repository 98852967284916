import React, { Component } from 'react';
import './SelectOptionCustom.scss';

class SelectOptionCustom extends Component {
    initstate = {
        titleSelected: '',
        valueSelected: '',
        objSelected: null,
        isOpen: false
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initstate
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        const { defaultTitle, defaultValue, options } = this.props
        if (defaultValue) {
            let _objSelected = options.find((item, index) => (item.value === defaultValue));
            if (_objSelected) {
                this._setState({
                    titleSelected: _objSelected.title,
                    valueSelected: _objSelected.value,
                    objSelected: _objSelected,
                })
            } else {
                this._setState({
                    titleSelected: '',
                    valueSelected: '',
                })
            }
        }
        if (defaultTitle) {
            let _objSelected = options.find((item, index) => (item.title === defaultTitle));
            if (_objSelected) {
                this._setState({
                    titleSelected: _objSelected.title,
                    valueSelected: _objSelected.value,
                    objSelected: _objSelected,
                })
            }
        }
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }



    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        this.mounted = false
    }


    componentDidUpdate(prevProps, prevState) {
        const { defaultTitle, defaultValue, options } = this.props
        const { defaultValue: prevDefaultValue } = prevProps

        if (defaultValue !== prevDefaultValue) {
            let _objSelected = options.find((item, index) => (item.value === defaultValue));
            if (_objSelected) {
                this._setState({
                    titleSelected: _objSelected.title,
                    valueSelected: _objSelected.value,
                    objSelected: _objSelected,
                })
            } else {
                this._setState({
                    titleSelected: '',
                    valueSelected: '',
                })
            }
        }
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this._setState({ isOpen: false })
        }
    }

    handleSelect = (value, title) => {
        const { options } = this.props
        let _objSelected = options.find((item, index) => (item.value === value));
        this._setState(prevState => ({
            isOpen: !prevState.isOpen,
            valueSelected: value,
            titleSelected: title,
            objSelected: _objSelected
        }),
            () => {
                this.onChangeSelectOption()
            });
    };

    handleOpen = () => {
        this._setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    setIsOpen = (isOpen) => {
        this._setState(prevState => ({
            isOpen: isOpen
        }));
    }

    onChangeSelectOption = () => {
        const { titleSelected, valueSelected, objSelected } = this.state;
        this.props.onChange && this.props.onChange(valueSelected, titleSelected, objSelected)
    }

    checkItemSelected = (title) => {
        const { titleSelected } = this.state;
        let _class = ""
        if (titleSelected === title) {
            _class = "item-active"
        }
        return _class
    }

    render() {
        const { options, selected, classWrap, classInput, classList, isDisable } = this.props;
        const { titleSelected } = this.state;
        let _classWrap = "option-custom " + classWrap
        let _classInput = "select-input " + classInput
        let _classList = "select-list " + classList
        let lengthData = options && options.length

        if (isDisable) {
            _classWrap = _classWrap + " disable-warp"
        }
        // console.log("binh_SelectOptionCustom", options, this.props, titleSelected)
        return (
            <div className={_classWrap} ref={this.setWrapperRef}>
                <div className={_classInput} onClick={this.handleOpen}>
                    <span
                        className={"select-input-title  "}>
                        {titleSelected}
                    </span>
                </div>

                {this.state.isOpen ?
                    <div className={_classList}>
                        {options && options.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => this.handleSelect(item.value, item.title)}
                                className={"select-item " + (this.checkItemSelected(item.title))}
                            >
                                <span className={"select-title "}>
                                    {item.title}
                                </span>
                            </div>
                        ))}
                    </div>
                    : ''
                }
            </div>
        );
    }
}

export default SelectOptionCustom;