import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { CardHeader, } from 'reactstrap';

import { ReactComponent as CloseIconDark } from '../../assets/icons/header/CloseIcon_Dark.svg';
import { ReactComponent as CloseIconLight } from '../../assets/icons/header/CloseIcon_Light.svg';
import * as actions from "../../store/actions";

import './HeaderCustom4ScreenModal.scss';


class HeaderCustom4ScreenModal extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    toggleOpenModal = () => {
        const { setIsOpenModalHaveData, isOpenScreenModal } = this.props;
        setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: !isOpenScreenModal,
            curScreenModalActive: "",
        })
    };

    handleBack = () => {
        const { headerCustom, setIsOpenModalHaveData } = this.props;
        setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: true,
            curScreenModalActive: headerCustom && headerCustom.backScreen ? headerCustom.backScreen : null,
            accountInfo: this.props.accountInfo,
            useCustomHeader: false,
            headerCustom: {}
        })
    }

    genderIcon = (keyIcon) => {
        let { widthMenuSideBar, isOpenScreenModal, layoutsCustom, defaultTheme } = this.props
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < CloseIconDark /> : < CloseIconLight />
                break;
            default:
                break;
        }
    }

    render() {
        const { headerCustom } = this.props
        return (
            <CardHeader className="header-container">
                <div className="header-and-btn">
                    <i className="fa fa-arrow-left" onClick={() => this.handleBack()} aria-hidden="true"></i><div className='title headB---700-20-30'>  <FormattedMessage id={headerCustom.title} /></div>
                </div>
                <span onClick={() => this.toggleOpenModal()}>
                    {this.genderIcon(1)}
                </span>
            </CardHeader>)
    }
}

const mapStateToProps = state => {
    const menuHeader = state.user.userInfo && state.user.userInfo.menuHeader
    const widthMenuSideBar = menuHeader.menuSideBar.widthMenuSideBar
    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        widthMenuSideBar: widthMenuSideBar,
        curScreenModalActive: state.layout.listIsOpenModal["C&B"]["ScreenModal"].curScreenModalActive,
        isOpenScreenModal: state.layout.listIsOpenModal["C&B"]["ScreenModal"].isOpenScreenModal,
        currentLayoutPageActive: state.layout.listIsOpenModal["C&B"]["ScreenModal"].curScreenModalActive,
        useCustomHeader: state.layout.listIsOpenModal["C&B"]["ScreenModal"].useCustomHeader,
        headerCustom: state.layout.listIsOpenModal["C&B"]["ScreenModal"].headerCustom,
        defaultTheme,
        accountInfo: _accountInfo,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCustom4ScreenModal);


