import React, { Component } from 'react';

import './GridLoading.scss';

class GridLoading extends Component {

    render() {
        return (
            <div className="lds-grid-wrapper">
                <div className="lds-grid">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
        );
    }
}

export default GridLoading;