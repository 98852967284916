import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { CustomInput } from 'reactstrap';

import config from '../../combineConfig';
import { localService } from '../../services';
import * as actions from "../../store/actions";
import { CommonObject, CommonUtils, JsxUtils, KeyCodeUtils, LoadStatus, reconfirmTypes, Role, ToastUtil, TradeAuthType, TradeAuthTypes } from '../../utils';

import { OtpInput, PinInput, MatrixInput, TokenInput } from "./../Input";
import { emitter } from 'utils/EventEmitter';

import './TradeAuth2.scss';


class TradeAuth2 extends Component {
    constructor(props) {
        super(props);
        const { defaultAuthtype } = this.props;
        this.state = {
            authType: defaultAuthtype,
            pin: '',
            isValidPin: false,
            token: '',
            isValidToken: false,
            matrixCode: '',
            isValidMatrixCode: false,
            otp: '',
            isValidOtp: false,
            smartotp: '',
            isValidSmartOtp: false,
            tokenid: '',
            // resendingOtp: false,
            resendDisabled: false,
            listCAInfor: [],
            currentCA: null,
            pinCA: '',
            isValidPinCA: false,
            showPin: false,
            authProcessing: false
        };
        this.otpInputRef = React.createRef();
        this.confirmBtnRef = React.createRef();
        this.inputToRefocusRef = React.createRef();
        this.initGen = true; // true nếu nhấn xác nhận lần đầu , false nếu ngược lại
        this.listenToTheEmitter();
    }

    INTERVAL = 1000; // Thời gian môi lần bấm enter (2s)
    getPropDefaultValue(props) {
        let { labelClass, inputClass, containerClass, btnContainerClass, noteMessageClass, listAuthType, challengeQuestion } = props;
        containerClass = containerClass ? containerClass : 'row gutters-5 default-container';
        labelClass = labelClass != null ? labelClass : 'col-4 default-label';
        inputClass = inputClass != null ? inputClass : 'col-8 default-input';
        btnContainerClass = btnContainerClass != null ? btnContainerClass : 'col-12 default-btn-container';
        noteMessageClass = noteMessageClass != null ? noteMessageClass : 'col-12';
        listAuthType = listAuthType != null ? listAuthType : [];
        challengeQuestion = challengeQuestion ? challengeQuestion : '';
        return {
            ...props,
            containerClass,
            labelClass,
            inputClass,
            listAuthType,
            challengeQuestion
        }
    };



    callback = () => {
        this.reFocusInput();
    }

    listenToTheEmitter = () => {
        emitter.on('RE_FOCUS_AUTH', this.callback);
    }

    componentWillUnmount() {
        emitter.removeListener('RE_FOCUS_AUTH', this.callback);
        this.mounted = false
    }

    loadCAList = () => {
        localService.loadCAList().then(res => {
            let data = res.data;
            if (data.EC === 0) {
                let listCert = JSON.parse(data.DT);
                let listCAInfor = [];
                listCert.map(cert => {
                    let { Name, Serial } = cert,
                        caName = Name.substring(0, Name.indexOf("==[")),
                        expire = Name.substring(Name.indexOf("Expire") + 7, Name.length);
                    listCAInfor.push({
                        caName, expire, Serial
                    });
                });
                this._setState({
                    listCAInfor,
                    currentCA: listCAInfor.length > 0 ? listCAInfor[0] : null
                })
            }
            //do nothing if cant get data;
        }).catch(error => {
            //do nothing if cant get data;
        });
    };

    onAuthTypeChange = (event) => {
        const authType = event.target.value;
        this._setState({
            authType,
        });
        const { genAuthFunc, doProcessData, transactionId } = this.props;
        if (authType !== TradeAuthType.OTP) {
            if (genAuthFunc) {
                genAuthFunc({ authtype: authType, transactionId })
                    .then((data) => {
                        const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                        this._setState({
                            tokenid: tokenid
                        });
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'trade.auth.initialize-fail');
                        this.initGen = false;
                    });
            }
        }
    };

    onCAChange = (event) => {
        const serial = event.target.value;
        const { listCAInfor } = this.state;
        let currentCA = listCAInfor.find(caInfor => {
            return caInfor.Serial === serial;
        });
        this._setState({
            currentCA
        });

    };

    onPINChange = (value) => {
        const pin = value;
        const pinValid = pin && pin.length > 0;
        this._setState({
            pin: pin,
            isValidPin: pinValid
        });
        this.props.onAuthCodeChange && this.props.onAuthCodeChange(pin, pinValid)
    };
    onTokenChange = (value) => {
        const token = value;
        const tokenValid = token && token.length > 0;
        this._setState({
            token: token,
            isValidToken: tokenValid
        });
        this.props.onAuthCodeChange && this.props.onAuthCodeChange(token, tokenValid)
    };
    onMatrixCodeChange = (value) => {
        const matrixCode = value;
        const matrixCodeValid = matrixCode && matrixCode.length > 0;
        this._setState({
            matrixCode: matrixCode,
            isValidMatrixCode: matrixCodeValid
        });
        this.props.onAuthCodeChange && this.props.onAuthCodeChange(matrixCode, matrixCodeValid)
    }
    onCAPINChange = (value) => {
        const pinCA = value;
        const pinCAValid = pinCA && pinCA.length > 0;
        this._setState({
            pinCA: pinCA,
            isValidPinCA: pinCAValid
        });
        this.props.onAuthCodeChange && this.props.onAuthCodeChange(pinCA, pinCAValid)
    };

    onOTPChange = (value) => {
        const otp = value;
        const otpValid = otp && otp.length > 0;
        this._setState({
            otp: otp,
            isValidOtp: otpValid
        });
        this.props.onAuthCodeChange && this.props.onAuthCodeChange(otp, otpValid)
    };

    onSmartOTPChange = (value) => {
        const smartotp = value;
        const smartotpValid = smartotp && smartotp.length > 0;
        this._setState({
            smartotp: smartotp,
            isValidSmartOtp: smartotpValid
        });
        this.props.onAuthCodeChange && this.props.onAuthCodeChange(smartotp, smartotpValid)
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        this.loadCAList();
        const { noAutoInit, isSaveTradeAuthenticated, disableAuth, tokenid, verified, saveTradeAuthenticated, clearTradeAuthenticated } = this.props;
        // Case đã lưu xác thực nhưng ô tích chưa chuyển sang true
        if (verified && !isSaveTradeAuthenticated) {
            saveTradeAuthenticated();
        } else if (!verified && isSaveTradeAuthenticated) {
            clearTradeAuthenticated();
        }
        if (verified) {
            this.handlerFocus();
        }
        if (noAutoInit) return;
        this.handlerFocus();
        document.getElementById("transauth-container").addEventListener('keydown', this.handlerKeyDown);
        if (this.props.defaultAuthtype === TradeAuthType.OTP && !disableAuth && tokenid) {
            this._setState({ tokenid: tokenid });
        }
    };
    handlerFocus = () => {
        if (this.confirmBtnRef.current && !this.confirmBtnRef.current.disabled) {
            this.confirmBtnRef.current.focus();
        };
    };
    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        const { authtype } = this.state;
        if (keyCode === KeyCodeUtils.ENTER) {
            if (authtype || !this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) return;

            if (!this.validateValue()) {
                return
            }


            event.preventDefault();
            //VIX: ấn enter tương tụ với tab xuống nút xác nhận , xong ấn enter mới confirm
            // this.confirmBtnRef.current.click();

            this.confirmBtnRef.current && this.confirmBtnRef.current.focus();
        }
        if (keyCode === KeyCodeUtils.TAB) {
            if (authtype || !this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) {
                event.preventDefault();
                return;
            }

            if (!this.validateValue()) {
                event.preventDefault();
                return;
            }
            // event.preventDefault();
            // this.confirmBtnRef.current && this.confirmBtnRef.current.focus();
        }
    };

    handlerKeyDownConfirm = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            const { authtype } = this.state;
            if (authtype || !this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) return;

            if (!this.validateValue()) {
                return
            }

            event.preventDefault();
            this.confirmBtnRef.current.click();
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { defaultAuthtype, transactionId, verified } = this.props;
        const { defaultAuthtype: prevDefaultAuthtype, transactionId: prevTransactionId, verified: prevVerified } = prevProps;
        if (defaultAuthtype !== prevDefaultAuthtype) {
            this._setState({ authType: defaultAuthtype })
        };

        const { genTest } = this.state;
        const { genTest: prevGenTest } = prevState;

        if(verified !== prevVerified && verified === true){
            this.handlerFocus();
        }

        //ham sinh ma duoc goi trong khi sinh transaction thi doi sinh transaction xong moi goi ham
        if (transactionId !== prevTransactionId && transactionId) {
            this._setState({ tokenid: this.props.tokenid }); // đợi khi request check transfer gọi xong
            if (genTest) {
                genTest();
                this._setState({
                    genTest: null,
                });
            };
        };

        //ham gen ma duoc khoi khong phai trong luc sinh transaction
        if (genTest !== prevGenTest && !this.initingTrans) {
            if (genTest) {
                genTest();
                this._setState({
                    genTest: null
                });
            };
        };

    }

    intervalOTP = null;
    genAuthCode = (resend) => {
        const { resendDisabled } = this.state;
        if (resendDisabled && resendDisabled > 0) return;
        const { setLoadStatus, transactionId, clientConfig } = this.props;
        if (!transactionId) return;
        const { authType } = this.state;
        if (this.otpInputRef.current) {
            this.otpInputRef.current.input.focus();
        }
        if (_.includes(TradeAuthTypes, authType)) {
            if (resend) {
                this.intervalOTP && clearInterval(this.intervalOTP);
                this._setState({
                    // resendingOtp: true, 
                    // resendDisabled: config.constant.RESEND_OTP_INTERVAL
                    resendDisabled: (clientConfig && clientConfig.configData && clientConfig.configData.resend_otp_interval) ? clientConfig.configData.resend_otp_interval : config.constant.RESEND_OTP_INTERVAL
                }, () => {
                    this.intervalOTP = setInterval(() => {
                        if (this.state.resendDisabled && this.state.resendDisabled > 0) {
                            this._setState({ resendDisabled: this.state.resendDisabled - 1 });
                        } else {
                            clearInterval(this.intervalOTP);
                        }
                    }, 1000);
                });
            } else {
                setLoadStatus(LoadStatus.LOADING);
            };

            const genTest = () => this.doGenAuth(resend);
            if (!this.initGen) {
                this._setState({
                    genTest: genTest
                });
            } else {
                this._setState({
                    genTest: genTest
                });
            }


        }
    };


    doGenAuth = (resend) => {
        const { genAuthFunc, currentAccount, setLoadStatus, transactionId, doProcessData } = this.props;
        const { accountId } = { ...doProcessData }
        const { authType } = this.state;
        if (genAuthFunc) {
            genAuthFunc({ authtype: authType, transactionId: transactionId })
                .then((data) => {
                    if (resend) {
                        this._setState({
                            // resendingOtp: false, 
                            tokenid: data.tokenid
                        });
                        ToastUtil.success('trade.auth.otp-send-success');
                    } else {
                        setLoadStatus(LoadStatus.LOADED);
                        this._setState({ tokenid: data.tokenid });
                    }
                    this.initGen = false;
                })
                .catch((error) => {
                    if (resend) {
                        // this._setState({ resendingOtp: false });
                        // this._setState({ resendDisabled: false });
                    } else {
                        setLoadStatus(LoadStatus.LOADED);
                    }
                    if (authType === TradeAuthType.OTP) {
                        ToastUtil.errorApi(error, 'trade.auth.otp-send-fail');
                    } else {
                        ToastUtil.errorApi(error, 'trade.auth.initialize-fail');
                    }
                    this.initGen = false;
                });
        }
    }

    reFocusInput = () => {
        const { authType } = this.state;
        if (authType === TradeAuthType.PIN || authType === TradeAuthType.CTS || authType === TradeAuthType.TOKEN) {
            this.inputToRefocusRef && this.inputToRefocusRef.current && this.inputToRefocusRef.current.input.focus();
        } else if (authType === TradeAuthType.OTP || authType === TradeAuthType.SMARTOTP) {
            this.otpInputRef && this.otpInputRef.current && this.otpInputRef.current.input.focus();
        } else if (authType === TradeAuthType.MATRIX) {
            this.inputToRefocusRef && this.inputToRefocusRef.current && this.inputToRefocusRef.current.input1.focus();
        }
    }

    doProcessAuth = (code, tokenid, transactionId, doProcessData) => {

        const { twoFactorAuthCb, twoFactorAuthFunc, autoCb, setLoadStatus, isSaveTradeAuthenticated // isSaveTradeAuthenticated trạng thái nút save
            , authtype, currentAccount, saveTradeAuthenticated, setTradeAuthSuccess, isOrder, verified } = this.props;
        //authtype = true ---> các transauth giao dịch
        //authtype = false ---> các transauth đặt lệnh
        const { authType } = this.state;
        if (!twoFactorAuthFunc) {
            if (twoFactorAuthCb) {
                return twoFactorAuthCb();
            }
        };
        setLoadStatus(LoadStatus.LOADING);
        // twoFactorAuthFunc({ ...doProcessData, code, tokenid, transactionId, authtype: authType, isSaveVerify: isOrder === true ? true : false }).then((data) => { // hard code đặt lệnh isSaveVerify: true
        twoFactorAuthFunc({ ...doProcessData, code, tokenid, transactionId, authtype: authType, isSaveVerify: verified ? true : isSaveTradeAuthenticated }).then((data) => { // hard code đặt lệnh isSaveVerify: true

            // Thành công dispatch đổi trạng thái lưu xác thực
            isSaveTradeAuthenticated && CommonUtils.setIsStep2Authenticated('true', '/', true, '');
            setLoadStatus(LoadStatus.LOADED);
            // if (authtype) ToastUtil.success('trade.auth.auth-success'); //Haki.: Ẩn thông báo xác thực 2 lớp
            if (autoCb) autoCb();
            if (twoFactorAuthCb) twoFactorAuthCb(data);
        }).catch(error => {
            this.reFocusInput();
            setLoadStatus(LoadStatus.LOADED);
            if (authtype) ToastUtil.errorApi(error, 'trade.auth.auth-fail');
            if (autoCb) autoCb();
        })

    };
    processAuth = () => {
        const { authProcessing } = this.state;
        if (authProcessing) return
        this._setState({ authProcessing: true }, () => {
            let timer = setTimeout(() => {
                this._setState({ authProcessing: false });
                clearTimeout(timer)
            }, this.INTERVAL);
        });
        const { setLoadStatus, username, transactionId, tokenid: initTokenId, doProcessData } = this.props;
        const { pin, otp, pinCA, currentCA, smartotp, matrixCode, token } = this.state;
        let { authType, tokenid } = this.state;
        let code = null;
        let input = {}
        if (doProcessData) {
            input = doProcessData;
        }

        if (!this.validateValue()) {
            return
        }

        switch (authType) {
            case TradeAuthType.PIN:
                code = pin;
                this.doProcessAuth(code, initTokenId, transactionId, input);
                break;
            case TradeAuthType.OTP:
                code = otp;
                this.doProcessAuth(code, tokenid, transactionId, input);
                break;
            case TradeAuthType.SMARTOTP:
                code = smartotp;
                this.doProcessAuth(code, initTokenId, transactionId, input);
                break;
            case TradeAuthType.CTS:
                //ki username
                tokenid = currentCA.Serial;
                setLoadStatus(LoadStatus.LOADING);
                localService.signData({ serial: tokenid, username, pin: pinCA })
                    .then(res => {
                        setLoadStatus(LoadStatus.LOADED);
                        let signRes = res.data;
                        if (signRes.EC === 0) {
                            code = signRes.DT;
                            this.doProcessAuth(code, tokenid, transactionId, input);
                        }
                        else throw new Error();
                    })
                    .catch(error => {
                        setLoadStatus(LoadStatus.LOADED);
                        ToastUtil.error('trade.auth.signfailed', 'trade.auth.signfailed');
                    });
                break;
            case TradeAuthType.MATRIX:
                code = matrixCode;
                this.doProcessAuth(code, initTokenId, transactionId, input);
                break;
            case TradeAuthType.TOKEN:
                code = token;
                this.doProcessAuth(code, initTokenId, transactionId, input);
                break;
            case null:
                code = '';
                this.doProcessAuth(code, initTokenId, transactionId, input);
                break;
            default:
                break;
        }
    };

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    changeReconfirmOrder = () => {
        const { changeReconfirmOrder, showReconfirmOrderConfig } = this.props;
        changeReconfirmOrder(!showReconfirmOrderConfig);
    };
    // Dùng ẩn hiện cho pin và token
    toggleDisplayPin = () => {
        this._setState(prevState => ({
            showPin: !prevState.showPin
        }));
    }

    getTokenSerial = () => {
        const { tokenid: initTokenId } = this.props;
        let tokenSerial = '';
        if (initTokenId) {
            let splitted = initTokenId.split('{');
            if (splitted[3] != null) {
                tokenSerial = splitted[3].replaceAll('}', '');
            }
        }
        return tokenSerial;
    }

    validateValue = () => {
        const { labelClass, inputClass, containerClass, btnContainerClass, listAuthType,
            onFinish, noteMessageClass, noteMessageId, authtype, autoFocus, disableAuth,
            isSaveTradeAuthenticated, showReconfirmOrderConfig, saveTradeAuthenticated, showOrderSwitch, fillPassword, challengeQuestion } = this.getPropDefaultValue(this.props);
        const { isValidPin, isValidOtp, resendDisabled, pin, otp, authType, listCAInfor, currentCA, pinCA
            , isValidPinCA, showPin, smartotp, isValidSmartOtp, token, isValidToken, isValidMatrixCode } = this.state;
        const valid = (authType === TradeAuthType.PIN && isValidPin)
            || (authType === TradeAuthType.OTP && isValidOtp) || (authType === TradeAuthType.CTS && isValidPinCA) || (authType === TradeAuthType.SMARTOTP && isValidSmartOtp)
            || (authType === TradeAuthType.MATRIX && isValidMatrixCode) || (authType === TradeAuthType.TOKEN && isValidToken);

        let isDisableConfirm = this.props.isDisableConfirmBtn ? true : (disableAuth ? false : !valid)
        if (isDisableConfirm) {
            ToastUtil.error('FE004014');
            return false
        }
        return true
    }


    setSaveAuthenticate = (e) => {
        const { saveTradeAuthenticated, clearTradeAuthenticated, isSaveTradeAuthenticated } = this.props;
        if (isSaveTradeAuthenticated) {
            clearTradeAuthenticated();
        } else {
            saveTradeAuthenticated();
        }

    }

    render() {
        //init
        //genCode
        //auth
        const { labelClass, inputClass, containerClass, btnContainerClass, listAuthType, inputClassAuth,
            onFinish, noteMessageClass, noteMessageId, authtype, autoFocus, disableAuth,
            isSaveTradeAuthenticated, showReconfirmOrderConfig, saveTradeAuthenticated, showOrderSwitch, fillPassword, challengeQuestion, noButton } = this.getPropDefaultValue(this.props);
        const { isValidPin, isValidOtp, resendDisabled, pin, otp, authType, listCAInfor, currentCA, pinCA
            , isValidPinCA, showPin, smartotp, isValidSmartOtp, token, isValidToken, isValidMatrixCode } = this.state;
        const valid = (authType === TradeAuthType.PIN && isValidPin)
            || (authType === TradeAuthType.OTP && isValidOtp) || (authType === TradeAuthType.CTS && isValidPinCA) || (authType === TradeAuthType.SMARTOTP && isValidSmartOtp)
            || (authType === TradeAuthType.MATRIX && isValidMatrixCode) || (authType === TradeAuthType.TOKEN && isValidToken);
        const isBroker = this.isBroker();
        let focusInput = autoFocus && !disableAuth;

        // Lấy token serial với hình thức xác thực token
        const tokenSerial = authType === TradeAuthType.TOKEN ? this.getTokenSerial() : '';
        //console.log("resendDisabled---: ", resendDisabled)
        return (
            <div className="trade-auth2">
                <div id="transauth-container" className={JsxUtils.joinClass('trade-auth txt---400-14-20', containerClass)} style={{ display: disableAuth ? "none" : "flex" }}>
                    <div className={labelClass + " custom-hide"}>
                        <span className="label text-not-active">
                            <FormattedMessage id="trade.common.select-auth" />
                        </span>
                    </div>
                    <div className={inputClass + " custom-hide"}>
                        <select className="custom-form-control text-active" onChange={this.onAuthTypeChange} disabled={disableAuth || listAuthType.length === 1}>
                            {listAuthType.map((authTypeInfo, index) => {
                                return <Fragment key={index}>
                                    {authTypeInfo === TradeAuthType.PIN && <FormattedMessage id="trade.auth.pin" >{(txt) => (
                                        <option
                                            value={authTypeInfo}
                                        >{txt}</option>
                                    )}</FormattedMessage>}
                                    {authTypeInfo === TradeAuthType.OTP && <FormattedMessage id="trade.auth.otp" >{(txt) => (
                                        <option
                                            value={authTypeInfo}
                                        >{txt}</option>
                                    )}</FormattedMessage>}
                                    {authTypeInfo === TradeAuthType.CTS && <FormattedMessage id="trade.auth.cts" >{(txt) => (
                                        <option
                                            value={authTypeInfo}
                                        >{txt}</option>
                                    )}</FormattedMessage>}
                                    {authTypeInfo === TradeAuthType.SMARTOTP && <FormattedMessage id="trade.auth.smartotp" >{(txt) => (
                                        <option
                                            value={authTypeInfo}
                                        >{txt}</option>
                                    )}</FormattedMessage>}
                                    {authTypeInfo === TradeAuthType.TOKEN && (
                                        <option
                                            value={authTypeInfo}
                                        >Token</option>
                                    )}

                                    {authTypeInfo === TradeAuthType.MATRIX && (
                                        <option
                                            value={authTypeInfo}
                                        >Matrix</option>
                                    )}
                                </Fragment>
                            })}
                        </select>
                    </div>
                    {authType === TradeAuthType.PIN && (
                        <Fragment>
                            <div className={labelClass}>
                                <span className="label text-not-active">
                                    <FormattedMessage id="trade.common.pin" />
                                </span>
                            </div>
                            <div className={inputClassAuth + JsxUtils.addClassCondition(' pin-input-container editable enable-edit', 'editing', this.state.showPin)}>
                                <PinInput
                                    ref={this.inputToRefocusRef}
                                    id="pin-input"
                                    autoFocus={focusInput}
                                    disabled={disableAuth}
                                    // type={showPin ? "text" : "password"}
                                    // autoComplete={'new-password'}
                                    // autoComplete={'new-password'}
                                    autoCorrect={'off'}
                                    autoCapitalize={'none'}
                                    className="custom-form-control"
                                    maxLength={30}
                                    valueInput={!disableAuth ? pin : "•••••••"}
                                    onChange={this.onPINChange}
                                    toggleDisplayPin={this.toggleDisplayPin}
                                    showPin={showPin}
                                    onKeyDown={this.handlerKeyDown}
                                // placeholder="Nhập"
                                />
                            </div>
                        </Fragment>
                    )}

                    {authType === TradeAuthType.OTP && (
                        <Fragment>
                            {/* <div className="row gutters-5">
                                        <div className="col-12">
                                            <div className="pin-hint text-center text-green">
                                                <FormattedMessage id="trade.auth.otp-hint" />
                                            </div>
                                        </div>
                                    </div> */}

                            <div className={labelClass}>
                                <span className="label text-not-active">
                                    <FormattedMessage id="trade.auth.otp" />
                                </span>
                            </div>
                            <div className={inputClassAuth}>
                                <div className="row gutters-5 align-items-center">
                                    <div className="col-7">
                                        <div className="">
                                            <OtpInput
                                                ref={this.otpInputRef}
                                                autoFocus={focusInput}
                                                disabled={disableAuth}
                                                type="text"
                                                autoComplete={'off'}
                                                autoCorrect={'off'}
                                                autoCapitalize={'none'}
                                                className="custom-form-control"
                                                maxLength={10}
                                                valueInput={!disableAuth ? otp : "•••••••"}
                                                onChange={this.onOTPChange}
                                                onKeyDown={this.handlerKeyDown}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="re-send-otp">
                                            <div className=" btn-re-send-otp-new" onClick={() => { this.genAuthCode(true) }} disabled={disableAuth || resendDisabled}>
                                                {resendDisabled ? resendDisabled : (<FormattedMessage id="trade.auth.re-send" />)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}

                    {authType === TradeAuthType.SMARTOTP && (
                        <Fragment>
                            <div className={labelClass}>
                                <span className="label text-not-active">
                                    <FormattedMessage id="trade.common.smartotp" />
                                </span>
                            </div>
                            <div className={inputClassAuth + JsxUtils.addClassCondition(' pin-input-container editable enable-edit', 'editing', this.state.showPin)}>
                                <OtpInput
                                    ref={this.otpInputRef}
                                    autoFocus={focusInput}
                                    disabled={disableAuth}
                                    type="text"
                                    autoComplete={'off'}
                                    autoCorrect={'off'}
                                    autoCapitalize={'none'}
                                    className="custom-form-control"
                                    maxLength={10}
                                    valueInput={!disableAuth ? smartotp : "•••••••"}
                                    onChange={this.onSmartOTPChange}
                                    onKeyDown={this.handlerKeyDown}
                                />
                            </div>
                        </Fragment>
                    )}

                    {
                        authType === TradeAuthType.CTS && (
                            <Fragment>
                                <div className={labelClass}>
                                    <span className="label text-not-active">
                                        <FormattedMessage id="trade.common.cts" />
                                    </span>
                                </div>
                                <div className={inputClassAuth}>
                                    <Fragment>
                                        {
                                            listCAInfor && listCAInfor.length > 0 &&
                                            <select className="custom-form-control" onChange={this.onCAChange}>
                                                {
                                                    listCAInfor.map((caInfor, index) => {
                                                        return (
                                                            <option value={caInfor.Serial} key={index}>{caInfor.caName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        }
                                        {
                                            (!listCAInfor || listCAInfor.length === 0) &&
                                            <div className="ca-nocaloaded">
                                                <FormattedMessage id="trade.auth.nocaloaded" />
                                            </div>
                                        }
                                    </Fragment>
                                </div>
                                {
                                    currentCA && (
                                        <Fragment>
                                            <div className={labelClass}>
                                                <span className="label">
                                                    <FormattedMessage id="trade.auth.name" />
                                                </span>
                                            </div>
                                            <div className={inputClass}>
                                                {currentCA.caName}
                                            </div>
                                            <div className={labelClass}>
                                                <span className="label">
                                                    <FormattedMessage id="trade.auth.serial" />
                                                </span>
                                            </div>
                                            <div className={inputClass}>
                                                {currentCA.Serial}
                                            </div>
                                            <div className={labelClass}>
                                                <span className="label">
                                                    <FormattedMessage id="trade.auth.expire" />
                                                </span>
                                            </div>
                                            <div className={inputClass}>
                                                {currentCA.expire}
                                            </div>
                                        </Fragment>
                                    )
                                }
                                {
                                    listCAInfor && listCAInfor.length > 0 &&
                                    <Fragment>
                                        <div className={labelClass}>
                                            <span className="label">
                                                <FormattedMessage id="trade.common.password" />
                                            </span>
                                        </div>
                                        <div className={inputClass + JsxUtils.addClassCondition(' pin-input-container editable enable-edit', 'editing', this.state.showPin)}>
                                            <PinInput
                                                ref={this.inputToRefocusRef}
                                                disabled={disableAuth}
                                                // type={showPin ? "text" : "password"}
                                                // autoComplete={'new-password'}
                                                // autoComplete={'off'}
                                                autoCorrect={'off'}
                                                autoCapitalize={'none'}
                                                className="custom-form-control"
                                                maxLength={10}
                                                valueInput={!disableAuth ? pinCA : "•••••••"}
                                                onChange={this.onCAPINChange}
                                                toggleDisplayPin={this.toggleDisplayPin}
                                                showPin={showPin}
                                                onKeyDown={this.handlerKeyDown}
                                            />
                                        </div>
                                    </Fragment>
                                }
                            </Fragment>
                        )
                    }
                    {
                        (showOrderSwitch && false) &&
                        <Fragment Fragment >
                            <div className="line" />
                            <div className={this.props.labelClass}>
                                <FormattedMessage id="trade.common.auth-trade-orders" />
                            </div>
                            <div className={this.props.inputClass}>
                                <CustomInput
                                    type="switch"
                                    id="auth-trade-switch"
                                    onChange={saveTradeAuthenticated}
                                    checked={isSaveTradeAuthenticated}
                                    onKeyDown={this.handlerKeyDown}
                                />
                            </div>
                            {
                                config.switch.enableReconfirmOrderSwitch &&
                                <Fragment>
                                    <div className={this.props.labelClass}>
                                        <FormattedMessage id="settings.config.general-config.reconfirm-order-status" />
                                    </div>
                                    <div className={this.props.inputClass}>
                                        <CustomInput
                                            type="switch"
                                            id="advanced-order-input-switch"
                                            onChange={this.changeReconfirmOrder}
                                            checked={showReconfirmOrderConfig}
                                            onKeyDown={this.handlerKeyDown}
                                        />
                                    </div>
                                </Fragment>
                            }
                        </Fragment>
                    }


                    {authType === TradeAuthType.TOKEN && (
                        <Fragment>
                            <div className={labelClass}>
                                <span className="label">
                                    <FormattedMessage id="trade.auth.token" />
                                </span>
                            </div>
                            <div className={inputClassAuth + JsxUtils.addClassCondition(' pin-input-container editable enable-edit', 'editing', this.state.showPin)}>
                                <TokenInput
                                    ref={this.inputToRefocusRef}
                                    id="token-input"
                                    autoFocus={focusInput}
                                    disabled={disableAuth}
                                    autoCorrect={'off'}
                                    autoCapitalize={'none'}
                                    className="custom-form-control"
                                    maxLength={30}
                                    valueInput={!disableAuth ? token : "•••••••"}
                                    onChange={this.onTokenChange}
                                    toggleDisplayToken={this.toggleDisplayPin}
                                    showToken={showPin}
                                    onKeyDown={this.handlerKeyDown}
                                />
                            </div>
                            <div className='col-12 row serial-container'>
                                <div className='col-4 label'><FormattedMessage id="trade.auth.serial" /></div>
                                <div className='col-8'>{tokenSerial}</div>
                            </div>
                        </Fragment>
                    )}


                    {authType === TradeAuthType.MATRIX && (
                        <Fragment>
                            <div className={labelClass}>
                                <span className="label">
                                    <FormattedMessage id="trade.auth.pass-code" />
                                </span>
                            </div>
                            <div className={JsxUtils.addClassCondition(' col-7 pin-input-container editable enable-edit', 'editing', this.state.showPin)}>
                                <MatrixInput
                                    ref={this.inputToRefocusRef}
                                    autoFocus={focusInput}
                                    disabled={disableAuth}
                                    autoCorrect={'off'}
                                    autoCapitalize={'none'}
                                    className="custom-form-control"
                                    maxLength={1}
                                    onChange={this.onMatrixCodeChange}
                                    handlerKeyDown={this.handlerKeyDown}
                                    challengeQuestion={challengeQuestion}
                                />
                            </div>
                        </Fragment>
                    )}


                    {
                        showOrderSwitch &&
                        <Fragment>
                            <div className={labelClass}>
                                <span className="label txt---400-14-20">
                                    <FormattedMessage id="trade.common.auth-trade-orders" />
                                </span>
                            </div>
                            <div className={this.props.inputClass + ' save-auth'}>
                                <CustomInput
                                    type="switch"
                                    id="auth-trade-switch"
                                    onChange={this.setSaveAuthenticate}
                                    checked={isSaveTradeAuthenticated}
                                    onKeyDown={this.handlerKeyDown}
                                    tabIndex={-1}
                                />
                            </div>
                        </Fragment>
                    }

                </div>

                {noteMessageId &&
                    <div className="block-note-msg">
                        {noteMessageId.map((messageId, index) => {
                            return <div className={noteMessageClass} key="index">
                                <FormattedMessage id={messageId} />
                            </div>
                        })}
                    </div>
                }


                <div className="container-confirm-auth text-green item-center txt---400-14-20" style={{ display: !disableAuth ? "none" : "flex" }}>
                    <div className="icon-confirm item-center">
                        <i className="fa fa-check"></i>
                    </div>
                    <div className="title">
                        <FormattedMessage id="trade.auth.authed" />
                    </div>
                </div>

                {
                    this.props.tradeOrderInputMsg && (
                        <div className="blinking">
                            {this.props.tradeOrderInputMsg}
                        </div>
                    )
                }

                {
                    this.props.currentReconfirmOrderType === reconfirmTypes.GROUP_ORDER && (
                        <div className="blinking">
                            <FormattedMessage id="trade.auth.grouporder-warning" />
                        </div>
                    )
                }
                {
                    !noButton &&
                    <div className={JsxUtils.joinClass('trade-auth-btn-container txt---500-14-20', btnContainerClass)}>
                        <button className="btn-action btn-refresh" onClick={onFinish} tabIndex={-1}>
                            <FormattedMessage id="trade.auth.action-close" />
                        </button>
                        <button
                            ref={this.confirmBtnRef}
                            className={"btn-action bg-primary text-custom " + this.props.btnConfirmClass}
                            onClick={this.processAuth}
                            disabled={this.props.isDisableConfirmBtn ? true : (disableAuth ? false : !valid)}
                            onKeyDown={this.handlerKeyDownConfirm}
                        >
                            <FormattedMessage id="trade.auth.action-auth" />
                        </button>
                    </div>
                }
            </div >
        )
    }
}

const mapStateToProps = state => {
    let _infoAuth = state.checkAuth.infoAuth["C&B"]
    let _authKey = _infoAuth.authKey
    let _transactionId = _infoAuth[_authKey].transactionId
    let _tokenid = _infoAuth[_authKey].tokenid
    let _listAuthType = _infoAuth[_authKey].listAuthType
    let _authtype = _infoAuth[_authKey].authtype
    let _verified = _infoAuth[_authKey].verified
    //Matrix
    let _challengeQuestion = _infoAuth[_authKey].challengeQuestion
    let clientConfig = state.app.config && state.app.config.configData ? state.app.config : CommonObject.emptyObject;
    return {
        verified: _verified,
        username: state.user.userInfo && state.user.userInfo.username,
        role: state.user.userInfo.role,
        transactionId: _transactionId,
        tokenid: _tokenid,
        listAuthType: _listAuthType,
        defaultAuthtype: _authtype,
        authtype: _authtype,
        disableAuth: _verified,
        challengeQuestion: _challengeQuestion,
        tradeAuthenticated: state.app.tradeAuthenticated,
        showReconfirmOrderConfig: state.app.showReconfirmOrderConfig,
        isSaveTradeAuthenticated: state.layout.listIsOpenModal["C&B"].isSaveTradeAuthenticated,
        clientConfig: clientConfig
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShowReconfirmOrder: (status) => dispatch(actions.setShowReconfirmOrder(status)),
        changeReconfirmOrder: (status) => dispatch(actions.changeReconfirmOrder(status)),
        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        setTradeAuthSuccess: () => dispatch(actions.setTradeAuthSuccess()),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        clearTradeAuthenticated: () => dispatch(actions.clearTradeAuthenticated()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TradeAuth2);
