import React, { Component, Fragment } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import { ReactComponent as ErrorIcon } from '../assets/icons/toast/ErrorIcon.svg'
import { ReactComponent as SuccessIcon } from '../assets/icons/toast/SuccessIcon.svg'
import { ReactComponent as WarningIcon } from '../assets/icons/toast/WarningIcon.svg'
import CustomScrollBar from '../components/CustomScrollbars';
import { emitter } from 'utils/EventEmitter';
import './CustomToast.scss';
const globalVar = window._env_
const isShowTrackLogId = globalVar.app.IS_SHOW_TRACK_LOG_ID
class CustomToast extends Component {

    initstate = { isReRender: false };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initstate
        }
        this.listenToTheEmitter();
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    callback = data => {
        this._setState({ isReRender: !this.state.isReRender })
    }

    listenToTheEmitter = () => {
        emitter.on('REMOVE_LocalConfigWeb', this.callback);
    }

    componentDidMount() {
        this.mounted = true
        window.addEventListener("storage", this.onLocalStorageChange);
    }

    onLocalStorageChange = (e) => {
        const { key, newValue, oldValue } = e;
        if (key === "LocalConfigWeb") {
            let _newValue, _oldValue;
            try {
                _newValue = JSON.parse(newValue)
                _oldValue = JSON.parse(oldValue)
            } catch (error) {
                localStorage.removeItem("LocalConfigWeb");
            }
            if (_newValue && _oldValue) {
                if (_newValue.isOpenTrackLogId !== _oldValue.isOpenTrackLogId) {
                    this._setState({ isReRender: this.state.isReRender })
                }
            }
        }

    };

    componentWillUnmount() {
        emitter.removeListener('REMOVE_LocalConfigWeb', this.callback);
        window.removeEventListener("storage", this.onLocalStorageChange);
        this.mounted = false
    }

    render() {
        const { titleId, message, messageId, time, type, trackLogId } = this.props;

        let isOpenTrackLogId = false
        if (trackLogId) {
            let LocalConfigWeb = JSON.parse(localStorage.getItem('LocalConfigWeb'))
            if (LocalConfigWeb && LocalConfigWeb.isOpenTrackLogId) {
                isOpenTrackLogId = LocalConfigWeb.isOpenTrackLogId
            }
        }
        return (
            <Fragment>
                <div className="custom-toast">
                    <div>
                        {
                            type === "SUCCESS" && <SuccessIcon />

                        }
                        {
                            type === "ERROR" && <ErrorIcon />

                        }
                        {
                            type === "WARN" && <WarningIcon />

                        }
                        {
                            type === "INFO" && <WarningIcon />

                        }
                    </div>
                    <div className='container-toast'>
                        <div className='headB---700-16-24'>
                            <FormattedMessage id={titleId} />
                            {/*type === "ERROR" && isOpenTrackLogId && trackLogId &&
                                <div span style={{ paddingLeft: "5px" }}>
                                    <span>{trackLogId}</span>
                                    <span style={{ paddingLeft: "5px", cursor: "pointer" }} className="icon-copy" onClick={() => { CommonUtils.onCopyText(trackLogId) }}>
                                        <i class="fa fa-clone" aria-hidden="true"></i>
                                        COPY
                                    </span>
                                </div>
                        */}
                        </div>
                        <div className='txt---500-14-20 toast-title'>
                            {
                                (message && typeof message === 'object') ?
                                    <CustomScrollBar autoHeight={true} autoHeightMin={50} autoHeightMax={100}>
                                        {
                                            message.map((msg, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <div className="toast-content">{msg}</div>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </CustomScrollBar> :
                                    <div className="toast-content">
                                        {message ? message : (messageId ? (<FormattedMessage id={messageId} />) : null)}
                                    </div>
                            }
                        </div>
                        {time && (
                            <div className="date">
                                <FormattedTime hour='numeric' minute='numeric' value={time} />
                                <div style={{ marginLeft: "6px", marginRight: "2px" }}>- </div>
                                <FormattedDate value={time} />
                                <div style={{ marginLeft: "2px", marginRight: "2px" }}>|</div>
                                {type === "ERROR" && isOpenTrackLogId && trackLogId && trackLogId.substring(0, 10)}
                            </div>
                        )}
                    </div>
                </div>
            </Fragment >
        );
    }
}

export class CustomToastCloseButton extends Component {

    render() {
        return (
            <button type="button" className="toast-close" onClick={this.props.closeToast}>
                <i className="fa fa-fw fa-times-circle" />
            </button>
        );
    }
}

export default CustomToast;