import React, { Component } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { connect } from "react-redux";
import $ from 'jquery';
import './CustomScrollbars.scss';
// import { JsxUtils } from 'utils';
let scrollLeftCur = undefined
// isHiddenY chặn scroll Y bị ẩn giao diện
let isHiddenY = false // 


class CustomScrollbars extends Component {

    ref = React.createRef();

    getScrollTop = () => {
        const scrollbars = this.ref.current;
        return scrollbars.scrollValues.scrollTop;
    }

    getScrollHeight = () => {
        const scrollbars = this.ref.current;
        return scrollbars.scrollValues.scrollHeight;
    }

    getClientHeight = () => {
        const scrollbars = this.ref.current;
        return scrollbars.scrollValues.clientHeight;
    }

    // scrollToBottom = () => {
    //     if (!this.ref || !this.ref.current) {
    //         return;
    //     }
    //     this.scrollToView(this.getScrollTop());
    // };

    scrollToBottom = () => {
        if (!this.ref || !this.ref.current) {
            return;
        }
        this.ref.current.scrollTo(0, this.getScrollHeight())

    };

    scrollFirstTop = (targetTop) => {
        if (!this.ref || !this.ref.current) {
            return;
        }
        this.ref.current.scrollTo(0, targetTop)
    };


    scrollToView = () => {
        const { quickScroll } = this.props;
        if (!this.ref || !this.ref.current) {
            return;
        }
        let targetTop = this.getScrollTop() + 2
        //console.log("scrollToView=", this.ref.current, targetTop, this.getScrollHeight() - this.getClientHeight(), this.getScrollHeight(), this.getClientHeight())
        if (targetTop > this.getScrollHeight() - this.getClientHeight()) {
            // Lap lai scroll tu dau
            targetTop = 0
        }
        this.ref.current.scrollTo(0, targetTop)
        if (quickScroll && quickScroll === true) {
            this.scrollToView();
        } else {
            let timer = setTimeout(() => {
                this.scrollToView();
                clearTimeout(timer)
            }, 150);
            if (!this.props.isAutoScroll) { //scroll top và dừng scroll
                clearTimeout(timer);
                return;
            }
        }
        return;
    };

    handleUpdate = (scrollState, prevScrollValues, props) => {

        // return null
        const { addClassAsyncScroll } = props
        //console.log("handleUpdate====0", scrollState, prevScrollValues, addClassAsyncScroll)
        if (addClassAsyncScroll) {
            if (scrollState.scrollLeft != prevScrollValues.scrollLeft || prevScrollValues.scrollLeft == 0) {
                let classScroll = $(`.${addClassAsyncScroll}-y`)
                if (classScroll) {
                    let widthClassParent = $(`.${addClassAsyncScroll}`).width() - 10 + scrollState.scrollLeft
                    $(`.${addClassAsyncScroll}-y .ScrollbarsCustom-TrackY`).css("left", `${widthClassParent}px`)
                }
            }
        }
    }

    handleStart = (scrollState, props) => {
        const { addClassAsyncScroll } = props
        //console.log("handleStart====1", scrollState, scrollLeftCur, addClassAsyncScroll)
        if (addClassAsyncScroll.indexOf("-y") > 0) {
            // "pb-custom-y"
            // isHiddenY chặn scroll Y bị ẩn giao diện
            isHiddenY = false
        }
        else {
            if (addClassAsyncScroll && isHiddenY) {
                //console.log("handleStart====hidden", scrollState, scrollLeftCur, addClassAsyncScroll)
                $(`.${addClassAsyncScroll}-y .ScrollbarsCustom-TrackY`).css("visibility", `hidden`)
            } else {
                isHiddenY = true
            }
        }
    }

    handleStop = (scrollState, props) => {
        const { addClassAsyncScroll } = props
        if (addClassAsyncScroll) {
            //console.log("handleStop====inherit", scrollLeftCur, scrollState.scrollLeft)
            $(`.${addClassAsyncScroll}-y .ScrollbarsCustom-TrackY`).css("visibility", `inherit`)
        }
    }

    componentWillUnmount(){
        this.ref = undefined;
    }

    render() {
        const { ref, autoHeightMin, autoHeightMax, disableScroll, from, className, disableVerticalScroll, disableHorizontalScroll, children, isAsyncScroll, addClassAsyncScroll, noScrollX, noScrollY, ...otherProps } = this.props;
        let classNameRender = "ScrollbarsCustom"
        if (className) classNameRender = classNameRender + ` ${className}`
        if (addClassAsyncScroll) classNameRender = classNameRender + ` ${addClassAsyncScroll}`
        let styles = {
            minHeight: autoHeightMin,
            maxHeight: autoHeightMax
        }
        return (
            disableScroll ? <>{children}</> :
                <Scrollbar
                    ref={this.ref}
                    onScrollStart={isAsyncScroll ? (e) => this.handleStart(e, { ...this.props }) : undefined}
                    onScrollStop={isAsyncScroll ? (e) => this.handleStop(e, { ...this.props }) : undefined}
                    onUpdate={isAsyncScroll ? (scrollState, prevScrollValues) => this.handleUpdate(scrollState, prevScrollValues, { ...this.props }) : undefined}
                    noScrollX={noScrollX}
                    trackXVisible={noScrollX}
                    scrollXBlocked={noScrollX}
                    style={styles}
                    // 
                    noScrollY={noScrollY}
                    trackYVisible={noScrollY}
                    removeTrackXWhenNotUsed={noScrollY}
                    // isRTL={true}
                    renderer={(props) => {
                        const { elementRef, ...restProps } = props;
                        return <div {...restProps} ref={elementRef} className={classNameRender} />;
                    }}
                    scrollerProps={{
                        // Đồng bộ virtual scroll
                        renderer: props => {
                            const {
                                elementRef,
                                onScroll: scrollbarsScrollHandler, // Passed by scrollbars
                                ...restProps
                            } = props;
                            return (
                                <span
                                    {...restProps}
                                    onScroll={e => {
                                        otherProps.onScroll && otherProps.onScroll(e);
                                        scrollbarsScrollHandler(e);
                                    }}
                                    ref={refScroll => {
                                        ref && ref(refScroll);
                                        elementRef(refScroll);
                                    }}
                                />
                            );
                        }
                    }}
                // scrollDetectionThreshold={100}
                >
                    {children}
                </Scrollbar>
        );
    }
}
const mapStateToProps = (state, props) => {
    return {
        isAutoScroll: state.app.isAutoScroll
    };
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(CustomScrollbars);
// export default CustomScrollbars;