import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";

import { getValueFromLocalStorage, path } from "../../utils";
import CommonUtils from '../../utils/CommonUtils';

const globalVar = window._env_

const INTERVAL = 2000
const keyName = "persist:user";
const INTERVAL_CHECK_SESSION = 5 * 60 * 1000;

function getReduxStoreString() {
    // let reduxStoreString = Cookies.get(keyName);
    let reduxStoreString = getValueFromLocalStorage(keyName);
    let user = JSON.parse(reduxStoreString)
    let token = user && user.token
    return token || undefined
}
class CookieManager extends Component {


    state = {
        token: undefined
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        // let reduxStore = Cookies.get(keyName);
        let reduxStore = getValueFromLocalStorage(keyName);
        if (reduxStore) {
            var intervalId = setInterval(this.intervalHandler.bind(this), INTERVAL);
            // Haki.: Tạm disable tính năng timer checkToken
            // var intervalcheckTokenId = null
            var intervalcheckTokenId = this.props.isLoggedIn && setInterval(this.intervalcheckTokenHandler.bind(this), INTERVAL_CHECK_SESSION);
            // store intervalId in the state so it can be accessed later:
            this._setState({ intervalId: intervalId, intervalcheckTokenId: intervalcheckTokenId });
        }
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        this.state.intervalId && clearInterval(this.state.intervalId);
        this.state.intervalcheckTokenId && clearInterval(this.state.intervalcheckTokenId);
        this.mounted = false
    }

    logoutHandler = () => {
        // Haki.: Cần xem lại chỗ lưu localStorage và cookies
        localStorage.setItem('persist:user', '');
        CommonUtils.setCookie("authInfo", null, '/', true, '');
        CommonUtils.setIsStep2Authenticated(null, '/', true, '');
        window.location.reload(path.LOG_OUT)
    };


    intervalHandler() {
        // setState method is used to update the state
        let token = getReduxStoreString();
        if (globalVar.app.IS_LOGIN_POPUP == true || globalVar.app.IS_LOGIN_POPUP == 'local') {
            let crrToken = this.state.token
            if (!crrToken) {
                this._setState({ token: token })
                return;
            }
            if (token && crrToken && token != crrToken) {
                this._setState({ token: token })
                this.state.intervalId && clearInterval(this.state.intervalId);
                // window.location.reload(true);
            }
        }
        else {
            // let reduxStoreString = Cookies.get(keyName);
            let reduxStoreString = getValueFromLocalStorage(keyName);

            if (reduxStoreString) {
                let user = JSON.parse(reduxStoreString)
                let token = user && user.token
                if (token && token != 'null') {
                    return;
                }
            }
            this.state.intervalId && clearInterval(this.state.intervalId);
            window.location.reload();
        }
    }

    intervalcheckTokenHandler() {
        // try {
        //     userService.checkToken().then(data => {
        //         // if (data.httpStatusCode == null) {
        //         //     this.state.intervalcheckTokenId && clearInterval(this.state.intervalcheckTokenId);
        //         //     this.logoutHandler();
        //         // }
        //     }).catch((e) => {
        //         // //console.log('intervalcheckTokenHandler.:e1=', e)
        //         // this.state.intervalcheckTokenId && clearInterval(this.state.intervalcheckTokenId);
        //         // this.logoutHandler();
        //     })
        // } catch (e) {
        //     // //console.log('intervalcheckTokenHandler.:e2=', e)
        //     // this.state.intervalcheckTokenId && clearInterval(this.state.intervalcheckTokenId);
        //     // this.logoutHandler();
        // }
    }
    render() {
        return (<Fragment />);
    }

}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
    };
};

export default connect(mapStateToProps)(CookieManager);