import React, { Component } from 'react';
import { FormattedDate, FormattedPrice, FormattedQuantity } from 'components/Formating';

import { TYPE_ACCOUNT } from '../../utils';

import './CustomRowItem.scss';
import { FormattedMessage } from 'react-intl';
class CustomRowItem extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    renderValue = () => {
        const { showLabel, showValue, isQtty, isPrice, typeAccount, isDate, symbolCurrent,
            nvalue, isValueText, isToFixed, isNotThousand, isTotal,
        } = this.props;
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            if (isValueText) {
                return <FormattedMessage id={showValue} />
            } else {
                if (isQtty) {
                    return (
                        <FormattedQuantity value={showValue} />
                    )
                } else if (isPrice) {
                    if (isToFixed) {
                        return (
                            <FormattedPrice value={Number(nvalue ? showValue * nvalue : showValue)} shortForm={true} isNotThousand={true} />
                        )
                    }
                    return (
                        <FormattedPrice value={Number(nvalue ? showValue * nvalue : showValue)} isNotThousand={true} />
                    )
                } else if (isTotal) {
                    return (
                        <FormattedPrice value={Number(nvalue ? showValue * nvalue : showValue)} shortForm={false} isNotThousand={true} />
                    )
                } else {
                    return (
                        <span>
                            {showValue}
                        </span>
                    )
                }
            }

        } else {
            if (isValueText) {
                return <FormattedMessage id={showValue} />
            } else {
                if (isQtty) {
                    return (
                        <FormattedQuantity value={showValue} />
                    )
                } else if (isPrice) {
                    if (isToFixed) {
                        return (
                            <FormattedPrice value={showValue} shortForm={true} isNotThousand={isNotThousand} />
                        )
                    }
                    return (
                        <FormattedPrice value={showValue} />
                    )
                } else if (isDate) {
                    return (
                        <FormattedDate value={showValue} />
                    )
                } else if (isTotal) {
                    return (
                        <FormattedPrice value={showValue} shortForm={false} />
                    )
                } else {
                    return (
                        <span>
                            {showValue}
                        </span>
                    )
                }
            }
        }
    }

    render() {
        const { showLabel, showValue, isQtty, col, isPrice, classNameValue, classNameLabel, isCustomLabel, isCustomValue,
            hiddenLine,
            classCustomLabel, classCustomValue, } = this.props;
        let _classNameLabel = ("label txt---400-14-20 " + classNameLabel)
        let _classNameValue = ("value txt---400-14-20 " + classNameValue)
        let _classCustomLabel = isCustomLabel === true ? classCustomLabel : "col-6 p-0"
        let _classCustomValue = isCustomValue === true ? classCustomValue : "col-6 p-0"
        return (
            <div className={hiddenLine === true ? "custom-row-group hidden-line" : "custom-row-group"} >
                <div className="custom-row-form row m-0">
                    <div className={_classCustomLabel}>
                        <div className={_classNameLabel}>
                            <FormattedMessage id={showLabel} />
                        </div>
                    </div>
                    <div className={_classCustomValue}>
                        <div className={_classNameValue}>
                            {this.renderValue()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default CustomRowItem;