
import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { batch, useDispatch, useSelector } from 'react-redux';
import { connect } from "react-redux";
import { emitter } from 'utils/EventEmitter';

import { ReactComponent as CloseIconDark } from '../../assets/icons/header/CloseIcon_Dark.svg';
import { ReactComponent as CloseIconLight } from '../../assets/icons/header/CloseIcon_Light.svg';

import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as IconSaveSecondaryDark } from '../../assets/icons_new/icon_save_secondary_dark.svg'
import * as LayoutUtils from '../../containers/LayoutPage/LayoutUtils';
import WidgetIcons from '../../containers/LayoutPage/Widget/WidgetIcons';
import * as actions from "../../store/actions";
import { CommonWidgetUtils, LanguageUtils, ToastUtil } from "../../utils";

import './LayoutPageSetting.scss';
import ListDataMapKeyByLanguage from './../../utils/ListDataMapKeyByLanguage';
import $ from "jquery"
import CustomScrollbars from 'components/CustomScrollbars';
// Define a default UI for filtering
const isDragDropSetting = false

function LayoutPageSetting(props) {
    const { item, onChangeMenuActiveSideBar, defaultTheme } = props
    const { user } = useSelector((state) => state);
    const { isLoggedIn } = user
    const dispatch = useDispatch();
    const [isShowRename, setShowRename] = useStateIfMounted(false)
    React.useEffect(() => {
        if (isShowRename) {
            document.getElementById("input-edit-name") && document.getElementById("input-edit-name").focus()
        } else {
        }
    }, [isShowRename]);
    // const layoutPage = useSelector(state => state.layout.layoutPage)
    const currentLayoutPageActive = useSelector(state => CommonWidgetUtils.getReplaceLayoutPageActive())
    const layoutsCustom = useSelector(state => state.layout.layoutsCustom)
    let layoutCustom = undefined
    let _layoutName = undefined
    let index = layoutsCustom.findIndex((e) => e.id == currentLayoutPageActive)
    if (index > -1) {
        layoutCustom = _.cloneDeep(layoutsCustom[index])
        _layoutName = layoutCustom.layoutName
    }
    const [layoutName, setLayoutName] = useStateIfMounted(_layoutName)
    const OBJLAYOUT = currentLayoutPageActive
    // const [style, setStyle] = useStateIfMounted({ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", textAlign: "center", width: "150px", height: "100%", background: "#645848", paddingTop: "5px", borderRadius: "5px", color: "white", marginRight: "5px", })
    const keyOBJLAYOUT = LayoutUtils.getKeyLayoutsCustomSave(OBJLAYOUT)
    let sections = LayoutUtils.filterSections(keyOBJLAYOUT);
    if (sections && sections.length > 0) { // Sort danh sách hiện thị theo ABC (chỉ sort cho ngôn ngữ tiếng việt)
        sections = sections.map((item, index) => {
            return {
                ...item,
                titleName: LanguageUtils.getMessageByKey(ListDataMapKeyByLanguage.listDataTitleWidget(item.id), "vi")
            }
        }).sort((a, b) => {
            return a["titleName"].localeCompare(b["titleName"]);
        })
    }

    // //console.log("onSetSectionVisible", sections)
    const isDelete = (OBJLAYOUT.indexOf('$TC') > -1) && (OBJLAYOUT.indexOf('$TC_TEMP') < 0)
    const isRename = (OBJLAYOUT.indexOf('$TC') > -1) && (OBJLAYOUT.indexOf('$TC_TEMP') < 0)
    const isRefresh = (OBJLAYOUT.indexOf('$TC') > -1) && (OBJLAYOUT.indexOf('$TC_TEMP') < 0)
    const isLayoutDefault = (OBJLAYOUT.indexOf('$TC_TEMP') > -1) || (OBJLAYOUT.indexOf('$TC') < 0)
    const onSetSectionVisible = (section, checked, isPermission) => {
        if (isPermission) return

        let obj = {
            OBJLAYOUT,
            section,
            checked,
        }
        emitter.emit('onSetSectionVisible', obj)
        // let _OBJLAYOUT = OBJLAYOUT.split('$TC_TEMP')
        // _OBJLAYOUT = _OBJLAYOUT[0]
        // alert('onSetSectionVisible_' + _OBJLAYOUT)
        // emitter.emit('onSetSectionVisible_' + _OBJLAYOUT, obj)
    }
    const deleteLayoutCustom = () => {
        if (isLayoutDefault) {

            return;
        }
        batch(() => {
            dispatch(actions.deleteLayoutCustom(OBJLAYOUT, 'LayoutPage.:deleteLayoutCustom'))
            dispatch(actions.deleteLayoutPage(item))
        });
    }

    const updateLayoutCustom = () => {
        if (isLayoutDefault) {
            let _OBJLAYOUT_TEMP = OBJLAYOUT
            if (OBJLAYOUT.indexOf('$TC_TEMP') < 0) {
                _OBJLAYOUT_TEMP = OBJLAYOUT + '$TC_TEMP'
            }
            const layoutCustomDefault = {
                id: _OBJLAYOUT_TEMP,
                grid: { "lg": [], "md": [], "sm": [] },
                visibleSectionIds: [],
                mergeSections: {},
                floatLayout: [],
                sourceLayoutId: _OBJLAYOUT_TEMP
            }
            batch(() => {
                dispatch(actions.updateLayoutCustom(layoutCustomDefault, 'LayoutPage.:updateLayoutCustom'))
            });
            return;
        }
        else {
            const layoutCustomDefault = {
                id: OBJLAYOUT,
                grid: { "lg": [], "md": [], "sm": [] },
                visibleSectionIds: [],
                mergeSections: {},
                floatLayout: [],
                sourceLayoutId: OBJLAYOUT
            }
            batch(() => {
                dispatch(actions.updateLayoutCustom(layoutCustomDefault, 'LayoutPage.:updateLayoutCustom'))
            });
        }
    }

    const resetLayoutCustom_bk = (e) => {
        //console.log('isLayoutDefault', isLayoutDefault)
        if (isLayoutDefault) {
            let _OBJLAYOUT_TEMP = OBJLAYOUT
            if (OBJLAYOUT.indexOf('$TC_TEMP') < 0) {
                _OBJLAYOUT_TEMP = OBJLAYOUT + '$TC_TEMP'
            }
            let _OBJLAYOUT = OBJLAYOUT.replace('$TC_TEMP', '')
            const layoutCustomDefault = {
                id: _OBJLAYOUT_TEMP,
                grid: { "lg": [], "md": [], "sm": [] },
                visibleSectionIds: [],
                mergeSections: {},
                floatLayout: [],
                sourceLayoutId: _OBJLAYOUT_TEMP
            }
            let role = role
            let layoutReset = LayoutUtils.getDesktopLayouts(_OBJLAYOUT)
            layoutReset = { ...layoutCustomDefault, ...layoutReset[0] }
            layoutReset.id = _OBJLAYOUT_TEMP
            layoutReset.sourceLayoutId = _OBJLAYOUT_TEMP
            //console.log('layoutReset1', layoutReset)
            batch(() => {
                dispatch(actions.updateLayoutCustom(layoutReset, 'LayoutPage.:resetLayoutCustom'))
            });
            onChangeMenuActiveSideBar(e)
            return;
        }
        else {
            const layoutCustomDefault = {
                id: OBJLAYOUT,
                grid: { "lg": [], "md": [], "sm": [] },
                visibleSectionIds: [],
                mergeSections: {},
                floatLayout: [],
                sourceLayoutId: OBJLAYOUT
            }
            let role = role
            let _OBJLAYOUT = LayoutUtils.getDesktopLayoutsByRole('ROLE#CS#TRADE#NC') // Mặc định theo role
            let layoutReset = LayoutUtils.getDesktopLayouts(_OBJLAYOUT)
            layoutReset = { ...layoutCustomDefault, ...layoutReset[0] }
            layoutReset.id = OBJLAYOUT
            layoutReset.sourceLayoutId = OBJLAYOUT
            //console.log('layoutReset2', layoutReset)
            batch(() => {
                dispatch(actions.updateLayoutCustom(layoutReset, 'LayoutPage.:resetLayoutCustom'))
            });
        }
    }



    const resetLayoutCustom = (e) => {
        //console.log('resetLayoutCustom', OBJLAYOUT)
        if (OBJLAYOUT) {
            let _OBJLAYOUT = OBJLAYOUT.replace('$TC_TEMP', '')
            batch(() => {
                dispatch(actions.deleteLayoutCustom(_OBJLAYOUT, 'LayoutPage.:_resetLayout'))
                dispatch(actions.changeLayoutPageActive(_OBJLAYOUT));
                CommonWidgetUtils.toggleEnableEditLayout(false, "from_resetLayoutCustom")
                // dispatch(push(layoutPage[keyLayout].activeURL));
                // dispatch(actions.deleteLayoutPage(OBJLAYOUT))
            });
        }
        // //console.log('resetLayoutCustom1', isLayoutDefault, OBJLAYOUT, layoutsCustom)
        // let layoutReset = layoutsCustom && layoutsCustom.length > 0 && layoutsCustom.filter((item, index) => {
        //     return item.id !== "C#CS#TRADE#NC"
        // })
        // //console.log('resetLayoutCustom2', layoutReset)
        // if (isLayoutDefault) {
        //     batch(() => {
        //         dispatch(actions.updateLayoutCustom(layoutReset, 'LayoutPage.:resetLayoutCustom_BTN_SAVE_TO_SERVER'))
        //     });
        //     onChangeMenuActiveSideBar(e)
        //     return;
        // }
    }

    const cancelLayoutCustom = () => {
        setLayoutName(_layoutName)
        setShowRename(false)
    }

    const editLayoutCustom = () => {
        setShowRename(true)
    }

    const renameLayoutCustom = () => {
        if (isShowRename) {
            //nếu isShowRename đang là true thì thực hiện save
            if (layoutName && layoutName != '') {
                // lưu tên mới
                setNameLayoutCustom()
                setShowRename(false)
            }
        }
    }
    // const renameLayoutCustom = () => {
    //     if (isShowRename) {
    //         //nếu isShowRename đang là true thì thực hiện save
    //         if (layoutName && layoutName != '') {
    //             // lưu tên mới
    //             setNameLayoutCustom()
    //         }
    //         else {
    //             // reset về tên cũ
    //             setLayoutName(_layoutName)
    //             setNameLayoutCustom()
    //         }
    //     }
    //     setShowRename(!isShowRename)
    // }
    const setNameLayoutCustom = () => {
        //console.log('setNameLayoutCustom.: ========== SAVE')
        // renameLayoutCustom()
        if (layoutCustom) {
            layoutCustom.layoutName = layoutName
            batch(() => {
                dispatch(actions.updateLayoutCustom(layoutCustom, 'LayoutPage.:resetLayoutCustom'))
            });
        } else {
            ToastUtil.error('Không tìm thấy layout tùy chỉnh!')
        }
    }

    const onKeyDownInputRename = (e) => {
        if (e && e.keyCode == 13) {
            setNameLayoutCustom()
            setShowRename(false)
        }
    }

    const onChangeInputRename = (e) => {
        let name = e && e.target.value
        setLayoutName(name)
    }

    const onDragStart = (e, id) => {
        $(e.target).addClass("dragging");
        e.dataTransfer.setData("text/plain", id);

        emitter.emit('onDragStartWidgetSetting', id)
    }
    const onDragEnd = (e, id) => {
        // e.preventDefault();
        $(e.target).removeClass("dragging");
        // e.dataTransfer.setData("text/plain", id);
        let timer = setTimeout(function () {
            emitter.emit('onDragStartWidgetSetting', undefined)
            clearTimeout(timer) 
        }, 0)
    }

    let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent();
    let layoutCurrent = getLayoutPageCurrent.layoutCurrent;
    const visibleSectionIds = layoutCurrent && layoutCurrent.visibleSectionIds;
    //console.log('Haki.:visibleSectionIds', sections, visibleSectionIds, OBJLAYOUT, currentLayoutPageActive)
    const getWidgetIcon = (widget) => {
        switch (widget.id) {
            case 'advanceConditionOrder':
                return WidgetIcons.IconPlaceOrder;
            case 'placeOrder':
                return WidgetIcons.IconPlaceOrder;
            case 'placeGroupOrder':
                return WidgetIcons.IconPlaceOrder;
            case 'symbolInfo':
                return WidgetIcons.IconSymbolInfo;
            case 'orderbook':
                return WidgetIcons.IconOrderbook;
            case 'priceChart':
                return WidgetIcons.IconPriceChart;
            // case 'depthChart':
            //     return WidgetIcons.IconDepthChart;
            case 'symbolSelector':
                return WidgetIcons.IconSymbolSelector;
            case 'recentTrades':
                return WidgetIcons.IconRecentTrades;
            case 'activeOrders':
                return WidgetIcons.IconActiveOrders;
            case 'ordersList':
                return WidgetIcons.IconOrdersList;
            case 'draftOrders':
                return WidgetIcons.IconDraftOrders;
            case 'openPositions':
                return WidgetIcons.IconOpenPositions;
            case 'indexsList':
                return WidgetIcons.IconIndexsList;
            case 'closePositions':
                return WidgetIcons.IconClosePositions;
            // case 'matchedOrder':
            //     return matchedOrder;
            case 'conditionOrder':
                return WidgetIcons.IconOrdersList;
            case 'confirmOrder':
                return WidgetIcons.IconConfirmOrder;
            default: {
                console.error('No icon defined for widget ' + widget.id);
                return WidgetIcons.IconConfirmOrder;
            }
        }
    };

    const genderIcon = (keyIcon) => {
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < CloseIconDark /> : < CloseIconLight />
                break;
            default:
                break;
        }
    }

    return (
        <div style={isRename ? { height: 400 } : { height: 350 }} className="popupSettingLayoutCustom txt---400-14-20" >
            {isRename &&
                <div className='header-popup-setting d-flex flex-row justify-content-between'>
                    {
                        isShowRename ?
                            <input type='text' id='input-edit-name' className='input-edit-name text-active' value={layoutName}
                                onChange={(e) => onChangeInputRename(e)}
                                onKeyDown={(e) => onKeyDownInputRename(e)}
                            >
                            </input>
                            :
                            <span className='span-name text-active txt---700-14-20 item-center'>{layoutName}</span>
                    }
                    <div className="container-btn">
                        {isShowRename
                            ?
                            <>
                                <div className="icon ml-1 item-center" style={{ cursor: 'pointer' }} onClick={() => renameLayoutCustom()}>
                                    <IconSaveSecondaryDark />
                                </div>
                                <div className="icon ml-1 item-center" style={{ cursor: 'pointer', fontSize: "16px" }} onClick={() => cancelLayoutCustom()}>
                                    <i class="fal fa-times"></i>
                                </div>
                            </>
                            :
                            <div className="icon ml-1 img-layout-setting item-center" style={{ cursor: 'pointer' }} onClick={() => editLayoutCustom()} >
                                <EditIcon />
                            </div>
                        }
                    </div>

                </div>}
            <CustomScrollbars className='form-tick-layout' autoHeightMax={isRename ? "calc(100% - 90px)" : "calc(100% - 50px)"} >
                <div>
                    {sections.map((section, index) => {
                        if (!section.desktopVisible || !section.collapsible) {
                            return null;
                        }
                        let isDisabled = false
                        if (section.isPermission) {
                            isDisabled = !isLoggedIn
                        }
                        const Icon = getWidgetIcon(section);
                        const checked = visibleSectionIds && visibleSectionIds.includes(section.id);
                        const onChecked = () => { onSetSectionVisible(section, !checked, isDisabled) };
                        const _titleId = ListDataMapKeyByLanguage.listDataTitleWidget(section.id)

                        return (
                            <div key={section.id} className='row-select-input'>



                                <div className={"row-select-input-wrap " + (isDisabled ? "disabled-row" : "")}>

                                    <label id={section.id} className='container-checkbox container-checkbox-content droppable-element' htmlFor={index}
                                        draggable={isDragDropSetting}
                                        unselectable="on"
                                        // this is a hack for firefox
                                        // Firefox requires some kind of initialization
                                        // which we can do by adding this attribute
                                        // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                                        // onDragStart={e => e.dataTransfer.setData("text/plain", "")}
                                        onDragStart={e => (isDragDropSetting && !checked) && onDragStart(e, section.id)}
                                        onDragEnd={e => (isDragDropSetting) && onDragEnd(e, section.id)}
                                    >
                                        <input
                                            id={index}
                                            className={"checkbox"}
                                            type="checkbox"
                                            checked={visibleSectionIds && visibleSectionIds.includes(section.id)}
                                            onChange={onChecked}
                                        // disabled={isDisabled}
                                        />
                                        <span className="checkmark"></span>
                                        <span className="title-check-box txt---400-14-20"><FormattedMessage id={_titleId} /></span>
                                        {/* <div
                                            className={`droppable-element`}
                                            draggable={isDragDropSetting}
                                            unselectable="on"
                                            // this is a hack for firefox
                                            // Firefox requires some kind of initialization
                                            // which we can do by adding this attribute
                                            // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
                                            // onDragStart={e => e.dataTransfer.setData("text/plain", "")}
                                            onDragStart={e => (isDragDropSetting && !checked) && onDragStart(e, section.id)}
                                            onDragEnd={e => (isDragDropSetting) && onDragEnd(e, section.id)}
                                        >
                                            <span className="title-check-box txt---400-14-20"><FormattedMessage id={_titleId} /></span>
                                        </div> */}
                                    </label>

                                    {/* <div className='input-checkbox item-center'>
                                        <input
                                            type="checkbox"
                                            checked={visibleSectionIds && visibleSectionIds.includes(section.id)}
                                            onChange={onChecked}
                                        />
                                    </div>
                                    <div className="widget-title" onClick={onChecked}>
                                        <FormattedMessage id={'trade.sections.titles.' + section.id} />
                                    </div> */}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </CustomScrollbars>
            <div className='d-flex flex-row footer-btn justify-content-between'>
                {isDelete && <button type="button" className="btn-footer txt---500-14-20 item-center bg-red text-custom"
                    onClick={() => deleteLayoutCustom()}>
                    <FormattedMessage id='edit-layout.btn-delete' />
                </button>}
                {isRefresh && <button type="button" className="btn-footer txt---500-14-20 item-center bg-primary text-custom"
                    onClick={() => updateLayoutCustom()}>
                    <FormattedMessage id='edit-layout.btn-refresh' />
                </button>}
                {!isRefresh &&
                    < button type="button" className="button-reset-layout txt---500-14-20 item-center bg-primary text-custom"
                        onClick={(e) => resetLayoutCustom(e)}>
                        <FormattedMessage id='edit-layout.btn-reset' />
                    </button>
                }
                {/* {isRefresh && isDelete ?
                    < button type="button" className="btn-footer txt---500-14-20 item-center bg-primary"
                        onClick={(e) => resetLayoutCustom(e)}>
                        <FormattedMessage id='edit-layout.btn-reset-trade' />
                    </button>
                    :
                    < button type="button" className="button-reset-layout txt---500-14-20 item-center bg-primary"
                        onClick={(e) => resetLayoutCustom(e)}>
                        <FormattedMessage id='edit-layout.btn-reset' />
                    </button>
                } */}
            </div>
        </div >
    )
}



const mapStateToProps = state => {
    const currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    const layoutsCustom = state.layout.layoutsCustom
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        currentLayoutPageActive: currentLayoutPageActive,
        layoutsCustom: layoutsCustom,
        role: state.user.userInfo.role,
        defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteLayoutCustom: (OBJLAYOUT, from) => dispatch(actions.deleteLayoutCustom(OBJLAYOUT, from)),
    };
};
// export default LayoutPage
export default connect(mapStateToProps, mapDispatchToProps)(LayoutPageSetting);

