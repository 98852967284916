import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'reactstrap';
import * as actions from "../../store/actions";
import { batch, connect } from 'react-redux';
import { KeyCodeUtils } from "../../utils";
import JSXUtils from '../../utils/JsxUtils';
import DraggableWrapper from '../DraggableWrapper';
import _ from 'lodash';

import "./DraggableModal.scss"

class DraggableModal extends Component {
    constructor(props) {
        super(props);
        this.draggableWrapper = React.createRef();
        this.state = {
            timeOpen: 0
        }
    };

    isCheckCloseModalByKey = () => {
        let { listTimeOpen, setIsOpenModalHaveData } = this.props
        const { timeOpen } = this.state
        if (listTimeOpen && listTimeOpen.length > 0) {
            const lastTime = listTimeOpen[listTimeOpen.length - 1]
            if (lastTime && lastTime == timeOpen) {
                return true
            }
        } else {
            return true
        }
        return false
    }

    componentDidMount() {
        let { listTimeOpen, setIsOpenModalHaveData } = this.props
        document.addEventListener('keydown', this.handlerKeyDown);
        const time = new Date().getTime();
        this.setState(({ timeOpen: time }))
        document.addEventListener('keydown', this.handlerKeyDown);
        listTimeOpen.push(time)
        setIsOpenModalHaveData("TimeOpenModal", {
            listTimeOpen
        })
    }

    componentWillUnmount() {
        let { listTimeOpen, setIsOpenModalHaveData } = this.props
        const { timeOpen } = this.state
        listTimeOpen = _.filter(listTimeOpen, item => item !== timeOpen)
        setIsOpenModalHaveData("TimeOpenModal", {
            listTimeOpen
        })
        document.removeEventListener('keydown', this.handlerKeyDown);

    }

    checkboundUpper = () => {
        this.draggableWrapper && this.draggableWrapper.current && this.draggableWrapper.current.checkboundUpper();
    }

    handlerKeyDown = (event) => {
        const { onClose, onEnter } = this.props;
        const keyCode = event.which || event.keyCode;
        switch (keyCode) {
            case KeyCodeUtils.ESCAPE:
                setTimeout(() => {
                    if (this.isCheckCloseModalByKey()) {
                        event.preventDefault();
                        onClose();
                    }
                }, 100)
                break;
            case KeyCodeUtils.ENTER:
                event.preventDefault();
                if (onEnter) {
                    onEnter();
                }
                break;
            default:
                break;
        }
    };


    render() {
        const { isOpen, toggle, onClose, className, headerClass } = this.props;
        const { titleId, titleIdCheck, styleTitle, customHeader, isNotShowHeader } = this.props;

        let headerContainerClass = headerClass ? JSXUtils.joinClass("dragHandler", headerClass) : JSXUtils.joinClass("dragHandler", "header");
        let containerClass = JSXUtils.joinClass(className, 'draggable-modal');
        return (
            <DraggableWrapper
                ref={this.draggableWrapper}
                dragClass=".dragHandler"
                wrapperClass="modal-content"
            >
                <Modal
                    backdrop={"static"}
                    keyboard={false}
                    isOpen={isOpen}
                    toggle={this.toggle}
                    className={containerClass}
                    centered={true}
                    autoFocus={false}
                    fade={false}
                >
                    {!isNotShowHeader && (
                        <div className={headerContainerClass}>
                            {customHeader ? customHeader() : <div className={'title-modal txt---700-14-20 ' + styleTitle}>
                                {titleIdCheck ?
                                    titleId
                                    :
                                    <FormattedMessage id={titleId} />
                                }
                            </div>}
                            <div className="btn-icon-fm" onClick={onClose}><i className="fal fa-times" /></div>
                        </div>
                    )}
                    {this.props.children}
                </Modal >
            </DraggableWrapper >
        )
    };
};


const mapStateToProps = state => {
    return {
        listTimeOpen: state.layout.listIsOpenModal["C&B"].TimeOpenModal.listTimeOpen || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DraggableModal)

