import React, { Component } from 'react';
import { Fragment } from 'react';
import { getPriceColorClassName } from "./NumberFormatCustom";

const getSymbolColorClassName = (instrument) => {
    try {
        if (instrument == null) {
            return '';
        }
        let change = 0
        if (instrument.CP != 0 && instrument.CP != undefined) {
            if (instrument.RE != 0 && instrument.RE != undefined) {
                change = parseFloat(instrument.CP) - parseFloat(instrument.RE);
            }
            if (instrument.CP === instrument.CL) {
                return 'text-ceil-price';
            }

            if (instrument.CP === instrument.FL) {
                return 'text-floor-price';
            }
        }

        if (change < 0) {
            return 'text-red';
        }
        if (change > 0) {
            return 'text-green';
        }

        return 'text-ref-price';
    } catch (e) {
        return 'text-ref-price';
    }
};

/**
 * Lấy class màu cho mã TPRL (theo màu giá gần nhất)
 * @returns 
 */
const getSymbolCorpBondColorClassName = (instrument) => {
    if (instrument.PMP) {
        return getPriceColorClassName(instrument.PMP, { ...instrument, CL: instrument['CE'] }); // Giá khớp của TPRL là giá gần nhất, CL là CE
    } else {
        return ''
    }
}

class FormattedSymbol extends Component {

    render() {
        const { value, instrument, className, warningContent, isCorpBondPriceBoard, ...otherProps } = this.props;
        const classes = className ? [className] : [];
        let colorClass = getSymbolColorClassName(instrument);
        if (isCorpBondPriceBoard) {
            colorClass = getSymbolCorpBondColorClassName(instrument);
        }
        classes.push(colorClass);
        return (
            <Fragment>
                <span className={classes.join(' ')} {...otherProps}>{value}</span>
                &nbsp;{warningContent && (<i className={"fas fa-star " + colorClass} style={{ fontSize: 7, verticalAlign: 'super' }}></i>)}
            </Fragment>
        );
    }
}

export default FormattedSymbol;