import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import {LoadStatus} from "../utils";

import './RetryableContent.scss';

class RetryableContent extends Component {

    render() {
        const { className, children, onRetry, status, disableRetry, ...otherProps } = this.props;
        const classes = className ? [className] : [];
        classes.push('retryable-content');

        if (status === LoadStatus.LOADING) {
            classes.push('loading');
        } else if (status === LoadStatus.FAIL) {
            classes.push('fail');
        }

        if (disableRetry) {
            classes.push('disable-retry');
        }

        return (
            <div className={ classes.join(' ') } {...otherProps}>
                { React.Children.map(children, child => {
                    return React.cloneElement(child, {
                        className: child.props.className ? child.props.className + ' retryable-content-body' : 'retryable-content-body'
                    })
                }) }
                { !disableRetry && (
                    <div className="retryable-content-retry">
                        <div className="wrapper">
                            <div className="message">
                                <FormattedMessage id="common.fail-to-load-data"/>
                            </div>
                            <div className="action">
                                <button className="btn btn-info link" onClick={ onRetry }>
                                    <FormattedMessage id="common.retry"/>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default RetryableContent;