import React, { Component } from 'react';
import FitText from "../../components/FitText";


import NumberFormatCustom from "./NumberFormatCustom";
import { getDiffColorClassName, getPriceColorClassName } from "./NumberFormatCustom";

class FormattedPriceDiff extends Component {

    static defaultProps = {
        withIntrinsicColor: true
    };

    renderfitText = (formattedValue) => {
        const { value, withIntrinsicColor, instrument, colorCalcValue } = this.props;
        return (
            <span className={withIntrinsicColor ? getDiffColorClassName(value) : getPriceColorClassName(colorCalcValue != null ? colorCalcValue : value, instrument)}>
                {/* Giá trị chỉ được clear khi value = null (Nếu value= undefined ===> formattedVolume là giá trị cũ) */}
                {/*formattedVolume && value && value !== 0 ? formattedVolume.substr(0, formattedVolume.length - 1) : null*/}
                <FitText>
                    {formattedValue}
                </FitText>
            </span>
        );
    };

    render() {
        const { value, withIntrinsicColor, instrument, colorCalcValue, shortForm, decimalScale, digit, suffix, prefix, isFormatZero, fitText } = this.props;
        // if (instrument && instrument['Id'] === 'AAA') {
        //     //console.log('laojahackgame========> CHECK DATA AAAA', { instrument, value, isFormatZero, valueToDisplay: decimalScale && value || (shortForm && value ? (value / 1000).toFixed(digit ? digit : 2) : value) });
        // }
        // if (!value && isFormatZero) {
        //     return (
        //         <NumberFormat
        //             value={decimalScale || (shortForm && value ? (value / 1000).toFixed(digit ? digit : 2) : value.toFixed(digit ? digit : 2))}
        //             displayType={'text'}
        //             renderText={fitText ? this.renderfitText : null}
        //             decimalScale={decimalScale && decimalScale || (shortForm ? 3 : 2)}
        //             fixedDecimalScale={false}
        //             thousandSeparator={','}
        //             decimalSeparator={'.'}
        //             suffix={suffix ? suffix : ""}
        //             prefix={prefix ? prefix : ""}
        //             className={withIntrinsicColor ? getDiffColorClassName(value) : getPriceColorClassName(colorCalcValue != null ? colorCalcValue : value, instrument)}
        //         />
        //     )
        // }

        // return (
        //     <NumberFormat
        //         value={decimalScale && value || (shortForm && value ? (value / 1000).toFixed(digit ? digit : 2) : value)}
        //         displayType={'text'}
        //         renderText={fitText ? this.renderfitText : null}
        //         decimalScale={decimalScale && decimalScale || (shortForm ? 3 : 2)}
        //         fixedDecimalScale={false}
        //         thousandSeparator={','}
        //         decimalSeparator={'.'}
        //         suffix={suffix ? suffix : ""}
        //         prefix={prefix ? prefix : ""}
        //         className={withIntrinsicColor ? getDiffColorClassName(value) : getPriceColorClassName(colorCalcValue != null ? colorCalcValue : value, instrument)}
        //     />
        // );

        return (
            <NumberFormatCustom
                value={value}
                displayType={'text'}
                decimalScale={decimalScale}
                fixedDecimalScale={false}
                thousandSeparator={','}
                decimalSeparator={'.'}
                suffix={suffix}
                prefix={prefix}
                fitText={fitText}
                withIntrinsicColor={withIntrinsicColor}
                colorCalcValue={colorCalcValue}
                digit={digit}
                isFormatZero={isFormatZero}
                renderfitText={this.renderfitText}
                shortForm={shortForm}
                instrument={instrument}
                {...this.props}
            />
        );
    }
}

export default FormattedPriceDiff;
