import fastdom from 'fastdom';
import _ from 'lodash';
import React, { Component } from 'react';

import './ValueChangeAnimation.scss';

class ValueChangeAnimation extends Component {

    static defaultProps = {
        showIsNew: true,
        dependencies: []
    };

    isOldTimeout = null;
    node = null;
    nodeRef = element => {
        this.node = element;
    };

    setIsOldTimeout = () => {
        if (this.isOldTimeout) {
            clearTimeout(this.isOldTimeout);
            this.isOldTimeout = null
        }
        this.isOldTimeout = setTimeout(() => {
            this.animID = fastdom.mutate(() => {
                if (this.node) {
                    this.node.classList.remove("isNew");
                    this.animID = null;
                }
            });
            clearTimeout(this.isOldTimeout)
            this.isOldTimeout = null
        }, 5000);
    };

    showIsNew = (newVal, oldVal) => {
        const oldNumber = "number" === typeof oldVal ? oldVal : parseFloat(oldVal);
        const newNumber = "number" === typeof newVal ? newVal : parseFloat(newVal);
        const isNumber = Number.isFinite(oldNumber) && Number.isFinite(newNumber);
        const isInc = isNumber && newNumber > oldNumber;
        this.animID = fastdom.mutate(() => {
            if (this.node) {
                const node = this.node;
                if (node.classList.contains("isNew")) {
                    node.classList.add("isNewDisable");
                    this.animFrameID = requestAnimationFrame(function () {
                        node && node.classList.remove("isNewDisable")
                    });
                } else {
                    node.classList.add("isNew");
                }
                if (isNumber) {
                    const classToAdd = isInc ? "highlightInc" : "highlightDec";
                    const classToRemove = isInc ? "highlightDec" : "highlightInc";
                    node.classList.remove(classToRemove);
                    node.classList.add(classToAdd);
                }
                this.setIsOldTimeout();
                this.animID = null
            }
        })
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.showIsNew) {
            if (this.props.value !== nextProps.value && (!isNaN(this.props.value) && !isNaN(nextProps.value))) {
                if (this.animID) {
                    fastdom.clear(this.animID);
                }
                this.showIsNew(nextProps.value, this.props.value);
            }
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true;
    }

    shouldComponentUpdate(nextProps) {
        const props = this.props;
        return props.value !== nextProps.value
            || props.onClick !== nextProps.onClick
            || props.showIsNew !== nextProps.showIsNew
            || _.difference(props.dependencies, nextProps.dependencies).length > 0
            // || props.isRender
            || props.isRender == true
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.animID) {
            fastdom.clear(this.animID);
        }
        if (this.animFrameID) {
            cancelAnimationFrame(this.animFrameID)
        }
    }

    render() {
        // Value, dependencies props just used to compare
        // noinspection JSUnusedLocalSymbols
        const { tag, className, onClick, showIsNew, value, dependencies, children, colSpan, ...otherProps } = this.props;
        if (tag === 'td') {
            return (
                <td className={className} onClick={onClick} colSpan={this.props.colSpan} ref={showIsNew ? this.nodeRef : null} {...otherProps}>
                    {children}
                </td>
            );
        }
        if (tag === 'span') {
            return (
                <span className={className} onClick={onClick} colSpan={this.props.colSpan} ref={showIsNew ? this.nodeRef : null} {...otherProps}>
                    {children}
                </span>
            );
        }
        return (
            <div className={className} onClick={onClick} ref={showIsNew ? this.nodeRef : null} {...otherProps}>
                {children}
            </div>
        );
    }
}

export default ValueChangeAnimation;