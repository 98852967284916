// import * as TimeService from '../services/time.service';

class Timer {
	constructor() {
		this.time = new Date().getTime();
	}

	setTime(time) {
		this.time = time;
	}

	getTime() {
		return this.time;
	}

	clock() {
		setInterval(() => {
			this.time += 5000;
		}, 5000);
	}
	toTimestamp(strDate) {
		var datum = Date.parse(strDate);
		return datum / 1000;
	}
	formatDate_YYYYMMDD() {
		var d = new Date(),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = '' + d.getFullYear();
		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;
		return [year, month, day].join('-');
	}

}

let _timer = new Timer();

// TimeService.init(currentTime => {
// 	_timer.setTime(currentTime);
// 	_timer.clock();
// });

export const timer = _timer;
